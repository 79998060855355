import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from '../document.svg'
import ResponseModal from './ResponseModal'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import Download from '../Group 3238.svg'
import { RecordVoiceOverRounded } from "@material-ui/icons";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {openOnboardingModal, setOpenOnboardingModal, showToast, updateMyData } = props
  const [excel, setExcel] = useState(null)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [name, setName] = useState("")
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [invalidData, setInvalidData] = useState()
  const [fileLink, setFileLink] = useState(null)
  const [groupflexId,setGroupflexId]=useState(null)
  const [responseData, setResponseData] = useState()
  // console.log({excel})
  const handleClose = () => {
    setOpenOnboardingModal(false);
  };
  const handleUpload=(event) =>{
    const fileName=event.target.files[0].name.split(".").slice(-1)[0];
    if(fileName.toLowerCase()==="xlsx" || fileName.toLowerCase()==="xls"){
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name)
      setExcelError(false)
    }
    else{
      setExcelError(true)
      setExcel(null);
      setName("");
    }
    setResponseData();

  }
  const createInvalidData =(records,summary) =>{
    // console.log({records})
    if(records.length>1){
      let list=[];
      records.map(record =>{
        if(record.hasOwnProperty("WorkerDetail")){
          // console.log({record});
          if(record.WorkerDetail.isWorkerCreated===0){
            let firstName=""
            let lastName=""
            if(record.WorkerDetail.workerName){
              var i = record.WorkerDetail.workerName.indexOf(' ');
              console.log(i)
              if(i>0){
                firstName = record.WorkerDetail.workerName.slice(0, i).trim();
                lastName = record.WorkerDetail.workerName.slice(i + 1, record.WorkerDetail.workerName.length).trim();
              }else{
                firstName =record.WorkerDetail.workerName;
              }
            } 
            if(record.WorkerDetail.isWorkerCreated!==1){
              list.push({
                "First Name":firstName || "",
                "Last Name": lastName || "",
                "Mobile No":record.WorkerDetail.mobileNo || "",
                "Aadhaar No":""+record.WorkerDetail.adhaarNo || "",
                "Source by": record.WorkerDetail.sourceBy==="VENDOR"?"Vendor":record.WorkerDetail.sourceBy==="TA"?"HRBP/TA":record.WorkerDetail.sourceBy==="EREF"?"Employee reference": record.WorkerDetail.sourceBy,
                "Vendor Name/Emp id/HRBP or TA id ":record.WorkerDetail.sourceByName || "",
                "Hub Name":record.WorkerDetail.hubName || "",
                "Designation":record.WorkerDetail.designation || "",
                "Rate Card":record.WorkerDetail.rateCardType || "",
                "Joining Date":record.WorkerDetail.dateOfJoining?moment(record.WorkerDetail.dateOfJoining).format("DD/MM/YYYY"):"",
                "EDAB":record.WorkerDetail.physicallyDisabled===1?"Yes":record.WorkerDetail.physicallyDisabled===0?"No":"",
                "Groupflex ID":record.WorkerDetail.groupflexId ||"",
                "Reason for failure":record.WorkerDetail.uploadStatus || ""
              })
            }
          }
        }
        return true;
      })
      return list.length>0?list:null;
    }
  }
  const handleExcel = () =>{
    setIsLoading(true)
    let formData = new FormData();
    formData.append("file", excel);
    let api=`${UrlLinks.baseUrl}${UrlLinks.workerUrls.uploadGroupFlexExcel}${localStorage.getItem("userID")}`;
    if(groupflexId!==null){
      api=api+`&groupflexId=${groupflexId}`;
    }
    Axios.post(api,formData ,{
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(response =>{
      // console.log({response});
      if(response.data){
        const summary=response.data.slice(-1)[0];
        setInvalidData(createInvalidData(response.data,summary));
        console.log({summary})
        setResponseData(summary);
        setGroupflexId(summary.GroupflexId)
        updateMyData();
        setIsLoading(false)
      }
      else{
        setResponseData([]);
      }
    })
    .catch(error =>{
      // console.log({error})
      setIsLoading(false)
      showToast("error", error.message);
    })
  }
  useEffect(() => {
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.groupFlexExcel}`,null , {timeout:60*5*1000})
      .then(response =>{
        // console.log({response})
        setFileLink(response.data)
      })
      
  }, [])
  useEffect(() => {
    if(responseData){
      setOpenResponseModal(true);
    }
  }, [responseData])
  let dialogData=
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openOnboardingModal}
            className={Styles.onboardingDialog}
            classes={{paper: Styles.paper}}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <span>Upload Groupflex Data</span> <a
                  href={fileLink}
                  target="_blank"
                  className={Styles.downloadTemplate}
                >
                  <span>Download File Template</span><img src={Download} alt="download" />
                </a>
            </DialogTitle>
            <DialogContent dividers>
              {/* <RadioGroup  adioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel label="New workers" control={<Radio color="primary" />} value="new" />
                  <FormControlLabel label="Existing workers" control={<Radio color="primary" />} value="existing" />
              </RadioGroup> */}
              <Grid item xs={12} className="padding0">
                
                  <input
                      style={{display:"none"}}
                      id="contained-button-file"
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleUpload}
                  />
                  <label htmlFor="contained-button-file">
                      <Button 
                          variant="outlined" 
                          className= {Styles.uploadButton}
                          component="span"
                          startIcon= {<img src={document} alt={"upload"} style={{color:"white"}} />}
                      >
                        Browse File
                      </Button>
                  </label>
                  {name && <span className={Styles.fileName}><span>File name: </span>{`${name}`}</span>}
              </Grid>
              <Grid item xs={12} className="padding0">
                <span className={`fontSize13 ${excelError?"redColor":""}`}>only .xls and .xlsx are allowed</span>
              </Grid>
            </DialogContent>
            <DialogActions>
              {isLoading?<span>Please wait... it may take few minutes</span>:<Button variant="contained" onClick={handleExcel} className={`${!excel?"disabledButton":"blueButtonContained"}`} disabled={!excel}>
                Upload
              </Button>}
            </DialogActions>
            {openResponseModal && <ResponseModal
              openResponseModal={openResponseModal}
              setOpenResponseModal={setOpenResponseModal}
              responseData={responseData}
              setOpenOnboardingModal={setOpenOnboardingModal}
              showToast={showToast}
              invalidData={invalidData}
              updateMyData={updateMyData}
              agreement={true}
              type="groupflex"
            />}
        </Dialog>
  
  

  return dialogData; 
  
}
