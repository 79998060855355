import React, { useState, useEffect } from 'react'
import { FormControl, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'
import * as moment from "moment"
import Axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';
import style from './index.module.scss'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import AutoSelectFilter from './FilterComponents/AutoSelectFilter';
import ScaleLoader from "react-spinners/ScaleLoader";
import SelectFilter from './FilterComponents/SelectFilter'
import DateFnsUtils from '@date-io/date-fns';
import FormLabel from '@material-ui/core/FormLabel';
import urlLinks from '../../UrlLinks'
import FacilitySearch from './FilterComponents/FacilitySearch'

const FilterDrawer = (props) => {
    const { handleClose, resetFilters, applyFilters, filters, setFilters } = props;
    const [workerPartners, setWorkerPartners] = useState([])
    const [hubList, setHubList] = useState([])
    const [hubFacilityList, sethubFacilityList] = useState([])
    const [hubstateList, sethubstateList] = useState([])
    const [hubCityList, sethubCityList] = useState([])
    const [shiftStatusList, setShiftStatusList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const handleCancelFilters =() =>{
        // resetFilters();
        handleClose();
    }
    const workerStatusData=[
        {
            id: 10,
            name: "1 employer"
        },
        {
            id: 0,
            name: "more than one"
        }
    ] 

    const jobStatus = [
        { id:1, name:"Active"},
        { id:2, name:"Closed"}
    ]
    const clientPartners=[
        {
            id: 100,
            name: "Flipkart"
        },
        {
            id: 101,
            name: "Swiggy"
        },{
            id: 103,
            name: "Zomato"
        }
    ]
    // // console.log({filters})
    const loader=<div style={{margin:"130px auto"}}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}    
        />
    </div>
    const makeWorkerPartnerData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.partner_id,
                name: el.company_name
            })
            return true
        })
        return list
    }
    const makeShiftStatusData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.typeValue,
                name: el.typeDesc
            })
            return true
        })
        return list
    }
    const makeHubData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }

    const makeHubCityData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    const makeHubStateData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    const handleWorkingFor =(event) =>{
        setFilters({...filters, [event.target.name]: event.target.value})
    }
    useEffect(() => {
        if(workerPartners.length>0 && shiftStatusList.length>0){
            setIsLoading(false)
        }
    }, [workerPartners, shiftStatusList])
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`)
            .then(response =>{
                console.log({response})
                setWorkerPartners(makeWorkerPartnerData(response.data.response))
            })
        
        Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.jobAreas}`)
        .then(response =>{
            sethubFacilityList(makeHubData(response.data.response)) 
        })

        Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.jobCities}`)
        .then(response =>{
            sethubCityList(makeHubCityData(response.data.response))
        })


        Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.shiftStatusList}`)
        .then(response =>{
            setShiftStatusList(makeShiftStatusData(response.data.response.content))
        })
    }, [])
    const output=isLoading?<Grid container style={{ width: "520px", marginTop: '20px' }}>{loader}</Grid>:<Grid container style={{ width: "520px", marginTop: '20px' }} className={style.FilterContainer}>
        <Grid container className={`${style.FilterHeadingContainer}`}>
            <div className={style.flexBaseLine}>
                <Button onClick={handleClose} className={style.filterCloseArrow}>
                    <ArrowForwardIcon />
                </Button>
                <span className={style.filterHeading}>Filters</span>
            </div>
            <div>
                <Button
                    onClick={resetFilters}
                    className={style.resetFiltersButton}
                >
                    Reset
                </Button>
            </div>
        </Grid>
        <div className={style.filterHeadingDivider}></div>
        <Grid container className={style.filtersContainer}>
            
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Client
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="partnerId"
                    options={workerPartners}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="jobStatus"
                    options={jobStatus}
                />
            </Grid>
            
              {/* <Grid item xs={12} className={`padding0 ${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Working for
                </span>
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="workFor" value={filters["workFor"]} onChange={handleWorkingFor}>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="1 Employer" />
                        <FormControlLabel value="2" control={<Radio color="primary" />} label="More than one" />
                    </RadioGroup>
                </FormControl>
            </Grid>   */}

            {/* <Grid item xs={12} className={style.singleFilterContainer}>
                <span className={style.filterTitle}>
                    Shift status
                </span>
                <SelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multiple={false}
                    id="shiftStatus"
                    optionList={shiftStatusList}
                />
            </Grid> */}
            {/* <Grid item xs={12} className={style.singleFilterContainer}>
                <span className={style.filterTitle}>
                    Working for
                </span>
                <SelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multiple={false}
                    id="workerStatus"
                    optionList={workerStatusData}
                />
            </Grid> */}
            {/* <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Area
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="area"
                    options={hubFacilityList}
                />
            </Grid> */}
            
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    City
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="facilityCity"
                    options={hubCityList}
                />
            </Grid>

        </Grid>
        <Grid container className={style.filterButtonDividerContainer}>
            <div className={style.filterHeadingDivider}></div>
            <Grid container style={{ marginTop: '30px' }} className='padding0'>
                <div style={{
                    paddingRight: '16px',
                    paddingLeft: '300px'
                }}>  
                    <Button variant="outlined" onClick={handleCancelFilters} size="medium" color="primary" >
                        Cancel
                    </Button>
                </div>
                <Button variant="contained" size="medium" color="primary" onClick={()=>{applyFilters(); handleClose()}} className={style.PrimaryColorButton}>Apply</Button>
                
            </Grid>
        </Grid>
    </Grid >
    return output;
}

export default FilterDrawer;