import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import UploadReload from './uploadReuploadComponent'
import document from '../UploadButtonIcon.png'
import ResponseModal from './ResponseModal'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import Download from '../Group 3238.svg'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
    
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {open, close, data, showToast, updateMyData } = props
  const [DocData, setDocData] = useState({})
  const [uploadedDocs, setUploadedDocs] = useState({})
  const [disableSave, setDisableSave] = useState(true)
  const [docUpdated, setDocUpdated] = useState(false)
  const [saveDisabledText, setSaveDisabledText] = useState("Save")
  useEffect(() => {
    const found= Object.values(uploadedDocs).find(doc => doc.docId)
    if(found ){
      setDisableSave(false)
    }
    else{
      setDisableSave(true)
    }
    if(uploadedDocs.panNumber && uploadedDocs.panNumber.value.length!==10 && uploadedDocs.panNumber && data.panNumber.toLowerCase()===uploadedDocs.panNumber.value.toLowerCase()){
      setDisableSave(true)  
    }
    console.log({uploadedDocs})
  }, [uploadedDocs])
  const updatePanNumber =() =>{
    if(uploadedDocs.panNumber && data.panNumber.toLowerCase()!==uploadedDocs.panNumber.value.toLowerCase()){
      Axios.get(`${UrlLinks.baseUrl}workerdata/updateGSTFlag?pan_number=${uploadedDocs.panNumber.value}&user_id=${data.userId}`)
        .then(response =>{
          setDocUpdated(true)
        })
    }
  }
  const handleSaveDocs = () =>{
    setDisableSave(true)
    setSaveDisabledText("Please wait...")
    updatePanNumber();
    let apiList=[]
    console.log(Object.values(uploadedDocs))
    Object.values(uploadedDocs).forEach(doc =>{
      if(doc.docId && doc.docId!=="panNumber"){
        let formData = new FormData();
        formData.append("file", doc.file);
        apiList.push(
          Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.uploadDocLink}${data.userId}&doc_id=${doc.docId}`,formData ,{
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
        )
      }
    })
    Axios.all(apiList)
      .then(response  =>{
        console.log({response});
        apiList=[]
        response.forEach(doc =>{
          const linkData =doc.data.response.split("/")
          apiList.push(
            Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.uploadDocument}${localStorage.getItem("userID")}&document_type_id=${linkData[2]}&file_name=${linkData[3]}&file_path=${doc.data.response}&user_id=${linkData[1]}`,{
              headers: {
                "Content-Type": "multipart/form-data",
              }
            })
          )
        })
        
        Axios.all(apiList)
          .then(res =>{
            setSaveDisabledText("Save")
            setDocUpdated(true)
            updateMyData();
            close();
          })
      })
  }
  useEffect(() => {
    if(docUpdated){
      showToast("success","Documents uploaded successfully");
    }
  }, [docUpdated])
  
  const GetDocData = () => {
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.docList}${data.userId}`)
      .then(response =>{
        console.log({response})
        let tempData={}
        response.data.response.content.forEach(doc => {
          let documentData={
            fileUrl: doc.file_path,
            fileName: `${data.okaygoId}_${doc.file_name.split("?")[0] }`
          }
          if(doc.document_type_id===1869){
            tempData["addressProof"]=documentData
          }
          if(doc.document_type_id===1866){
            tempData["panCard"]=documentData
          }
          if(doc.document_type_id===1929){
            tempData["aadhaarBack"]=documentData
          }
          if(doc.document_type_id===1867){
            tempData["aadhaar"]=documentData
          }
          if(doc.document_type_id===1870){
            tempData["dl"]=documentData
          }
        })
        setDocData({...tempData})
        setUploadedDocs({...tempData})
      })
  }
  useEffect(() => {
    GetDocData();
  }, [])
  useEffect(() => {
    console.log({DocData});
  }, [DocData])
  let dialogData=
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            className={Styles.onboardingDialog}
            classes={{paper: Styles.paperUploadDocs}}
        >
            <DialogTitle id="customized-dialog-title" onClose={close}>
                <span>Documents</span> 
            </DialogTitle>
            <DialogContent dividers>
              <table className={Styles.DocTable}>
                <UploadReload
                  name={"Aadhaar Front"}
                  type="aadhaar"
                  uploadedDocs={uploadedDocs}
                  setUploadedDocs= {setUploadedDocs}
                  DocData={DocData}
                />
                <UploadReload
                  name={"Aadhaar Back"}
                  type="aadhaarBack"
                  uploadedDocs={uploadedDocs}
                  setUploadedDocs= {setUploadedDocs}
                  DocData={DocData}
                />
                <UploadReload
                  name={"Driving License"}
                  type="dl"
                  uploadedDocs={uploadedDocs}
                  setUploadedDocs= {setUploadedDocs}
                  DocData={DocData}
                />
                <UploadReload
                  name={"Pan Card"}
                  type="panCard"
                  uploadedDocs={uploadedDocs}
                  setUploadedDocs= {setUploadedDocs}
                  DocData={DocData}
                  value={data.panNumber}
                />
                <UploadReload
                  name={"Address Proof"}
                  type="addressProof"
                  uploadedDocs={uploadedDocs}
                  setUploadedDocs= {setUploadedDocs}
                  DocData={DocData}
                />
              </table>
            </DialogContent>
            <DialogActions>
              {/* {isLoading?<span>Please wait... it may take few minutes</span>:<Button variant="contained" onClick={handleExcel} className={`${!excel?"disabledButton":"blueButtonContained"}`} disabled={!excel}>
                Upload
              </Button>} */}
              <button 
                onClick={handleSaveDocs}
                disabled={disableSave}
                className={Styles.SaveDocsButton}
              >
                {saveDisabledText}
              </button>
            </DialogActions>
        </Dialog>
  
  

  return dialogData; 
  
}
