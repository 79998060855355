import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import "./style.css"
import a from "./SS/2.jpg"
import b from "./SS/3.jpg"
import c from "./SS/4.jpg"
import d from "./SS/5.jpg"
import e from "./SS/6.png"
import f from "./SS/part.jpg"
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


function getModalStyle() {
  const top = 50 
  const left = 50 

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 250,
    height:500,
    overflowY:"auto",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const {open, setOpen} =props

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="stickHeader"><Button className="closeButton" onClick={handleClose}><CloseIcon></CloseIcon></Button></div>
      <h4  id="simple-modal-title">Step 1:</h4>
      <p>Open the menu by clicking the right button </p>
      <img alt="" style={{width: '100%',paddingLeft: "10px"}} src={b}/>
 
      <h4  id="simple-modal-title">Step 2:</h4>
      <p>Scroll down and click on <b>Settings</b></p>
      <img alt="" style={{width: '100%',paddingLeft: "10px"}} src={a}/>
     <h4  id="simple-modal-title">Step 3:</h4>
     <p>Choose <b>Site Settings</b></p>
      <img alt="" style={{width: '100%',paddingLeft: "10px"}} src={f}/>
     <h4  id="simple-modal-title">Step 4:</h4>
     <p>Choose <b>Location</b></p>
      <img alt="" style={{width: '100%',paddingLeft: "10px"}} src={c}/>
     <h4  id="simple-modal-title">Step 5:</h4>
      <p>In block section, choose <b>https:/eflex.okaygo.in</b></p>
      <img alt="" style={{width: '100%',paddingLeft: "10px"}} src={d}/>
      <h4  id="simple-modal-title">Step 6:</h4>
      <p>Click on<b> Allow</b></p>
      <img alt="" style={{width: '100%',paddingLeft: "10px"}} src={e}/>
      <h4  id="simple-modal-title">Step 7:</h4>
      <p>Close the <b>Settings</b> and press <b> Share Location button</b> again.</p>
 
      <SimpleModal />
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}