import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Axios from 'axios'
import { useDebounce } from "use-debounce";
import UrlLinks from "../../../UrlLinks";

export default function Asynchronous(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const {facilityData, setFacilityData} = props
    const [searchBy, setSearchBy] = useState("")

    const [debouncedText] = useDebounce(searchBy, 350);
    let loading = open && (options.length===0 && !debouncedText) ;
  const onChangeHandle = (value) => {
      setSearchBy(value)
  };
  
  const getOptionsData =() =>{
    if(debouncedText){

        Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.facilityList}${debouncedText}`)
        .then(response =>{
            setOptions(response.data.response)
        })
    }
  }
  console.log(options)
  useEffect(() => {
    getOptionsData()
  }, [debouncedText])


  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  // useEffect(() => {
  //   if(facilityData){
  //     // console.log("facility updating",{facilityData})
  //     setValues({...values, 
  //         facilityCity:"", 
  //         facilityState: "", 
  //         facilityName: ""  ,
  //         zone: "",
  //         facilityType: ""
  //     })
  //       setValues({...values, 
  //           facilityCity: facilityData.facilityCity, 
  //           facilityState: facilityData.facilityState, 
  //           facilityName: facilityData.facilityName  ,
  //           zone: facilityData.zone,
  //           facilityType: facilityData.facilityType
  //       })
  //   }
  //   else if(!facilityData){
  //     // console.log("facility deleting",{facilityData})
  //     setValues({...values, 
  //         facilityCity:"", 
  //         facilityState: "", 
  //         facilityName: ""  ,
  //         zone: "",
  //         facilityType: ""
  //     })
  // }
  // }, [facilityData])

  

  
  return (
    <Autocomplete
      id="FacilitySearch"
      value={facilityData}
      // freeSolo
      open={open}
      
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    //   getOptionSelected={(option, value) =>
    //     option.facilityName === value.facilityName
    //   }
      onChange={(event, value) => setFacilityData(value)}
      getOptionLabel={(option) => option.facilityName}
      options={options}
      loading={loading}
      renderInput={(params) => (
            <TextField
            {...params}
            variant="outlined"
            placeholder="Search and select facility"
            onChange={(ev) => {
                if (ev.target.value !== "" || ev.target.value !== null) {
                onChangeHandle(ev.target.value);
                }
            }}
            InputProps={{
                ...params.InputProps,
                style:{ width: 240, borderRadius: 0, padding:0, marginTop: "-6px"},
                endAdornment: (
                <React.Fragment>
                    {loading ? (
                    <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
                ),
                }}
            // inputProps={{
            //     style:{ padding:6 }
            // }}
        />
      )}
    />
  );
}
