import React, { useState, useEffect } from 'react'
import { TextField, MenuItem, IconButton, } from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import urlLinks from '../../UrlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';
import EditIcon from '@mui/icons-material/Edit';
// import ThreeDRotation from '@mui/icons-material/ThreeDRotation' 
 

const EditableTextInput = (props) => {
    const { row, column, setLoading, showToast, updateMyData } = props
    const initialValue = row.original[column.id]
    const statusUpdateUrl = `${urlLinks.baseUrl}${urlLinks.jobs.jobEditSave}`
    const [value, setValue] = useState(initialValue);
    const label = ""
    const [enableEdit,setEnableEdit] = useState(true)
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    // console.log({props},row.original.jobId ) 
    const updateStatus = () => {

        var parts = value.split('/');
        var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
        if (mydate < new Date()|| mydate === new Date()) {
            setLoading(false);
            setShowButtons(false)
            updateMyData()
            throw showToast("error", "Wrong Closing date");
        } else {
            setLoading(true)
            const postData = {
                recruiterMasterId: row.recruiterMasterId,
                jobId: row.original.jobId
            }
            // // console.log(row)
            Axios.post(`${urlLinks.baseUrl}${urlLinks.jobs.jobEditSave}?updated_by=${localStorage.getItem("userID")}&field=closingDate&value=${value}&job_id=${postData.jobId}`)
                .then(response => {
                    if (response.data.code === 1000) {
                        updateMyData();
                        setShowButtons(false)
                        setLoading(false);
                        console.log("Render start")
                        showToast("success", "Job Close date added successfully");
                        console.log("Rendered")
                    } else {
                        throw showToast("error", response.data.message);
                        setLoading(false);
                    }
                })
        }
    }
    // console.log(value.length)
        const updateCasper = () => {
           
            if(value.length>1){
                setLoading(true)
                Axios.post(`${urlLinks.baseUrl}${urlLinks.workerUrls.editEntity}casperId=${value}&insertedBy=${localStorage.getItem("userID")}&userId=${row.original.userId}`)
                    .then(response => {
                        if (response.data.code === 1000) {
                            showToast("success", "Casper Id updated Sucessfully");
                            updateMyData();
                            setShowButtons(false)
                            setLoading(false);
                        }
                        else if (response.data.code === 1002) {
                            updateMyData();
                            setShowButtons(false)
                            setLoading(false);
                            throw showToast("error", response.data.response);
                        } else {
                            setLoading(false);
                        }
                    })
            }else{
                setLoading(false);
                throw showToast("error", "Please enter Casper ID"); 
            }
        }
    
    const handleConfirm = () => {
        setLoading(true)
        updateStatus();
    }
    const handleConfirmCasper = () => {
        setLoading(true)
        updateCasper();
    }
    const handleCancel = () => {
        // // console.log({ initialValue })
        setValue(initialValue)
        setTimeout(() => {
            setShowButtons(false)

        }, 200);
    }

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    return (
        <div>
           { !enableEdit? <TextField
                id="standard-basic"
                disableUnderline
                value={value}
                // disabled={enableEdit}
                onChange={onChange}
                className="borderStd margin0"
                placeholder={column.Header==="Casper id"?"":"DD/MM/YYYY"}
                multiline={true}
                InputProps={{
                    disableUnderline: true
                }}
            />:value}
           <IconButton style={{paddingTop:"7px"}} sizeSmall color={enableEdit?"default":"primary"} onClick={()=>{setEnableEdit(!enableEdit)}}><EditIcon sty  /></IconButton>
            {showButtons &&
                <div>
                    <IconButton color="primary" onClick={()=>{
                        if(column.Header==="Casper id"){
                            // // console.log("DID NOTHING")
                            handleConfirmCasper()
                        }else{
                            handleConfirm()
                        }
                    }}>
                        <DoneSharpIcon />
                    </IconButton>
                    <IconButton sizeSmall color="secondary" onClick={handleCancel} >
                        <CloseSharpIcon />
                    </IconButton>
                </div>}
        </div>
    )
}

export default EditableTextInput;