import React from 'react'
import { Grid ,Divider} from '@material-ui/core'
import * as moment from 'moment'

function HRBPview(props) {
    const {data}=props
    // console.log({data})
    const sourceBy=data.sourceByName?`, ${data.sourceByName}`:""
    // const = ()=>{}
    const putComma=(val1,val2,val3)=>{
        let str=" "
        if (val1!==null&&val1!=="")
        str=str+val1+","
        if (val2!==null&&val2!=="")
        str=str+" "+val2+","
        if (val3!==null&&val3!=="")
        str=str+" "+val3+" "

        return str.substring(0,str.length-1)
    }
    return (
        <Grid container className="flexDisplay" direction ="column">
            <Grid item xs={6}  className="marginBottom40" >
                <span className="fontSize1_5rem fontWeightBold">Contract Details</span>
            </Grid>
            { data.sourceByName!=="" &&
            <div>
            <Grid item xs={12} className="flexDisplay " direction="row" style={{marginBottom:'8px',fontSize:"24px"}} >
                <Grid item xs={4}>
                    <span style ={{color:'#1c75c0'}} className="fontWeightBold">Client</span>
                </Grid>         
                <Grid item xs={8}>
                    <span style ={{color:'#1c75c0'}}>Flipkart</span>    
                </Grid>                
            </Grid>

            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Casper ID</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.casperId}</span>    
                </Grid>                
            </Grid>
            
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Source by</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{`${data.sourceByView}${sourceBy}`}</span>    
                </Grid>                
            </Grid>
                        
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility name,city,state</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{putComma(data.facilityName,data.facilityCity,data.facilityState)}
                        {/* {`${data.facilityName} ${data.facilityCity} ${data.facilityState}`} */}
                        </span>     
                </Grid>                
            </Grid>
            {/* <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility city</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.facilityCity}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility state</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.facilityState}</span>    
                </Grid>                
            </Grid> */}
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Zone facility type</span>
                </Grid>         
                <Grid item xs={8}>
                <span >   {putComma(data.zone,data.facilityType,null)}</span> 
                    {/* <span >{data.zone}, {data.facilityType}</span>     */}
                </Grid>                
            </Grid>
            
            {/* <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility type</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.facilityType}</span>    
                </Grid>                
            </Grid> */}
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Designation, Rate card</span>
                </Grid>         
                <Grid item xs={8}>
                <span >   {putComma(data.designation,data.rateCard,null)}</span> 
                    {/* <span>{data.designation}, {data.rateCard}</span>     */}
                </Grid>                
            </Grid>
            {/* <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Rate card</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.rateCard}</span>    
                </Grid>                
            </Grid> */}
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Date of joining</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.date_of_joining?moment(data.date_of_joining).format("DD/MM/YYYY"): ""}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" style={{height: "35px"}}>
                <Grid item xs={4}>
                    <span className="fontWeightBold ">Criminal record</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.criminalRecord}</span>    
                </Grid>                
            </Grid>
             <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold ">EDAB (physically disabled)</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.edab}</span>    
                </Grid>                
            </Grid> 
            {/* <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Cluster Name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.clusterName}</span>    
                </Grid>                
            </Grid> */}
            <Divider className="margin20" /> 
            </div>}
            
            {
                data.facilityData.map(client =>{
                    if(client.clientName!=="Flipkart")
                        return (<div>
                        <Grid item xs={12} className="flexDisplay " direction="row" style={{marginBottom:'8px',fontSize:"24px"}} >
                            <Grid item xs={4}>
                                <span style ={{color:'#1c75c0'}} className="fontWeightBold">Client</span>
                            </Grid>         
                            <Grid item xs={8}>
                                <span style ={{color:'#1c75c0'}}>{client.clientName}</span>    
                            </Grid>                
                        </Grid>
        
                        <Grid item xs={12} className="flexDisplay height40" direction="row" >
                            <Grid item xs={4}>
                                <span className="fontWeightBold">{`${client.clientName} ID`}</span>
                            </Grid>         
                            <Grid item xs={8}>
                                <span>{client.client_id}</span>    
                            </Grid>                
                        </Grid>
                        
                        <Grid item xs={12} className="flexDisplay height40" direction="row" >
                            <Grid item xs={4}>
                                <span className="fontWeightBold">Zone name</span>
                            </Grid>         
                            <Grid item xs={8}>
                                <span>{client.clusterName}</span>    
                            </Grid>                
                        </Grid>
                                    
                        <Grid item xs={12} className="flexDisplay height40" direction="row" >
                            <Grid item xs={4}>
                                <span className="fontWeightBold">Date of joining</span>
                            </Grid>         
                            <Grid item xs={8}>
                                <span>{client.dateOfJoining ?moment(client.dateOfJoining).format("DD/MM/YYYY"):""}</span>    
                            </Grid>                
                        </Grid>
                        {/* <Divider className="margin20" />  */}
                    </div>)
                    return null;
                })
            }
            
        </Grid>
    )
}

export default HRBPview
