import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import styles from './index.module.scss'
import { CSVLink } from 'react-csv';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import SearchIcon from "@material-ui/icons/Search";
import OnboardingModal from './components/onboardingModal'
import OnboardingModalV2 from './components/onboardingModalV2'
import GroupFlexModal from './components/GroupFlexModal'
import CasperOnboardingModal from './components/CasperOnboardingModal'
import OffboardingModal from './components/offboardingModal'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import AdminNotesModel from './AdminNotes';
import * as moment from 'moment'
import onboardingIcon from './onbaording.svg'
import Pencil from './pencil.svg'
import FilterDrawer from './filters'
import NoRecord from './noRecord.svg'
import PencilDisabled from './pencilDisabled.svg'
import EditContactModal from './components/EditPhoneNumber'
import ReactivationModal from './components/ReactivtionDialog'
import offboardingIcon from './offboarding.svg'
import ToggleButton from '@material-ui/lab/ToggleButton';
import CriminalRecord from './components/CriminalRecord';
// import filter from '../../assets/filter.svg'
// import Filters from './filterModal'
// import Filters from './FilterBase'
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { AuthContext } from '../../services/AuthProvider'

import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom'
import UrlLinks from '../../UrlLinks'
import EditableDropdown from "./Functions/EditableDropdown"
import { ClockLoader, PulseLoader } from 'react-spinners'
import Decrypt from '../../Utils/decrypt'
// import SimplePopover from './FilterBase'
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            // width: fit-content;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const ExternalWorker = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [allRecordLoading, setAllRecordLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allRecord, setAllRecord] = useState([])
    const [agreementDownloading, setAgreementDownloading] = useState(false)
    const [emailAgreement, setEmailAgreement] = useState(false)
    // const [exportExcelLoading, setExportExcelLoading] = useState(false)
    // const [excelData, setExcelData] = useState([])
    // const [selectedRows, setSelectedRows] = useState(0)
    const [totalRows, setTotalRows] = useState(0);
    const [allDocumentDownload, setAllDocumentDownload] = useState(null)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false)
    const [openCasperOnboardingModal, setOpenCasperOnboardingModal] = useState(false)
    const [openGroupflexModal, setOpenGroupflexModal] = useState(false)
    const [searchByAuto, setsearchByAuto] = useState("");
    const [row, setRow] = useState(null)
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [editContactData, setEditContactData] = useState({
        userId: null,
        contact: null,
        open: false,
        error: ""
    })
    const [reactivationData, setReactivationData] = useState({
        open: false,
        userId: null,
        date: null
    })
    const [actions, setActions] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState("")
    const [sortDirection, setSortDirection] = useState(false)
    const [sortDirectionUrl, setSortDirectionUrl] = useState('')
    const [searchBy, setSearchBy] = useState("")
    const [sortUrl, setSortUrl] = useState("")
    const [filterUrl, setFilterUrl] = useState("")
    const [groupflexAdmin, setGroupflexAdmin] = useState(false)
    const [filterDrawer, setFilterDrawer] = useState(false)
    const [openAdminNotes, setOpenAdminNotes] = useState(false)
    const [referralsReset, setReferralsReset] = useState(true)
    const [opncClaimsReset, setOpncClaimsReset] = useState(false)
    const [exportFileName, setExportFileName] = useState("")
    const [temp, setTemp] = useState()
    const [processedClaimsReset, setProcessedClaimsReset] = useState(false)
    const [criminalRecordData, setCriminalRecordData] = useState({
        open: false,
        userId: null
    })
    const [filters, setFilters] = useState({
        searchBy: "",
        openClaims: false,
        referral: true,
        processedClaims: false,
        candidateStatus: "",
        claimStatus: "",
        referralType: "",
        claimDate: [null, null],
        referralDate: [null, null]


    })
    useEffect(() => {
        ApplyFilters(filters);
    }, [searchByAuto]);
    const { showToast } = useContext(AuthContext);
    const createDocumentList = (documents, profilePhoto) => {
        let list = []
        let aadharBackError = 'Aadhar Back,';
        let aadharFrontError = ' Aadhar Front,';
        let profilePicError = ' Profile pic';
        let errorText = ' not Found!'
        documents.map(doc => {
            if (doc.document_type_id === 1929 || doc.document_type_id === 1867) {
                if (doc.document_type_id === 1929)
                    aadharBackError = '';
                if (doc.document_type_id === 1867)
                    aadharFrontError = '';
                if (doc.file_path.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

                    list.push('https' + doc.file_path.substring(4, doc.file_path.length))
                } else
                    list.push(doc.file_path)


            }
        })
        if (profilePhoto !== null && profilePhoto !== '') {
            profilePicError = '';
            if (profilePhoto.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

                list.push('https' + profilePhoto.substring(4, profilePhoto.length))
            } else
                list.push(profilePhoto)
        }
        if (aadharBackError.length !== 0 || aadharFrontError.length !== 0 || profilePicError.length !== 0) {
            errorText = aadharBackError + aadharFrontError + profilePicError + errorText;
            showToast("error", errorText);
        }

        return list.forEach(path => {
            window.open(path, '_blank');
            // sleep(3000)
        });


    }
    function sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }
    const getDocumentList = (userId, profilePhoto) => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(userId)}`)
            .then(response => {
                setAllDocumentDownload(createDocumentList(response.data.response.content, profilePhoto))
            })
    }
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)

    }
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
        setsearchByAuto("")
    }
    const handleCriminalRecord = (event) => {
        setCriminalRecordData({ userId: event.target.id, open: true });
    }
    const ApplySort = () => {
        let url = ''
        switch (sortBy) {
            case "casper_id":
                url = '&orderBy=wm.casper_id'
                break;
            case "profile_id":
                url = '&orderBy=wm.worker_id'
                break;
            case "okaygo_id":
                url = '&orderBy=wm.okaygo_id'
                break;
            case "onboarding_date":
                url = '&orderBy=wm.onboarding_initiation_date'
                break;
            case "active_date":
                url = '&orderBy=wm.active_date'
                break;
            case "offboarding_date":
                url = '&orderBy=wm.offboarding_initiation_date'
                break;
            case "inactive_date":
                url = '&orderBy=wm.inactive_date'
                break;
            default:
                url = ''
                break;
        }
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url)
    }
    const handleEditPhoneNumber = (row) => {
        setEditContactData({
            userId: row.row.original.userId,
            contact: row.row.original.contactNo,
            open: true,
            error: ""
        })

    }
    const handleEditContactClose = () => {
        setEditContactData({
            userId: null,
            contact: null,
            open: false,
            error: ""
        })
    }
    const handleReactivationDateClose = () => {
        setReactivationData({
            open: false,
            userId: null,
            date: null
        })
    }
    const handleTemporaryInactive = (data) => {

        setReactivationData({
            open: true,
            userId: data.userId,
            date: data.reactivationDateTimestamp ? data.reactivationDateTimestamp : null
        })
    }

    useEffect(() => {
        ApplySort();
        setSortDirection(false)
        setSortDirectionUrl('')
    }, [sortBy])
    const handleStatuses = (event, name) => {
        if (name === "referral") {
            setFilters({ ...filters, openClaims: false, processedClaims: false, referral: true })
            setReferralsReset(true)
            setOpncClaimsReset(false)
            setProcessedClaimsReset(false)
            setTemp(1)
            // ApplyFilters(filters);

        }
        else if (name === "openClaims") {
            setFilters({ ...filters, openClaims: true, processedClaims: false, referral: false })
            setReferralsReset(true)
            setOpncClaimsReset(false)
            setProcessedClaimsReset(false)
            setTemp(2)
            // ApplyFilters(filters);
        }
        else if (name === "processedClaims") {
            setFilters({ ...filters, openClaims: false, processedClaims: true, referral: false })
            setReferralsReset(false)
            setOpncClaimsReset(false)
            setProcessedClaimsReset(true)
            setTemp(3)
            // ApplyFilters(filters);
        }
    }
    const ApplyFilters = () => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`
        if (filters["candidateStatus"])
            url = `${url}&candidateStatus=${filters["candidateStatus"].id}`
        if (filters["claimStatus"])
            url = `${url}&claimStatus=${filters["claimStatus"].id}`
        if (filters["referralType"])
            url = `${url}&referralType=${filters["referralType"].id}`
        //Date filters

        if (filters["claimDate"][0] && !filters["claimDate"][1])
            url = `${url}&claimDateMin=${moment(filters["claimDate"][0]).format("YYYY-MM-DD")}&claimDateMax=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["claimDate"][0] && filters["claimDate"][1])
            url = `${url}&claimDateMin=2019-01-01&claimDateMax=${moment(filters["claimDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["claimDate"][0] && filters["claimDate"][1])
            url = `${url}&claimDateMin=${moment(filters["claimDate"][0]).format("YYYY-MM-DD")}&claimDateMax=${moment(filters["claimDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["referralDate"][0] && !filters["referralDate"][1])
            url = `${url}&referralDateMin=${moment(filters["referralDate"][0]).format("YYYY-MM-DD")}&referralDateMax=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["referralDate"][0] && filters["referralDate"][1])
            url = `${url}&referralDateMin=2019-01-01&referralDateMax=${moment(filters["referralDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["referralDate"][0] && filters["referralDate"][1])
            url = `${url}&referralDateMin=${moment(filters["referralDate"][0]).format("YYYY-MM-DD")}&referralDateMax=${moment(filters["referralDate"][1]).format("YYYY-MM-DD")} 23:59:59`



        setFilterUrl(url)
        setFilters(filters)
        // if (url) {
        //     setIsFilterApplied(true)
        // }
        setCurrentPage(1)
    }

    const handleAdminNote = (props) => {
        setRow(props)
        setOpenAdminNotes(true)
    }
    const resetFilters = () => {
        const filterList = {
            searchBy: "",
            candidateStatus: "",
            claimStatus: "",
            referralType: "",
            openClaims: opncClaimsReset,
            referral: referralsReset,
            processedClaims: processedClaimsReset,
            claimDate: [null, null],
            referralDate: [null, null]

        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    useEffect(() => {
        ApplyFilters(filters);
        getExternalWorkerList(perPage, 1);
    }, [temp])
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }





    const referrals = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            //         </div>
            //     ),
            // },
            {
                Header: "Lead ID/ OkayGo ID",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referral Type",
                accessor: "referralType",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Name",
                accessor: "name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Number",
                accessor: "phoneNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Location",
                accessor: "referralLocation",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // 
            {
                Header: "Candidate Status",
                accessor: "referralStatus",
                // Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Status Updated On",
                accessor: "lastStatusDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "First Earning Date",
                accessor: "firstEarningDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Earning Date",
                accessor: " ",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referred On",
                accessor: "referredOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referred by",
                accessor: "referredBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Remarks",
                accessor: "remark",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true,
                Cell: row => {
                    return (<Grid container xs={12} className="padding0" style={{ maxWidth: "240px", minWidth: "200px" }} >

                        <Grid item xs={10} className="padding0" style={{ textAlign: "left" }}><span>{row.row.original.remark.length > 45 ? row.row.original.remark.substring(0, 45) + "... " : row.row.original.remark}  </span> </Grid>
                        <Grid item xs={2} className="padding0">    <button className="linkButton" onClick={() => handleAdminNote(row.row.original)}>{row.row.original.remark === "" ? <b><u>Add</u></b> : <b><u>Edit</u></b>}</button></Grid>

                    </Grid>)
                }

            }
        ],
        []
    );
    // const i="svd".length()
    const openClaims = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            //         </div>
            //     ),
            // },
            {
                Header: "OkayGo ID",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referral Type",
                accessor: "referralType",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Name",
                accessor: "name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Number",
                accessor: "phoneNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Status",
                accessor: "referralStatus",
                // Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Status",
                accessor: "claimPaymentStatus",
                // Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Activation Date",
                accessor: "activationDate",
                // Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "First Earning Date",
                accessor: "firstEarningDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Earning Date",
                accessor: "lastEarningDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referred On",
                accessor: "referredOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referred by",
                accessor: "referredBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Claim generated on",
                accessor: "claimGeneratedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Approval Status",
                accessor: "approvalStatus",
                Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Remarks",
                accessor: "remark",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true,
                Cell: row => {
                    return (<Grid container xs={12} className="padding0" style={{ maxWidth: "240px", minWidth: "200px" }} >

                        <Grid item xs={10} className="padding0" style={{ textAlign: "left" }}><span>{row.row.original.remark.length > 45 ? row.row.original.remark.substring(0, 45) + "... " : row.row.original.remark}  </span> </Grid>
                        <Grid item xs={2} className="padding0">    <button className="linkButton" onClick={() => handleAdminNote(row.row.original)}>{row.row.original.remark === "" ? <b><u>Add</u></b> : <b><u>Edit</u></b>}</button></Grid>

                    </Grid>)
                }

            }


        ],
        []
    );
    const processedClaims = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            //         </div>
            //     ),
            // },
            {
                Header: "OkayGo ID",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referral Type",
                accessor: "referralType",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Name",
                accessor: "name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Candidate Number",
                accessor: "phoneNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referred On",
                accessor: "referredOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Referred by",
                accessor: "referredBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "First Earning Date",
                accessor: "firstEarningDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Earning Date",
                accessor: "lastEarningDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction Dates",
                accessor: "transactionDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction ID",
                accessor: "transactionId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Remarks",
                accessor: "remark",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true,
                Cell: row => {
                    return (<Grid container xs={12} className="padding0" style={{ maxWidth: "240px" }} >

                        <Grid item xs={10} className="padding0" style={{ textAlign: "left" }}><span>{row.row.original.remark.substring(0, 45) + "... "}  </span> </Grid>
                        <Grid item xs={2} className="padding0">    <button className="linkButton" onClick={() => handleAdminNote(row.row.original)}>{row.row.original.remark === "" ? <b><u>Add</u></b> : <b><u>Edit</u></b>}</button></Grid>

                    </Grid>)
                }

            }


        ],
        []
    );

    const createWorkerStatus = (status) => {
        let text = ""
        switch (status) {
            case 0:
                text = "Not Onboarded";
                break;
            case 1:
                text = "Active";
                break;
            case 2:
                text = "Partially Onboarded";
                break;
            case 3:
                text = "Inactive";
                break;
            case 4:
                text = "Offboarding Initiated";
                break;
            case 5:
                text = "Onboarding Completed";
                break;
            case 6:
                text = "Temporarily Inactive";
                break;
            case 7:
                text = "Temporary Inactivation Initiated";
                break;
            case 8:
                text = "Reactivation Initiated";
                break;
            default:

                break;
        }
        return text;
    }

    console.log(localStorage.getItem("userID"))
    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        response.content.map(el => {
            extractedData.push({
                referralId: el.referralClaimId || "",
                okaygoId: el.okaygoId ? el.okaygoId : el.leadId,
                referralType: el.referralType ? (el.referralType === "CONTACT_SHARED" ? "Contact Shared" : "") : "App Download",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                phoneNumber: Decrypt(el.phoneNumber) || "",
                referralStatus: el.referralStatus || "",
                referralLocation:el.candidateLocation===null?(el.referbyLocation!==null?el.referbyLocation:"-"):el.candidateLocation,
                claimPaymentStatus:el.claimPaymentStatus|| "-",
                lastStatusDate: el.lastStatusDate ? moment(el.lastStatusDate).format("DD/MM/YYYY hh:mm a") : "",
                referredOn: el.insertedOn ? moment(el.insertedOn).format("DD/MM/YYYY hh:mm a") : "",
                referredBy: el.referredBy + (el.referredByContact ? " - " + el.referredByContact : "") || "",
                remark: el.remark || "",
                activationDate: el.activationDate ? moment(el.activationDate).format("DD/MM/YYYY") : "",
                claimGeneratedOn: el.claimGeneratedOn ? moment(el.claimGeneratedOn).format("DD/MM/YYYY hh:mm a") : "",
                // approvalStatus:el.claim_approval_status?(el.claim_approval_status===1?"Approved":"Not Approved"):"",
                approvalStatus: el.claim_approval_status || "-",
                paymentStatus: el.claimPaymentStatus || "",
                transactionDate: el.claimPaymentDate ? el.claimPaymentDate : "",
                transactionId: el.transactionId || "",
                firstEarningDate:el.firstEarningDate?moment(el.firstEarningDate).format("DD/MM/YYYY") : "",
                lastEarningDate:el.lastEarningDate?moment(el.lastEarningDate).format("DD/MM/YYYY") : "",
            });
            return true;
        });
        return extractedData;
    }

    //Function to create All record export data
    const makeAllRecordData = (response) => {
        let extractedData = [];
        let lastupdatedon = [];

        if (filters["referral"]) {
            setExportFileName("referrals")
            response.map(el => {

                extractedData.push({
                    okaygoId: el.okaygoId ? el.okaygoId : el.leadId,
                    referralType: el.referralType ? (el.referralType === "CONTACT_SHARED" ? "Contact Shared" : "") : "App Download",
                    candidateName: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                    candidateNumber: Decrypt(el.phoneNumber) || "",
                    candidateLocation:el.candidateLocation===null?(el.referbyLocation!==null?el.referbyLocation:"-"):el.candidateLocation,
                    candidateStatus: el.referralStatus || "",
                    lastStatusUpdatedOn: el.lastStatusDate ? moment(el.lastStatusDate).format("DD/MM/YYYY hh:mm a") : "",
                    firstEarningDate:el.firstEarningDate?moment(el.firstEarningDate).format("DD/MM/YYYY") : "",
                    lastEarningDate:el.lastEarningDate?moment(el.lastEarningDate).format("DD/MM/YYYY") : "",
                    referredOn: el.insertedOn ? moment(el.insertedOn).format("DD/MM/YYYY hh:mm a") : "",
                    referredBy: el.referredBy || "",
                    referredByContact: el.referredByContact || "", 
                    remark: el.remark || "",

                })

                return true;
            });
        }
        if (filters["openClaims"]) {
            setExportFileName("openClaims")
            response.map(el => {

                extractedData.push({
                    okaygoId: el.okaygoId ? el.okaygoId : el.leadId,
                    referralType: el.referralType ? (el.referralType === "CONTACT_SHARED" ? "Contact Shared" : "") : "App Download",
                    candidateName: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                    candidateNumber: Decrypt(el.phoneNumber) || "",
                    candidateStatus: el.referralStatus || "",
                    claimPaymentStatus:el.claimPaymentStatus|| "-",
                    activationDate: el.activationDate ? moment(el.activationDate).format("DD/MM/YYYY") : "",
                    firstEarningDate:el.firstEarningDate?moment(el.firstEarningDate).format("DD/MM/YYYY") : "",
                    lastEarningDate:el.lastEarningDate?moment(el.lastEarningDate).format("DD/MM/YYYY") : "",
                    referredOn: el.insertedOn ? moment(el.insertedOn).format("DD/MM/YYYY hh:mm a") : "",
                    referredBy: el.referredBy || "",
                    referredByContact: el.referredByContact || "",
                    claimGeneratedOn: el.claimGeneratedOn ? moment(el.claimGeneratedOn).format("DD/MM/YYYY hh:mm a") : "",
                    approvalStatus: el.claim_approval_status ? (el.claim_approval_status === 1 ? "Approved" : "Not Approved") : "",
                    remark: el.remark || "",


                });
                return true;
            });
        }
        if (filters["processedClaims"]) {
            setExportFileName("processedClaims")
            response.map(el => {

                extractedData.push({
                    okaygoId: el.okaygoId ? el.okaygoId : el.leadId,
                    referralType: el.referralType ? (el.referralType === "CONTACT_SHARED" ? "Contact Shared" : "") : "App Download",
                    candidateName: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                    candidateNumber: Decrypt(el.phoneNumber) || "",
                    referredOn: el.insertedOn ? moment(el.insertedOn).format("DD/MM/YYYY hh:mm a") : "",
                    referredBy: el.referredBy || "",
                    referredByContact: Decrypt(el.referredByContact) || "",
                    paymentStatus: el.claimPaymentStatus || "",
                    firstEarningDate:el.firstEarningDate?moment(el.firstEarningDate).format("DD/MM/YYYY") : "",
                    lastEarningDate:el.lastEarningDate?moment(el.lastEarningDate).format("DD/MM/YYYY") : "",
                    transactionDate: el.claimPaymentDate ? moment(el.claimPaymentDate ).format("DD/MM/YYYY"): "",
                    transactionId: el.transactionId || "",
                    remark: el.remark || ""

                });
                return true;
            });
        }





        return extractedData;
    }
    const updateMyData = () => {
        getExternalWorkerList(perPage, currentPage);
    }

    const createAllRecordExcel = () => {
        setAllRecordLoading(true)
        let requestedBy = ""
        let pageUrl = ""
        if (localStorage.getItem("roleType") === "1") {
            requestedBy = `requestedById=${localStorage.getItem("userID")}`
        }
        if (filters["referral"]) {
            pageUrl = urlLinks.referral.referralListing
        }
        if (filters["openClaims"]) {
            pageUrl = urlLinks.referral.openClaimsListing
        }
        if (filters["processedClaims"]) {
            pageUrl = urlLinks.referral.processedClaimsListing
        }
        Axios.get(`${urlLinks.baseUrl}${pageUrl}?${requestedBy}${filterUrl ? filterUrl + "&" : filterUrl}${sortUrl}${sortDirectionUrl}` + "exportExcel=1")
            .then(response => {
                if (response) {
                    setAllRecord(makeAllRecordData(response.data.response))
                }
            })
    }
    // const exportCurrentRecords= () =>{
    //     setExportExcelLoading(true)
    //     let list=[]
    //     data.map(el =>{
    //         delete el.userId;
    //         delete el.workerId;
    //         list.push(el)
    //         return true;
    //     })
    //     setExcelData(list)
    // }

    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getExternalWorkerList = (rows, pageNo) => {
        setLoading(true)
        let requestedBy = ""
        let pageUrl = ""
        if (localStorage.getItem("roleType") === "1") {
            requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        }
        if (filters["referral"]) {
            pageUrl = urlLinks.referral.referralListing
        }
        if (filters["openClaims"]) {
            pageUrl = urlLinks.referral.openClaimsListing
        }
        if (filters["processedClaims"]) {
            pageUrl = urlLinks.referral.processedClaimsListing
        }
        if (localStorage.getItem("roleType")) {
            Axios.get(`${urlLinks.baseUrl}${pageUrl}?page_no=${pageNo - 1}&rows=${rows}${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}`)
                .then(response => {

                    if (response) {
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if (currentPage > response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })

        }

    }
    const handleReactivationDate = () => {
        const postData = {
            userId: reactivationData.userId,
            reActivationDate: moment(reactivationData.date).format("YYYY-MM-DD 00:00:00"),
            // updatedBy: parseInt(localStorage.getItem("userID"))
        }
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.workerReactivation}?reActivationDate=${moment(reactivationData.date).format("YYYY-MM-DD 00:00:00")}&userId=${reactivationData.userId}&updatedBy=${parseInt(localStorage.getItem("userID"))}`)
            .then(response => {
                showToast("success", "Activation date set successfully")
                getExternalWorkerList(perPage, currentPage)
                setReactivationData({
                    open: false,
                    userId: null,
                    date: null
                })
            })
            .catch(error => {
                showToast("error", error.message)
            })
    }
    useEffect(() => {
        getExternalWorkerList(perPage, 1);

    }, [filterUrl, sortUrl, sortDirectionUrl, localStorage.getItem("roleType")])
    // const columns =  referrals 
    console.log(filters)
    const columns = filters['referral'] ? referrals : (filters['processedClaims'] ? processedClaims : (filters['openClaims'] ? openClaims : null))
    const handlePageChange = page => {
        getExternalWorkerList(perPage, page)
        setCurrentPage(page);
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { selectedRowIds } } = useTable({ columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getExternalWorkerList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const createZipForDocuments = (urls) => {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = "Agreements.zip";
        urls.forEach(async function (url, index) {
            const record = Object.entries(url)
            const filename = `${record[0][0]}`;
            const path = `${record[0][1]}`
            try {
                await JSZipUtils.getBinaryContent(path, function (err, data) {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    if (count === urls.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                            setAgreementDownloading(false)
                        });
                    }

                })
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    const handleEmailAgreements = () => {
        setEmailAgreement(true)
        const indexes = Object.keys(selectedRowIds)
        let userIdList = []
        indexes.map(id => {
            userIdList.push(data[id].userId)
        })
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.emailAgreements}${userIdList}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Agreements sent to your registered email id")

                }
                else if (response.data.code === 1001) {
                    showToast("error", response.data.message)
                }

                setEmailAgreement(false)
            })
            .catch(error => {
                setEmailAgreement(false)
                showToast("error", error.message)
            })

    }
    const handleDownloadAgreements = () => {
        setAgreementDownloading(true)
        const rowIds = Object.keys(selectedRowIds)
        let agreementUrls = []
        rowIds.map(row => {
            if (data[row].agreementPath) {
                const fileName = `${data[row].contactNo}_${data[row].name}.pdf`
                agreementUrls.push({
                    [fileName]: data[row].agreementPath
                })
            }
        })
        if (agreementUrls.length > 0) {
            createZipForDocuments(agreementUrls)
        }
        else {
            setAgreementDownloading(false)
            showToast("error", "It will take some time to generate agreement.")
        }

    }
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameMatchNew zIndex1080 header" :
                                        // (column.render('Header') === "Delivery status" && ((localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5"))) ? "displayNone" :// not visible to hrbp and super hrbp
                                        // (column.render('Header') === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" : // only visible to okaygo admin annd super admin
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" : "header"}>

                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (

                            <tr {...row.getRowProps()} className={`row ${row.original.criminalRecord ? "criminalRecord" : ""}`}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={`${cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameMatchNew zIndex540" :
                                                // (cell.column.Header === "Delivery status" && ((localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5"))) ? "displayNone" :
                                                // (cell.column.Header === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"} ${row.original.criminalRecord === "Yes" ? "criminalRecord" : ""}`}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid item sm={12}><span className="fontSize24 fontWeightBold">Referral </span></Grid>

                <Grid container xs={12} className="flexDisplay alignItemsFlexStart">

                    <Grid item sm={6} className="flexDisplay padding0 paddingTop8 justifySpaceBetween">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder="Search by Referred / Referree Phone no"
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                    <Grid container xs={6} className="flexDisplay padding0 justifySpaceBetween" style={{ justifyContent: "flex-end" }}> <Button
                        variant="contained"
                        className="dataButton"
                        onClick={createAllRecordExcel}
                        disabled={data.length === 0}
                    >
                        Export to Excel
                    </Button>
                        {allRecordLoading ?
                            <span style={{ marginRight: "20px", marginLeft: "9px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={exportFileName + ".csv"}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginRight: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    <span style={{ marginRight: "20px", marginLeft: "9px" }}>  Download now</span>
                                </CSVLink>
                                : null}

                        <button
                            className={styles.blueOutlinedButton}
                            style={{ marginLeft: "16px" }}
                            onClick={() => setOpenFilterDrawer(true)}
                        >
                            filters
                        </button>
                    </Grid>


                </Grid>

                <Grid item xs={12} className="flexDisplay paddingTop16 justifyFlexStart" >
                    <Drawer anchor="right" open={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                        <FilterDrawer
                            handleClose={() => setOpenFilterDrawer(false)}
                            filters={filters}
                            setFilters={setFilters}
                            applyFilters={ApplyFilters}
                            resetFilters={resetFilters}
                        ></FilterDrawer>
                    </Drawer>
                    <Button
                        //variant="outlined" 
                        onClick={(event) => handleStatuses(event, "referral")}
                        // className={`statusButtons marginRight20 ${tab==="referral" ? "blueColorBorder" : "greyColorBorder"}`}
                        className={`statusButtons marginRight20 ${filters.referral ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Referrals
                    </Button>
                    <Button
                        onClick={(event) => handleStatuses(event, "openClaims")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.openClaims ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Open Claims
                    </Button>
                    <Button
                        onClick={(event) => handleStatuses(event, "processedClaims")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.processedClaims ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Processed Claims
                    </Button>


                </Grid>
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
                {/* <Drawer anchor="right" open={filterDrawer} onClose={() => setFilterDrawer(false)}>
                    <Filters
                        filters={filters}
                        updateFilters={ApplyFilters}
                        filterDrawer={filterDrawer}
                        setFilterDrawer={setFilterDrawer}
                        resetFilters={resetFilters}
                        isFilterApplied={isFilterApplied}
                        setIsFilterApplied={setIsFilterApplied}
                    />
                                </Drawer> */}
                {openOnboardingModalV2 && <OnboardingModalV2
                    openOnboardingModal={openOnboardingModalV2}
                    setOpenOnboardingModal={setOpenOnboardingModalV2}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openOnboardingModal && <OnboardingModal
                    openOnboardingModal={openOnboardingModal}
                    setOpenOnboardingModal={setOpenOnboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openOffboardingModal && <OffboardingModal
                    openOffboardingModal={openOffboardingModal}
                    setOpenOffboardingModal={setOpenOffboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openCasperOnboardingModal && <CasperOnboardingModal
                    openCasperOnboardingModal={openCasperOnboardingModal}
                    setOpenCasperOnboardingModal={setOpenCasperOnboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openGroupflexModal && <GroupFlexModal
                    openOnboardingModal={openGroupflexModal}
                    setOpenOnboardingModal={setOpenGroupflexModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {
                    criminalRecordData.open && <CriminalRecord
                        criminalRecordData={criminalRecordData}
                        setCriminalRecordData={setCriminalRecordData}
                    />
                }
                {openAdminNotes && <AdminNotesModel
                    openAdminNotes={openAdminNotes}
                    setOpenAdminNotes={setOpenAdminNotes}
                    noteData={row.remark}
                    row={row}
                    recruiterMasterId={row.jobId}
                    updateMyData={updateMyData}

                    showToast={showToast}
                />}
                {/* {
                    editContactData.open &&
                    <EditContactModal
                        open={editContactData}
                        handleClose={handleEditContactClose}
                        handleEditPhoneNumberService={handleEditPhoneNumberService}
                    />
                } */}
                {
                    reactivationData.open &&
                    <ReactivationModal
                        open={reactivationData}
                        handleClose={handleReactivationDateClose}
                        handleReactivationDate={handleReactivationDate}
                        setReactivationData={setReactivationData}
                    />
                }
            </Grid>
        </Styles>
    )
}

export default ExternalWorker;


