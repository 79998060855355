import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import styles from './index.module.scss'
import ExportModal from './components/ExportModal'
import { CSVLink } from 'react-csv';
import swal from 'sweetalert';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import SearchIcon from "@material-ui/icons/Search";
import DocumentDownload from './components/downloadDocuments'
import OnboardingModal from './components/onboardingModal'
import OnboardingModalV2 from './components/onboardingModalV2'
import OnBoardingModalV3 from './components/onBoardingModalV3'
import UploadAJWorkerModal from './components/UploadAJWorkers'
import GroupFlexModal from './components/GroupFlexModal'
import CasperOnboardingModal from './components/CasperOnboardingModal'
import OffboardingModal from './components/offboardingModal'
import AssignToRecruiterModal from '../WorkerData/components/AssignToRecruiter'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FilterDrawer from './filters'
import * as moment from 'moment'
import onboardingIcon from './onbaording.svg'
import Pencil from './pencil.svg'
import NoRecord from './noRecord.svg'
import LastAppliedJob from './components/LastJobApplied'
import BGVStatus from '../BGVListing/components/BGVDocumentStatus' 
import PencilDisabled from './pencilDisabled.svg'
import EditContactModal from './components/EditPhoneNumber'
import ReactivationModal from './components/ReactivtionDialog'
import offboardingIcon from './offboarding.svg'
import ToggleButton from '@material-ui/lab/ToggleButton';
import CriminalRecord from './components/CriminalRecord';
import SendMsg from './components/SendMsg';
// import filter from '../../assets/filter.svg'
import Filters from './filterModal'
// import Filters from './FilterBase'
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { AuthContext } from '../../services/AuthProvider'
import InfoIcon from '@material-ui/icons/Info';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom'
import UrlLinks from '../../UrlLinks'
import { ClockLoader, PulseLoader } from 'react-spinners'
import SimplePopover from './FilterBase'
import Tooltip from '@material-ui/core/Tooltip';
import BGVVerificationModal from './components/BGVVerificationModal'
import LastSentMsgs from './components/LastSentMsgs'
import { RepeatOneSharp } from '@material-ui/icons'
import { filter } from 'bluebird'
import './indeterminateCheckbox.css'
import Decrypt from '../../Utils/decrypt'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} />
            </React.Fragment>
        )
    }
)

const WorkerData = (props) => {

    const classes = useStyles();
    const [data, setData] = useState([])
    const [selectedUserIds, setSelectedUserIds] = useState("")
    const [allRecordLoading, setAllRecordLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openSendMsg, setOpenSendMsg] = useState(false)
    const [allRecord, setAllRecord] = useState([])
    const [openAssignModal, setOpenAssignModal] = useState(false)
    const [agreementDownloading, setAgreementDownloading] = useState(false)
    const [emailAgreement, setEmailAgreement] = useState(false)
    // const [exportExcelLoading, setExportExcelLoading] = useState(false)
    // const [excelData, setExcelData] = useState([])
    // const [selectedRows, setSelectedRows] = useState(0)
    const [totalRows, setTotalRows] = useState(0);
    const [allDocumentDownload, setAllDocumentDownload] = useState(null)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false)
    const [openAJworkerUploadModal, setOpenAJworkerUploadModal] = useState(false)
    const [openOnboardingApnaModalV3, setOpenOnboardingApnaModalV3] = useState(false)
    const [openCasperOnboardingModal, setOpenCasperOnboardingModal] = useState(false)
    const [openBGVTriggerModal, setOpenBGVTriggerModal] = useState(false)
    const [openGroupflexModal, setOpenGroupflexModal] = useState(false)
    const [disable, setDisable] = useState(true)
    const [searchByAuto, setsearchByAuto] = useState("");
    const [editContactData, setEditContactData] = useState({
        userId: null,
        contact: null,
        open: false,
        error: ""
    })
    const [reactivationData, setReactivationData] = useState({
        open: false,
        userId: null,
        date: null
    })
    const [crossFilter, setCrossFilter] = useState("")
    const [assignFilterString, setassignFilterString] = useState("");
    const [actions, setActions] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [options, setOptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(-1)
    const [sortDirection, setSortDirection] = useState(false)
    const [sortDirectionUrl, setSortDirectionUrl] = useState('')
    const [searchBy, setSearchBy] = useState("")
    const [sortUrl, setSortUrl] = useState("")
    const [viewMore, setViewMore] = useState(false)
    const [filterUrl, setFilterUrl] = useState("")
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [BGVResponse, setBGVResponse] = useState(null)
    const [docListLoader, setDocListLoader] = useState(null)
    const [downloadDocError, setDownloadDocError] = useState(null)
    const [downloadError, setDownloadError] = useState("second")
    //const [filterDrawer, setFilterDrawer] = useState(false)
    const [criminalRecordData, setCriminalRecordData] = useState({
        open: false,
        userId: null
    })
    const [tab, setTab] = useState({
        workerDataTab: true,
        leadDataTab: false
    })
    const [isLeadData, setisLeadData] = useState("");
    const [filters, setFilters] = useState({
        searchBy: "",
        source: "",
        all: true,
        onboardingInitiated: false,
        active: false,
        inactive: false,
        criminalRecord: false,
        temporarilyInactive: false,
        offboardingInitiated: false,
        zone: "",
        facilityState: "",
        facilityName: "",
        facilityCity: "",
        partnerId: "",
        workFor: "",
        workerStatus: "",
        psf: "",
        callStatus: "",
        interviewStatus: "",
        recruiterId: "",
        trainingUpdate: "",
        preferedHub: "",
        appLead: "",
        interviewDate: [null, null],
        firstEarningDate: [null, null],
        leadAddedDate: [null, null],
        bankDetails: "",
        leadCity: "",
        leadSource: "",
        lastEarning:"",
        clientEarningStatus: "",
        clientStatus: "",
        RecruiterAssignedBetween: [null, null],
        idGenerationDate: [null, null],
        IsLmaStatus: "",
        bgvStatus: "", 
        lastAppliedBy: ""

    })
    const [AssignFilter, setAssignFilter] = useState({
        all: true,
        assignTab: false,
        notAssignTab: false
    })
    const [partners, setPartners] = useState([{}]);
    const FirstUpperCase = (str) => {
        let s = "";
        s = str;

        if (s.length > 1) {
            let sub = s.substring(0, 1).toUpperCase();
            let remaining = s.substring(1, s.length);
            return sub + remaining;
        }

    }
    let c = null
    let partnersArray = [];
    useEffect(() => {

        Axios.get(`${UrlLinks.baseUrl}/configmaster/getCities`)
            .then(response => {
                // console.log(response.data)
                let v = []
                response.data.response.map(e => v.push(FirstUpperCase(e.trim())))
                c = v.sort()
                c.unshift("All Cities")

                c = v
                // console.log(c)
                handleOptions()
            })



        Axios.get(`${UrlLinks.baseUrl}${UrlLinks.getPartner}`)
            .then(response => {
                setPartners(response.data.response)

            })


    }, [])
    console.log({partners})
    const handleOptions = () => {
        // console.log(c)
        setOptions(c.map(option => {
            // option = FirstUpperCase(option)
            return { title: option }
            // return <MenuItem key={option} value={option}>{option}</MenuItem>
        }))
    }
    useEffect(() => {
        ApplyFilters(filters);
    }, [searchByAuto]);
    const [aadhaarFront, setAadhaarFront] = useState(null);
    const [aadhaarBack, setAadhaarBack] = useState(null);
    const { showToast } = useContext(AuthContext);
    const [columnType, setColumnType] = useState(null);
    const sortOptions = [
        // ["casper_id", "Casper Id"],
        // ["okaygo_id", "OkayGo Id"],
        // ["profile_id", "Profile Id"],
        // ["onboarding_date", "Onboarding date"],
        ["active_date", "Activation date"],
        ["offboarding_date", "offboarding initiate date"],

        // ["inactive_date", "Inactive date"]
    ]
    function urlToPromise(url) 
    {
        return new Promise(function(resolve, reject) 
        {
            JSZipUtils.getBinaryContent(url, function (err, data) 
            {
                if(err) 
                {
                    return reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }
    const createDocumentZip =(documents, profilePhoto, okaygoId, name) =>{
        var zip = new JSZip();
        var fileUrl= ""
        var profilePhotoURL=""
        var photoName=""
        documents.forEach(function(doc, index){
            // var filename = "doc" + index+".jpg";
            var filename = doc.file_name;
            filename = filename.substring(0,filename.indexOf('?'))
            if (doc.file_path.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss
                fileUrl ='https' + doc.file_path.substring(4, doc.file_path.length)
            }
            else{
                fileUrl =doc.file_path
            }
            // fileUrl =doc.file_path
            // console.log(filename.substring(0,filename.indexOf('?')))
            zip.file(filename, urlToPromise(fileUrl), {binary:true});
            if (doc.profilePhoto !== null && doc.profilePhoto !== '' && !profilePhotoURL ) {
                // console.log({doc})
                if (doc.profilePhoto.substring(0, 5) === 'http:') { 
                    profilePhotoURL ='https' + doc.profilePhoto.substring(4, doc.profilePhoto.length)
                } else{
                    profilePhotoURL =doc.profilePhoto
                } 
                // profilePhotoURL =doc.profilePhoto
                if(profilePhotoURL.includes(".jpg?")){
                    console.log({doc, photoName, profilePhotoURL})
                    photoName=`${name.replaceAll(" ","")}_Profile_Photo.jpg`
                }
                else if(profilePhotoURL.includes(".png?")){
                    console.log({doc, photoName, profilePhotoURL})
                    photoName=`${name.replaceAll(" ","")}_Profile_Photo.png`
                }
                zip.file(photoName, urlToPromise(profilePhotoURL), {binary:true});
            }
        });
        zip.generateAsync({type:'blob'}).then(function(content) {
            saveAs(content, `${okaygoId}_${name}_Documents`);
            setDocListLoader(null)
        });

    }

    
    // const createDocumentList = (documents, profilePhoto) => {
    //     let list = []
    //     let aadharBackError = 'Aadhar Back,';
    //     let aadharFrontError = ' Aadhar Front,';
    //     let profilePicError = ' Profile pic';
    //     let errorText = ' not Found!'
    //     documents.map(doc => {
    //         console.log({doc})
    //         if (doc.document_type_id === 1929 || doc.document_type_id === 1867) {
    //             if (doc.document_type_id === 1929)
    //                 aadharBackError = '';
    //             if (doc.document_type_id === 1867)
    //                 aadharFrontError = '';
    //             if (doc.file_path.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

    //                 list.push('https' + doc.file_path.substring(4, doc.file_path.length))
    //             } else
    //                 list.push(doc.file_path)


    //         }
    //     })
    //     if (profilePhoto !== null && profilePhoto !== '') {
    //         profilePicError = '';
    //         if (profilePhoto.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

    //             list.push('https' + profilePhoto.substring(4, profilePhoto.length))
    //         } else
    //             list.push(profilePhoto)
    //     }
    //     if (aadharBackError.length !== 0 || aadharFrontError.length !== 0 || profilePicError.length !== 0) {
    //         errorText = aadharBackError + aadharFrontError + profilePicError + errorText;
    //         showToast("error", errorText);
    //     }
    //     console.log(list)

    //     return list.map(path => {
    //         window.open(path, '_blank');
    //         // sleep(3000)
    //         return true;
    //     });


    // }
    function sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }
    const getDocumentList = (userId, profilePhoto, okaygoId, name) => {
        setDocListLoader(userId)
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(userId)}`)
            .then( (response) => {
                if(response.data.response.content.length>0 && profilePhoto){
                    createDocumentZip(response.data.response.content, profilePhoto, okaygoId, name)
                   

                }
                else{
                    setDocListLoader(null)

                    setDownloadDocError({err:<span>No Document Available</span>,userId:userId})
                }
                // setAllDocumentDownload(createDocumentList(response.data.response.content, profilePhoto))
            })
            .catch(error =>{
                setDocListLoader(null)
                // setDownloadDocError(<span style={{color: 'red'}}>Error while downloading</span>)
                setDownloadDocError({err:<span style={{color: 'red'}}>Error while downloading</span>,userId:userId})

            })
    }
    console.log(localStorage.getItem("roleType"))
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)

    }
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
        setFilterUrl('')
        // updateMyData();
    }
    const handleCriminalRecord = (event) => {
        // console.log({event})
        setCriminalRecordData({ userId: event.target.id, open: true });
    }
    const ApplySort = () => {
        let url = ''
        switch (sortBy) {
            case "casper_id":
                url = '&orderBy=wm.casper_id'
                break;
            case "profile_id":
                url = '&orderBy=wm.worker_id'
                break;
            case "okaygo_id":
                url = '&orderBy=wm.okaygo_id'
                break;
            case "onboarding_date":
                url = '&orderBy=wm.onboarding_initiation_date'
                break;
            case "active_date":
                url = '&orderBy=wm.active_date'
                break;
            case "offboarding_date":
                url = '&orderBy=wm.offboarding_initiation_date'
                break;
            case "inactive_date":
                url = '&orderBy=wm.inactive_date'
                break;
            default:
                url = ''
                break;
        }
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url)
    }
    const handleEditPhoneNumber = (row) => {
        // console.log({row})
        setEditContactData({
            userId: row.row.original.userId,
            contact: row.row.original.contactNo,
            open: true,
            error: ""
        })

    }
    const handleEditContactClose = () => {
        setEditContactData({
            userId: null,
            contact: null,
            open: false,
            error: ""
        })
    }
    const handleReactivationDateClose = () => {
        setReactivationData({
            open: false,
            userId: null,
            date: null
        })
    }
    const handleTemporaryInactive = (data) => {
        // console.log({data})

        setReactivationData({
            open: true,
            userId: data.userId,
            date: data.reactivationDateTimestamp ? data.reactivationDateTimestamp : null
        })
    }

    // console.log(filters)
    useEffect(() => {
        ApplySort();
        setSortDirection(false)
        setSortDirectionUrl('')
    }, [sortBy])
    const handleTab = (event, name) => {
        
        // RESET filters except search bar
        resetFiltersWithoutSearch()
        //////////////////////////////////

        if (name === "2") {
            // updateMyData()
            setTab({ ...tab, leadDataTab: true, workerDataTab: false })
            setisLeadData("&leadRecords=1")
            setCurrentPage(1)
            // updateMyData();
        }
        else if (name === "1") {
            // if (!filters[name]) {
            // updateMyData()
            setTab({ ...tab, leadDataTab: false, workerDataTab: true })
            setisLeadData("")
            setCurrentPage(1)
            // updateMyData();
        }
    }

    const handleStatuses = (event, name) => {
        if ((filters.onboardingInitiated && filters.active && filters.inactive)) {
            setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, all: true })
        }
        else if (name === "all") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: true })
            }
        }
        else if (name === "onboardingInitiated") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: true, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "active") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: true, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "offboardingInitiated") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: true, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "temporarilyInactive") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: true, all: false })
            }
        }
        else if (name === "inactive") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: true, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "criminalRecord") {
            setFilters({ ...filters, criminalRecord: !filters.criminalRecord })

        }

    }
    const ApplyFilters = () => {
        console.log(filters);
        // debugger
        
        let url=updateFilterUrl()

        setFilterUrl(url)
        setFilters(filters)
        // if (url) {
        //     setIsFilterApplied(true)
        // }
        setCurrentPage(1)
    }
    const updateFilterUrl =()=>{
        
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`
        if (filters["onboardingInitiated"]) {
            url = `${url}&workerStatus=2,5`
        }
        if (filters["status"]) {
            url = `${url}&status=${filters["status"]}`
            setIsFilterApplied(true)
        }
        if (filters["offboardingInitiated"]) {
            url = `${url}&workerStatus=4`
        }
        if (filters["temporarilyInactive"]) {
            url = `${url}&workerStatus=6,8`
        }
        if (filters["criminalRecord"]) {
            url = `${url}&criminal_record=1`
        }
        if (filters["partnerId"]) {
            url = `${url}&partnerId=${filters["partnerId"].id}`
            setIsFilterApplied(true)
        }
        if (filters["facilityName"]) {
            url = `${url}&facilityName=${filters["facilityName"].name}`
            setIsFilterApplied(true)
        }
        if (filters["facilityCity"]) {
            url = `${url}&facilityCity=${filters["facilityCity"].name}`
            setIsFilterApplied(true)
        }
        if (filters["facilityState"]) {
            url = `${url}&facilityState=${filters["facilityState"].name}`
            setIsFilterApplied(true)
        }
        if (filters["source"]) {
            url = `${url}&source=${filters["source"].id}`
            setIsFilterApplied(true)
        }
        if (filters["clientStatus"]) {
            url = `${url}&clientStatus=${filters["clientStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["clientEarningStatus"]) {
            url = `${url}&earningStatus=${filters["clientEarningStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["workerStatus"]) {
            url = `${url}&workerStatus=${filters["workerStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["workFor"]) {
            url = `${url}&workFor=${filters["workFor"]}`
            setIsFilterApplied(true)
        }
        if (filters["psf"]) {
            url = `${url}&psf=${filters["psf"].id}`
            setIsFilterApplied(true)
        }
        if (filters["leadSource"]) {
            url = `${url}&leadSource=${filters["leadSource"].id}`
            setIsFilterApplied(true)
        }
        if (filters["callStatus"]) {
            url = `${url}&callStatus=${filters["callStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["lastAppliedBy"]) {
            url = `${url}&lastAppliedBy=${filters["lastAppliedBy"].id}`
            setIsFilterApplied(true)
        }
        if (filters["appStatus"]) {
            url = `${url}&appStatus=${filters["appStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["interviewStatus"]) {
            url = `${url}&interviewStatus=${filters["interviewStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["recruiterId"]) {
            url = `${url}&recruiterId=${filters["recruiterId"].id}`
            setIsFilterApplied(true)
        }
        if (filters["trainingUpdate"]) {
            url = `${url}&trainingUpdate=${filters["trainingUpdate"].id}`
            setIsFilterApplied(true)
        }
        if (filters["preferedHub"]) {
            url = `${url}&preferedHub=${filters["preferedHub"].id}`
            setIsFilterApplied(true)
        }
        if (filters["appLead"]) {
            url = `${url}&appLead=${filters["appLead"].id}`
            setIsFilterApplied(true)
        }
        if (filters["bankDetails"] || filters["bankDetails"] === 0) {
            url = `${url}&bankdetail=${filters["bankDetails"].id}`
        }
        if (filters["leadCity"] || filters["leadCity"] === 0) {
            url = `${url}&leadCity=${filters["leadCity"].id}`
        }
        if (filters["IsLmaStatus"]) {
            url = `${url}&is_lma_worker=${filters["IsLmaStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["bgvStatus"]) {
            url = `${url}&bgvStatus=${filters["bgvStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["RecruiterAssignedBetween"][0] && !filters["RecruiterAssignedBetween"][1])
            url = `${url}&recruiterAssingedFrom=${moment(filters["RecruiterAssignedBetween"][0]).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["RecruiterAssignedBetween"][0] && filters["RecruiterAssignedBetween"][1])
            url = `${url}&recruiterAssingedFrom=2019-01-01&recruiterAssingedTO=${moment(filters["RecruiterAssignedBetween"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["RecruiterAssignedBetween"][0] && filters["RecruiterAssignedBetween"][1])
            url = `${url}&recruiterAssingedFrom=${moment(filters["RecruiterAssignedBetween"][0]).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(filters["RecruiterAssignedBetween"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["idGenerationDate"][0] && !filters["idGenerationDate"][1])
            url = `${url}&idGeneratedFrom=${moment(filters["idGenerationDate"][0]).format("YYYY-MM-DD")}&idGeneratedTO=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["RecruiterAssignedBetween"][0] && filters["RecruiterAssignedBetween"][1])
            url = `${url}&idGeneratedFrom=2019-01-01&idGeneratedTO=${moment(filters["idGenerationDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["idGenerationDate"][0] && filters["idGenerationDate"][1])
            url = `${url}&idGeneratedFrom=${moment(filters["idGenerationDate"][0]).format("YYYY-MM-DD")}&idGeneratedTO=${moment(filters["idGenerationDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["interviewDate"][0] && !filters["interviewDate"][1])
            url = `${url}&interviewDateFrom=${moment(filters["interviewDate"][0]).format("YYYY-MM-DD")}&interviewDateTO=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["interviewDate"][0] && filters["interviewDate"][1])
            url = `${url}&interviewDateFrom=2019-01-01&interviewDateTO=${moment(filters["interviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["interviewDate"][0] && filters["interviewDate"][1])
            url = `${url}&interviewDateFrom=${moment(filters["interviewDate"][0]).format("YYYY-MM-DD")}&interviewDateTO=${moment(filters["interviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["leadAddedDate"][0] && !filters["leadAddedDate"][1])
            url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format("YYYY-MM-DD")}&leadAddedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
            url = `${url}&leadAddedFrom=2019-01-01&leadAddedTo=${moment(filters["leadAddedDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
            url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format("YYYY-MM-DD")}&leadAddedTo=${moment(filters["leadAddedDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["firstEarningDate"][0] && !filters["firstEarningDate"][1])
            url = `${url}&firstEarningFrom=${moment(filters["firstEarningDate"][0]).format("YYYY-MM-DD")}&firstEarningTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
            url = `${url}&firstEarningFrom=2019-01-01&firstEarningTo=${moment(filters["firstEarningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
            url = `${url}&firstEarningFrom=${moment(filters["firstEarningDate"][0]).format("YYYY-MM-DD")}&firstEarningTo=${moment(filters["firstEarningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        // appLead
        // callStatus:"",
        // interviewStatus:"",
        // recruiterId:"",
        // trainingUpdate:"",
        // preferedHub:""

        if (filters["lastEarning"].id === "last3days")
        url = `${url}&lastEarningFrom=${moment().subtract(2, "days").format("YYYY-MM-DD")}&lastEarningTo=${moment().format("YYYY-MM-DD")} 23:59:59`
        if (filters["lastEarning"].id === "last5days")
        url = `${url}&lastEarningFrom=${moment().subtract(4, "days").format("YYYY-MM-DD")}&lastEarningTo=${moment().format("YYYY-MM-DD")} 23:59:59`
        if (filters["lastEarning"].id === "last7days")
        url = `${url}&lastEarningFrom=${moment().subtract(6, "days").format("YYYY-MM-DD")}&lastEarningTo=${moment().format("YYYY-MM-DD")} 23:59:59`
    return url
    }

    const resetFilters = () => {
        const filterList = {
            source: "",
            searchBy: "",
            zone: "",
            facilityState: "",
            facilityName: "",
            partnerId: "",
            facilityCity: "",
            workFor: "",
            workerStatus: "",
            psf: "",
            callStatus: "",
            interviewStatus: "",
            recruiterId: "",
            trainingUpdate: "",
            preferedHub: "",
            appLead: "",
            interviewDate: [null, null],
            firstEarningDate: [null, null],
            leadAddedDate: [null, null],
            bankDetails: "",
            leadCity: "",
            leadSource: "",
            lastEarning:"",
            appStatus: "",
            clientEarningStatus: "",
            clientStatus: "",
            RecruiterAssignedBetween: [null, null],
            idGenerationDate: [null, null],
            IsLmaStatus:"",
            bgvStatus: "", 
            lastAppliedBy: ""
            
        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    const resetFiltersWithoutSearch = () => {
        const filterList = {
            source: "",
            searchBy: filters.searchBy,
            zone: "",
            facilityState: "",
            facilityName: "",
            partnerId: "",
            facilityCity: "",
            workFor: "",
            workerStatus: "",
            psf: "",
            callStatus: "",
            interviewStatus: "",
            recruiterId: "",
            trainingUpdate: "",
            preferedHub: "",
            appLead: "",
            interviewDate: [null, null],
            firstEarningDate: [null, null],
            leadAddedDate: [null, null],
            bankDetails: "",
            leadCity: "",
            leadSource: "",
            lastEarning:"",
            appStatus: "",
            clientEarningStatus: "",
            clientStatus: "",
            RecruiterAssignedBetween: [null, null],
            idGenerationDate: [null, null],
            IsLmaStatus:"",
            bgvStatus: "", 
            lastAppliedBy: ""
            
        }
        setFilters(filterList)
        setFilterUrl(filters.searchBy?"&searchBy="+filters.searchBy:"")
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    // useEffect(() => {
    //     ApplyFilters(filters);
    // }, [filters])
    // useEffect(() => {
    //     // if(tab.leadDataTab===true){
    //     //     setisLeadData("&leadRecords=1")
    //     // }else{
    //     //     setisLeadData("")
    //     // }
    //     updateMyData();
    //     // ApplyFilters(filters);
    // }, [tab])
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }

    // const createZipForDocuments =() =>{
    //     var zip = new JSZip();
    //     var count = 0;
    //     var zipFilename = "zipFilename.zip";
    //     var urls = [
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1870/8b7dc94f-98ff-4316-bebb-1c476f2709f8.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c5e9caab4c0e9d094879adc2a3966d5d682e6d268ad8c88958ccbbb88794ca7b",
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1867/30618c97-175e-409b-a3ef-5fd726844b16.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=43fccb148919d336ae972fbc644e252f65c023b7f789c14286c014a06fc3ec97",
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1867/30618c97-175e-409b-a3ef-5fd726844b16.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=43fccb148919d336ae972fbc644e252f65c023b7f789c14286c014a06fc3ec97",
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1869/8eb75c96-a75a-4f0d-b848-c0e828142d31.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=2323ebd055168edbbc1ca4e12e3ab252086e230a42254d984ea3be5a2033241e"
    //     ];
    //     urls.forEach(async function (url, index){
    //     const filename = `file-${index}.jpg`;
    //     try {
    //         const file = await JSZipUtils.getBinaryContent(url)
    //         zip.file(filename, file, { binary: true});
    //         count++;
    //         // console.log({zip})
    //         if(count === urls.length) {
    //         zip.generateAsync({type:'blob'}).then(function(content) {
    //             saveAs(content, zipFilename);
    //         });
    //         }
    //     } 
    //     catch (err) {
    //         // console.log(err);
    //     }
    //     });



    // }
    // useEffect(() => {
    //     // console.log({allDocumentDownload})
    //     if(allDocumentDownload){

    //         if(allDocumentDownload.length===0){
    //             showToast("error", "No documents found")       
    //         }
    //         else{
    //             createZipForDocuments()
    //         }
    //     }
    // }, [allDocumentDownload])


    //columns list
    // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const exp = (p) => {
        return (<>{!viewMore ? <div>HEYYYYYYY {p}
            <button className={styles.workerName} type="button" onClick={handleViewMore}>View More</button>
        </div> : <div>chnaged</div>}</>)
    }
    const handleViewMore = () => {
        // console.log("C")
        setViewMore(!viewMore)
    }
    const createBGVStatus= (status) =>{
        switch (status) {
            case 0:  //Not initiated
              return "Not initiated"
            case 1:  //in prgress
              return "In Process"
            case 2: //verified
              return "Verified"
            case 3:  //failed
              return "Failed"
            case 4:  //incomplete details
              return "Incomplete Details"
            case 5:  //No status
              return "-"
            default:
              return
        }
    }
    const workerDataColumn = useMemo(
        () => [

            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{display:"flex", justifyItems:"center"}} user={row} />
                    </div>
                ),
            },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Name",
                accessor: "name",
                Cell: row => {
                    return row.row.original.criminalRecord === "Yes" ? <button className={styles.workerName} type="button" id={row.row.original.userId} onClick={handleCriminalRecord} >{row.row.original.name}</button> : <Link to={{ pathname: `/workerview/v2/${row.row.original.contactNo_E}` }} target="_blank" className="nameLink">{row.row.original.name}</Link>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "#SMS delivered",
            //     accessor: "smsCount",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Father's Name",
                accessor: "fathersName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "DOB",
                accessor: "dob",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Source",
                accessor: "source",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Lead Source",
                accessor: "sourceLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Lead Added On",
                accessor: "leadAddedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client Status",
                // accessor: "status",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            if (el.clientName)
                                return <div key={`${el.partnerId}-${el.workerId}-status`}><b>{el.clientName}: </b>{createClientStatus(el.clientIdStatus)}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client ID(s)",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            return el.client_id && <div key={`${el.partnerId}-${el.workerId}-id`}><b>{el.clientName}: </b>{el.client_id}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "BGV Status",
                accessor: "BGVstatus",
                Cell: ({row}) => {
                    return <BGVStatus status={row.original.BGVstatus} />
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Earning Status",
                // accessor: "zone",
                Cell: row => {
                        return (<div>
                        {row.row.original.facilityData.map(el => {
                            return (el.earning_status === 1 || el.earning_status === 0) && <div key={`${el.partnerId}-${el.workerId}-earning`}><b>{el.clientName}: </b>{el.earning_status === 1 ? "Active" : "Inactive"}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: row => {
                    return <div>App Status          
                        <HtmlTooltip 
                            title={
                                <div>
                                    <ul>
                                        <li><b>Registered:</b> verified OTP</li>
                                        <li><b>Basic details filled:</b> filled name ,father name,dob</li>
                                        <li><b>Documents-Partially pending:</b> Some document uploaded</li>
                                        <li><b>Onboarding Done:</b>All docs uploaded(Aadhar front/back ,DL,Current address ,PAN)</li>
                                        <li><b>Not registered:</b> app not downloaded</li>
                                    </ul>
                                </div>
                            }
                        >
                        <InfoIcon style={{ verticalAlign: 'bottom' }} fontSize='small' /></HtmlTooltip></div>
                },
                accessor: "appStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Documents Pending",
                Cell: row => {
                    return <div style={{width: "250px"}}>{row.row.original.documentsPending}</div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Documents",
                accessor: "documents",
                Cell: DocumentDownload,
                // Cell: row => {
                //     return <button className={styles.workerName} type="button" style={{ fontSize: '16px !important' }} onClick={() => getDocumentList(row.row.original.userId, row.row.original.profilePhoto, row.row.original.okaygoId, row.row.original.name)} >Download</button>
                // },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank Details",
                accessor: "bankDetails",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last SMS sent",
                Cell: LastSentMsgs,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Aadhar Number",
                accessor: "aadharNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "City",
                accessor: "city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Address (Google address)",
                Cell: row => {
                    return <div style={{width: "250px"}}>{row.row.original.addressGoogle}</div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility name",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            if (el.clientName === "Flipkart")
                                return el.facilityName && <div key={`${el.partnerId}-${el.workerId}-Fname`}><b>{el.clientName}: </b>{el.facilityName}</div>
                            else
                                return el.clusterName && <div key={`${el.partnerId}-${el.workerId}-Fname`}><b>{el.clientName}: </b>{el.clusterName}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility city",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                             if (el.clientName === "Flipkart")
                            return el.facilityCity && <div key={`${el.partnerId}-${el.workerId}-Fcity`}><b>{el.clientName}: </b>{el.facilityCity}</div>
                            else
                            return el.clusterCity && <div key={`${el.partnerId}-${el.workerId}-Fcity`}><b>{el.clientName}: </b>{el.clusterCity}</div>

                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility state",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                             if (el.clientName === "Flipkart")
                            return el.facilityState && <div  key={`${el.partnerId}-${el.workerId}-Fstate`}><b>{el.clientName}: </b>{el.facilityState}</div>
                            else
                            return el.clusterState && <div key={`${el.partnerId}-${el.workerId}-Fstate`}><b>{el.clientName}: </b>{el.clusterState}</div>
                            
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Zone",
                defaultCanFilter: false,
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            return el.zone && <div key={`${el.partnerId}-${el.workerId}-zone`}><b>{el.clientName}: </b>{el.zone}</div>
                        })}
                    </div>);
                },
                defaultCanSort: false
            },
            {
                Header: "Modal Flag",
                accessor: "lmaGroupFlexEflex",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Rate Card",
                defaultCanFilter: false,
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            if(el.clientName==="Flipkart"){
                                return <div key={`${el.partnerId}-${el.workerId}-rateCard`}><b>{el.clientName}: </b>{row.row.original.is_lma_worker?el.lmaRateCard:el.rateCard}</div>
                            }
                            return el.rateCardType && <div key={`${el.partnerId}-${el.workerId}-rateCard`}><b>{el.clientName}: </b>{el.rateCardType}</div>
                        })}
                    </div>);
                },
                defaultCanSort: false
            },
            {
                Header: "First Earning Date",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            return el.first_earning_date && <div key={`${el.partnerId}-${el.workerId}-firstEarning`}><b>{el.clientName}: </b>{moment(el.first_earning_date).format("DD/MM/YYYY")}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Earning Date",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            return el.lastEarningOn && <div key={`${el.partnerId}-${el.workerId}-lastEarning`}><b>{el.clientName}: </b>{moment(el.lastEarningOn).format("DD/MM/YYYY")}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Cross Utilization Status",
                accessor: "crossUtilizationStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Job applied on",
                accessor: "lastJobAppliedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Job applied for",
                Cell: LastAppliedJob,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Recruiter Assigned",
                accessor: "recruiterAssignedName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Assigned On",
                accessor: "assignedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Interview For",
                accessor: "client",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            
            {
                Header: "Call Status",
                accessor: "callStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Interview Date",
                accessor: "interviewDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Interview Status",
                accessor: "interviewStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Training Start Date",
                accessor: "trainingStartDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Training Update",
                accessor: "trainingUpdate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Updated On",
                accessor: "lastUpdate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "ID Generated On",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            return el.client_id_inserted_on && <div key={`${el.partnerId}-${el.workerId}-idGenOn`}><b>{el.clientName}: </b>{moment(el.client_id_inserted_on).format("DD/MM/YYYY")}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "FOD Status",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            return el.fodStatus && <div key={`${el.partnerId}-${el.workerId}-fod`}><b>{el.clientName}: </b>{el.fodStatus===1?"Done":el.fodStatus===2?"Pending":""}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "FOD Follow up status",
                Cell: row => {
                    return (<div>
                        <div>{row.row.original.followupstatus}</div>
                        {row.row.original.concern && <div><b>Remark : </b>{row.row.original.concern}</div>}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added on",
                accessor: "addedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added by",
                accessor: "addedBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },

        ],
        []
    );
    const handleSendMsg = () => {
        const indexes = Object.entries(selectedRowIds);
        let idList = ""
        indexes.map((id, index) => {
            if (id[1]) {
                // console.log(data[parseFloat(id[0])].userId)
                idList += data[parseFloat(id[0])].userId + ",";
            }

            // console.log(idList)
            return true;
        })
        idList = idList.substr(0, idList.length - 1)
        // console.log(idList)
        setSelectedUserIds(idList)
        setOpenSendMsg(!openSendMsg)
    }
    const handleTriggerBGV =() =>{
        const indexes = Object.entries(selectedRowIds);
        let idList = []

        indexes.map((id, index) => {
            idList.push(data[parseFloat(id[0])].userId)
            return true;
        })
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.triggerBGV}${idList.toString()}`)
            .then(response => { 
                let errors=[]
                response.data.response.errors.map(error =>{
                    errors.push({
                        userId: error.userId,
                        name: `${error.firstName} ${error.lastName?error.lastName:""}`,
                        contactNo: error.contact,
                        reason: error.resaon
                    })
                    return true
                })
                setBGVResponse({
                    errorList: errors,
                    success: response.data.response.success,
                    fail: response.data.response.fail,
                    total: response.data.response.total,
                })
                console.log({response})
        }).catch(error => {
            console.log(error)
        })
        
    }
    useEffect(() => {
      if(BGVResponse){
          setOpenBGVTriggerModal(true)
      }
    }, [BGVResponse])
    const handleCloseBGVModal =() =>{
        setBGVResponse(null)
        setOpenBGVTriggerModal(false)
        updateMyData()
    }
    const leadsColumn = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => (
                    <><div >
                        <IndeterminateCheckbox   {...row.getToggleRowSelectedProps()} style={{display:"flex", justifyItems:"center"}} />
                    </div> </>
                ),
            },
            {
                Header: "Lead id",
                accessor: "leadId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: row => { // till the worker id not assigned to lead -> showing lead view
                    if (row.row.original.workerId === null || row.row.original.workerId === undefined) {
                        return row.row.original.criminalRecord === "Yes" ? <button className={styles.workerName} type="button" id={row.row.original.userId} onClick={handleCriminalRecord} >{row.row.original.name}</button> : <Link to={{ pathname: `/LeadWorkerview/v2/${row.row.original.contactNo_E}` }} className="nameLink">{row.row.original.name}</Link>
                    } else {
                        return row.row.original.criminalRecord === "Yes" ? <button className={styles.workerName} type="button" id={row.row.original.userId} onClick={handleCriminalRecord} >{row.row.original.name}</button> : <Link to={{ pathname: `/workerview/v2/${row.row.original.contactNo_E}` }} className="nameLink">{row.row.original.name}</Link>

                    }
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            
            {
                Header: "Father's Name",
                accessor: "fathersName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "DOB",
                accessor: "dob",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Source",
                accessor: "sourceLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                // Header: "Profile Completion"+<InfoIcon></InfoIcon>,
                Header: row => {
                    return <div>App Status      
                        <HtmlTooltip 
                            title={
                                <div>
                                    <ul>
                                        <li><b>Registered:</b> verified OTP</li>
                                        <li><b>Basic details filled:</b> filled name ,father name,dob</li>
                                        <li><b>Documents-Partially pending:</b> Some document uploaded</li>
                                        <li><b>Onboarding Done:</b>All docs uploaded(Aadhar front/back ,DL,Current address ,PAN)</li>
                                        <li><b>Not registered:</b> app not downloaded</li>
                                    </ul>
                                </div>
                            }
                        >
                        <InfoIcon style={{ verticalAlign: 'bottom' }} fontSize='small' /></HtmlTooltip></div>
                },
                accessor: "appStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Documents Pending",
            //     accessor: "documentsPending",
            //     Cell: row => {
            //         return <div style={{width: "250px"}}>{row.row.original.documentsPending}</div>
            //     },
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Last SMS sent",
                // accessor: "zone",
                Cell: LastSentMsgs,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Aadhar Number",
                accessor: "aadhaarNumberLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Documents",
                accessor: "documents",
                Cell: DocumentDownload,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "City",
                accessor: "leadCity",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Address",
                accessor: "addressLead",
                Cell: row => {
                    return <div style={{width: "250px"}}>{row.row.original.addressLead}</div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Job applied on",
                accessor: "lastJobAppliedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Job applied for",
                Cell: LastAppliedJob,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Recruiter Assigned",
                accessor: "recruiterAssignedLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Assigned On",
                accessor: "assignedOnLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Call Status",
                accessor: "callStatusLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "First called on",
                accessor: "firstCalledOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last called on",
                accessor: "lastCalledOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Interview Date",
                accessor: "interviewDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client Name",
                accessor: "clientNameLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Preferred Hub",
                accessor: "preferredHubLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Interview Status",
                accessor: "interviewStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Training Start Date",
                accessor: "trainingStartDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Training Update",
                accessor: "trainingUpdate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last Updated On",
                accessor: "lastUpdateLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Follow Up Date & Time",
                accessor: "followUpLead",
                Cell: row => {
                    return <div style={{width: "0px"}}>{row.row.original.followUpLead}</div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added on",
                accessor: "addedOnLead",
                defaultCanFilter: false,
                defaultCanSort: false
            },
        ],
        []
    );
    const createClientStatus = (status) => {
        let text = ""
        switch (status) {
            case 0:
                text = "Offboarded";
                break;
            case 1:
                text = "Active";
                break;
            default:

                break;
        }
        return text;
    }

    const createWorkerStatus = (status) => {
        let text = ""
        switch (status) {
            case 0:
                text = "Not Onboarded";
                break;
            case 1:
                text = "Active";
                break;
            case 2:
                text = "Partially Onboarded";
                break;
            case 3:
                text = "Inactive";
                break;
            case 4:
                text = "Offboarding Initiated";
                break;
            case 5:
                text = "Onboarding Completed";
                break;
            case 6:
                text = "Temporarily Inactive";
                break;
            case 7:
                text = "Temporary Inactivation Initiated";
                break;
            case 8:
                text = "Reactivation Initiated";
                break;
            default:

                break;
        }
        return text;
    }

    const handleAssignFilter = (ev) => {
        if (ev === "assigned") {

            setAssignFilter({ ...AssignFilter, notAssignTab: false, assignTab: true, all: false })
            setassignFilterString("&notAssigned=1")
            setCurrentPage(1)
            // setTab({ ...tab, leadDataTab: true, workerDataTab: false })
        } else if (ev === "notAssigned") {
            setAssignFilter({ ...AssignFilter, notAssignTab: true, assignTab: false, all: false })
            setassignFilterString("&notAssigned=0")
            setCurrentPage(1)
        }
        else if (ev === "all") {
            console.log("hey")
            setAssignFilter({ ...AssignFilter, notAssignTab: false, assignTab: false, all: true })
            setassignFilterString("")
            setCurrentPage(1)
        }


    }
    const setLeadRecruiterDetail = (recruiterList, type) =>{
        // console.log(recruiterList)
        // console.log(type)
        // console.log(recruiterList[recruiterList.length-1]?.callStatus, recruiterList.length-1, type)
        if(recruiterList.length===0){
            return "" 
        }
        if(type==="client"){
            return recruiterList[recruiterList.length-1]?.employerName?recruiterList[recruiterList.length-1]?.employerName:"";
        }
        if(type==="interviewDate"){
            return `${recruiterList[recruiterList.length-1]?.interviewDate? moment(recruiterList[recruiterList.length-1]?.interviewDate).format("DD/MM/YYYY"):""}`
        }
        if(type==="trainingStartDate"){
            return `${recruiterList[recruiterList.length-1]?.trainingStartDate? moment(recruiterList[recruiterList.length-1]?.trainingStartDate).format("DD/MM/YYYY"):""}`
        }
        if(type==="callStatus"){
            return `${recruiterList[recruiterList.length-1]?.callStatus?recruiterList[recruiterList.length-1]?.callStatus: ""}`
        }
        if(type==="followupstatus"){
            return `${recruiterList[recruiterList.length-1]?.followupStatus?recruiterList[recruiterList.length-1]?.followupStatus: ""}`
        }
        
        if(type==="interviewStatus"){
            return `${recruiterList[recruiterList.length-1]?.interviewStatus?recruiterList[recruiterList.length-1]?.interviewStatus : ""}`
        }
        if(type==="trainingUpdate"){
            return `${recruiterList[recruiterList.length-1]?.trainingStatus?recruiterList[recruiterList.length-1]?.trainingStatus : ""}`
        }
        if(type==="assignedOn"){
            return `${recruiterList[recruiterList.length-1]?.insertedOn? moment(recruiterList[recruiterList.length-1]?.insertedOn).format("DD/MM/YYYY"):""}`
        }
        
        if(type==="lastUpdate"){
            return `${recruiterList[recruiterList.length-1]?.updatedOn? moment(recruiterList[recruiterList.length-1]?.updatedOn).format("DD/MM/YYYY hh:mm a"):""}`
        }
        
        
        if(type==="firstCalledOn"){
            return `${recruiterList[recruiterList.length-1]?.firstcalled_on? moment(recruiterList[recruiterList.length-1]?.firstcalled_on).format("DD/MM/YYYY hh:mm a"):""}`
        }
        
        if(type==="lastCalledOn"){
            return `${recruiterList[recruiterList.length-1]?.lastcalled_on? moment(recruiterList[recruiterList.length-1]?.lastcalled_on).format("DD/MM/YYYY hh:mm a"):""}`
        }
        if(type==="callStatusLead"){
            return `${recruiterList[recruiterList.length-1]?.callStatus?recruiterList[recruiterList.length-1]?.callStatus : ""}`
        }
        // return `${recruiterList[recruiterList.length-1].recruiterAssignedName} ${recruiterList[recruiterList.length-1].recruiterAssignedNumber}`
        return ""
    }
    //Function to create table data
    const makeData = (response) => {
        
        
        let extractedData = [];
        let lastupdatedon = [];
        try {
            response.content.map(el => {
                console.log("el====>", { el });
                const sourceByType = el.sourceBy === "VENDOR" ? "Vendor" : el.sourceBy === "TA" ? "HRBP/TA" : el.sourceBy === "EREF" ? "Employee reference" : "";
                const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : "";
                const addedEmail = el.workerInsertedByEmail ? `(${Decrypt(el.workerInsertedByEmail)})` : "";
                const lastAppliedDetails = el.lastJobAppliedDetails ? el.lastJobAppliedDetails.split("^") : [];
                console.log("=====>", lastAppliedDetails.slice(1));
                // console.log({lastAppliedDetails})
                extractedData.push({
                    okaygoId: el.okaygoId ? el.okaygoId : "",
                    name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                    contactNo: el.phoneNumber ? Decrypt(el.phoneNumber) : "",
                    contactNo_E: el.phoneNumber ? el.phoneNumber.replaceAll('/', '%2F').replaceAll('+', '%2B') : "",
                    source: el.partnerName ? el.partnerName : "",
                    facilityData: el.clientList ? el.clientList : [],
                    appStatus: el.appStatus ? el.appStatus : "",
                    documentsPending: el.pendingDoucment ? el.pendingDoucment : "",
                    bankDetails: el.accountNumber ? "Yes" : "No",
                    lastSmsTemplate: el.lastSmsTemplate ? el.lastSmsTemplate : "",
                    lastSmsTime: el.lastSmsTime ? (moment(el.lastSmsTime).format("hh:mm a, DD/MM/YYYY")) : "",
                    lastJobAppliedOn: lastAppliedDetails[0] ? (moment(lastAppliedDetails[0]).format("DD/MM/YYYY")) : "",
                    lastJobAppliedFor: lastAppliedDetails.length > 1 ? lastAppliedDetails.slice(1) : [],
                    lastSmsMessage: el.lastSmsMessage ? el.lastSmsMessage : "",
                    aadharNumber: Decrypt(el.aadhaarNumber) ? Decrypt(el.aadhaarNumber) : "",
                    BGVstatus: el.bgvFinalStatus !== null ? el.bgvFinalStatus : "", // Add null check here
                    city: el.userCity ? el.userCity : "",
                    addressGoogle: el.userGoogleLocation ? el.userGoogleLocation : "",
                    is_lma_worker: el.is_lma_worker !== null ? el.is_lma_worker : null, // Add null check here
                    lmaGroupFlexEflex: el.is_lma_worker ? "LMA" : el.groupflexId ? "GroupFlex" : "Eflex",
                    crossUtilizationStatus: el.crossUtilStatus ? el.crossUtilStatus : "",
                    recruiterAssignedName: el.recruiterAssignedName !== null ? el.recruiterAssignedName + (el.recruiterAssignedNumber !== null ? " - " + Decrypt(el.recruiterAssignedNumber) : "") : "",
                    assignedOn: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "assignedOn") : "",
                    client: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "client") : "",
                    callStatus: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "callStatus") : "",
                    interviewDate: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "interviewDate") : "",
                    interviewStatus: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "interviewStatus") : "",
                    trainingStartDate: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "trainingStartDate") : "",
                    trainingUpdate: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "trainingUpdate") : "",
                    lastUpdate: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "lastUpdate") : "",
                    followUpLead: `${el.lastUpdate ? (el.lastUpdate.follow_up_date ? moment(el.lastUpdate.follow_up_date).format("DD/MM/YYYY") : "-") : "-"} ${el.lastUpdate ? (el.lastUpdate.follow_up_time ? moment(el.lastUpdate.follow_up_time)._i : "-") : "-"}`,
                    idGeneratedOn: el.client_id_inserted_on ? moment(el.client_id_inserted_on).format("DD/MM/YYYY") : "",
                    addedOn: el.workerInsertedOn ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a") : "",
                    addedBy: `${el.workerInsertedByName ? el.workerInsertedByName : ""} ${addedEmail}`,
                    leadId: el.leadId ? el.leadId : "",
                    clientNameLead: el.clientName ? el.clientName : "",
                    sourceLead: el.leadSource ? el.leadSource : "",
                    aadhaarNumberLead: Decrypt(el.aadharNumberLead) ? Decrypt(el.aadharNumberLead) : "",
                    addressLead: el.userGoogleLocation ? el.userGoogleLocation : "",
                    recruiterAssignedLead: `${el.recruiterAssignedName ? el.recruiterAssignedName : ""} ${el.recruiterAssignedNumber ? Decrypt(el.recruiterAssignedNumber) : ""}`,
                    assignedOnLead: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "assignedOn") : "",
                    callStatusLead: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "callStatusLead") : "",
                    interviewDateLead: el.interviewDate ? moment(el.interviewDate).format("DD/MM/YYYY") : "",
                    preferredHubLead: el.preferredHub ? el.preferredHub : "",
                    interviewStatusLead: el.interviewStatus ? el.interviewStatus : "",
                    trainingStartDateLead: el.trainingStartDate ? moment(el.trainingStartDate).format("DD/MM/YYYY") : "",
                    trainingUpdateLead: el.trainingUpdate ? el.trainingUpdate : "",
                    firstCalledOn: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "firstCalledOn") : "",
                    lastCalledOn: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "lastCalledOn") : "",
                    leadAddedOn: el.leadAddedOn ? moment(el.leadAddedOn).format("DD/MM/YYYY hh:mm a") : "",
                    lastUpdateLead: el.recruitertList ? setLeadRecruiterDetail(el.recruitertList, "lastUpdate") : "",
                    followupstatus: `${el.follow_up_status ? el.follow_up_status : ""}`,
                    concern: `${el.concern ? el.concern : ""}`,
                    addedOnLead: el.leadInsertedOn ? moment(el.leadInsertedOn).format("DD/MM/YYYY hh:mm a") : "",
                    registeredOnApp: (el.appLead !== null && el.appLead !== 0) ? (moment(el.leadInsertedOn).format("DD/MM/YYYY hh:mm a")) : "--",
                    leadCity: el.leadCity ? el.leadCity : "",
                    userGoogleLocation: el.userGoogleLocation ? el.userGoogleLocation : "",
                    leadIsAdhaar: el.leadIsAdhaar ? (el.leadIsAdhaar === 1 ? "Yes" : "No") : "No",
                    leadIsDrivingLicense: el.leadIsDrivingLicense ? (el.leadIsDrivingLicense === 1 ? "Yes" : "No") : "No",
                    leadIsBike: el.leadIsBike ? (el.leadIsBike === 1 ? "Yes" : "No") : "No",
                    leadPincode: el.leadPincode ? el.leadPincode : "",
                    profileStatus: el.profileStatus === "4" ? "Completed" : el.profileStatus + "/4",
                    functionalArea: el.functionalArea ? el.functionalArea : "",
                    smsCount: el.smsSentCount ? el.smsSentCount : "",
                    smsDeliveryStatus: el.smsDeliveryStatus ? el.smsDeliveryStatus : "",
                    LastSmsDeliveredOn: el.smsDeliveredOn ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a") : "",
                    status: createWorkerStatus(el.workerStatus),
                    fathersName: el.fatherName ? el.fatherName : "",
                    gender: el.gender === "26" ? "Male" : el.gender === "27" ? "Female" : el.gender === "30" ? "Other" : "",
                    dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
                    maritalStatus: el.maritalStatus === 1871 ? "Single" : el.maritalStatus === 1872 ? "Married" : el.maritalStatus === 1873 ? "Other" : "",
                    edab: el.physicallyDisabled === 1 ? "Yes" : el.physicallyDisabled === 0 ? "No" : "",
                    criminalRecord: el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
                    present_address: el.curAddressLine1 ? el.curAddressLine1 : "",
                    workerStatus: el.workerStatus,
                    present_city: el.curCity ? el.curCity : "",
                    present_state: el.curStateName ? el.curStateName : "",
                    permanent_pincode: el.perPincode ? el.perPincode : "",
                    present_pincode: el.curPincode ? el.curPincode : "",
                    leadSource: el.leadSource ? el.leadSource : "",
                    permanent_address: el.perAddressLine1 ? el.perAddressLine1 : "",
                    permanent_city: el.perCity ? el.perCity : "",
                    permanent_state: el.perStateName ? el.perStateName : "",
                    emergencyContactNo: el.emergencyContact ? el.emergencyContact : "",
                    qualification: el.qualification ? el.qualification : "",
                    workExperience: (el.totalExpYear || el.totalExpMonth) ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${el.totalExpMonth ? el.totalExpMonth : 0} months` : "",
                    rolePreference: el.jobRolePreferenceName ? el.jobRolePreferenceName : "",
                    sourcedBy: `${sourceByType}${sourceByName}`,
                    zone: el.zone ? el.zone : "",
                    facility_name: el.facilityName ? el.facilityName : "",
                    facility_city: el.facilityCity ? el.facilityCity : "",
                    facility_state: el.facilityState ? el.facilityState : "",
                    rateCard: el.rateCardType ? el.rateCardType : "",
                    facility_type: el.facilityType ? el.facilityType : "",
                    designation: el.designation ? el.designation : "",
                    date_of_joining: el.dateOfJoining ? moment(el.dateOfJoining).format("DD/MM/YYYY") : "",
                    activationDate: el.activeDate ? moment(el.activeDate).format("DD/MM/YYYY") : "",
                    reactivationDate: el.reactivationDate ? moment(el.reactivationDate).format("DD/MM/YYYY") : null,
                    reactivationDateTimestamp: el.reactivationDate ? el.reactivationDate : null,
                    exitType: el.exitType ? el.exitType : "",
                    exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
                    deactivationDate: el.inactiveDate ? moment(el.inactiveDate).format("DD/MM/YYYY") : "",
                    reasonOfLeaving: el.reasonOfLeaving ? el.reasonOfLeaving : "",
                    offboardingInitiateDate: el.offboardingInitiationDate ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY") : "",
                    offboardingInitiateBy: el.offboardingInitiateBy ? el.offboardingInitiateBy : "",
                    blacklisted: el.blacklisted === 1 ? "Yes" : el.blacklisted === 0 ? "No " : "",
                    userId: el.userId ? el.userId : null,
                    agreementPath: el.agreementFilePath ? el.agreementFilePath : "",
                    agreementName: el.agreementFileName ? el.agreementFileName : "",
                    profilePhoto: el.profilePhoto ? el.profilePhoto : "",
                    groupflex: el.groupflexId ? el.groupflexId : 'Eflex',
                    partnerName: el.partnerName ? el.partnerName : "",
                    workerId: el.workerId ? el.workerId : null,
                    clients: makeClients(el.facilityName, el.zomatoWorkerStatus, el.swiggyWorkerStatus, el.delhiveryWorkerStatus, el.jiomartWorkerStatus),
                    documentsVerified: el.documentsVerified !== null ? el.documentsVerified : "", // Add null check here
                    leadInsertedOn: el.leadInsertedOn ? moment(el.leadInsertedOn).format("DD/MM/YYYY") : "",
                    profileCompleted: el.docsUploadedUser !== null ? (el.docsUploadedUser === 1 ? "YES" : "NO") : "-",
                    leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
                    leadState: el.leadState ? el.leadState : '-',
                    roleType: el.category ? el.category : '-',
                    jobCity: el.jobCity ? el.jobCity : '-',
                    jobState: el.jobState ? el.jobState : '-'
                });
                lastupdatedon.push(el.lastUpdatedOn);
                return true;
            });
            
            
            // console.log("extractedData====>",{ extractedData })
            return extractedData;
        } catch(err){
            console.log("errror======>",err)
        }
        
    }
    // console.log(data.zomatoWorkerStatus)

    const makeClients = (casperId, zomatoId, swiggyId, delhiveryId, jiomartId) => {
        let str = " ";
        if (casperId !== null && casperId !== undefined) {
            str = str + "Flipkart,"
        }
        if (zomatoId !== null && zomatoId !== undefined) {
            str = str + "Zomato,"
        }
        if (swiggyId !== null && swiggyId !== undefined) {
            str = str + "Swiggy,"
        }
        if (delhiveryId !== null && delhiveryId !== undefined) {
            str = str + "Delhivery,"
        }
        if (jiomartId !== null && jiomartId !== undefined) {
            str = str + "Jiomart,"
        }
        return str.substring(0, str.length - 1);


    }
    const makeMultiClient = (clients) => {
        let str = " ";
        clients.map(el => {
            str += el.clientName + ","
        })
        return str.substring(0, str.length - 1);
    }



    //Function to create All record export data
    // const makeAllRecordData = (response) => {
    //     let extractedData = [];
    //     let lastupdatedon = [];
    //     response.map(el => {
    //         const addedEmail = el.workerInsertedByEmail ? `(${el.workerInsertedByEmail})` : ""
    //         const temp = {
    //             casperId: el.casperId || "",
    //             okaygoId: el.okaygoId || "",
    //             smsCount: el.smsSentCount || "",
    //             smsDeliveryStatus: el.smsDeliveryStatus || "",
    //             LastSmsDeliveredOn: el.smsDeliveredOn ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a") : "",
    //             status: createWorkerStatus(el.workerStatus),
    //             name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
    //             fathersName: el.fatherName || "",
    //             contactNo: el.phoneNumber || "",
    //             aadharNumber: el.aadhaarNumber || "",
    //             gender: el.gender === "26" ? "Male" : el.gender === "27" ? "Female" : el.gender === "30" ? "Other" : "",
    //             dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
    //             maritalStatus: el.maritalStatus === 1871 ? "Single" : el.maritalStatus === 1872 ? "Married" : el.maritalStatus === 1873 ? "Other" : "",
    //             edab: el.physicallyDisabled === 1 ? "Yes" : el.physicallyDisabled === 0 ? "No" : "",
    //             criminalRecord: el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
    //             present_address: el.curAddressLine1 || "",
    //             present_city: el.curCity || "",
    //             present_state: el.curStateName || "",
    //             permanent_address: el.perAddressLine1 || "",
    //             permanent_city: el.perCity || "",
    //             permanent_state: el.perStateName || "",
    //             emergencyContactNo: el.emergencyContact || "",
    //             qualification: el.qualification || "",
    //             bankDetails: el.accountNumber ? "Yes" : "No",
    //             workExperience: (el.totalExpYear || el.totalExpMonth) ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${el.totalExpMonth ? el.totalExpMonth : 0} months` : "",
    //             groupflex: el.groupflexId ? el.groupflexId : 'Eflex',
    //             rolePreference: el.jobRolePreferenceName || "",
    //             sourcedBy: el.sourceBy === "VENDOR" ? "Vendor" : el.sourceBy === "TA" ? "HRBP/TA" : el.sourceBy === "EREF" ? "Employee reference" : "",
    //             zone: el.zone || "",
    //             facility_name: el.facilityName || "",
    //             facility_city: el.facilityCity || "",
    //             facility_state: el.facilityState || "",
    //             rateCard: el.rateCardType || "",
    //             facility_type: el.facilityType ? el.facilityType : "",
    //             designation: el.designation || "",
    //             date_of_joining: el.dateOfJoining ? moment(el.dateOfJoining).format("DD/MM/YYYY") : "",
    //             activationDate: el.activeDate ? moment(el.activeDate).format("DD/MM/YYYY") : "",
    //             reactivationDate: el.reactivationDate ? moment(el.reactivationDate).format("DD/MM/YYYY") : null,
    //             exitType: el.exitType || "",
    //             exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
    //             deactivationDate: el.inactiveDate ? moment(el.inactiveDate).format("DD/MM/YYYY") : "",
    //             reasonOfLeaving: el.reasonOfLeaving || "",
    //             offboardingInitiateDate: el.offboardingInitiationDate ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY") : "",
    //             offboardingInitiateBy: el.offboardingInitiateBy || "",
    //             blacklisted: el.blacklisted === 1 ? "Yes" : "No ",
    //             addedBy: `${el.workerInsertedByName ? el.workerInsertedByName : ""} ${addedEmail}`,
    //             addedOn: el.workerInsertedOn ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a") : "",
    //             partner: el.partnerName ? el.partnerName : ""


    //         }
    //         if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") {
    //             delete temp["smsCount"]
    //             delete temp["smsDeliveryStatus"]
    //             const status = temp["LastSmsDeliveredOn"]
    //             const addedBy = temp["addedBy"]
    //             const addedOn = temp["addedOn"]
    //             delete temp["addedOn"]
    //             delete temp["addedBy"]
    //             delete temp["LastSmsDeliveredOn"]
    //             temp["LastSmsDeliveredOn"] = status;
    //             temp["addedBy"] = addedBy;
    //             temp["addedOn"] = addedOn
    //         }
    //         extractedData.push(temp)

    //         return true;
    //     });

    //     return extractedData;
    // }
    const makeWorkerData = (response) => {
        let extractedData = [];
        let lastupdatedon = [];
        response.map(el => {
            const addedEmail = el.workerInsertedByEmail ? `(${el.workerInsertedByEmail})` : ""
            const sourceByType = el.sourceBy === "VENDOR" ? "Vendor" : el.sourceBy === "TA" ? "HRBP/TA" : el.sourceBy === "EREF" ? "Employee reference" : ""
            const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : ""
            const lastAppliedDetails= el.lastJobAppliedDetails?el.lastJobAppliedDetails.split("^"):[]
            let temp = {
                okaygoId: el.okaygoId || "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                Source: el.partnerName ? el.partnerName : "",
                BGVStatus: createBGVStatus(el.bgvFinalStatus),
                appStatus: el.appStatus || "",
                documentsPending: el.pendingDoucment || "",
                bankDetails: el.accountNumber ? "Yes" : "No",
                aadharNumber: Decrypt(el.aadhaarNumber) || "",
                city: el.userCity || "",
                addressGoogle: el.userGoogleLocation ||  "",
                lmaGroupFlexEflex: el.is_lma_worker?"LMA":el.groupflexId?"GroupFlex":"Eflex",
                crossUtilizationStatus: el.crossUtilStatus==="0"?"Inactive":el.crossUtilStatus==="1"?"Single organisation":el.crossUtilStatus==="2"?"Crossutilized": "",
                lastJobAppliedOn: lastAppliedDetails[0] ? (moment(lastAppliedDetails[0]).format("DD/MM/YYYY")) : "",
                lastJobAppliedFor: lastAppliedDetails.length>1?lastAppliedDetails.slice(1):[],
                recruiterAssignedName: el.recruiterAssignedName !== null ? el.recruiterAssignedName + (el.recruiterAssignedNumber !== null ? " - " + el.recruiterAssignedNumber : "") : "",
                assignedOn : setLeadRecruiterDetail(el.recruitertList, "assignedOn"),
                interviewFor: setLeadRecruiterDetail(el.recruitertList, "client"),
                callStatus: setLeadRecruiterDetail(el.recruitertList, "callStatus"),
                interviewDate: setLeadRecruiterDetail(el.recruitertList, "interviewDate"),
                interviewStatus: setLeadRecruiterDetail(el.recruitertList, "interviewStatus"),
                trainingStartDate: setLeadRecruiterDetail(el.recruitertList, "trainingStartDate"),
                trainingUpdate: setLeadRecruiterDetail(el.recruitertList, "trainingUpdate"),
                addedOn: el.workerInsertedOn ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a") : "",
                addedBy: `${el.workerInsertedByName ? el.workerInsertedByName : ""} ${addedEmail}`,
                lastUpdatedOn:setLeadRecruiterDetail(el.recruitertList, "lastUpdate"),
                fathersName: el.fatherName || "",
                gender: el.gender === "26" ? "Male" : el.gender === "27" ? "Female" : el.gender === "30" ? "Other" : "",
                dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",                
                sourceLead: el.leadSource || "",
                leadAddedOn: el.leadAddedOn ? moment(el.leadAddedOn).format("DD/MM/YYYY hh:mm a") : "",
                present_address: el.curAddressLine1 || "",
                present_state: el.curStateName || "",
                present_pincode: el.curPincode || "",
                permanent_address: el.perAddressLine1 || "",
                permanent_city: el.perCity || "",
                permanent_state: el.perStateName || "",
                permanent_pincode: el.perPincode || "",
                leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
                sourcedBy: `${sourceByType}${sourceByName}`,
                blacklisted: el.blacklisted === 1 ? "Yes" : el.blacklisted === 0 ? "No " : "",
                ...handleClientList(el)
            }

            extractedData.push(temp)

            return true;
        });
        return extractedData;
    }
    // facility_name: el.facilityName || "",
    // facility_city: el.facilityCity || "",
    // facility_state: el.facilityState || "",
    // rateCard: el.rateCardType || "",
    // facility_type: el.facilityType ? el.facilityType : "",
    const handleClientList = (el, ) => {
        let clientData = {}
        el.clientList.map(e => {
            if (e.clientName) {
                if (e.clientName === "Flipkart") {
                    clientData = {
                        ...clientData,
                        // client_id
                        [`${e.clientName} Id`]: e.client_id || "",
                        [`${e.clientName} Zone`]: e.zone || "",
                        [`${e.clientName} DateOfJoining`]: e.dateOfJoining ? moment(e.dateOfJoining).format("DD/MM/YYYY") : "",
                        [`${e.clientName} InactiveDate`]: e.inactiveDate ? moment(e.inactiveDate).format("DD/MM/YYYY") : "",
                        [`${e.clientName} earningStatus`]: e.earning_status==0?"Inactive":e.earning_status === 1? "Active":"",
                        [`${e.clientName} facilityName`]: e.facilityName || "",
                        [`${e.clientName} facilityCity`]: e.facilityCity || "",
                        [`${e.clientName} facilityState`]: e.facilityState || "",
                        [`${e.clientName} rateCardType`]: el.is_lma_worker?e.lmaRateCard:e.rateCard || "",
                        [`${e.clientName} facilityType`]: e.facilityType || "",
                        [`${e.clientName} ClientStatus`]: createClientStatus(e.clientIdStatus) || "",
                        [`${e.clientName} firstEarningDate`]: e.first_earning_date ? moment(e.first_earning_date).format("DD/MM/YYYY") : "",
                        [`${e.clientName} lastEarningDate`]: e.lastEarningOn ? moment(e.lastEarningOn).format("DD/MM/YYYY") : "",
                        [`${e.clientName} IdGeneratedOn`]: e.client_id_inserted_on ? moment(e.client_id_inserted_on).format("DD/MM/YYYY") : "",
                        [`${e.clientName} fodStatus`]: e.fodStatus===1?"Done":e.fodStatus===2?"Pending":""
                    }
                }else{
                    clientData = {
                        ...clientData,
                        // client_id
                        [`${e.clientName} Id`]: e.client_id || "",
                        [`${e.clientName} Zone`]: e.zone || "",
                        [`${e.clientName} DateOfJoining`]: e.dateOfJoining ? moment(e.dateOfJoining).format("DD/MM/YYYY") : "",
                        [`${e.clientName} InactiveDate`]: e.inactiveDate ? moment(e.inactiveDate).format("DD/MM/YYYY") : "",
                        [`${e.clientName} earningStatus`]: e.earning_status==0?"Inactive":e.earning_status === 1? "Active":"",
                        [`${e.clientName} facilityName`]: e.clusterName || "",
                        [`${e.clientName} clusterCity`]: e.clusterCity || "",
                        [`${e.clientName} clusterState`]: e.clusterState || "",
                        [`${e.clientName} clientStatus`]: createClientStatus(e.clientIdStatus) || "",
                        [`${e.clientName} firstEarningDate`]: e.first_earning_date ? moment(e.first_earning_date).format("DD/MM/YYYY") : "",
                        [`${e.clientName} lastEarningDate`]: e.lastEarningOn ? moment(e.lastEarningOn).format("DD/MM/YYYY") : "",
                        [`${e.clientName} IdGeneratedOn`]: e.client_id_inserted_on ? moment(e.client_id_inserted_on).format("DD/MM/YYYY") : "",
                    }

                }
            }


        })
        return clientData;
    }
    const makeLeadData = (response) => {
        let extractedData = [];
        let lastupdatedon = [];
        response.map(el => {
            // const addedEmail = el.workerInsertedByEmail ? `(${el.workerInsertedByEmail})` : ""
            // const sourceByType = el.sourceBy === "VENDOR" ? "Vendor" : el.sourceBy === "TA" ? "HRBP/TA" : el.sourceBy === "EREF" ? "Employee reference" : ""
            // const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : ""
            const lastAppliedDetails= el.lastJobAppliedDetails?el.lastJobAppliedDetails.split("^"):[]
            const temp = {

                leadId: el.leadId || "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                appStatus: el.appStatus || "",
                documentsPending: el.pendingDoucment || "",

                registeredOnApp: (el.appLead !== null && el.appLead !== 0) ? (moment(el.leadInsertedOn).format("DD/MM/YYYY hh:mm a")) : "--",
                City: el.leadCity || "",
                Pincode: el.leadPincode || "",
                Location: el.userGoogleLocation || "",
                AdhaarCard: el.leadIsAdhaar ? (el.leadIsAdhaar === 1 ? "Yes" : "No") : "No",
                Bike: el.leadIsBike ? (el.leadIsBike === 1 ? "Yes" : "No") : "No",
                DrivingLicense: el.leadIsDrivingLicense ? (el.leadIsDrivingLicense === 1 ? "Yes" : "No") : "No",
                // profileCompleted:el.docsUploadedUser!==null?(el.docsUploadedUser===1?"YES":"NO"):"-",
                fathersName: el.fatherName || "",
                dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
                gender: el.gender === "26" ? "Male" : el.gender === "27" ? "Female" : el.gender === "30" ? "Other" : "",
                AadharNumber: Decrypt(el.aadharNumberLead) || "",
                present_address: el.curAddressLine1 || "",
                present_city: el.curCity || "",
                present_state: el.curStateName || "",
                permanent_address: el.perAddressLine1 || "",
                permanent_city: el.perCity || "",
                permanent_state: el.perStateName || "",
                lastJobAppliedOn: lastAppliedDetails[0] ? (moment(lastAppliedDetails[0]).format("DD/MM/YYYY")) : "",
                lastJobAppliedFor: lastAppliedDetails.length>1?lastAppliedDetails.slice(1):[],
                recruiterAssignedName: el.recruiterAssignedName !== null ? el.recruiterAssignedName + (el.recruiterAssignedNumber !== null ? " - " + el.recruiterAssignedNumber : "") : "",
                assignedOn: setLeadRecruiterDetail(el.recruitertList, "assignedOn"),
                callStatus: el.callSatuts !== null ? el.callSatuts : "",
                firstCalledOn: setLeadRecruiterDetail(el.recruitertList, "firstCalledOn"),
                lastCalledOn: setLeadRecruiterDetail(el.recruitertList, "lastCalledOn"),
                interviewDate: setLeadRecruiterDetail(el.recruitertList, "interviewDate"),
                preferredHub: el.preferredHub !== null ? el.preferredHub : "",
                
                clientName:el.clientName||"",
                interviewStatus: setLeadRecruiterDetail(el.recruitertList, "interviewStatus"),
                trainingStartDate: setLeadRecruiterDetail(el.recruitertList, "trainingStartDate"),
                trainingUpdate: setLeadRecruiterDetail(el.recruitertList, "trainingUpdate"),
                AddedOn: el.leadInsertedOn ? moment(el.leadInsertedOn).format("DD/MM/YYYY") : "",
                Lead_Source: el.leadSource || "",
                lastUpdatedOn:setLeadRecruiterDetail(el.recruitertList, "lastUpdate"),
            }
            extractedData.push(temp)
            
            return true;
        });

        return extractedData;
    }
    const updateMyData = () => {
        getExternalWorkerList(perPage, currentPage);
    }
    const handleEditPhoneNumberService = (contact, userId) => {
        Axios.post(`${urlLinks.baseUrl}${urlLinks.workerUrls.editContact}?userId=${userId}&value=${contact}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Contact number updated successfully");
                    handleEditContactClose();
                    updateMyData();
                }
                else {
                    setEditContactData({ ...editContactData, error: response.data.response.error })
                }
            })
            .catch(error => {
                setEditContactData({ ...editContactData, error: error.message })
            })


    }
    const createAllRecordExcel = (obj) => {
        // setAllRecordLoading(true)
        var filterUrl=obj.url;
        let requestedBy = ""
        let excelExportedBy = localStorage.getItem("userID");
        if (localStorage.getItem("roleType") === "1") {
            requestedBy = `requestedById=${localStorage.getItem("userID")}`
        }
        if (isLeadData === "") {
            console.log(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataListingv3}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}&exportExcel=1&sentToAll=${obj.sentToAll?1:0}${tab.leadDataTab ? assignFilterString : crossFilter}&excelExportedBy=${excelExportedBy}`)
            Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataListingv3}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}&exportExcel=1&sentToAll=${obj.sentToAll?1:0}${tab.leadDataTab ? assignFilterString : crossFilter}&excelExportedBy=${excelExportedBy}`)
                .then(response => {
                    if (response) {
                        console.log(response.data.response)
                        swal(response.data.response,"",  "success");
                        // setAllRecord(makeWorkerData(response.data.response)) 
                    }
                })
        } else {
            console.log(`${urlLinks.baseUrl}${urlLinks.workerUrls.leadDataListingv3}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}&exportExcel=1&sentToAll=${obj.sentToAll?1:0}${tab.leadDataTab ? assignFilterString : crossFilter}&excelExportedBy=${excelExportedBy}`)
            Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.leadDataListingv3}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}&exportExcel=1&sentToAll=${obj.sentToAll?1:0}${tab.leadDataTab ? assignFilterString : crossFilter}&excelExportedBy=${excelExportedBy}`)
                .then(response => {
                    if (response) {
                        console.log(response)
                        swal(response.data.response,"",  "success");
                        // setAllRecord(makeLeadData(response.data.response))
                    }
                })
        }
    }
    const handleOpenExportModal = () => {
        setExportModal(true)
    }
    // const exportCurrentRecords= () =>{
    //     setExportExcelLoading(true)
    //     let list=[]
    //     data.map(el =>{
    //         delete el.userId;
    //         delete el.workerId;
    //         list.push(el)
    //         return true;
    //     })
    //     setExcelData(list)
    // }
    // useEffect(() => {
    //     setExportExcelLoading(false)
    // }, [excelData])
    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getExternalWorkerList = (rows, pageNo) => {
        setLoading(true)
        let requestedBy = ""
        if (localStorage.getItem("roleType") === "1") {
            // console.log(localStorage)
            requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        }
        if (localStorage.getItem("roleType")) {
            Axios.get(`${urlLinks.baseUrl}${tab.leadDataTab ? urlLinks.workerUrls.leadDataListingv3 : urlLinks.workerUrls.workerDataListingv3}?page_no=${pageNo - 1}&rows=${rows}${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${tab.leadDataTab ? assignFilterString : crossFilter}${ localStorage.getItem("roleType") === "30"?"&source=107":""}`)
                .then(response => {
                    if (response) {
                        
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if (currentPage > response.data.response.totalPages)
                            setCurrentPage(1)
                        // console.log("response====>",[...makeData(response.data.response)])
                        setData([...makeData(response.data.response)]);
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })

        }
    }
    const handleReactivationDate = () => {
        const postData = {
            userId: reactivationData.userId,
            reActivationDate: moment(reactivationData.date).format("YYYY-MM-DD 00:00:00"),
            // updatedBy: parseInt(localStorage.getItem("userID"))
        }
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.workerReactivation}?reActivationDate=${moment(reactivationData.date).format("YYYY-MM-DD 00:00:00")}&userId=${reactivationData.userId}&updatedBy=${parseInt(localStorage.getItem("userID"))}`)
            .then(response => {
                showToast("success", "Activation date set successfully")
                getExternalWorkerList(perPage, currentPage)
                setReactivationData({
                    open: false,
                    userId: null,
                    date: null
                })
            })
            .catch(error => {
                showToast("error", error.message)
            })
    }
    // console.log("filter--> " + filterUrl)
    useEffect(() => {
        console.log({tab})
        getExternalWorkerList(perPage, 1);
        setCurrentPage(1)

    }, [filterUrl, sortUrl, sortDirectionUrl, localStorage.getItem("roleType"), tab, assignFilterString, crossFilter])
    // setColumnType()
    // if(filters.all===true){
    //     setColumnType(workerDataColumn)
    // }
    const columns = tab.workerDataTab ? workerDataColumn : leadsColumn
    const handlePageChange = page => {
        getExternalWorkerList(perPage, page)
        setCurrentPage(page);
    };
    const handleActions = (event) => {
        if (event.target.value === "uploadWorkerData" /* &&(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6")*/) {
            // new onboarding Model v2
            setOpenOnboardingModalV2(true)
        }
        // else if (event.target.value === "uploadWorkerData") {
        //     setOpenOnboardingModal(true)
        // }
        else if (event.target.value === "uploadAJWorkerData") {
            setOpenAJworkerUploadModal(true)
        }
        else if (event.target.value === "uploadApnaWorkerData") {
            setOpenOnboardingApnaModalV3(true)
        }
        else if (event.target.value === "uploadOffboardingData") {
            setOpenOffboardingModal(true)
        }
        else if (event.target.value === "UploadCasperID") {
            setOpenCasperOnboardingModal(true);
        }
        else if (event.target.value === "agreement") {
            // setOpenGroupflexModal(true);
            handleDownloadAgreements()

        }
    }


    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, updateMyData, getDocumentList,docListLoader,downloadDocError, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getExternalWorkerList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const createZipForDocuments = (urls) => {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = "Agreements.zip";
        urls.forEach(async function (url, index) {
            const record = Object.entries(url)
            // console.log({ record })
            const filename = `${record[0][0]}`;
            const path = `${record[0][1]}`
            try {
                await JSZipUtils.getBinaryContent(path, function (err, data) {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    // console.log({ zip })
                    if (count === urls.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                            setAgreementDownloading(false)
                        });
                    }

                })
            }
            catch (err) {
                // console.log(err);
            }
        });
    }
    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisable(disableButton);
            // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    // console.log(filters)
    const handleEmailAgreements = () => {
        setEmailAgreement(true)
        const indexes = Object.keys(selectedRowIds)
        let userIdList = []
        indexes.map(id => {
            userIdList.push(data[id].userId)
        })
        // console.log({ userIdList })
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.emailAgreements}${userIdList}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Agreements sent to your registered email id")

                }
                else if (response.data.code === 1001) {
                    showToast("error", response.data.message)
                }

                setEmailAgreement(false)
            })
            .catch(error => {
                setEmailAgreement(false)
                showToast("error", error.message)
            })

    }
    useEffect(() => {
        console.log({ filters })
    }, [filters])
    const handleDownloadAgreements = () => {
        setAgreementDownloading(true)
        const rowIds = Object.keys(selectedRowIds)
        // console.log({rowIds})
        let agreementUrls = []
        rowIds.map(row => {
            if (data[row].agreementPath) {
                const fileName = `${data[row].contactNo}_${data[row].name}.pdf`
                agreementUrls.push({
                    [fileName]: data[row].agreementPath
                })
            }
        })
        // console.log({ agreementUrls })
        if (agreementUrls.length > 0) {
            createZipForDocuments(agreementUrls)
        }
        else {
            setAgreementDownloading(false)
            showToast("error", "It will take some time to generate agreement.")
        }

    }
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameV2 zIndex1080 header" :
                                        column.render('Header') === "Profile Completion" ? "" :// not visible to hrbp and super hrbp
                                            // (column.render('Header') === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" : // only visible to okaygo admin annd super admin
                                            column.id === "selection" ? "stickCheckbox zIndex1080 header" :
                                                column.render('Header') === "Last SMS sent" ? "width200 header" : "header"}>
                                {/* width200 */}

                                <span style={(column.render('Header')==="addressGoogle" || column.render('Header')==="documentsPending")?{width: "250px"}:{}}>
                                    {column.render('Header')}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (

                            <tr {...row.getRowProps()} className={`row ${row.original.criminalRecord ? "criminalRecord" : ""}`}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={`${cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameV2 zIndex540" :
                                                // (cell.column.Header === "Delivery status" && ((localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5"))) ? "displayNone" :
                                                // (cell.column.Header === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"} ${row.original.criminalRecord === "Yes" ? "criminalRecord" : ""}`}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid item sm={12}><span className="fontSize24 fontWeightBold marginRight20">Worker Data </span>
                    <Button
                        //variant="outlined" 
                        onClick={(event) => { if (tab.leadDataTab === true) { handleTab(event, "1") } }}
                        className={`statusButtons marginRight20 ${tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Worker Data
                    </Button>
                   { localStorage.getItem("roleType") !== "30" && <Button
                        onClick={(event) => { if (tab.workerDataTab === true) { handleTab(event, "2") } }}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${tab.leadDataTab ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Leads data
                    </Button>
                    }
                </Grid>
                <Grid item xs={12} container justify="space-between" className="flexDisplay alignItemsFlexStart">
                    <Grid item sm={5} className="flexDisplay padding0 paddingTop8 justifySpaceBetween">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder={tab.leadDataTab ? "Search by Aadhar no./Contact no." : "Search by Aadhar No./ Contact number / OkayGo ID / Client ID"}
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                    <Grid
                        item xs={5}
                        className="flexDisplay paddingBottom8"
                        style={{justifyContent:"space-between", maxWidth:"550px"}}
                    >
                        {(localStorage.getItem("roleType")!=="27") && <FormControl variant="outlined" className="margin0" style={{ marginTop:"0px" }} >
                            <Select
                                id="actions"
                                value={actions}
                                style={{ marginLeft: "", marginTop: "7px" }}
                                classes={{ outlined: styles.actionSelect }}
                                onChange={handleActions}
                            >
                                <MenuItem value={0} disabled={true}> Actions</MenuItem>
                                {tab.workerDataTab && <MenuItem value="uploadWorkerData">Upload Onboarding data</MenuItem>}
                                {/* {tab.workerDataTab && <MenuItem value="uploadAJWorkerData">Upload AJ Worker</MenuItem>} */}
                                {tab.leadDataTab && <MenuItem value="uploadApnaWorkerData">Upload leads data</MenuItem>}
                                {tab.workerDataTab && <MenuItem value="UploadCasperID">Upload Client IDs</MenuItem>}
                                {tab.workerDataTab && <MenuItem value="uploadOffboardingData">Upload Offboarding data</MenuItem>}
                                {tab.workerDataTab && <MenuItem disabled={Object.keys(selectedRowIds).length === 0} value="agreement">Download Agreements</MenuItem>}
                            </Select>
                        </FormControl>}
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={handleOpenExportModal}
                            style={{marginTop:"7px"}}
                            disabled={allRecordLoading}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading ?
                            <span style={{ marginRight: "5px" }}>Please wait...it might take few minutes</span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={isLeadData === "" ? `Worker Data.csv` : `Lead Data.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginRight: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink>
                                : null}
                        <button
                            className={styles.blueOutlinedButton}
                            style={{ padding:"10px 16px", marginTop:"7px"}}
                            onClick={() => setOpenFilterDrawer(true)}
                        >
                            filters
                        </button>
                        {/* <Grid item xs={1} className="buttonList padding8" style={{ paddingLeft: "50px" }} alignItems='flex-start'> */}

                        {(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && <button
                            className={disable ? styles.greyOutlinedButton : styles.blueOutlinedButton}
                            style={{ padding:"10px 16px", marginTop:"7px"}}
                            onClick={() => handleSendMsg()}
                            disabled={disable}
                        >
                            Send Message
                        </button>}
                    </Grid>
                    {/* </Grid> */}
                    {/* <Button variant="outlined">Outlined</Button> */}
                    {/* </Grid> */}
                    {/* </Grid> */}
                    {/* <Grid item sm={1} className="flexDisplay paddingTop8" direction="row-reverse" justify="space-between"> */}
                    {/* <IconButton 
                            
                            className="filterButton padding8"
                            onClick={() =>setFilterDrawer(true)}
                        >
                            <img src={filter} alt="img" />
                        </IconButton> */}
                    {/* </Grid> */}

                </Grid>
                
                {/* <Grid item xs={12} className="flexDisplay paddingTop16 alignItemsFlexStart">
                    {<Grid item xs={4} className=" alignItemsFlexStart">
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={createAllRecordExcel}
                            disabled={allRecordLoading}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading?
                            <span style={{marginLeft: "20px"}}>Please wait ... it might take few minutes. </span>
                            :allRecord.length>0?
                            <CSVLink
                                data={allRecord}
                                filename={`Worker List.csv`}
                                className='btn btn-primary blueColor downloadExcelButton'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    marginLeft:"20px",
                                    fontWeight: '500'
                                }}
                            >
                                Download now
                            </CSVLink>
                        :null}
                    </Grid>}
                    {(localStorage.getItem("roleType")==="1" || localStorage.getItem("roleType")==="5") && <Grid item xs={8} className="flexDisplay" direction="row-reverse" alignItems='flex-start'>
                        <Button
                            variant="outlined"
                            className={styles.outlinedButton}
                            onClick={() =>setOpenOffboardingModal(true)}
                            startIcon={<img src={offboardingIcon} alt="offboarding icon" />}
                        >
                            Upload Offboarding Data
                        </Button>
                        <Button
                            variant="outlined"
                            className={styles.outlinedButton}
                            onClick={() =>setOpenOnboardingModal(true)}
                            startIcon={<img src={onboardingIcon} alt="onboarding icon" />}
                        >
                            Upload Onboarding Data
                        </Button>
                    </Grid>}
                </Grid> */}
                {/* <Grid item xs={12} className="flexDisplay paddingTop16 alignItemsFlexStart">
                    <Grid item xs={8} className=" alignItemsFlexStart">
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={handleDownloadAgreements}
                            disabled={Object.keys(selectedRowIds).length === 0 || agreementDownloading}
                            startIcon={agreementDownloading && <ClockLoader
                                size={20}
                                color={"#1c75bc"}
                            />}
                        >
                            Download Agreements
                        </Button>
                        
                    </Grid>
                </Grid> */}
                { localStorage.getItem("roleType") !== "30" && <Grid item xs={12} className="flexDisplay paddingTop16 justifyFlexStart" >
                    {(localStorage.getItem("roleType")!=="27") && <Button
                        variant="contained"
                        className="dataButton"
                        style={{ marginTop: "7px" }}
                        onClick={() => {
                            const indexes = Object.entries(selectedRowIds);
                            console.log({ indexes })
                            let idList = ""
                            indexes.map((id, index) => {
                                if (id[1]) {
                                    // console.log(data[parseFloat(id[0])].userId)
                                    idList += data[parseFloat(id[0])].userId + ",";
                                }

                                // console.log(idList)
                                return true;
                            })
                            idList = idList.substr(0, idList.length - 1)
                            console.log(idList)
                            setSelectedUserIds(idList)
                            setOpenAssignModal(true)
                        }}
                        disabled={disable}
                    >
                        Assign To Recruiter
                    </Button>}
                    {tab.workerDataTab && (localStorage.getItem("roleType")!=="27") &&  <Button
                        variant="contained"
                        className="dataButton"
                        style={{ marginTop: "7px", marginLeft: "20px" }}
                        onClick={handleTriggerBGV}
                        disabled={disable}
                    >
                        Trigger Bgv
                    </Button>}
                    {tab.workerDataTab === false && <>
                        <Button
                            //variant="outlined" 
                            style={{ marginLeft: "20px" }}
                            onClick={(event) => { handleAssignFilter("all") }}
                            className={`statusButtons marginRight20 ${AssignFilter.all ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            All
                        </Button>
                        <Button
                            //variant="outlined" 
                            onClick={(event) => { { handleAssignFilter("notAssigned") } }}
                            className={`statusButtons marginRight20 ${AssignFilter.notAssignTab ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Not Assigned
                        </Button>
                        <Button
                            onClick={(event) => { { handleAssignFilter("assigned") } }}
                            // variant="outlined"
                            className={`statusButtons marginRight20 ${AssignFilter.assignTab ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Assigned
                        </Button> </>
                    }
                    {tab.workerDataTab === true && <>
                        <Button
                            //variant="outlined" 
                            style={{ marginLeft: "20px" }}
                            onClick={(event) => { setCrossFilter(""); setCurrentPage(1) }}
                            className={`statusButtons marginRight20 ${crossFilter==="" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            All
                        </Button>
                        <Button
                            //variant="outlined" 
                            onClick={(event) => { { setCrossFilter("&crossUtilStatus=2"); setCurrentPage(1) } }}
                            className={`statusButtons marginRight20 ${crossFilter==="&crossUtilStatus=2" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Crossutilized
                        </Button>
                        <Button
                            onClick={(event) => { { setCrossFilter("&crossUtilStatus=1"); setCurrentPage(1) } }}
                            // variant="outlined"
                            className={`statusButtons marginRight20 ${crossFilter==="&crossUtilStatus=1" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Single organisation
                        </Button>
                        <Button
                            onClick={(event) => { { setCrossFilter("&crossUtilStatus=0"); setCurrentPage(1) } }}
                            // variant="outlined"
                            className={`statusButtons marginRight20 ${crossFilter==="&crossUtilStatus=0" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Inactive
                        </Button> </>
                    }


                    <div className={styles.criminalCheckBox} >
                        <div></div>
                        <span>Candidate with Criminal record</span>
                    </div>
                </Grid>}
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
                <Drawer anchor="right" open={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                    <FilterDrawer
                        handleClose={() => setOpenFilterDrawer(false)}
                        filters={filters}
                        tab={tab}
                        setFilters={setFilters}
                        applyFilters={ApplyFilters}
                        resetFilters={resetFilters}
                    ></FilterDrawer>
                </Drawer>
                {openOnboardingModalV2 && <OnboardingModalV2
                    openOnboardingModal={openOnboardingModalV2}
                    setOpenOnboardingModal={setOpenOnboardingModalV2}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openAssignModal && <AssignToRecruiterModal
                    open={openAssignModal}
                    setOpen={setOpenAssignModal}
                    showToast={showToast}
                    userIds={selectedUserIds}
                    updateMyData={updateMyData}
                    tabData={tab.workerDataTab}
                />}
                {openOnboardingApnaModalV3 && <OnBoardingModalV3
                    openOnboardingModal={openOnboardingApnaModalV3}
                    setOpenOnboardingModal={setOpenOnboardingApnaModalV3}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openAJworkerUploadModal && <UploadAJWorkerModal
                    openOnboardingModal={openAJworkerUploadModal}
                    setOpenOnboardingModal={setOpenAJworkerUploadModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openOnboardingModal && <OnboardingModal
                    openOnboardingModal={openOnboardingModal}
                    setOpenOnboardingModal={setOpenOnboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openOffboardingModal && <OffboardingModal
                    openOffboardingModal={openOffboardingModal}
                    setOpenOffboardingModal={setOpenOffboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openCasperOnboardingModal && <CasperOnboardingModal
                    openCasperOnboardingModal={openCasperOnboardingModal}
                    setOpenCasperOnboardingModal={setOpenCasperOnboardingModal}
                    showToast={showToast}
                    partners={partners}
                    updateMyData={updateMyData}
                />}
                {/* {openGroupflexModal && <GroupFlexModal
                    openOnboardingModal={openGroupflexModal}
                    setOpenOnboardingModal={setOpenGroupflexModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />} */}
                {
                    criminalRecordData.open && <CriminalRecord
                        criminalRecordData={criminalRecordData}
                        setCriminalRecordData={setCriminalRecordData}
                    />
                }
                {
                    editContactData.open &&
                    <EditContactModal
                        open={editContactData}
                        handleClose={handleEditContactClose}
                        handleEditPhoneNumberService={handleEditPhoneNumberService}
                    />
                }
                {
                    reactivationData.open &&
                    <ReactivationModal
                        open={reactivationData}
                        handleClose={handleReactivationDateClose}
                        handleReactivationDate={handleReactivationDate}
                        setReactivationData={setReactivationData}
                    />
                }
                {
                    openBGVTriggerModal &&
                    <BGVVerificationModal
                        response={BGVResponse}
                        openBGVTriggerModal={openBGVTriggerModal}
                        handleClose= {handleCloseBGVModal}
                     />
                }
                {
                    exportModal &&
                    <ExportModal
                    openOffboardingModal={exportModal}
                    setOpenOffboardingModal={setExportModal}
                    filters={filters}
                    setFilterUrl={setFilterUrl}
                    filterUrl={filterUrl}
                    createAllRecordExcel={createAllRecordExcel}
                    updateFilterUrl={updateFilterUrl}
                    setFilters={setFilters}
                    tab={tab}
                     />
                }
                {
                    openSendMsg &&
                    <SendMsg
                        isLeadData={isLeadData}
                        filterUrl={filterUrl}
                        open={openSendMsg}
                        setOpen={setOpenSendMsg}
                        userIds={selectedUserIds}

                    // handleReactivationDate={handleReactivationDate}
                    // setReactivationData={setReactivationData}
                    />
                }
            </Grid>
        </Styles>
    )
}

export default WorkerData;


