import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import * as moment from "moment"
import Axios from 'axios';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';
import style from '../WorkerDataV2/index.module.scss';
import DateFilter from '../WorkerDataV2/FilterComponents/DateFilter';
import AutoSelectFilter from '../WorkerDataV2/FilterComponents/AutoSelectFilter';
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from '../../UrlLinks'

const FilterDrawer = (props) => {
    const { handleClose, resetFilters, applyFilters, filters, setFilters} = props;
    const [workerPartners, setWorkerPartners] = useState([])
    const [status, setStatus] = useState([])
    const [recruiterList, setRecruiterList] = useState([])
    const [hubstateList, sethubstateList] = useState([])
    const [hubCityList, sethubCityList] = useState([])
    const [shiftStatusList, setShiftStatusList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const handleCancelFilters =() =>{
        handleClose();
    }
    const loader=<div style={{margin:"130px auto"}}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}    
        />
    </div>
    const makeWorkerPartnerData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.partner_id,
                name: el.company_name
            })
            return true
        })
        return list
    }

    const makeRecruiterData =(data) =>{
        let list=[];
        data.Content.map(el =>{
            list.push({
                id: el.recruiterId,
                name:` ${el.firstName} ${el.lastName ?" "+el.lastName:""}` ,
            })
            return true
        })
        console.log(list)
        return list
    }
    const makeStatusData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.typeKey,
                name: el.typeValue
            })
            return true
        })
        return list
    }
    const makeHubCityData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    const makeHubStateData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    useEffect(() => {
        if(workerPartners.length>0){
            setIsLoading(false)
        }
    }, [workerPartners, shiftStatusList])
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`)
            .then(response =>{
                console.log(response)
                setWorkerPartners(makeWorkerPartnerData(response.data.response))
            })
        
        Axios.get(`${urlLinks.baseUrl}configmaster/?category_sub_type=retention_call_status&category_type=retention_call_status`)
            .then(response =>{
                setStatus(makeStatusData(response.data.response.content))
            })
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.recruiterList}`)
            .then(response =>{
                setRecruiterList(makeRecruiterData(response.data.response)) 
            })

        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubCity}`)
        .then(response =>{
            sethubCityList(makeHubCityData(response.data.response))
        })

        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubState}`)
        .then(response =>{
            sethubstateList(makeHubStateData(response.data.response))
        })

        
    }, [])
    const output=isLoading?<Grid container style={{ width: "520px", marginTop: '20px' }}>{loader}</Grid>:<Grid container style={{ width: "520px", marginTop: '20px' }} className={style.FilterContainer}>
        <Grid container className={`${style.FilterHeadingContainer}`}>
            <div className={style.flexBaseLine}>
                <Button onClick={handleClose} className={style.filterCloseArrow}>
                    <ArrowForwardIcon />
                </Button>
                <span className={style.filterHeading}>Filters</span>
            </div>
            <div>
                <Button
                    onClick={resetFilters}
                    className={style.resetFiltersButton}
                >
                    Reset
                </Button>
            </div>
        </Grid>
        <div className={style.filterHeadingDivider}></div>
        <Grid container className={style.filtersContainer}>
          
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    State
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="state"
                    options={hubstateList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Client
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="client"
                    options={workerPartners}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    City
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="city"
                    options={hubCityList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Assigned to
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="assignedTo"
                    options={recruiterList}
                />
            </Grid>
            <Grid container xs={12} className={`${style.singleFilterContainer}`}>
                <Grid xs={5}>    
                    <span className={style.filterTitle}>
                        Last earning date
                    </span>
                    </Grid>
                <Grid  xs={7}>
                    <DateFilter
                        filters={filters}
                        setFilters={setFilters}
                        labelTo="To"
                        labelFrom="From"
                        id="lastEarningDate"
                    />
                   </Grid>
            </Grid>
            {/* <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="status"
                    options={status}
                />
            </Grid> */}
        </Grid>
        <Grid container className={style.filterButtonDividerContainer}>
            <div className={style.filterHeadingDivider}></div>
            <Grid container style={{ marginTop: '30px' }} className='padding0'>
                <div style={{
                    paddingRight: '16px',
                    paddingLeft: '300px'
                }}>  
                    <Button variant="outlined" onClick={handleCancelFilters} size="medium" color="primary" >
                        Cancel
                    </Button>
                </div>
                <Button variant="contained" size="medium" color="primary" onClick={()=>{applyFilters(); handleClose()}} className={style.PrimaryColorButton}>Apply</Button>
                
            </Grid>
        </Grid>
    </Grid >
    return output;
}

export default FilterDrawer;