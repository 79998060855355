import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { numberFormat } from '../../../Utils/CommonFunctions';
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from '../document.svg'
import ResponseModal from './response'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import Download from '../Group 3238.svg'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

control: {
    //   padding: theme.spacing(2),
},
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    loader:{
        marginTop:'8rem',
        
        
            },
    paper: {
        marginTop: 15,
        height: 270,
        width: 330,
        borderRadius: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.78)',

    },
    inputRoot: {
        
        // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
          // Default left padding is 6px
          color: 'black',
          fontSize: '0.8rem'
        },
        "& .MuiOutlinedInput-notchedOutline": {
          
        },
        '& .MuiSvgIcon-root':{
            fontSize: '2rem'
        }
      },
       popupIndicator: {
        fill: "black",
        color: 'black',
        
       


    },
    control: {
        //   padding: theme.spacing(2),
    },
}));
export default function CustomizedDialogs(props) {
  const { openOnboardingModal, setOpenOnboardingModal, showToast, updateMyData } = props
  const [excel, setExcel] = useState(null)
  const classes = useStyles()
  const FirstUpperCase = (str) => {
    let s = "";
    s = str;

        if (s.length > 1) {
            let sub = s.substring(0, 1).toUpperCase();
            let remaining = s.substring(1, s.length);
            return sub + remaining;
        }
    
}
  let   city = "Select City"
  const upperCaseCity = FirstUpperCase(city);
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [name, setName] = useState("")
  const [options, setOptions] = useState([]);
  const [cityChange,setCityChange]=useState(false)
  const [currentCity, steCurrentCity] = useState(upperCaseCity);
  const [val, setVal] = React.useState({ title: currentCity });
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [invalidData, setInvalidData] = useState()
  const [fileLink, setFileLink] = useState(null)


  const [responseData, setResponseData] = useState()
  const [actions, setActions] = useState(0)
  const [inputValue, setInputValue] = React.useState('');
  // console.log({excel})
  const handleClose = () => {
    setOpenOnboardingModal(false);
  };
  const handleUpload = (event) => {
    const fileName = event.target.files[0].name.split(".").slice(-1)[0];
    if (fileName.toLowerCase() === "xlsx" || fileName.toLowerCase() === "xls") {
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name)
      setExcelError(false)
    }
    else {
      setExcelError(true)
      setExcel(null);
      setName("");
    }
    setResponseData();

  }

  let c = null
  useEffect(() => {
  
          Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.workerDataHubCity}`)
              .then(response => {
                  console.log(response.data)
                  let v = []
                  response.data.response.map(e => v.push(FirstUpperCase(e.trim())))
                  c = v.sort()
                 
                       
                  c=v
                  console.log(c)
                  handleOptions()
              })
      
  }, [])
  const handleOptions = () => {
      console.log(c)
      setOptions(c.map(option => {
          // option = FirstUpperCase(option)
          return { title: option }
          // return <MenuItem key={option} value={option}>{option}</MenuItem>
      }))
  }
 
  const createInvalidData = (records) => {
    console.log( records.response.invalidExcel)
    if (records.response.invalidExcel.length > 1) {
      let list = [];
      records.response.invalidExcel.map(record => {
        // if (record.hasOwnProperty("invalidExcel")) {
          // console.log({record});
          // if(record.WorkerDetail.isWorkerCreated===0){
          //   let firstName=""
          //   let lastName=""
          //   if(record.WorkerDetail.workerName){
          //     var i = record.WorkerDetail.workerName.indexOf(' ');
          //     if(i>0){
          //       firstName = record.WorkerDetail.workerName.slice(0, i).trim();
          //       lastName = record.WorkerDetail.workerName.slice(i + 1, record.WorkerDetail.workerName.length).trim();
          //     }else{
          //       firstName =record.WorkerDetail.workerName;
          //     }
          //   }
        //   if (record.invalidExcel !== "uploaded") {
            list.push({
              // "Apna Id": record.invalidExcel.apnaLeadId || "",
              "Name": record.name?record.name: "",
              "Phone Number": record.phoneNumber?record.phoneNumber: "",
              "Area": record.area? record.area: "",
              "Company": record.company? record.company: "",
              "Current Job": record.currentJob?record.currentJob : "",
              "Education":  record.education ?record.education : "",
              "Experience": record.experience?record.experience: "",
              "status": record.status?record.status : "",
              "feedback": record.feedback?record.feedback : "",
              "Applied On":record.appliedOn ?record.appliedOn : "",
              "Reason of failure" : record.reason?record.reason: "",
            //   "inserted On": moment(record.insertedOn).format("DD/MM/YYYY") || "",
            //   "Inserted By": "" + record.insertedBy|| "",


            })
          


          return true;
        }
      // }
      )
      return list.length > 0 ? list : null;
    }
  }
  const handleExcel = () => {
    setIsLoading(true)
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(`${UrlLinks.baseUrl}upload/apnaLeads?city=${currentCity}&insertedBy=1`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(response => {
        // console.log({response});
        if (response.data) {
          setInvalidData(createInvalidData(response.data));
        //   console.log(response.data.response.slice(-1)[0])
        //   const summary = response.data.response.slice(-1)[0];
          // console.log({summary})
          setResponseData(response.data.response);
          updateMyData();
          setIsLoading(false)
        }
        else {
          setResponseData([]);
        }
      })
      .catch(error => {
        // console.log({error})
        setIsLoading(false)
        showToast("error", error.message);
      })
  }
  // useEffect(() => {
  //   Axios.post(`${UrlLinks.baseUrl}${UrlLinks.newOnboardingExcel}`,null , {timeout:60*5*1000})
  //     .then(response =>{
  //       // console.log({response})
  //       setFileLink(response.data)
  //     })

  // }, [])

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData])
  let dialogData =
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Apna leads data</span> <a
          style={{ paddingRight: '2.5rem' }}
        
          download
          target="_blank"
          className={Styles.downloadTemplate}
        >
          {/* <span>Download File Template</span><img style={{ paddingLeft: '0.5rem' }} src={Download} alt="download" /> */}
        </a>
      </DialogTitle>
      <DialogContent dividers><Grid container spacing={2} ><Grid item xs={4}>Please Select city:</Grid>
      <Grid item xs={8}  spacing={2}>
      <FormControl variant="outlined" className={classes.formControl}>
                        {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                        {/* <Select
                            style={{ fontWeight: 'Bold', justifyContent: 'center', alignContent: 'center', background: '#1c75c0', width: '17rem', height: '36px', color: 'white', borderRadius: '12px', fontSize: '100%' }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={value}

                            onChange={handleCityChange}

                        >{options}

                        </Select> */}
                        <Autocomplete
                            id="combo-box-demo"
                            options={options}
                            // value={currentCity}
                            value={val}
                            classes={{popupIndicator: classes.popupIndicator, inputRoot: classes.inputRoot}}
                            
                            // classes={{

                            //     popupIndicator: classes.popupIndicator
                            // }}
                            onChange={(event, newValue) => {
                                setVal(newValue);
                         
                                console.log(newValue)
                                steCurrentCity(newValue.title)
                                setCityChange(true)
                              
                            }}
                            getOptionLabel={(option) => option.title}
                            style={{ marginTop: '0.5rem', width: 150, background: 'white', borderRadius: '1rem',   }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}

                            disableClearable
                            renderInput={(params) => <TextField InputProps={{
                                className: classes.inputColor
                                
                            }} {...params} variant="outlined" placeholder = "Select a city"/>}
                        />
                    </FormControl>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2} ><Grid item xs={4}>Please Select a File:</Grid>
        <Grid item xs={8}  className="padding2">
{currentCity === upperCaseCity ?( <div>
  <div>
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            disabled
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
            disabled
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={<img src={document} alt={"upload"} style={{ color: "white" }} />}
            >
              Browse File
            </Button>
          </label>
          {/* <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>only .xls and .xlsx are allowed</span> */}
          </div>
          {name && <span className={Styles.fileName}><span>File name: </span>{`${name}`}</span>}
</div>):(
   <div>
   <input
     style={{ display: "none" }}
     id="contained-button-file"
     type="file"
     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
     onChange={handleUpload}
   />
   <label htmlFor="contained-button-file">
     <Button
       variant="outlined"
       className={Styles.uploadButton}
       component="span"
       startIcon={<img src={document} alt={"upload"} style={{ color: "white" }} />}
     >
       Browse File
     </Button>
   </label>
   {name && <span className={Styles.fileName}><span>File name: </span>{`${name}`}</span>}
</div>
)
}
          </Grid>
          </Grid>
        {/* <Grid item xs={12} className="padding0">
        
        </Grid> */}
      </DialogContent>
      <DialogActions>
        {isLoading ? <span>Please wait... it may take few minutes</span> : <Button variant="contained" onClick={handleExcel} className={`${!excel ? "disabledButton" : "blueButtonContained"}`} disabled={!excel}>
          Upload
        </Button>}
      </DialogActions>
      {openResponseModal && <ResponseModal
        openResponseModal={openResponseModal}
        setOpenResponseModal={setOpenResponseModal}
        responseData={responseData}
        setOpenOnboardingModal={setOpenOnboardingModal}
        showToast={showToast}
        invalidData={invalidData}
        updateMyData={updateMyData}
        agreement={true}
        type="Onboarding"
      />}
    </Dialog>



  return dialogData;

}
