import { Grid, Tooltip, withStyles } from '@material-ui/core';
import React from 'react'
import style from './index.module.scss'
import FlipkartIcon from './Flipart-Logo-Icon-PNG-Image.png'
import SwiggyIcon from './swiggy-logo.png'
import DelhiveryIcon from './Delhi.png'
import JioIcon from './jio.png'
import ZomotoIcon from './zomato-logo.svg';
const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }))(Tooltip);


const TimeLineColumn =(props) =>{
    const {row} =props;
    const workerHourlyShift=Object.entries(row.original.hourlyShifts)
    const shiftStatusList= row.original.workerShifts
    const address= row.original.address
    const clusterName= row.original.clusterName
    const list=[]
    let partner=null
    let beginingIndex=7
    let lastIndex=0
    let shiftIndex=0
    console.log({workerHourlyShift})
    workerHourlyShift.map((shift, index) =>{
    
        if(index>6){
            console.log({index})
            if(index>0 && index<23 && shift[1]!==workerHourlyShift[index-1 ][1] && shift[1]!==workerHourlyShift[index+1][1]){
                beginingIndex=index;
                lastIndex=index;
                partner=shift[1]
                let shiftStatus=0
                list.push({beginingIndex ,lastIndex, partner, shiftStatus})
            }
            else if(index>0 && shift[1]!==workerHourlyShift[index-1][1]){
                beginingIndex=index;
            }
            else if(index<23 && shift[1]!==workerHourlyShift[index+1][1]){
                lastIndex=index;
                partner=shift[1]
                let shiftStatus=0
                console.log(shift[1], workerHourlyShift[index+1][1], index)
                if(shift[1] && shiftStatusList[shiftIndex]){
                    const thisShift=shiftStatusList[shiftIndex++];
                    shiftStatus=thisShift.shift_status
                }
                list.push({beginingIndex ,lastIndex, partner, shiftStatus})
            }
            else if(index===23 && shift[1]){
                lastIndex=index;
                partner=shift[1]
                let shiftStatus=0
                if(shift[1] && shiftStatusList[shiftIndex]){
                    const thisShift=shiftStatusList[shiftIndex++];
                    shiftStatus=thisShift.shift_status
                }
                list.push({beginingIndex ,lastIndex, partner, shiftStatus})
            }

        }
        return true
    })
    console.log({list})
    let shiftDivs=[]
    list.map(shift =>{
        console.log({shift})
        let classValues=null
        let divWidth=`${(shift.lastIndex-shift.beginingIndex +1)*60}px`
        let divValues=null
        const timing=`${shift.beginingIndex===0?"12":shift.beginingIndex>12?shift.beginingIndex%12:shift.beginingIndex}:00 ${(shift.beginingIndex<11 || shift.beginingIndex>23)?"AM":(shift.beginingIndex>10)?"PM":""} - ${shift.lastIndex===23?"12":shift.lastIndex>11?shift.lastIndex%12+1:shift.lastIndex+1}:00 ${(shift.lastIndex<11 || shift.lastIndex===23)?"AM":(shift.lastIndex>10)?"PM":""} `
        if(shift.partner===null){
            classValues= `${style.shiftTimeContainerBlank}`
        }
        else if(shift.partner.toLowerCase().trim()==="flipkart"){
            divValues=<LightTooltip title={<span><b>Hub address:</b><br></br>{address}</span>}><div className={style.divValuesContainer}><img src={FlipkartIcon} alt="flipkart-icon" className={style.partnerIcon} /><span>{timing} </span></div></LightTooltip>
            classValues= `${style.shiftTimeContainer} ${shift.shiftStatus===1?style.goingGreen:shift.shiftStatus===2?style.notGoingRed:style.notMarkedGrey}`
        }
        else if(shift.partner.toLowerCase().trim()==="swiggy"){
            divValues=<LightTooltip title={<span><b>Zone name:</b><br></br>{clusterName}</span>}><div className={style.divValuesContainer}><img src={SwiggyIcon} alt="swiggy-icon" className={style.partnerIcon} /> <span> {timing} </span></div></LightTooltip>
            classValues= `${style.shiftTimeContainer} ${shift.shiftStatus===1?style.goingGreen:shift.shiftStatus===2?style.notGoingRed:style.notMarkedGrey}`
        }
        //Added by Vaibhav
        else if(shift.partner.toLowerCase().trim()==="zomato"){
            divValues=<LightTooltip title={<span><b>Zone name:</b><br></br>{clusterName}</span>}><div className={style.divValuesContainer}><img src={ZomotoIcon} alt="zomato-icon" className={style.partnerIcon} /> <span> {timing} </span></div></LightTooltip>
            classValues= `${style.shiftTimeContainer} ${shift.shiftStatus===1?style.goingGreen:shift.shiftStatus===2?style.notGoingRed:style.notMarkedGrey}`
        }
        else if(shift.partner.toLowerCase().trim()==="delhivery"){
            divValues=<LightTooltip title={<span><b>Zone name:</b><br></br>{clusterName}</span>}><div className={style.divValuesContainer}><img src={DelhiveryIcon} alt="DelhiveryIcon" className={style.partnerIcon} /> <span> {timing} </span></div></LightTooltip>
            classValues= `${style.shiftTimeContainer} ${shift.shiftStatus===1?style.goingGreen:shift.shiftStatus===2?style.notGoingRed:style.notMarkedGrey}`
        }
        else if(shift.partner.toLowerCase().trim()==="jiomart"){
            divValues=<LightTooltip title={<span><b>Zone name:</b><br></br>{clusterName}</span>}><div className={style.divValuesContainer}><img src={JioIcon} alt="jio-icon" className={style.partnerIcon} /> <span> {timing} </span></div></LightTooltip>
            classValues= `${style.shiftTimeContainer} ${shift.shiftStatus===1?style.goingGreen:shift.shiftStatus===2?style.notGoingRed:style.notMarkedGrey}`
        }
        shiftDivs.push(
            <div className={classValues} style={{width: divWidth}}>
                {divValues}
            </div>
        )
        return true;
    })
    
    return (
        <Grid container>
            <div className={style.timeLineContainer}>
                {shiftDivs}
            </div>
        </Grid>
    )
}
export default TimeLineColumn;