import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { numberFormat } from '../../../Utils/CommonFunctions';
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from '../document.svg'
import qs from 'querystring'
import ResponseModal from './response'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import Status from "./status";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { openStatusUpdateModal, setOpenStatusUpdateModal, showToast, updateMyData , status, data} = props
  const [leftStatuses, setLeftStatuses] = useState([])
  const [statusValues, setStatusValues] = useState({
      status: "",
      showIssueTextArea: false,
      showWorkerLeftSelect: false,
      issueTextArea: "",
      workerLeftSelect:""
  })
  const [disableSave, setDisableSave] = useState(true)
  const makeStatusData =(data) =>{
    let list=[];
    data.map(el =>{
        list.push({
            id: el.typeKey,
            name: el.typeValue
        })
        return true
    })
    return list
}
    useEffect(() => {
        if(statusValues.status==="4" && statusValues.issueTextArea)
            setDisableSave(false)
        else if(statusValues.status==="5" && statusValues.workerLeftSelect)
            setDisableSave(false)
        else if(statusValues.status && !statusValues.showIssueTextArea && !statusValues.showWorkerLeftSelect)
            setDisableSave(false)
        else
            setDisableSave(true)
    }, [statusValues])
    
  const getWorkerLeftReasons =() =>{
      if(leftStatuses.length<1){
          Axios.get(`${UrlLinks.baseUrl}configmaster/?category_sub_type=left_status&category_type=retention_call_status`)
          .then(async responseStatus =>{
              await setLeftStatuses(makeStatusData(responseStatus.data.response.content))
          })

      }
  }
  const handleUpdateStatus = () => {
      let postData={
          field: "status",
          retentionCallId: data.retentionCallId,
          status: statusValues.status
      }
      if(statusValues.status==="4"){
          postData.field="reason"
          postData.reason= statusValues.issueTextArea
      }
      else if(statusValues.status==="5"){
        postData.reason= statusValues.workerLeftSelect
    }
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.retentionCalls.statusUpdate}`, qs.stringify(postData))
      .then(response => {
          showToast("success", "Status updated successfully");
          updateMyData();
      })
      .catch(error => {
        showToast("error", error.message);
      })

    console.log("save button")
  }
    const handleChange =(event) =>{
        console.log(event.target.value)
        if(event.target.value==="4"){
            setStatusValues({...statusValues, showWorkerLeftSelect:false, showIssueTextArea:true, status: event.target.value, workerLeftSelect:""})

        }
        else if(event.target.value==="5"){
            getWorkerLeftReasons()
            setStatusValues({...statusValues,  showIssueTextArea:false, showWorkerLeftSelect:true, status: event.target.value, issueTextArea:""})

        }
        else{

            setStatusValues({...statusValues, showIssueTextArea:false, showWorkerLeftSelect:false, status: event.target.value, workerLeftSelect:"", issueTextArea:""})
        }
        
    }
  const handleWorkerLeftStatusChange =(event) =>{
    setStatusValues({...statusValues, workerLeftSelect: event.target.value})
  }
  let dialogData =
    <Dialog
      aria-labelledby="status-update-dialog"
      open={openStatusUpdateModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
      onClose={() => setOpenStatusUpdateModal(false)}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => setOpenStatusUpdateModal(false)}>
      </DialogTitle>
      <DialogContent >
          <Grid container className={Styles.StatusUpdateContainer}>
              <span>Status update </span>
              <Select
                    native
                    value={statusValues.status}
                    disableUnderline
                    onChange={handleChange}
                    style={{border: "1px solid #cccccc", width: "70%", paddingLeft:"10px"}}
                >
                    <option value={""} key={"none"}></option>
                    {
                        status.map(el =>{
                          if(el.id==="5" || el.id==="6"){
                            return <option value={el.id} key={el.id}>{el.name}</option>           
                          }
                          return true
                        })
                    }
            </Select>
          </Grid>
          {statusValues.showIssueTextArea && <Grid container className={Styles.StatusUpdateContainer}>
            <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                rows={4}
                placeholder="Type the issue here"
                variant="outlined"
                value={statusValues.issueTextArea}
                onChange={(e) => setStatusValues({...statusValues, issueTextArea: e.target.value})} 
            />
          </Grid>}
          {statusValues.showWorkerLeftSelect && <Grid container className={Styles.StatusUpdateContainer}>
              <span>Worker left </span>
              <Select
                    native
                    value={statusValues.workerLeftSelect}
                    disableUnderline
                    onChange={handleWorkerLeftStatusChange}
                    style={{border: "1px solid #cccccc", width: "70%", paddingLeft:"10px"}}
                >
                    <option value={""} key={"none"}></option>
                    {
                        leftStatuses.map(el =>{
                            return <option value={el.id} key={el.id}>{el.name}</option>            
                        })
                    }
            </Select>
          </Grid>}
      </DialogContent>
      <DialogActions className={Styles.StatusUpdateButtonContainer}>   
            <button
                disabled={disableSave}
                onClick={handleUpdateStatus}
            >
                Save
            </button>
      </DialogActions>
    </Dialog>



  return dialogData;

}
