/* eslint-disable no-use-before-define */
import React, {useEffect, useState, useCallback} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
export default function LocationFilter(props) {
  const {filters, setFilters, id , label, data, multipleFlag}= props
  const options = data.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  })
  const handleOptions = options.sort((a, b) => {
    if(id==="remark_status"){
      return -b.heading.localeCompare(a.heading)
    } 
    else{
      return -b.firstLetter.localeCompare(a.firstLetter)
    }
  })
  const handleGroupBy =(option) =>{
    if(id==="remark_status"){
      return option.heading
    }
    else{
      return option.firstLetter
    }
  }
  return (
    <Autocomplete
      multiple = {multipleFlag===false ? false: true}
      id={id}
      options={handleOptions}
      groupBy={handleGroupBy}
      value={filters[id]}
      onChange={(event, value) => setFilters({...filters, [id]: value})}
      getOptionLabel={(option) => option.name}
      style={{ width: "250px" }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}



