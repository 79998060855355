import React, { useState } from 'react'
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateSelector from '../../../Utils/FilterComponents/Dateselector'
// import urlLinks from '../../urlLinks'
// import {experience, ageBracket, weekTodays, setqualification, setcuisine, setgender} from "../Job/JobList/newJobViewComponents/jobRequirement"
// import JobModal from '../../utils/Modals/jobModal'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px",
        }
    }
}));
const SelectInput = (props) => {
    const classes = useStyles();
    const [openDateSelector, setOpenDateSelector] = useState(false)
    const { label, filters, setFilters, id, multipleFlag, extraClass, labelClass } = props

    const optionsSortBy = [
        ["dateAssigned", "Date assigned"],
        ["lastUpdated", "Last updated"],
        ["profileSharedDate", "Profile shared date"],
        ["interviewSelectionDate", "Interview selection date"],
        ["interviewDate", "Interview date"],
        ["selectionDate", "Selection date"],
        ["joiningDate", "Joining date"]
    ]

    const optionsInterviewMode = [
        ["", "All"],
        [1, "Profile shared"],
        [2, "Telephonic"],
        [3, "Face to face"]
    ]

    // const id=column.id;
    const optionsCallStatus = [
        ["", "All"],
        ["INTERESTED", "Interested"],
        ["NOT_INTERESTED", "Not interested"],
        ["CALL_NOT_RECIEVED", "Call not Received"],
        ["BUSY", "Busy"],
        ["CALL_LATER", "Call later"],
        ["INVALID_NUMBER", "Invalid number"],
        ["SWITCHED_OFF", "Switched off"],
        ["0", "No status"],
        ["1", "Not null"]
    ]
    const optionsDate = [
        ["today", "Today"],
        ["last2Days", "Last 2 days"],
        ["last4Days", "Last 4 days"],
        ["customDates", "Custom dates"],
    ]
    const workerStatus = [
        ["", "All"],
        ["Activated", "OnBoarded"],
        ["Deactivated", "Not OnBoarded"]
    ]
    const optionsSourceList = [
        ["", "All"],
        ["Quickr", "Quickr"],
        ["Naukri", "Naukri"],
        ["SkillMirror", "SkillMirror"],
        ["Facebook", "Facebook"],
        ["Shine", "Shine"],


    ]
    const optionsGender = [
        ["", "All"],
        [26, "Male"],
        [27, "Female"],
    ]
    const employerStatus = [
        ["", "All"],
        ["Activated", "Activated"],
        ["Deactivated", "Deactivated"],
        ["Suspended", "Suspended"]
    ]
    const currentYear = (new Date()).getFullYear()
    const optionsYearofPassing = []
    for (var i = currentYear; i >= currentYear - 35; i--) {
        optionsYearofPassing.push([i, i])
    }
    const optionsQualification = [
        [1, "Below 10th"],
        [2, "10th passed"],
        [3, "12th passed"],
        [4, "Diploma"],
        [5, "In college"],
        [6, "Graduate"],
        [7, "Post Graduate"]
    ]
    const jobStatus = [
        ["", "All"],
        [1, "Active"],
        [2, "Inactive"],
        [0, "Closed"]
    ]
    const optionsWorkType = [
        ["", "All"],
        [1, "Full time"],
        [2, "Part time"],
        [3, "On demand"]
    ]
    const optionsApprovalStatus = [
        ["", "All"],
        ["APPROVED", "Approved"],
        ["NOT_APPROVED", "Not approved"]
    ]
    const optionsInterviewFollowUp = [
        ["", "All"],
        ["GOING", "Going"],
        ["NOT_GOING", "Not going"],
        ["RESCHEDULE", "Reschedule"],
        ["NO_RESPONSE", "No Response"],
        ["APPEARED", "Appeared"]
    ]
    const optionsApplicationStage = [
        ["", "All"],
        ["APPLIED", "Applied"],
        ["SHORTLISTED", "Shortlisted"],
        ["REJECTED", "Candidate rejected"],
        ["INTERVIEWING", "Interviewing"],
        ["IREJECTED", "Rejected"],
        ["SELECTED", "Selected"],
        ["ACCEPTED", "Offer accepted"],
        ["UPDATE_JOINING_DATE", "Updated joining date"],
        ["OREJECTED", "Offer rejected"],
        ["JOINED", "Joined"],
        ["LEFT_AFTER_JOINING", "Left after joining"],
        ["POSTPONED", "Postponed"],
        ["NOT_JOINING", "Candidate not joining"],
        ["DROPPED", "Candidate dropped"],
        ["AWAITED", "Result awaited"]
    ]

    const optionsFeedback = [
        ["", "All"],
        ["SELECTED", "Selected"],
        ["REJECTED", "Rejected"],
        ["ROUNDS_PENDING", "Rounds pending"],
        ["RESULT_AWAITED", "Result awaited"],
        ["CANDIDATE_DROPPED", "Candidate dropped"],
        ["NOT_APPEARED", "Not appeared"]
    ]
    const optionsJoiningStatus = [
        ["", "All"],
        ["JOINED", "Joined"],
        ["NOT_JOINING", "Not joining"],
        ["POSTPONED", "Postponed"],
        ["NO_RESPONSE", "No response"],
        ["LEFT_AFTER_JOINING", "Left after joining"]
    ]

    const optionCandidateStatus = [
        ["", "All"],
        ["SELECTED", "Selected"],
        ["JOINED", "Joined"],
        ["NOT_JOINING", "Not joining"],
        ["LEFT_AFTER_JOINING", "Left after joining"]
    ]

    const optionInvoiceStatus = [
        ["", "All"],
        ["RAISED", "Raised"],
        ["DEAD", "Dead"],
        ["CANCELLED", "Cancelled"],
        ["CREDITED", "Credited"],
        ["TO_BE_RAISED", "To be raised"],
        ["PENDING", "Pending"]
    ]
    const optionCommissionType = [
        ["", "All"],
        [0, "Fixed"],
        [1, "Variable"]
    ]
    const zone = [
        ["North", "North"],
        ["South", "South"],
        ["East", "East"],
        ["West", "West"]
    ]
    const partners = [
        [100, "Flipkart"],
        [101, "Swiggy"],
        [102, "OkayGo"]
    ]
    const paymentStatus = [
        ["queued", "Queued"],
        ["processing", "Processing"],
        ["processed", "Processed"],
        ["null", "Without payment status"]
    ]
    const workerStatus2 = [
        [100, "Not Onboarded"],
        [1, "Active"],
        [2, "Partially Onboarded"],
        [3, "Inactive"],
        [4, "Offboarding Initiated"],
        [5, "Onboarding Completed"],
        [6, "Temporarily Inactive"],
        [7, "Temporary Inactivation Initiated"],
        [8, "Reactivation Initiated"]
    ]

    let optionsAppliedBy
    try {
        const allAdmins = JSON.parse(localStorage.getItem('allAdmins'));
        optionsAppliedBy = Object.entries(allAdmins);
        if (id === "addedBy")
            optionsAppliedBy.push(["-1", "employer"]);
        if (id === "appliedBy")
            optionsAppliedBy.push(["-1", "self"]);

        optionsAppliedBy.unshift(["", "All"]);
    } catch { }
    let options = null
    switch (id) {
        case "worker_status":
            options = workerStatus2.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "sortBy":
            options = optionsSortBy.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "employer_status":
            options = employerStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "jobStatus":
            options = jobStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "workType":
            options = optionsWorkType.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "callStatus":
            options = optionsCallStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "approvalStatus":
            options = optionsApprovalStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "applicationStage":
            options = optionsApplicationStage.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "interviewFollowUp":
            options = optionsInterviewFollowUp.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "dateAssignedOn":
        case "lastUpdatedOn":
        case "interviewDate":
            options = optionsDate.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "recruiterFeedback":
        case "employerFeedback":
        case "finalFeedback":
            options = optionsFeedback.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "joiningStatus":
            options = optionsJoiningStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "interviewMode":
            options = optionsInterviewMode.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "candidate_status":
            options = optionCandidateStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "zone":
            options = zone.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "partnerId":
            options = partners.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "workerStatus2":
            options = workerStatus2.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "payment_status":
            options = paymentStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "invoice_status":
            options = optionInvoiceStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "commission_type":
            options = optionCommissionType.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "appliedBy":
        case "addedBy":
            options = optionsAppliedBy.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "source":
            options = optionsSourceList.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "gender":
            options = optionsGender.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "year_of_passing":
            options = optionsYearofPassing.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "qualification":
            options = optionsQualification.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        default:
            options = null
            break;
    }
    const handleChange = (event) => {
        if (filters[id].includes("") && event.target.value.length > 1) {
            setFilters({ ...filters, [id]: event.target.value.filter(x => x !== "") })
        }
        else if (event.target.value.includes("") || event.target.value.length === 0) {
            setFilters({ ...filters, [id]: [""] })
        }
        else {
            setFilters({ ...filters, [id]: event.target.value })
        }
    };
    const handleSingleChange = (event) => {
        if (event.target.value === "customDates") {
            setOpenDateSelector(true)
        }
        else {

            setFilters({ ...filters, [id]: event.target.value })
        }
    };


    return (
        <Grid container>
            <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel htmlFor={id} className={`${filters[id] ? "" : `${labelClass}`}`}>{label}</InputLabel>
                <Select
                    labelId={id}
                    label={label}
                    id={id}
                    multiple={multipleFlag}
                    value={filters[id]}
                    IconComponent={() => <ExpandMoreIcon />}
                    onChange={multipleFlag ? handleChange : handleSingleChange}
                    // onChange={handleSingleChange}
                    className={`${classes.select} ${extraClass}`}
                    inputProps={{
                        name: { label },
                        id: { id },
                    }}
                >
                    {options}
                </Select>
            </FormControl>
            {openDateSelector && <DateSelector
                label={label}
                openDateSelector={openDateSelector}
                setOpenDateSelector={setOpenDateSelector}
                filters={filters}
                setFilters={setFilters}
                id={id}
                dateVal={id === "lastUpdatedOn" ? "datesLastUpdatedOn" : id === "dateAssignedOn" ? "datesAssignedOn" : id === "interviewDate" ? "datesInterviewDate" : ""}
            />}
        </Grid>

    )
}
export default SelectInput;