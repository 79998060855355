import Axios from "axios";
import React, { useEffect, useState } from "react";
import UrlLinks from "../../../UrlLinks";
import styles from '../index.module.scss'
import ResponseModalLastApplied from './ResponseModalLastApplied.js'
import * as moment from 'moment'
const LastSentMsgs = (props) => {
    const { row} = props
    // console.log({props})
    const [resultData, setResultData] = useState(null)
    const [resultModal, setResultModal] = useState(false)
    const handleLastAppliedView =() =>{
        const resArray=[]
        Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.lastAppliedHistory}${row.original.userId}`)
            .then(response =>{
                console.log({response})
                if(response.data.code===1000){
                    response.data.response.map(job =>{
                        resArray.push({
                            client: job.companyName || "",
                            hub: job.hubName || "",
                            appliedOn: job.insertedOn ? moment(job.insertedOn).format("DD-MM-YYYY") :"",
                            appliedBy: job.appliedBy || ''
                        })
                    })
                    setResultData(resArray)
                }
            })
    }
    const handleClose = () =>{
        setResultModal(false)
        setResultData(null)
    }
    useEffect(() => {
        if(Array.isArray(resultData)){
          console.log({resultData});
            setResultModal(true)
      }
    }, [resultData])

  return (
    <div className={styles.lastJobContainer}>
        <button
            onClick={handleLastAppliedView}
        >
            {`${row.original.lastJobAppliedFor[0]?row.original.lastJobAppliedFor[0]:""} ${row.original.lastJobAppliedFor[2]?row.original.lastJobAppliedFor[2]:""}`}
        </button>
        <span>{row.original.lastJobAppliedFor[1]?row.original.lastJobAppliedFor[1]:""}</span>
        {resultModal && <ResponseModalLastApplied
            open={resultModal}
            data= {resultData}
            close={handleClose}
        />}
    </div>
  );
};

export default LastSentMsgs;
