import React, { useState } from "react";
import {ClipLoader} from "react-spinners";
import Styles from '../index.module.scss'
import Axios from 'axios'
import UrlLinks from '../../../UrlLinks'
const DownloadReport = ({row}) => {
    const [disabled, setDisabled] = useState(false)
    const loader=<div style={{marginLeft:" 8px"}}>
        <ClipLoader
            size={18}
            color={"#1c75bc"}    
        />
    </div>
    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    const handleDownloadReport =() =>{
        setDisabled(true)
        Axios.get(`${UrlLinks.baseUrl}${UrlLinks.BGVDownloadReport}${row.original.userId}`)
            .then(response =>{
                var arrrayBuffer = base64ToArrayBuffer(response.data);
                var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                var link = document.createElement('a');  
                link.href = window.URL.createObjectURL(blob);
                var fileName = `${row.original.okaygoId}-${row.original.name}-BGV-report.pdf`;
                link.download = fileName;
                link.click();
                setDisabled(false)
            })
            .catch(error =>{
                console.log({error})
                setDisabled(false)
            })
    }
    return (
        <div className={Styles.DownloadReport}>
            <button
                
                disabled={disabled}
                onClick={handleDownloadReport}
            >
                Download 
            </button>
            {disabled?loader:""}
        </div>
    );
};

export default DownloadReport;