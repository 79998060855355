import React, { useState} from 'react'
import { Grid ,  Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    select:{
        "& .MuiOutlinedInput-inputMarginDense":{
            // padding: "0 10px",
        },
        width: 250
}
}));
const SelectInput =(props) =>{
    const classes = useStyles();
    const {filters, setFilters, id, multipleFlag,  optionList} =props
    
    const handleChange = (event) => {
        if(filters[id].includes("") && event.target.value.length>1){
            setFilters({...filters, [id]: event.target.value.filter(x => x!=="")})
        }
        else if(event.target.value.includes("") || event.target.value.length===0){
            setFilters({...filters, [id]: [""]})
        }
        else{
            setFilters({...filters, [id]: event.target.value})
        }
    };
    const handleSingleChange = (event) => {
        setFilters({...filters, [id]: event.target.value})
    };
    

    return (
            <FormControl variant="outlined"  margin="dense">
                <Select
                    id={id}
                    multiple={multipleFlag}
                    value={filters[id]}
                    onChange={multipleFlag?handleChange:handleSingleChange}
                    className={`${classes.select}`}
                    inputProps={{
                        id: {id},
                        }}
                >
                    {optionList.map(option =>{
                        return <MenuItem key={option.id} value={option.id }>{option.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
                
    )
}
export default SelectInput;