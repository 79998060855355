import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from '../index.module.scss'
import { ScaleLoader } from "react-spinners";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '0 16px 16px 16px',
    border: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between"
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { open, data,close } = props
  console.log({data})
  const loader = <div style={{ margin: "40px auto", width: "fit-content" }}>
    <ScaleLoader
      height={70}
      width={10}
      color={"#1c75bc"}
    />
  </div>
  let dialogData = 
      
        <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        className={Styles.onboardingDialog}
        classes={{ paper: Styles.paperResponse }}
      >
        <DialogTitle id="customized-dialog-title" onClose={close}>
            <Grid item xs={12} >
              <span className={Styles.summaryText}>Job Applied history</span>
            </Grid>
        </DialogTitle>
        <DialogContent dividers>
            {data && <Grid item xs={12} className="padding0 ">
              <table >
                  <th className={Styles.tableHeadLastApplied}>CLIENT</th>
                  <th className={Styles.tableHeadLastApplied}>APPLIED ON</th>
                  <th className={Styles.tableHeadLastApplied}>HUB </th>
                  <th className={Styles.tableHeadLastApplied}>APPLIED BY</th>
                  {data.map(job =>{
                    console.log({job})
                    return <tr>
                      <td>{job.client}</td>
                      <td>{job.appliedOn}</td>
                      <td>{job.hub}</td>
                      <td style={{textAlign: "left"}}>{job.appliedBy}</td>
                    </tr>
                  })}
              </table>
            </Grid>}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>



  return dialogData;

}
