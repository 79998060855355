import { Box, Divider, Grid } from '@material-ui/core';
import React, { useState, useEffect} from 'react'
import styles from './index.module.scss'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// import verifiedIcon from './Verified_icon.png'
// import notVerifiedIcon from './Not-Verified_icon.png'
// import inProcessIcon from './In_process_icon.png' 
// import mismatchIcon from './Mismatch_icon.png' 


import pendingIcon from './Pending_icon1.svg'
import verifyIcon from './Verified_icon.svg'
import mismatchIcon from './Mismatch_icon.svg'
import failedIcon from './Failed_icon.svg'
import clockIcon from './Clock_icon.svg'
import { Link } from 'react-router-dom';
import axios from 'axios';
import UrlLinks from '../../../UrlLinks';
// import swal from 'sweetalert';
import swal from 'sweetalert2'
import { TextDecrease } from '@mui/icons-material';
import { lineHeight, style } from '@mui/system';
import moment from 'moment';
import Decrypt from '../../../Utils/decrypt';

// import $ from 'jquery';

const PennyTesting = (props)=>{
    const { setFilterDrawer, data,handleNextRecord,getRecord} = props;
    const[nameMatchStatus,setNameMatchStatus] = useState(1)
    const[testingStatus,setTestingStatus] = useState(data.pennyTestingStatus)
console.log(testingStatus)

const handlePennyStatusUpdateButton= (status) =>{
    swal.fire({
        title: `Are you sure to mark ${status===5?"Mismtached":"Verified"} ?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update status'
      }).then((result) => {
        if (result.isConfirmed) {
        //   swal.fire(
        //     'Deleted!',
        //     'You have updated the status.',
        //     'success'
        //   )
        console.log(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.updatePennyTestingStatus}?pennyTestingStatus=${status}&userId=${data.userId}`)
        axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.updatePennyTestingStatus}?pennyTestingStatus=${status}&userId=${data.userId}`)
            .then(response =>{
                if(response){
                    if(response.data.code===1002){
                        // swal(response.data.response,"","error");
                        swal.fire(
                            'Error Occured',
                            response.data.response,
                            'error'
                          )
                          getRecord()
                        // swal("Custom Message Sent","",  "success");
                    }else if(response.data.code===1000){
                        swal.fire(
                            'Updated!',
                            'You have updated the status.',
                            'success'
                          )

                          getRecord()
                    }
                    console.log(response)
                    // handleNextRecord()
                    // makeData(response.data.response);
                    // setLoading(false)
                }
            }).catch((e)=>{console.log(e)})
        }
      })
    }


const  makePennyStatusUpper= (status) => {
    console.log("======> "+ status)
    if (status===null){
        return "-";

    }
    switch (status) {
        case 0:
            return <div className={styles.pannyStatusDiv}>-</div>;
       case 1:
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={verifyIcon} alt=""></img><div style={{marginLeft:'15px'}}> Verified </div> </div>;
        case 2:
            // return "In process";
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={pendingIcon} alt=""></img><div style={{marginLeft:'15px'}}>  In Process  </div></div>;
        case 3:
            // return "Failed";
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={mismatchIcon} alt=""></img><div style={{marginLeft:'15px'}}>  Not validated </div> </div>;
        case 4:
            // return "Mismatch";
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={mismatchIcon} alt=""></img><div style={{marginLeft:'15px'}}>  Mismatch </div> </div>;
        case 5:
            // return "Mismatch";22px
            return <div className={styles.pannyStatusDiv} style={{paddingLeft:"20px"}}><img width={'22px'} src={mismatchIcon} alt=""></img><div style={{ textAlign:"center"}} >  Mismatch Confirmed</div> </div>;
        default:
            break;
    }
}
const makePennyStatus = (status) => {
    console.log("======> "+ status)
    if (status===null){
        return "-";

    }
    switch (status) {
        case 0:
            return <div className={styles.pannyStatusDiv}>-</div>;
       case 1:
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={verifyIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}> Verified </div> </div>;
        case 2:
            // return "In process";
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={pendingIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  In Process  </div></div>;
        case 3:
            // return "Failed";
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={failedIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  Failed </div> </div>;
        case 4:
            // return "Mismatch";
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={verifyIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  Verified </div> </div>;
        case 5:
            // return "Mismatch";
            return <div className={styles.pannyStatusDiv}><img width={'22px'} src={verifyIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  Verified</div> </div>;
        default:
            break;
    }
}


    const top =<Grid className={styles.pnMain}>
        <div style={{display:"flex",justifyContent: 'space-between'}}>
            <div className={styles.pennyName}>{data.name}</div> 
            <div className={styles.pennyPhone}>{data.phoneNumber}</div>
        </div>
        <div>
        <b style={{fontWeight:"500"}}> OkayGo ID  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> <span>{data.okaygoId}</span> 
        </div>
    </Grid>

    const updateOn = data.pennyTestingDate!= "Invalid date" ? <div style={{background: "#E6E6E6", padding:"12px 35px"}}> 
        <span><img src={clockIcon} alt="" style={{marginBottom:"-3px"}}></img>&nbsp;Updated - {data.lastUpdatedOn ? (moment(data.lastUpdatedOn).format("DD/MM/YYYY")) : ""} &nbsp; {data.lastUpdatedOn ? (moment(data.lastUpdatedOn).format("hh:mm a")) : ""} &nbsp; &nbsp;</span>
        {data.verifiedByName && <span>By {data.verifiedByName}</span>}
    </div> : 
    <Divider variant="middle" style={{ width: '90%',marginLeft: '30px'}} />

    const middleSection=<>
    <div className={styles.pennyMiddleSection} >
        <Grid container className={styles.pennyBox}>
            <Grid item xs={7} style={{padding:"20px 0", textAlign:"center"}}> Account Holder Name</Grid>
            <Grid item xs={5} style={{borderLeft:"0.5px solid #CECECE", padding:"20px 0", display:"flex", justifyContent:"center"}}>{makePennyStatusUpper(testingStatus)}</Grid>
        </Grid>
    
        <table style={{margin:"1% 0"}}>
            {testingStatus!==3 && 
                <tr className={styles.pennyMiddleInfo}>
                    {/* <div className={styles.pennyMiddleLeftInfo}> */}
                    <td style={{width:"40%", lineHeight:"25px", padding:"12px 0"}} className="removeBorders">Account holder name (from penny testing)</td>
                    <td style={{marginLeft:"2px", marginRight:"3%"}} className="removeBorders">:</td>
                    <td className="removeBorders">{Decrypt(data.accountHolderNameFromPennyTesting)}</td>
                </tr>
            }
            <tr className={styles.pennyMiddleInfo}>
                {/* <div className={styles.pennyMiddleLeftInfo}> */}
                <td style={{width:"40%", lineHeight:"25px", padding:"12px 0"}} className="removeBorders">Account holder name (entered by user)</td>
                <td style={{marginLeft:"2px", marginRight:"3%"}} className="removeBorders">:</td>
                <td style={{padding:"12px 12px"}} className="removeBorders">{Decrypt(data.accountHolderName)}</td>
            </tr>
            <tr className={styles.pennyMiddleInfo}>
                {/* <div className={styles.pennyMiddleLeftInfo}> */}
                <td style={{width:"40%", lineHeight:"25px", padding:"12px 0"}} className="removeBorders">Worker name on App</td>
                <td style={{marginLeft:"2px", marginRight:"3%"}} className="removeBorders">:</td>
                <td style={{padding:"12px 12px"}} className="removeBorders">{data.firstName} {data.lastName}</td>
            </tr>
        </table>

        {testingStatus===4 && <div style={{display:"flex", justifyContent: "space-between"}}> 
            <Button variant="outlined" onClick={()=>{handlePennyStatusUpdateButton(5)}} className={styles.pennyButton} style={{border:"1px solid #D4D4D4"}}>Confirm Mismatch</Button>
            <Button className={styles.pennyButton} onClick={()=>{handlePennyStatusUpdateButton(1)}} variant="contained" >Verified</Button>
        </div>}
    </div>

    <Divider variant="middle" style={{ width: '90%',marginLeft: '30px'}} />
    </>

    const bottomSection=<div className={styles.pennyMiddleSection} >
      <table className={styles.pennyTable}>
         <tr>
            <td className={styles.width54Per} style={{borderBottom:"0.5px solid #CECECE", padding:"14px 40px"}}>
                <div style={{ fontSize: '20px',fontWeight: '500', lineHeight:"30px"}}>Account Number</div>
                <div style={{ fontSize: '18px', lineHeight:"30px"}}>{Decrypt(data.accountNumber)}</div>
            </td>
            <td rowSpan="2" style={{textAlign:"center", fontSize: '22px',fontWeight: '500', borderLeft:"0.5px solid #CECECE", width:"40%"}}> 
                <div style={{ display: 'flex',justifyContent :'center', marginTop: "60px"}}>{makePennyStatus(testingStatus)}</div>
            </td>
         </tr>
         <tr>
            <td style={{textAlign:"left", padding:"14px 40px"}}>
                <div style={{ fontSize: '20px',fontWeight: '500', lineHeight:"30px"}}>IFSC Code</div>
                <div style={{ fontSize: '18px', lineHeight:"30px"}}>{Decrypt(data.ifscCode)}</div>
            </td>
         </tr>
      </table>
     {testingStatus===3&&data.reason!==null && <div style={{marginTop:"15px",fontSize:"18px", fontWeight:"500"}}>Reason&nbsp;:&nbsp;&nbsp;{data.reason}</div>}
    </div>

    return(<>
        <div className={styles.pennyBoxOuter}>
            {top}
            {updateOn}
            {middleSection}
            {bottomSection}
        </div>
    </>);
}

export default PennyTesting;