import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios'
import Styles from './index.module.scss'
import { Popover, Button, Divider, Grid, IconButton, Badge } from '@material-ui/core';
import SelectFilter from './SelectFilter'
// import SliderFilter from '../../../utils/filters/SliderFilter'
import DateFilter from '../../Utils/FilterComponents/DateFilter'
import LocationFilter from '../../Utils/FilterComponents/locationFilter'
import FilterIcon from '../../assets/filter.svg'
import GroupedSelectFilter from './groupedSelectFilter'
import InputTextFilter from '../../Utils/FilterComponents/inputTextFilter'
import urlLinks from '../../UrlLinks'
const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function SimplePopover(props) {
    const { isFilterApplied, setIsFilterApplied, asaanRole } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = React.useState()
    const [hubsList, setHubsList] = useState([])
    const [facilityStateList, setFacilityStateList] = useState([])
  useEffect(() => {
    setFilters(props.filters)
  }, [])
  
    const makeHubList = data => {
        let list = []
        let count =0;
        if (data?.length > 0) {
            data.map(skill => {
                count++

                if (skill !== null) {
                    let list2 = 
                        {
                            name: skill.facilityName,
                            id: count
                        }
                    
                    // console.log(list2)
                    list.push(list2)
                }
            })
        }
        return list;
    }
    // console.log(hubsList)
    useEffect(() => {
        // Axios.get(`${urlLinks.baseUrl}configmaster/getActiveHubs`)
        //     .then(response => {
        //         setHubsList(makeHubList(response.data.response))
        //     })
    }, [])
    const makeFacilityStateList = data => {
        let list = []
        let count =0;
        if (data?.length > 0) {
            data.map(skill => {
                count++

                if (skill !== null) {
                    let list2 = 
                        {
                            name: skill,
                            id: count
                        }
                    
                    // console.log(list2)
                    list.push(list2)
                }
            })
        }
        return list;
    }
    // console.log(facilityStateList)
    useEffect(() => {
        // Axios.get(`${urlLinks.baseUrl}/configmaster/getFacilityStates`)
        //     .then(response => {
        //         setFacilityStateList(makeFacilityStateList(response.data.response))
        //     })
    }, [])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // console.log(filters)
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleResetFilters = () => {
        const newFilters = props.resetFilters()
        setFilters(newFilters);
        setIsFilterApplied(false)

    }
    const handleApplyFilters = () => {
        props.setFilters(filters);
        handleClose();
    }
    const handleCancelFilters = () => {
        setFilters(props.filters);
        handleClose();
    }
    let popoverData = <Grid></Grid>
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    popoverData =
        asaanRole?
        <Grid container className= {Styles.filtersContainer}>
            <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Payment Status"
                    id="payment_status"
                    style={{ width: "30px" }}
                />
            </div>
            <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Bank details filled"
                    id="bankDetails"
                    style={{ width: "30px" }}
                />
            </div>
            <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Bank details Verified"
                    id="bankDetailsVerified"
                    style={{ width: "30px" }}
                />
            </div>
            
            <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="PAN Verification Status"
                    id="panStatus"
                    style={{ width: "30px" }}
                />
            </div>
               
        </Grid>
        :<Grid container>
            <Grid item sm className="padding20 margin20" style={{ width: "30px" }}>
            <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Zone"
                    // style={{ width: "30px" }}
                    id="zone"
                />
                </div>
            </Grid>
            {/* <Grid item sm className="padding20">
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Partner"
                    id="partnerId"
                />
            </Grid> */}
               <Grid item sm className="padding20 margin20" style={{ width: "30px" }}>
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Facility State"
                    id="facility_state"
                    data={facilityStateList}
                    multipleFlag={false}
                    style={{ width: "30px" }}
                />
            </Grid>
            <Grid container>
            <Grid item sm className="padding20 margin20" style={{ width: "30px" }}>
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Facility Hubs"
                    id="facility_name"
                    data={hubsList}
                    multipleFlag={false}
                    style={{ width: "30px" }}
                />
                </Grid>
            
            <Grid item sm className="padding20 margin20" style={{ width: "10px" }}>
            <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Payment Status"
                    id="payment_status"
                    style={{ width: "30px" }}
                />
                </div>
            </Grid>
            </Grid>
            <Grid container>
      
            <Grid item sm className="padding20 margin20" style={{ width: "150px" }}>
                <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Worker Status"
                    id="worker_status"
                    style={{ width: "30px" }}
                />
                </div>
                
                </Grid>
                <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
                <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Worker type"
                    id="worker_type"
                    style={{ width: "30px" }}
                />
                </div>
                
                </Grid>
                
            </Grid>
            <Grid container>
            <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
                <div style={{width:"250px"}}>
                    <SelectFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Payment type"
                        id="payment_type"
                        style={{ width: "30px" }}
                    />
                </div>
                
                </Grid>
                <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
                    <div style={{width:"250px"}}>
                        <SelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Advance Taken"
                            id="advanceTaken"
                            style={{ width: "30px" }}
                        />
                    </div>
                
                </Grid>
                <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
                    <div style={{width:"250px"}}>
                        <SelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Modal Flag"
                            id="modalFlag"
                            style={{ width: "30px" }}
                        />
                    </div>
                
                </Grid>
                
                
            
            </Grid>
            {/* <Grid container> */}
            {/* <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
                <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Bank details filled"
                    id="bankDetails"
                    style={{ width: "30px" }}
                />
                </div>
                
                </Grid> */}
                {/* <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
                <div style={{width:"250px"}}>
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Advance Taken"
                    id="advanceTaken"
                    style={{ width: "30px" }}
                />
                </div>
                
                </Grid> */}
                
                
            
            {/* </Grid> */}
            
            
            
            {/* <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Job role"
                    id="jobRole"
                />
            </Grid> */}
        </Grid>


    return (
        <div>
            <Badge color="secondary" variant="dot" invisible={!isFilterApplied}>
                <IconButton
                    className="filterButton marginLight20"
                    onClick={handleClick}
                >
                    <img src={FilterIcon} alt="img" />
                </IconButton>
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container className="buttonList">
                    <Grid item xs={4} style={{padding: "10px"}}>
                        <span className="FilterHeading">All Payment filters</span>
                    </Grid>
                    <Grid item xs={8} style={{ padding: "20px" }} className="flexRowReverse">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={handleResetFilters}
                        >Reset</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={handleApplyFilters}
                        >Apply</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={handleCancelFilters}
                        >Cancel</Button>
                    </Grid>
                </Grid>
                <Divider />
                {popoverData}
            </Popover>
        </div>
    );
}
