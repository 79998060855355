import * as moment from 'moment';


export const dateFormat = (date) =>{
    return date?moment(date).format("DD/MM/YYYY"):"";
}

export const createWorkerStatus =(status) =>{
    console.log({status})
    let text=""
    switch (status) {
        case 0:
            text="Not Onboarded";
            break;
        case 1:
            text="Active";
            break;
        case 2:
            text="Partially Onboarded";
            break;
        case 3:
            text="Inactive";
            break;
        case 4:
            text="Offboarding Initiated";
            break;
        case 5:
            text="Onboarding Completed";
            break;    
        case 6:
            text="Temporarily Inactive";
            break;
        case 7:
            text="Temporary Inactivation Initiated";
            break; 
        case 8:
            text="Reactivation Initiated";
            break;                
        default:
            
            break;
    }
    return text;
}
export const createVerificationStatus =(status) =>{
    let text=""
    switch (status) {
        case 0:
            text="To be Verified";
            break;
        case 1:
            text="Verified";
            break;
        case 2:
            text="Mismatch";
            break;
        case 3:
            text="Mismatch confirmed";
            break;
        case 4:
            text="Not filled";
            break;      
        default:
            
            break;
    }
    return text;
}
export const numberFormat = (value) =>{
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
    }).format(value);}