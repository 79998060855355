import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton, Button} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { Link } from "react-router-dom";
import urlLinks from '../../../UrlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';



const EditableDropdownSubComponent =( props) =>{
    const {row, columnId, initialValue, updateMyData, showToast, setLoading, interviewAddress} = props
    const [value, setValue] = useState(initialValue);
    
    let label=""
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    console.log({row})
    const updateStatus = () => {
        setLoading(true)
        console.log("entered", value)
        if (columnId === "approvalStatus") {
            const postData = {
                field: "approvalStatus",
                userId:row.userId,
                referralId:row.referralId

            }


            Axios.post(`${urlLinks.baseUrl}${urlLinks.referral.updateReferralFields}?updatedBy=${localStorage.getItem("userID")}&referralId=${postData.referralId}&value=${value}&field=${postData.field}`)
                .then(response => {
                    if (response.data.code === 1000) {
                        updateMyData()
                        setLoading(false);
                    } else {
                        showToast(response.data.message);
                        setLoading(false);
                    }
                })
        }

    }
    const handleConfirm =() =>{
        setLoading(true)
        updateStatus();
    }
    const handleCancel =() =>{
        setValue(initialValue)
        setShowButtons(false)
    }
    
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    let dropdown=<div></div>
    if(columnId==="approvalStatus")
        dropdown=<div className="index">
            <Select
                id="approvalStatus"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="-" disabled>
                    <em>Approval Status</em>
                </MenuItem>
                <MenuItem value='1'>Approved</MenuItem>
                <MenuItem value='2'>Not Approved</MenuItem>
         
            </Select>
            
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
            {row.callStatusDate && 
                <div>
                    <span>{row.callStatusDate}</span>
            </div>}
            
        </div>
    
    return (
        <React.Fragment>
            {dropdown}
            
        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;



