import {useState, useEffect, React} from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Axios from 'axios'
import UrlLinks from '../../../UrlLinks';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import swal from 'sweetalert';
import "./SendMsg.css"
import { IconButton, TextField } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    overflow:"scroll",
    maxHeight: "482px",
    // height: 200,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 3,
    p: 2
};
export default function ResponsiveDialog(props, styles) {
    const {open, setOpen,userIds,isLeadData,filterUrl} = props;
    const [temaplateList, setTemplateList] = useState([{}]);
    const [menuItem, setMenuItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sendCustom, setSendCustom] = useState(false);
    const [customTitle,setCustomTitle] =useState(null)
    const [customBody,setCustomBody] =useState(null)
    const [diable, setDiable] = useState(true);
    const [value, setValue] = useState({tempName:null,serviceType:null});
    const [message, setMessage] = useState({title: null, body: null});
    const [modesAvailable, setModesAvailable] = useState([]);
console.log(userIds)
    const handleClose = () => {
        setOpen(false);
    };

    const settingData=(res)=>{
        res.map(e=>{

        })
    }
    useEffect(()=>{
        if(customBody!==null&&customBody.trim().length>0 && customTitle !==null&&customTitle.trim().length>0){
            setDiable(false)
        }else{
            setDiable(true)
        }
    },[customBody,customTitle])
    useEffect(() => {
        setLoading(true)
        Axios.get(`${
            UrlLinks.baseUrl
        }${
            UrlLinks.messageTemplates
        }`).then(response => {
            setLoading(false)
            setResponseIntoTemplateList(response.data.response)
        }).catch(error => {})
    }, [])
    const getMessageBody = (template) => {
        setLoading(true)
        setDiable(false)
        console.log(template)
        let serviceType=template.substr(0,template.indexOf("-")) 
        let templateType= template.substr(template.indexOf("-")+1,template.indexOf("#")) 
        template=template.substr(template.indexOf("#")+1,template.length)
        let temp=template
        if(template==="super_member_sms"){
            temp="super_member"
        }else if(template==="gold_member_popup"){
            temp="gold_member"
        }
        setValue({
            tempName:temp,
            serviceType:serviceType
        })
        console.log("bhai")
        Axios.get(`${
            UrlLinks.baseUrl
        }${
            UrlLinks.getMessageBody
        }?templateName=` + template + "&templateType=" + templateType).then(response => { // setResponseIntoTemplateList(response.data.response)
            setLoading(false)
            if (response.data.code===1000) 
                setMessage({title: response.data.response.emailSubject, body: response.data.response.emailText})
            
        }).catch(error => {})
    }
  
    const sendToAll=()=>{
        let url;
        if(sendCustom){
           url=`&title=${customTitle}&body=${customBody}`;
        }else
{
    url=`&templateName=${value.tempName}&serviceTypes=${value.serviceType}`;

}
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.sendMsgToAll}?${filterUrl}${isLeadData}${url}`)
        .then(response => {
            swal("Message Sent to all","",  "success");
            setOpen(false)
            
        }).catch(error => {
            // console.log(error)
            swal("Failed!" , "","error");
            setOpen(false)
        })
    }
    const sendMessageAPI = () => {
        console.log(value)
        // setLoading(true)
        // sendCustomMsg
        if(sendCustom){
            Axios.post(`${
                UrlLinks.baseUrl
            }${
                UrlLinks.sendCustomMsg
            }?insertedBy=${localStorage.getItem("userID")}&user_ids=${userIds}&title=${customTitle}&body=${customBody}`).then(response => { 
                // console.log(response)
                swal("Custom Message Sent","",  "success");
                setOpen(false)
                
            }).catch(error => {
                // console.log(error)
                swal("Failed!" , "","error");
                setOpen(false)
            })
        }else{
        Axios.post(`${
            UrlLinks.baseUrl
        }${
            UrlLinks.sendMsg
        }?insertedBy=${localStorage.getItem("userID")}&user_ids=${userIds}&templateName=${value.tempName}&serviceTypes=${value.serviceType}`).then(response => { 
            // console.log(response)
            swal("Message Sent","",  "success");
            setOpen(false)
            
        }).catch(error => {
            // console.log(error)
            swal("Failed!" , "","error");
            setOpen(false)
        })
    }
    }

    const setResponseIntoTemplateList = (response) => {
        setTemplateList(response)
        let menuList = []
        let i = 0;
        response.map(e => {
            i++;
            console.log(i)
            if(e.purpose==="Advance Payment"){
                
            }else{
            menuList.push (
                <MenuItem key={i}
                    value={e}>
                    {
                    e.purpose
                }</MenuItem>
            )
        }
        })

        menuList.push(<MenuItem  key={i}
            value={"Custom"}>
            {
            "Custom Message"
        }</MenuItem>)
        console.log(menuList)
        console.log("I left")
        setMenuItem(menuList)

    }
    console.log(customTitle)
    console.log(customBody)
    const handleSelect = (e) => {
        setSendCustom(false)
        setMessage({title: null, body: null})
        setDiable(true)
        let avModes = []
        console.log(e)
       
        if (e !== null && e !== undefined && e.modes !== null) {
            let givenModes = e.modes.split(",")
            let temaplateNames =[];
            temaplateNames = e.template_names.split(",")
            console.log(givenModes)
            givenModes.map(e => {
                switch (e) {
                    case "1":
                        {
                            avModes.push("email-1")
                            break;
                        }
                    case "2":
                        {
                            avModes.push("sms-2")
                            break;
                        }
                    case "3":
                        {
                            avModes.push("whatsapp-3")
                            break;
                        }
                    case "4":
                        {
                            avModes.push("notification-4")
                            break;
                        }
                    case "5":
                        {
                            avModes.push("popup-5")
                            break;
                        }
                    default:
                        {}
                }
            })
           for(let i=0; i<temaplateNames.length; i++){
               avModes[i]=avModes[i]+"#"+temaplateNames[i];
           }
           console.log(avModes)

           

        }
        setModesAvailable(avModes)
    }
    
    const customMessage =<>
    <FormLabel style={{paddingBottom:"12px",
    color: "black",
    fontWeight: "500"
}} component="legend">Title</FormLabel>
    <TextField
       inputProps={{ maxLength: 100 }}
    placeholder="Please write a title (Char limit 100)"
    multiline
    rows={1}
    fullWidth
    onChange={(event)=>{setCustomTitle(event.target.value)}}
    style={{paddingBottom:"10px"}}
    // rowsMax={8}
  />
  <FormLabel style={{paddingBottom:"12px",
    color: "black",
    fontWeight: "500"}} component="legend">Message Body</FormLabel>
    <TextField
    inputProps={{ maxLength: 200 }}
    placeholder="Please write a Message (Char limit 200)"
    multiline
    onChange={(event)=>{setCustomBody(event.target.value)}}
    rows={4}
    fullWidth
    // rowsMax={8}
  />
  </>

    console.log(modesAvailable)
    const deliciousRadioBoxes = <> {
        modesAvailable.length > 0 && <FormControl component="fieldset">
            <FormLabel component="legend">Available Modes</FormLabel>
            <RadioGroup aria-label="gender" name="controlled-radio-buttons-group" row
                // value={value}
                onChange={(event)=>{ 
                    getMessageBody(event.target.value)}}
            >
                {
                console.log(modesAvailable)
            }
                {
                modesAvailable.map(e => <FormControlLabel value={e}
                    control={
                        <Radio
                    size="small"/>
                    }
                    label={e.substr(0,e.indexOf("-"))}/>)
            } </RadioGroup>
        </FormControl>
    } </>

    const templateSelect = <>
        <div>
            <FormControl sx={
                {
                    m: 1,
                    minWidth: 300
                }
            }>
            
               <FormLabel style={{paddingBottom:"12px"}} component="legend">Please choose a purpose</FormLabel>
                <Select labelId="demo-simple-select-autowidth-label" id="demo-simple-select-autowidth"
                    //   value={"ad"}
                    // style={sendCustom?{backgroundImage: "linear-gradient(to right, rgb(185 90 245 / 46%), rgb(230 255 154))"}:null}
                    onChange={
                        (event) => {
                            if(event.target.value==="Custom"){
                                console.log("Custom Selected")
                                setSendCustom(true)
                                setDiable(true)
                                setMessage({title: null, body: null});
                                setModesAvailable([])
                            }else{
                            handleSelect(event.target.value)
                            }
                        }
                    }
                    autoWidth>
                    {menuItem}
                     </Select>
            </FormControl>
        </div>
    </>


    console.log(menuItem)
    return (
        <div>
            <Modal onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
    //   onClose(event, reason);
    handleClose()
    }
  }} open={open}
            BackdropProps={false}
                // onClose={handleClose}
                >
                <Box sx={style}>
                    <DialogTitle id="responsive-dialog-title">
                        {"Manual Communication Channel"} <span className="selected-worker">({userIds.split(",").length} selected)</span> 
                
                        <IconButton aria-label="delete" style={{left: "224px",position: "relative"}}> 
  <CancelRoundedIcon onClick={handleClose} />
</IconButton>
</DialogTitle>
                  
                        
                    <DialogContent>
                        <DialogContentText> {
                            menuItem.length > 0 && templateSelect
                        } </DialogContentText>
                        <div style={
                            {padding: "9px"}
                        }>
                            {deliciousRadioBoxes} 
                            </div>
                       
                            {(message.body!==null || message.title!==null) && <div className="text-box "> <p>{message.title}</p> <p>{message.body}</p></div>}
                   {loading? " ...please wait":""}

                   {sendCustom && <div style={
                            {padding: "9px"}}>
                    {customMessage}
                   </div>}
                   
                    </DialogContent>
                    <DialogActions>
                        <Button  size="small" variant="contained" disabled={diable}
                            onClick={sendToAll}>
                            Send to all
                        </Button>
                        <Button size="small" variant="contained" disabled={diable} onClick={sendMessageAPI}
                            >
                            Send to selected
                        </Button>
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    );
}
