import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Upload from './components/DelhiveryEarningAdmin'
import { Button, Grid, Checkbox } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as moment from 'moment'
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';


const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            width: max-content;
            position: absolute;
            left: 315px;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const EmployerData = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    // const { showToast } = useContext(AuthContext);
    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRecord, setAllRecord] = useState([]);
    const [disablePayments, setDisablePayments] = useState(true);
    // const [selectedRows, setSelectedRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);
    const [sortDirectionUrl, setSortDirectionUrl] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [sortUrl, setSortUrl] = useState("");
    const [financialAdmin, setFinancialAdmin] = useState(false)
    const [filterUrl, setFilterUrl] = useState("");
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [filterDrawer, setFilterDrawer] = useState(false);
    const [searchByAuto, setsearchByAuto] = useState("");
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openAdminNotes, setOpenAdminNotes] = useState(false)
    const [openDatePicker, setopenDatePicker] = useState(false)
    const [row, setRow] = useState(null)
    useEffect(() => {
        ApplyFilters(filters);
        // ApplySort();
        // setSortDirection(false);
        // setSortDirectionUrl('');
    }, [searchByAuto]);
    const [filters, setFilters] = useState({
        searchBy: "",
        billDate: [null, null],
        zone: "",
        facility_state: "",
        facility_name: "",
        jobStatus: "",
        facilityCity: "",
        partnerId: "",
        area: ""
    });

    const sortOptions = [
        ["swiggy_id", "Swiggy"],
        ["flipkart_id", "Flipkart"]
    ];
    const ApplySort = () => {
        let url = '';
        switch (sortBy) {
            case "swiggy_id":
                url = '&partnerId=101';
                break;
            case "flipkart_id":
                url = '&partnerId=100';
                break;
            default:
                url = ''
                break;
        };
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url);
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false);
        setSortDirectionUrl('');
    }, [sortBy]);
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)
        // ApplyFilters(filters);
    }
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
        setFilterUrl('')
    }
    // useEffect(() => {

    //     // setFilters(filters)
    // }, [])
    const midFunction = (prop) => {
        console.log("I WAS CALLED")
        ApplyFilters(prop);
    }
    const uploadOffboardingData = () => {
        setOpenOffboardingModal(true)
    }

    const ApplyFilters = () => {
        let url = '';
        console.log(filters)
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`;
        if (filters["area"]) {
            url = `${url}&area=${filters["area"].name}`
        }
        if (filters["facility_state"]) {
            url = `${url}&facility_state=${filters["facility_state"].name}`
        }
        if (filters["facility_name"]) {
            url = `${url}&facility_name=${filters["facility_name"].name}`
        }
        if (filters["facilityCity"]) {
            url = `${url}&city=${filters["facilityCity"].name}`
        }
        if (filters["jobStatus"]) {
            url = `${url}&jobStatus=${filters["jobStatus"].id}`
        }
        if (filters["partnerId"]) {
            url = `${url}&partner_id=${filters["partnerId"].id}`
        }
        setFilterUrl(url)
        setFilters(filters)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            searchBy: "",
            billDate: [null, null],
            zone: "",
            facility_state: "",
            facility_Name: "",
            payment_status: "",
            worker_status: ""

        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }

    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }

    //columns list
    //     partnerId
    // partnerName
    // logo
    // insertedOn
    // paymentConfiguration
    // isActive
    const columnsOkayGo = useMemo(
        () => [
            {
                Header: "Client id",
                accessor: "partnerId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client Logo",
                accessor: "logo",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return (<span>{row.row.original.logo && <img height='50' width='50' alt="not found" src={row.row.original.logo}></img>}</span>)
                },
            },
            {
                Header: "Client Name",
                accessor: "partnerName",
                defaultCanFilter: false,
                defaultCanSort: false,
            },
            {
                Header: "Payment Configuration",
                accessor: "paymentConfiguration",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Is Active",
                accessor: "isActive",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Job Role",
            //     accessor: "jobRole",
            //     Cell: row => {
            //         return <Link to={{ pathname: `/jobview/${row.row.original.jobId}` }} target="_blank" className="nameLink">{row.row.original.jobRole}</Link>
            //     },
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Added On",
                accessor: "insertedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "No. of Applicants",
            //     accessor: "numberofApplicants",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // // numberofApplicants
            // {
            //     Header: "Posted On",
            //     accessor: "postedOn",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Status",
            //     accessor: "status",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Hub Name",
            //     accessor: "hubName",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Hub Area",
            //     accessor: "hubAddress",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     Cell: row => {
            //         return (<Grid container style={{width:"300px"}}>
            //              <span>{row.row.original.hubAddress}  </span>
            //         </Grid>)
            //     }
            // },

            // {
            //     Header: "Hub Address",
            //     accessor: "hubAddressNew",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     Cell: row => {
            //         return (<Grid container style={{width:"300px"}}>
            //              <span>{row.row.original.hubAddressNew}  </span>
            //         </Grid>)
            //     }
            // },
            // {
            //     Header: "No of Openings",
            //     accessor: "noOfOpenings",
            //     defaultCanFilter: false,
            //     defaultCanSort: false,
            //     isVisible: true,
            //     Cell: row => {
            //         return (<Grid container className="padding0">
            //             <Grid container className="padding0">

            //                 <Grid item xs={12} className="padding0" style={{ textAlign: "right" }}><span style={{ marginRight: "30px" }}>{row.row.original.noOfOpenings}  </span>
            //                     {/* <button className="linkButton" onClick={() => handleAdminNote(row.row.original)}><b><u>Edit</u></b></button> */}
            //                 </Grid>
            //             </Grid>
            //         </Grid>)
            //     }

            // },
            // {
            //     Header: "Closing Date",
            //     accessor: "closingDate",
            //     defaultCanFilter: false,
            //     defaultCanSort: false,
            //     isVisible: true,
            //     // Cell: EditableTextInput,
            //     // Cell: row => {
            //     //     return (
            //     //         <Grid container  >
            //     //             {/* {row.row.original.closingDate!==""? */}
            //     //             <Grid xs={12} style={{ minWidth: "100px", padding: "20px" }}>
            //     //               {row.row.original.closingDate!=="" &&  moment(row.row.original.closingDate).format("DD/MM/YYYY")}  <button className="linkButton" onClick={handleAdminNote(row.row.original)}><b><u>Edit</u></b></button>

            //     //             </Grid>
            //     //         </Grid>
            //     //     )
            //     // }
            // },
            // {
            //     Header: " ",
            //     accessor: "spocNumber",
            //     defaultCanFilter: false,
            //     defaultCanSort: false,
            //     isVisible: true,
            //     Cell: row => {
            //         return (<Grid container className="padding0">

            //             <Grid item xs={12} className="padding0">
            //                 {/* <button disabled={row.row.original.status==="Active"? false:true} className="linkButton" onClick={() => handleClosingJob(row.row.original)}><b><u>Close Job</u></b></button> */}

            //             </Grid>
            //         </Grid>)
            //     }
            // }

        ],
        []
    );



    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        response.map(el => {
            extractedData.push({
                partnerId: el.partner_id || "",
                partnerName: el.company_name || "",
                logo: el.logo || "",
                insertedOn: el.inserted_on ? moment(el.inserted_on).format("DD/MM/YYYY") : "",
                paymentConfiguration: el.paymentConfiguration === 1 ? "Shipment Basis" : el.paymentConfiguration === 2 ? "Hourly Basis" : el.paymentConfiguration === 3 ? "Daily Basis" : "",
                isActive: el.is_active === 1 ? "Yes" : "No",
            });
            return true;
        });

        return extractedData;
    }

    //Function to create All record export data
    const showToast = (type, message) => {
        if (type === "success")
            toast.success(message);
        else if (type === "error")
            toast.error(message);
    }
    //const columns=(localStorage.getItem("roleType")==="1" || localStorage.getItem("roleType")==="5" || localStorage.getItem("roleType")==="11")?columnsHrbp:columnsOkayGo
    const columns = columnsOkayGo
    const updateMyData = () => {
        getPaymentListing(perPage, currentPage);
    }


    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getPaymentListing = (rows, pageNo) => {

        setLoading(true)
        let requestedBy = ""
        // if(localStorage.getItem("roleType")==="1"){
        //     requestedBy=`&requestedById=${localStorage.getItem("userID")}`
        // }
        if (localStorage.getItem("roleType")) {
            Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`)
                .then(response => {
                    console.log(response.data.response)
                    if (response) {
                        // setTotalRows(response.data.response.totalElements)
                        // setTotalPages(response.data.response.totalPages)
                        // if (currentPage > response.data.response.totalPages)
                        //     setCurrentPage(totalPages)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }
    useEffect(() => {
        getPaymentListing(perPage, 1);

    }, [filterUrl,
        sortUrl,
        localStorage.getItem("roleType")])

    const handlePageChange = page => {
        getPaymentListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getPaymentListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    useEffect(() => {
        // console.log({isAllRowsSelected})
        rows.map((record, index) => {
            // console.log({record})
            // console.log(record, selectedRowIds[index])
            if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
                selectedRowIds[index] = false
                // console.log(selectedRowIds[index])
            }
        })
        // console.log(selectedRowIds)
    }, [isAllRowsSelected])

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisablePayments(disableButton);
            // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    const makeAllRecordData = (response) => {
        let extractedData = [];
        response.map(el => {
            console.log({ el })
            console.log(parseFloat(el.surgeCharges))
            console.log(parseFloat(el.surgeCharges).toFixed(2))
            let temp = {
                jobId: el.jobId || "",
                jobRole: el.jobRole || "",
                client: el.partnerName || "",
                numberofApplicants: el.nubmerofApplicatns || "",
                postedOn: el.insertedOn ? moment(el.insertedOn).format("DD/MM/YYYY") : "",
                status: el.jobStatus === 1 ? "Active" : el.jobStatus === 2 ? "Closed" : "",
                hubName: el.hubName || "",

                hubArea: el.hubAddress || "",
                hubAddressNew: el.hubAddressFlipkart ? el.hubAddressFlipkart : (el.hubAddressOtherClients ? el.hubAddressOtherClients : ""),
                noofOpenings: el.noOfOpenings || "",
                closingDate: el.closingDate ? moment(el.closingDate).format("DD/MM/YYYY") : "",
                // closingDateRaw: el.closingDate  ||"",
                // verificationStatus: createVerificationStatus(el.verificationStatus),
                // adjustments: el.recovery ? `- ${numberFormat(parseFloat(el.recovery))}` : "",
                // isInvoiceCreated: el.isInvoiceCreated

            };

            if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") {
                delete temp["paymentStatus"]
                delete temp["paymentRemarks"]
            }
            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    console.log(localStorage.getItem("roleType"))
    // const createAllRecordExcel = () => {
    //     setAllRecordLoading(true)
    //     let requestedBy = ""
    //     // if (localStorage.getItem("roleType") === "1") {
    //     //     requestedBy = `&requestedById=${localStorage.getItem("userID")}`
    //     // }
    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.jobsdbListing}?orderBy=nubmerof_applicatns&exportExcel=1${requestedBy}${filterUrl}${sortUrl}`)
    //         .then(response => {
    //             if (response) {
    //                 setAllRecord(makeAllRecordData(response.data.response))
    //             }
    //         })
    // }
    // console.log({ "AllRecord": {allRecord} })

    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameMatchNew zIndex1080 header" :
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" : "header"}>

                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="row">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameMatchNew zIndex540" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>




    return (
        <Styles>
            <Grid container className="padding2percent">
            

                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <span style={{marginLeft: "273px"}} className="fontSize24 fontWeightBold">Employer Data</span>
                    </Grid>
                    <Grid style={{marginLeft: "54px"}} item xs={2}>
                        <Link to="/employer-data/company" style={{ textDecoration: "none" }} >
                            <Button variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />}>
                                Add New Company
                            </Button>

                        </Link>
                    </Grid>

                </Grid>
                {/* <Grid item xs={12} className="flexDisplay" alignItems='flex-start'> */}
                {/* <Grid item sm={6} className="flexDisplay padding0 paddingTop8" direction="row" justify="space-between"> */}

                {/* <TextField
                            // disabled
                            className={`${classes.root} marginLeft0`}
                            placeholder="Search by Client Name"
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div> */}
                {/* </Grid> */}



                {/* <Grid item sm={3} style={{ marginLeft: "200px" }} className="paddingTop8" alignItems='flex-end'>
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={uploadOffboardingData}
                        >
                            POST JOB
                        </Button>

                    </Grid> */}
                {/* <Grid item sm={5}  className="paddingTop8" direction="row-reverse" alignItems='flex-start'>
                        <Button
                            variant="contained"
                            className="dataButton"
                            // onClick={createAllRecordExcel}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading?
                            <span style={{marginRight: "20px"}}>Please wait ... it might take few minutes. </span>
                            :allRecord.length>0?
                            <CSVLink
                                data={allRecord}
                                filename={`Jobs List.csv`}
                                className='btn btn-primary blueColor downloadExcelButton'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    marginRight:"40px",
                                    fontWeight: '500',
                                    marginLeft: '1rem'
                                }}
                            >
                                Download now
                            </CSVLink>
                        :null}
                    </Grid> */}
                {/* <Grid item xs={2} className="buttonList padding8"> */}
                {/* <Link to="/employer-data/company" style={{textDecoration:"none"}} > */}
                {/* <Button variant="contained" color="primary" onClick={()=>{setOpenUploadModal(true)}}>
                            Upload Hubs
                        </Button> */}

                {/* </Link> */}
                {/* </Grid> */}
                {/* <Grid item xs={3} className="buttonList padding8" alignItems='flex-end'> */}

                {/* <Button variant="contained" color="primary" style={{marginRight:'20px'}} onClick={()=>{setOpenUploadModal(true)}}>
                            Upload Hubs
                        </Button> */}



                {/* </Grid> */}


                {/* </Grid> */}
                <Grid item xs={12} direction="row" alignItems='flex-start'>




                </Grid>
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    {/* <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    /> */}
                </Grid>}
                {openUploadModal && <Upload
                    openOnboardingModal={openUploadModal}
                    setOpenOnboardingModal={setOpenUploadModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                    data={data}
                ></Upload>}
                <ToastContainer
                    position='top-right'
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    autoClose={1000}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                ></ToastContainer>
            </Grid>
        </Styles>
    )
}
export default EmployerData;