import { Grid } from '@material-ui/core';
import React from 'react'
import styles from './index.module.scss';
import OkaygoLogo from './okagoLogo.svg';
import Checked from './checked.svg'
function RegistrationSuccess() {
    return (
        <div>
            <Grid container>
                <Grid item xs={12} className={styles.navBarStyle} >
                    <a href="/" ><img src={OkaygoLogo} alt="okaygoLogo" /></a>
                </Grid>
                <Grid item xs={12}>
                    <div className={styles.successContainer}>
                        <img src={Checked} alt="checked" />
                        <span className={styles.heading}>Account created successfully</span>
                        <span className={styles.content}>You can now proceed to login using your credentials.</span>
                        <a
                            href="/login" 
                            className={styles.loginButton}
                        >
                            Login
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default RegistrationSuccess
