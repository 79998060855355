import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import styles from './index.module.scss'
import { CSVLink } from 'react-csv';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import UploadAJWorkerModal from '../WorkerDataV3/components/UploadAJEmployeeIdModal'
import SearchIcon from "@material-ui/icons/Search";
import OnboardingModal from './components/onboardingModal'
import OnboardingModalV2 from './components/onboardingModalV2'
import Decrypt from '../../Utils/decrypt'

import OnboardingModalAJV2 from '../WorkerDataV3/components/onboardingModalV2'
import GroupFlexModal from './components/GroupFlexModal'
import CasperOnboardingModal from './components/CasperOnboardingModal'
import OffboardingModal from './components/offboardingModal'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import UploadDoc from './components/uploadDocButton'
import * as moment from 'moment'
import onboardingIcon from './onbaording.svg'
import Pencil from './pencil.svg'
import NoRecord from './noRecord.svg'
import PencilDisabled from './pencilDisabled.svg'
import EditContactModal from './components/EditPhoneNumber'
import ReactivationModal from './components/ReactivtionDialog'
import offboardingIcon from './offboarding.svg'
import ToggleButton from '@material-ui/lab/ToggleButton';
import CriminalRecord from './components/CriminalRecord';
// import filter from '../../assets/filter.svg'
import Filters from './filterModal'
// import Filters from './FilterBase'
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { AuthContext } from '../../services/AuthProvider'
import EditableTextInput from '../../Utils/functions/EditableTextInput'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom'
import UrlLinks from '../../UrlLinks'
import { ClockLoader, PulseLoader } from 'react-spinners'
import SimplePopover from './FilterBase'
import EditableDropdown from './Functions/EditableDropdown'
import './indeterminateCheckbox.css'

const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment >
                <Checkbox ref={resolvedRef} {...rest}/>
            </React.Fragment>
        )
    }
)

const ExternalWorker = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [allRecordLoading, setAllRecordLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allRecord, setAllRecord] = useState([])
    const [agreementDownloading, setAgreementDownloading] = useState(false)
    const [emailAgreement, setEmailAgreement] = useState(false)
    // const [exportExcelLoading, setExportExcelLoading] = useState(false)
    // const [excelData, setExcelData] = useState([])
    // const [selectedRows, setSelectedRows] = useState(0)
    const [totalRows, setTotalRows] = useState(0);
    const [allDocumentDownload, setAllDocumentDownload] = useState(null)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false)
    
    const [openOnboardingModalAJV2, setOpenOnboardingModalAJV2] = useState(false)
    const [openAJworkerUploadModal, setOpenAJworkerUploadModal] = useState(false)
    const [openCasperOnboardingModal, setOpenCasperOnboardingModal] = useState(false)
    const [openGroupflexModal, setOpenGroupflexModal] = useState(false)
    const [searchByAuto, setsearchByAuto] = useState("");
    const [partners, setPartners] = useState([])
    const [editContactData, setEditContactData] = useState({
        userId: null,
        contact: null,
        open: false,
        error: ""
    })
    const [reactivationData, setReactivationData] = useState({
        open: false,
        userId: null,
        date: null
    })
    const [actions, setActions] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState("")
    const [sortDirection, setSortDirection] = useState(false)
    const [sortDirectionUrl, setSortDirectionUrl] = useState('')
    const [searchBy, setSearchBy] = useState("")
    const [sortUrl, setSortUrl] = useState("")
    const [filterUrl, setFilterUrl] = useState("")
    const [groupflexAdmin, setGroupflexAdmin] = useState(false)
    const [filterDrawer, setFilterDrawer] = useState(false)
    const [criminalRecordData, setCriminalRecordData] = useState({
        open: false,
        userId: null
    })
    const [filters, setFilters] = useState({
        searchBy: "",
        all: true,
        onboardingInitiated: false,
        active: false,
        inactive: false,
        criminalRecord: false,
        temporarilyInactive: false,
        offboardingInitiated: false,
        zone: "",
        facilityState: "",
        facilityName: "",
        partnerId: "",
        client:"",
        city: "",
        state:"",
        addedOn: [null, null],
        panStatus: "",
        vacoAppStatus:""

    })    
    useEffect(() => {
        Axios.get(`${UrlLinks.baseUrl}${UrlLinks.getPartner}`)
        .then(response => {
            setPartners(response.data.response.filter(el => el.is_active && el.is_aj_client))

        })
    }, [])
    
    useEffect(() => {
        ApplyFilters(filters);
    }, [searchByAuto]);
    const [aadhaarFront, setAadhaarFront] = useState(null);
    const [aadhaarBack, setAadhaarBack] = useState(null);
    const { showToast } = useContext(AuthContext);
    const sortOptions = [
        // ["casper_id", "Casper Id"],
        // ["okaygo_id", "OkayGo Id"],
        // ["profile_id", "Profile Id"],
        // ["onboarding_date", "Onboarding date"],
        ["active_date", "Activation date"],
        ["offboarding_date", "offboarding initiate date"],
        // ["inactive_date", "Inactive date"]
    ]
    const createDocumentList = (documents, profilePhoto) => {
        let list = []
        let aadharBackError = 'Aadhar Back,';
        let aadharFrontError = ' Aadhar Front,';
        let profilePicError = ' Profile pic';
        let errorText = ' not Found!'
        documents.map(doc => {
            if (doc.document_type_id === 1929 || doc.document_type_id === 1867) {
                if (doc.document_type_id === 1929)
                    aadharBackError = '';
                if (doc.document_type_id === 1867)
                    aadharFrontError = '';
                if (doc.file_path.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

                    list.push('https' + doc.file_path.substring(4, doc.file_path.length))
                } else
                    list.push(doc.file_path)


            }
        })
        if (profilePhoto !== null && profilePhoto !== '') {
            profilePicError = '';
            if (profilePhoto.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

                list.push('https' + profilePhoto.substring(4, profilePhoto.length))
            } else
                list.push(profilePhoto)
        }
        if (aadharBackError.length !== 0 || aadharFrontError.length !== 0 || profilePicError.length !== 0) {
            errorText = aadharBackError + aadharFrontError + profilePicError + errorText;
            showToast("error", errorText);
        }
        console.log(list)

        return list.forEach(path => {
            window.open(path, '_blank');
            // sleep(3000)
        });


    }
    function sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }
    const getDocumentList = (userId, profilePhoto) => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(userId)}`)
            .then(response => {
                setAllDocumentDownload(createDocumentList(response.data.response.content, profilePhoto))
            })
    }
    console.log(localStorage.getItem("roleType"))
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)

    }
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
    }
    const handleCriminalRecord = (event) => {
        // console.log({event})
        setCriminalRecordData({ userId: event.target.id, open: true });
    }
    const ApplySort = () => {
        let url = ''
        switch (sortBy) {
            case "casper_id":
                url = '&orderBy=wm.casper_id'
                break;
            case "profile_id":
                url = '&orderBy=wm.worker_id'
                break;
            case "okaygo_id":
                url = '&orderBy=wm.okaygo_id'
                break;
            case "onboarding_date":
                url = '&orderBy=wm.onboarding_initiation_date'
                break;
            case "active_date":
                url = '&orderBy=wm.active_date'
                break;
            case "offboarding_date":
                url = '&orderBy=wm.offboarding_initiation_date'
                break;
            case "inactive_date":
                url = '&orderBy=wm.inactive_date'
                break;
            default:
                url = ''
                break;
        }
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url)
    }
    const handleEditPhoneNumber = (row) => {
        // console.log({row})
        setEditContactData({
            userId: row.row.original.userId,
            contact: row.row.original.contactNo,
            open: true,
            error: ""
        })

    }
    const handleEditContactClose = () => {
        setEditContactData({
            userId: null,
            contact: null,
            open: false,
            error: ""
        })
    }
    const handleReactivationDateClose = () => {
        setReactivationData({
            open: false,
            userId: null,
            date: null
        })
    }
    const handleTemporaryInactive = (data) => {
        // console.log({data})

        setReactivationData({
            open: true,
            userId: data.userId,
            date: data.reactivationDateTimestamp ? data.reactivationDateTimestamp : null
        })
    }

    console.log(filters, 1)
    useEffect(() => {
        ApplySort();
        setSortDirection(false)
        setSortDirectionUrl('')
    }, [sortBy])
    const handleStatuses = (event, name) => {
        if ((filters.onboardingInitiated && filters.active && filters.inactive)) {
            setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, all: true })
        }
        else if (name === "all") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: true })
            }
        }
        else if (name === "onboardingInitiated") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: true, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "active") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: true, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "offboardingInitiated") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: true, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "temporarilyInactive") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: true, all: false })
            }
        }
        else if (name === "inactive") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: true, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "criminalRecord") {
            setFilters({ ...filters, criminalRecord: !filters.criminalRecord })

        }

    }
    const ApplyFilters = (filters) => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`
        if (filters["onboardingInitiated"]) {
            url = `${url}&workerStatus=2,5`
        }
        if (filters["active"]) {
            url = `${url}&workerStatus=1,7`
        }
        if (filters["inactive"]) {
            url = `${url}&workerStatus=3`
        }
        if (filters["offboardingInitiated"]) {
            url = `${url}&workerStatus=4`
        }
        if (filters["temporarilyInactive"]) {
            url = `${url}&workerStatus=6,8`
        }
        if (filters["criminalRecord"]) {
            url = `${url}&criminal_record=1`
        }
        if (filters["zone"]) {
            url = `${url}&zone=${filters["zone"]}`
            setIsFilterApplied(true)
        }
        if (filters["panStatus"]===0 || filters["panStatus"]===1) {
            url = `${url}&isPanVerified=${filters["panStatus"]}`
            setIsFilterApplied(true)
        }
        if (filters["vacoAppStatus"]===0 || filters["vacoAppStatus"]===1 || filters["vacoAppStatus"]===2) {
            url = `${url}&vacoAppStatus=${filters["vacoAppStatus"]}`
            setIsFilterApplied(true)
        }
        if (filters["facilityState"]) {
            url = `${url}&facilityState=${filters["facilityState"].name}`
            setIsFilterApplied(true)
        }
        if (filters["facilityName"]) {
            url = `${url}&facilityName=${filters["facilityName"].name}`
            setIsFilterApplied(true)
        }
        if (filters["city"]) {
            url = `${url}&city=${filters["city"].id}`
            setIsFilterApplied(true)
        }
        if (filters["client"]) {
            url = `${url}&client=${filters["client"].id}`
            setIsFilterApplied(true)
        }
        if (filters["state"]) {
            url = `${url}&state=${filters["state"].id}`
            setIsFilterApplied(true)
        }
        if (filters["addedOn"][0] && !filters["addedOn"][1])
            url = `${url}&addedFrom=${moment(filters["addedOn"][0]).format("YYYY-MM-DD")}&addedto=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["addedOn"][0] && filters["addedOn"][1])
            url = `${url}&addedFrom=2019-01-01&addedto=${moment(filters["addedOn"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["addedOn"][0] && filters["addedOn"][1])
            url = `${url}&addedFrom=${moment(filters["addedOn"][0]).format("YYYY-MM-DD")}&addedto=${moment(filters["addedOn"][1]).format("YYYY-MM-DD")} 23:59:59`
    
        if (filters["partnerId"]) {
            url = `${url}&partnerId=${filters["partnerId"]}`
            setIsFilterApplied(true)
        }


        setFilterUrl(url)
        setFilters(filters)
        // if (url) {
        //     setIsFilterApplied(true)
        // }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            searchBy: "",
            zone: "",
            facilityState: "",
            facilityName: "",
            partnerId: "",
            client:"",
            city: "",
            state:"",
            addedOn: [null, null],
            panStatus: "",
            vacoAppStatus:""

        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    useEffect(() => {
        ApplyFilters(filters);
    }, [filters])
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }

    // const createZipForDocuments =() =>{
    //     var zip = new JSZip();
    //     var count = 0;
    //     var zipFilename = "zipFilename.zip";
    //     var urls = [
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1870/8b7dc94f-98ff-4316-bebb-1c476f2709f8.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c5e9caab4c0e9d094879adc2a3966d5d682e6d268ad8c88958ccbbb88794ca7b",
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1867/30618c97-175e-409b-a3ef-5fd726844b16.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=43fccb148919d336ae972fbc644e252f65c023b7f789c14286c014a06fc3ec97",
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1867/30618c97-175e-409b-a3ef-5fd726844b16.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=43fccb148919d336ae972fbc644e252f65c023b7f789c14286c014a06fc3ec97",
    //         "http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/1750/1869/8eb75c96-a75a-4f0d-b848-c0e828142d31.jpg?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiBXhqDaH2EFMY5WnzP%2BT4c2iG2%2FEglRIYse3rxdd1uUAAIhAMnYbU8WS1649N%2FPZHaoC%2FI56HOzs12%2BPUbKA04nWDsEKrYDCCYQARoMMjE4MTQwOTQ1MTA4IgzXFAShq4MT52y6JykqkwPwxAVdeGJHOroTh6rxqSxi4%2FQyL%2BB4HYwGfQvF1XlB%2FETKLanS45DA1leEKfMdvhqIuAuTRLt3PWc%2Bg%2FhsovVEpLGxDbQdsOs0jME5Msom5GSjXVT4HfpVtR%2FB2iHqwxxtZxlgXEIJw3%2F2bZsZFP1BQJ4CbGgl186lXakeNTiByihW2%2Fgj6r1b1jcQPoYSWD63KZG28rgjr7mGr8xx%2BU1xRGrvlVpL9kJ7QiZnI3e8i8MLdL%2Br%2FaEjNzy64L7Zbz472%2FUOLgd0me5peZMI%2FiRD%2BS3oFNvH3ZLGgQUMMzwuAerkDWDuYJSLrThOl40Y2hQqvE9uwClEq3MP%2FHN5Jg%2F7lUjK6puZDOFm6Yq4CZhwGzwQjnSq%2Ba0QHHkEnU2IrrApke07PKCC3%2F7pukV6AlVC7B%2F%2BrDEyA0l3P%2B9D0pfc4KOMruWPLIk1eLGWoarCd%2Fvx1pzlDmPFrmdXU%2BTRtXrkc75CqIBRUGmDx%2F8XXSRNdRyN0ZUew8FDV8qSe23DwmWbZ4%2FsOc%2Fu1FNaDHBBRoJGFrtpMIGgzf0FOusBJbtYucOJc4zuo%2BXpmvX1jJIJ5JG6nbHO2SAcTXvHd1ZUYVnRKpzo97DmV8fqILVDJSY%2FukoDXc%2FzsSlVdrDo4XNDcuQCscmZxFXbcIWIq3LNuiXt0dD1iP3ahd8iKSjHVwyFG9FhTPNtmcPGKv%2BvhpAG0piDXewof%2FtW02cqI0fWveSxmr5oznRjL3dGLCn6k%2FfBASDVCPPaQCUNZxf%2Brbcmcnbxa3%2F6vLpIc7Pas%2FbRdL5PwMiUwLdHdUjLHRo5e%2Bi1QuL9trRF5276Yds9T0o3Yv0xU5TZmsM8hP7Lu0k48hzhFFDuugj3SQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201117T045621Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=ASIATFSRWVLKPP6KGOTN%2F20201117%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=2323ebd055168edbbc1ca4e12e3ab252086e230a42254d984ea3be5a2033241e"
    //     ];
    //     urls.forEach(async function (url, index){
    //     const filename = `file-${index}.jpg`;
    //     try {
    //         const file = await JSZipUtils.getBinaryContent(url)
    //         zip.file(filename, file, { binary: true});
    //         count++;
    //         // console.log({zip})
    //         if(count === urls.length) {
    //         zip.generateAsync({type:'blob'}).then(function(content) {
    //             saveAs(content, zipFilename);
    //         });
    //         }
    //     } 
    //     catch (err) {
    //         // console.log(err);
    //     }
    //     });



    // }
    // useEffect(() => {
    //     // console.log({allDocumentDownload})
    //     if(allDocumentDownload){

    //         if(allDocumentDownload.length===0){
    //             showToast("error", "No documents found")       
    //         }
    //         else{
    //             createZipForDocuments()
    //         }
    //     }
    // }, [allDocumentDownload])

    const handleActionsAJ = (event) => {
        if (event.target.value === "uploadWorkerData") {
            setOpenOnboardingModalAJV2(true)
        }
        else if (event.target.value === "UploadCasperID") {
            setOpenAJworkerUploadModal(true);
        }
    }

    const handleActivateWorker = (userId) => {
        Axios.post(`${urlLinks.baseUrl}${urlLinks.workerUrls.editEntity}status=1&insertedBy=${localStorage.getItem("userID")}&userId=${userId}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Worker status changed to Active")
                    updateMyData()
                }
            })

    }
    //columns list
    const columnsOkayGo = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div id='check1'>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{display:"flex", justifyItems:"center"}}/>
                    </div>
                ),
            },
            // {
            //     Header: "Profile id",
            //     accessor: "profileId",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            {
                Header: "Casper id",
                accessor: "casperId",
                defaultCanFilter: false,
                defaultCanSort: false,
                // Cell: {EditableTextInput},
                Cell: row => {
                    // console.log(row.row.original.casperId)
                    // console.log(row.row.original.casperId!=="")
                    if ((localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && row.row.original.casperId !== "") {
                        return EditableTextInput(row)
                    } else {
                        return row.row.original.casperId
                    }
                }

            },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "#SMS delivered",
            //     accessor: "smsCount",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Last SMS sent on",
            //     accessor: "LastSmsDeliveredOn",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "delivery status",
            //     accessor: "smsDeliveryStatus",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Status",
                // accessor: "status",
                Cell: row => {

                    if ((( localStorage.getItem("roleType") === "6") || ( localStorage.getItem("roleType") === "2") || (localStorage.getItem("roleType") === "31")) && row.row.original.status === "Inactive") {
                        return <div>{row.row.original.status} <span ><Button style={{
                            // position: "relative",
                            // left: "25px", 
                            color: "mediumblue",
                            textDecoration: "underline",
                            textTransform: "none"
                        }} variant="text" onClick={() => handleActivateWorker(row.row.original.userId)}><i>Active</i></Button></span></div>
                    } else {
                        return row.row.original.status
                    }

                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Flipkart Earning Status",
                accessor: "flipkartEarningStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: row => {
                    return row.row.original.criminalRecord === "Yes" ? <button className={styles.workerName} type="button" id={row.row.original.userId} onClick={handleCriminalRecord} >{row.row.original.name}</button> : <Link to={{ pathname: `/workerview/${row.row.original.contactNo_E}` }} target="_blank" className="nameLink">{row.row.original.name}</Link>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Partner",
                accessor: "partnerName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Download Agreement",
                accessor: "downloadAgreement",
                Cell: row => {
                    return row.row.original.agreementPath ? <a
                        href={row.row.original.agreementPath}
                        
                        className={styles.downloadTemplate}
                    >
                        <span style={{ color: "#1c75bc" }}>Download Agreement</span>
                    </a> : <div></div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Documents",
                accessor: "documents",
                Cell: row => {
                    return <button className={styles.workerName} type="button" style={{ fontSize: '16px !important' }} onClick={() => getDocumentList(row.row.original.userId, row.row.original.profilePhoto)} >Download</button>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Father's Name",
                accessor: "fathersName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Source",
                accessor: "Source",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Aadhar Number",
                accessor: "aadharNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Gender",
                accessor: "gender",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Date of birth",
                accessor: "dob",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Marital status",
                accessor: "maritalStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "EDAB",
                accessor: "edab",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Criminal record",
                accessor: "criminalRecord",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present Address",
                accessor: "present_address",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present City",
                accessor: "present_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present State",
                accessor: "present_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent address",
                accessor: "permanent_address",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent city",
                accessor: "permanent_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent state",
                accessor: "permanent_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Emergency Contact No",
                accessor: "emergencyContactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Qualification",
                accessor: "qualification",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank details",
                accessor: "bankDetails",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Work Experience",
                accessor: "workExperience",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Groupflex/ Eflex",
                accessor: "groupflex",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Leader",
                accessor: "leader",
                // Cell: row => {

                //     return <div>
                //         {row.row.original.leader} {row.row.original.leader === "NO" && <Button onClick={() => {
                //             Axios.post(`${urlLinks.baseUrl}${urlLinks.workerUrls.updateLeader}?userid=${row.row.original.userId}&groupflexid=${row.row.original.groupflex}`)
                //                 .then(response => {
                //                     if (response.data.code === 1000) {
                //                         showToast("success", response.data.response)
                //                         updateMyData()
                //                     }
                //                 })
                //         }}>Edit</Button>}
                //     </div>
                // },
                Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Role preference",
                accessor: "rolePreference",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Sourced by",
                accessor: "sourcedBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Zone",
                accessor: "zone",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility name",
                accessor: "facility_name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility city",
                accessor: "facility_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility state",
                accessor: "facility_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Rate Card",
                accessor: "rateCard",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility type",
                accessor: "facility_type",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Designation",
                accessor: "designation",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Date of joining",
                accessor: "date_of_joining",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Activation Date",
                accessor: "activationDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Exit Date",
                accessor: "exitDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Exit Type",
                accessor: "exitType",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Deactivation Date",
            //     accessor: "deactivationDate",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            {
                Header: "Reason of leaving",
                accessor: "reasonOfLeaving",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Offboarding Initiate Date",
                accessor: "offboardingInitiateDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Offboarding Initiate By",
                accessor: "offboardingInitiateBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Blacklisted",
                accessor: "blacklisted",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added by",
                accessor: "addedBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added on",
                accessor: "addedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Documents",
            //     accessor: "documents",
            //     Cell: row =>{
            //         return <Button onClick={() => getDocumentList(row.row.original.userId)} >Download</Button>
            //     },
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
        ],
        []
    );
    const columnsAsaanJobs = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{display:"flex", justifyItems:"center"}}/>
                    </div>
                ),
            },
            // {
            //     Header: "Profile id",
            //     accessor: "profileId",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            {
                Header: "Employee id",
                accessor: "employeeId",
                defaultCanFilter: false,
                defaultCanSort: false,

            },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "App Status",
            //     accessor: "appStatus",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Earning Status",
            //     accessor: "earningStatus",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Name",
                accessor: "name",
                Cell: row => {
                    return row.row.original.criminalRecord === "Yes" ? <button className={styles.workerName} type="button" id={row.row.original.userId} onClick={handleCriminalRecord} >{row.row.original.name}</button> : <Link to={{ pathname: `/workerview/v2/${row.row.original.userId}` }} target="_blank" className="nameLink">{row.row.original.name}</Link>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Documents",
                Cell: UploadDoc,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Worker App Status",
                accessor: "workerAppStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client",
                accessor: "client",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client Emp ID",
                accessor: "clientEmpId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Agreement / Offer Letter",
                accessor: "downloadOfferAgreement",
                Cell: row => {
                    return row.row.original.downloadOfferAgreement ? <a
                        href={row.row.original.downloadOfferAgreement}
                        
                        download
                        className={styles.downloadTemplate}
                    >
                        <span style={{ color: "#1c75bc" }}>View</span>
                    </a> : <div></div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Father's Name",
                accessor: "fathersName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Aadhar Number",
                accessor: "aadharNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "PAN Number",
                accessor: "panNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "PAN Card Verification",
                accessor: "panVerification",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "TDS %",
                accessor: "tdsPercent",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Gender",
                accessor: "gender",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Date of birth",
                accessor: "dob",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Marital status",
                accessor: "maritalStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present Address",
                accessor: "present_address",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present City",
                accessor: "present_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present State",
                accessor: "present_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent address",
                accessor: "permanent_address",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent city",
                accessor: "permanent_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent state",
                accessor: "permanent_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Emergency Contact No",
                accessor: "emergencyContactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Qualification",
                accessor: "qualification",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank details Filled",
                accessor: "bankDetails",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Work Experience",
                accessor: "workExperience",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Branch",
                accessor: "branch",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "City",
                accessor: "city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "State",
                accessor: "state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Job Role",
                accessor: "jobRole",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Date of joining",
                accessor: "date_of_joining",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "First Earning Date",
            //     accessor: "firstEarningDate",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "last Earning Date",
            //     accessor: "lastEarningDate",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Offboarded On",
                accessor: "offboardedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Blacklisted",
                accessor: "blacklisted",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added by",
                accessor: "addedBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added on",
                accessor: "addedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            }
        ],
        []
    );
    const columnsHrbp = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{display:"flex", justifyItems:"center"}}/>
                    </div>
                ),
            },
            // {
            //     Header: "Profile id",
            //     accessor: "profileId",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // // },
            {
                Header: "Casper id",
                accessor: "casperId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },


            // {
            //     Header: "Delivery status",
            //     accessor: "smsDeliveryStatus",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Status",
                accessor: "status",
                Cell: row => {
                    return <div className="flexDisplayColumn">
                        <span>{row.row.original.status}</span>
                        {
                            row.row.original.workerStatus === 6 &&
                            <button
                                onClick={() => handleTemporaryInactive(row.row.original)}
                                className={styles.activateButton}
                            >
                                Activate
                            </button>
                        }
                    </div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Flipkart Earning Status",
                accessor: "flipkartEarningStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: row => {
                    return row.row.original.criminalRecord === "Yes" ? <button className={styles.workerName} type="button" id={row.row.original.userId} onClick={handleCriminalRecord} >{row.row.original.name}</button> : <Link to={{ pathname: `/workerview/${row.row.original.contactNo_E}` }} target="_blank" className="nameLink">{row.row.original.name}</Link>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Partner",
                accessor: "partnerName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Download Agreement",
                accessor: "downloadAgreement",
                Cell: row => {
                    return row.row.original.agreementPath ? <a
                        href={row.row.original.agreementPath}
                        className={styles.downloadTemplate}
                    >
                        <span style={{ color: "#1c75bc" }}>Download Agreement</span>
                    </a> : <div></div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Documents",
            //     accessor: "documents",
            //     Cell: row => {
            //         return  <button className={styles.workerName} type="button" style ={{fontSize:'16px !important'}} onClick={() => getDocumentList(row.row.original.userId, row.row.original.profilePhoto)} >Download</button>
            //     },
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Father's Name",
                accessor: "fathersName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                Cell: row => {
                    // console.log({row})
                    // console.log(row.row.original.workerStatus);
                    return <div>
                        <span>{row.row.original.contactNo}</span>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            disabled={row.row.original.workerStatus !== 0}
                            onClick={() => handleEditPhoneNumber(row)}
                        >

                            {row.row.original.workerStatus === 0 ? <img src={Pencil} alt="editPhone" /> : <img src={PencilDisabled} alt="editPhone" />}
                        </IconButton>
                    </div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Aadhar Number",
                accessor: "aadharNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Gender",
                accessor: "gender",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Source",
                accessor: "Source",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Date of birth",
                accessor: "dob",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Marital status",
                accessor: "maritalStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "EDAB",
                accessor: "edab",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Criminal record",
                accessor: "criminalRecord",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present Address",
                accessor: "present_address",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present City",
                accessor: "present_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Present State",
                accessor: "present_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent address",
                accessor: "permanent_address",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent city",
                accessor: "permanent_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Permanent state",
                accessor: "permanent_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Emergency Contact No",
                accessor: "emergencyContactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Qualification",
                accessor: "qualification",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank details",
                accessor: "bankDetails",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Work Experience",
                accessor: "workExperience",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Groupflex/ Eflex",
                accessor: "groupflex",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Leader",
                accessor: "leader",
                Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Role preference",
                accessor: "rolePreference",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Sourced by",
                accessor: "sourcedBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Zone",
                accessor: "zone",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility name",
                accessor: "facility_name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility city",
                accessor: "facility_city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility state",
                accessor: "facility_state",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Rate Card",
                accessor: "rateCard",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Facility type",
                accessor: "facility_type",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Designation",
                accessor: "designation",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Date of joining",
                accessor: "date_of_joining",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Activation Date",
                accessor: "activationDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Exit Date",
                accessor: "exitDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Exit Type",
                accessor: "exitType",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Deactivation Date",
            //     accessor: "deactivationDate",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            {
                Header: "Reason of leaving",
                accessor: "reasonOfLeaving",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Offboarding Initiate Date",
                accessor: "offboardingInitiateDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Offboarding Initiate By",
                accessor: "offboardingInitiateBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Blacklisted",
                accessor: "blacklisted",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Reactivation Date",
                accessor: "reactivationDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last SMS sent on",
                accessor: "LastSmsDeliveredOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added by",
                accessor: "addedBy",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Added on",
                accessor: "addedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Documents",
            //     accessor: "documents",
            //     Cell: row =>{
            //         return <Button onClick={() => getDocumentList(row.row.original.userId)} >Download</Button>
            //     },
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
        ],
        []
    );
    const userIdAvailable = () => {

        if (localStorage.getItem('userID') !== null) {
            console.log(localStorage.getItem('userID'))
            Axios.get(`${urlLinks.baseUrl}${urlLinks.paymentUrls.financeAdmin}${localStorage.getItem('phoneNumber')}`)
                .then(response => {
                    if (response.data?.groupflexAdmin === 1) {
                        setGroupflexAdmin(true)
                    }
                    else {
                        setGroupflexAdmin(false)
                    }
                })
                .catch(error => {
                    showToast("error", error.message)

                })
        }
    }
    useEffect(() => {
        userIdAvailable()
    }, [localStorage.getItem('userID')])
    const createWorkerStatus = (status) => {
        let text = ""
        switch (status) {
            case 0:
                text = "Not Onboarded";
                break;
            case 1:
                text = "Active";
                break;
            case 2:
                text = "Partially Onboarded";
                break;
            case 3:
                text = "Inactive";
                break;
            case 4:
                text = "Offboarding Initiated";
                break;
            case 5:
                text = "Onboarding Completed";
                break;
            case 6:
                text = "Temporarily Inactive";
                break;
            case 7:
                text = "Temporary Inactivation Initiated";
                break;
            case 8:
                text = "Reactivation Initiated";
                break;
            default:

                break;
        }
        return text;
    }


    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        let lastupdatedon = [];
        response.content.map(el => {
            const sourceByType = el.sourceBy === "VENDOR" ? "Vendor" : el.sourceBy === "TA" ? "HRBP/TA" : el.sourceBy === "EREF" ? "Employee reference" : ""
            const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : ""
            const addedEmail = el.workerInsertedByEmail ? `(${el.workerInsertedByEmail})` : ""
            extractedData.push({
                employeeId: el.clientId || "",
                flipkartEarningStatus: el.flipkartEarningStatus!==null ?el.flipkartEarningStatus? "Active" : "Inactive":null,
                casperId: el.casperId || "",
                okaygoId: el.okaygoId || "",
                smsCount: el.smsSentCount || "",
                client: el.client || "",
                clientEmpId: el.clientEmpId || "",
                tdsPercent: el.tds || "",
                smsDeliveryStatus: el.smsDeliveryStatus || "",
                LastSmsDeliveredOn: el.smsDeliveredOn ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a") : "",
                status: createWorkerStatus(el.workerStatus),
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                fathersName: el.fatherName || "",
                contactNo: Decrypt(el.phoneNumber) || "",
                contactNo_E: el.phoneNumber.replaceAll('/', '%2F').replaceAll('+', '%2B') || "",
                aadharNumber: Decrypt(el.aadhaarNumber) || "",
                panNumber: Decrypt(el.panNumber) ||"",
                panVerification: el.panVerificationStatus === 1 ? "Yes" : el.panVerificationStatus === 0 ? "No" : "", 
                workerAppStatus: el.vacoAppStatus===1?"Registered":el.vacoAppStatus===2?"Onboarded": "",
                gender: el.gender === "26" ? "Male" : el.gender === "27" ? "Female" : el.gender === "30" ? "Other" : "",
                dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
                maritalStatus: el.maritalStatus === 1871 ? "Single" : el.maritalStatus === 1872 ? "Married" : el.maritalStatus === 1873 ? "Other" : "",
                edab: el.physicallyDisabled === 1 ? "Yes" : el.physicallyDisabled === 0 ? "No" : "",
                criminalRecord: el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
                present_address: el.curAddressLine1 || "",
                workerStatus: el.workerStatus,
                present_city: el.curCity || "",
                present_state: el.curStateName || "",
                permanent_address: el.perAddressLine1 || "",
                permanent_city: el.perCity || "",
                permanent_state: el.perStateName || "",
                emergencyContactNo: el.emergencyContact || "",
                qualification: el.qualification || "",
                bankDetails: el.bankDetailsFilled ? "Yes" : "No",
                workExperience: (el.totalExpYear || el.totalExpMonth) ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${el.totalExpMonth ? el.totalExpMonth : 0} months` : "",
                rolePreference: el.jobRolePreferenceName || "",
                sourcedBy: `${sourceByType}${sourceByName}`,
                zone: el.zone || "",
                facility_name: el.facilityName || "",
                facility_city: el.facilityCity || "",
                facility_state: el.facilityState || "",
                branch: el.facilityName || "",
                city: el.facilityCity || "",
                state: el.facilityState || "",
                rateCard: el.rateCardType || "",
                facility_type: el.facilityType ? el.facilityType : "",
                designation: el.designation || "",
                jobRole:  el.designation || "",
                date_of_joining: el.dateOfJoining ? moment(el.dateOfJoining).format("DD/MM/YYYY") : "",
                activationDate: el.activeDate ? moment(el.activeDate).format("DD/MM/YYYY") : "",
                reactivationDate: el.reactivationDate ? moment(el.reactivationDate).format("DD/MM/YYYY") : null,
                reactivationDateTimestamp: el.reactivationDate ? el.reactivationDate : null,
                exitType: el.exitType || "",
                exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
                deactivationDate: el.inactiveDate ? moment(el.inactiveDate).format("DD/MM/YYYY") : "",
                reasonOfLeaving: el.reasonOfLeaving || "",
                offboardingInitiateDate: el.offboardingInitiationDate ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY") : "",
                offboardingInitiateBy: el.offboardingInitiateBy || "",
                blacklisted: el.blacklisted === 1 ? "Yes" : el.blacklisted === 0 ? "No " : "",
                userId: el.userId,
                downloadOfferAgreement: el.offerLetter ||"",
                agreementPath: el.agreementFilePath,
                agreementName: el.agreementFileName,
                profilePhoto: el.profilePhoto,
                addedBy: `${el.workerInsertedByName ? el.workerInsertedByName : ""} ${addedEmail}`,
                addedOn: el.workerInsertedOn ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a") : "",
                firstEarningDate:  el.firstEarningDate ? moment(el.firstEarningDate).format("DD/MM/YYYY") : "",
                lastEarningDate:  el.lastEarningDate ? moment(el.lastEarningDate).format("DD/MM/YYYY") : "",
                offboardedOn:  el.offboardingOn ? moment(el.offboardingOn).format("DD/MM/YYYY") : "",
                is_document_detail_filled: el.is_document_detail_filled,
                flipkartRateCard: el.is_lma_worker?el.lmaRateCard:el.rateCard,
                groupflex: el.is_lma_worker?"LMA":el.groupflexId ? el.groupflexId : 'Eflex',
                partnerName: el.partnerName ? el.partnerName : "",
                leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
            });
            lastupdatedon.push(el.lastUpdatedOn)
            return true;
        });
        // console.log({extractedData})
        return extractedData;
    }

    //Function to create All record export data
    const makeAllRecordData = (response) => {
        let extractedData = [];
        let lastupdatedon = [];
        response.map(el => {
            const addedEmail = el.workerInsertedByEmail ? `(${el.workerInsertedByEmail})` : ""
            const temp = {
                casperId: el.casperId || "",
                okaygoId: el.okaygoId || "",
                smsCount: el.smsSentCount || "",
                smsDeliveryStatus: el.smsDeliveryStatus || "",
                LastSmsDeliveredOn: el.smsDeliveredOn ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a") : "",
                status: createWorkerStatus(el.workerStatus),
                flipkartEarningStatus: el.flipkartEarningStatus!==null ?el.flipkartEarningStatus? "Active" : "Inactive":null,
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                fathersName: el.fatherName || "",
                contactNo: Decrypt(el.phoneNumber) || "",
                aadharNumber: Decrypt(el.aadhaarNumber) || "",
                gender: el.gender === "26" ? "Male" : el.gender === "27" ? "Female" : el.gender === "30" ? "Other" : "",
                dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
                maritalStatus: el.maritalStatus === 1871 ? "Single" : el.maritalStatus === 1872 ? "Married" : el.maritalStatus === 1873 ? "Other" : "",
                edab: el.physicallyDisabled === 1 ? "Yes" : el.physicallyDisabled === 0 ? "No" : "",
                criminalRecord: el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
                present_address: el.curAddressLine1 || "",
                present_city: el.curCity || "",
                present_state: el.curStateName || "",
                permanent_address: el.perAddressLine1 || "",
                permanent_city: el.perCity || "",
                permanent_state: el.perStateName || "",
                emergencyContactNo: el.emergencyContact || "",
                qualification: el.qualification || "",
                bankDetails: el.accountNumber ? "Yes" : "No",
                workExperience: (el.totalExpYear || el.totalExpMonth) ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${el.totalExpMonth ? el.totalExpMonth : 0} months` : "",
                groupflex: el.is_lma_worker?"LMA":el.groupflexId ? el.groupflexId : 'Eflex',
                leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
                rolePreference: el.jobRolePreferenceName || "",
                sourcedBy: el.sourceBy === "VENDOR" ? "Vendor" : el.sourceBy === "TA" ? "HRBP/TA" : el.sourceBy === "EREF" ? "Employee reference" : "",
                zone: el.zone || "",
                facility_name: el.facilityName || "",
                facility_city: el.facilityCity || "",
                facility_state: el.facilityState || "",
                rateCard: el.is_lma_worker?el.lmaRateCard:el.rateCard,
                facility_type: el.facilityType ? el.facilityType : "",
                designation: el.designation || "",
                date_of_joining: el.dateOfJoining ? moment(el.dateOfJoining).format("DD/MM/YYYY") : "",
                activationDate: el.activeDate ? moment(el.activeDate).format("DD/MM/YYYY") : "",
                reactivationDate: el.reactivationDate ? moment(el.reactivationDate).format("DD/MM/YYYY") : null,
                exitType: el.exitType || "",
                exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
                deactivationDate: el.inactiveDate ? moment(el.inactiveDate).format("DD/MM/YYYY") : "",
                reasonOfLeaving: el.reasonOfLeaving || "",
                offboardingInitiateDate: el.offboardingInitiationDate ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY") : "",
                offboardingInitiateBy: el.offboardingInitiateBy || "",
                blacklisted: el.blacklisted === 1 ? "Yes" : "No ",
                addedBy: `${el.workerInsertedByName ? el.workerInsertedByName : ""} ${addedEmail}`,
                addedOn: el.workerInsertedOn ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a") : "",
                partner: el.partnerName ? el.partnerName : ""


            }
            if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") {
                delete temp["smsCount"]
                delete temp["smsDeliveryStatus"]
                const status = temp["LastSmsDeliveredOn"]
                const addedBy = temp["addedBy"]
                const addedOn = temp["addedOn"]
                delete temp["addedOn"]
                delete temp["addedBy"]
                delete temp["LastSmsDeliveredOn"]
                temp["LastSmsDeliveredOn"] = status;
                temp["addedBy"] = addedBy;
                temp["addedOn"] = addedOn
            }
            extractedData.push(temp)

            return true;
        });

        return extractedData;
    }
    const makeAllRecordDataAsaan = (response) => {
        let extractedData = [];
        let lastupdatedon = [];
        response.map(el => {
            const addedEmail = el.workerInsertedByEmail ? `(${el.workerInsertedByEmail})` : ""
            const temp = {
                employeeId: el.clientId || "",
                okaygoId: el.okaygoId || "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                workerAppStatus: el.vacoAppStatus===1?"Registered":el.vacoAppStatus===2?"Onboarded": "",
                client: el.client ||"",
                clientEmpId: el.clientEmpId || "",
                fathersName: el.fatherName || "",
                contactNo: Decrypt(el.phoneNumber) || "",
                aadharNumber: Decrypt(el.aadhaarNumber) || "",
                panNumber: Decrypt(el.panNumber) ||"",
                panVerification: (el.panVerificationStatus===1 || el.panNumber)?"Yes":el.panVerificationStatus===0?"No":"", 
                tdsPercent: el.tds || "",
                gender: el.gender === "26" ? "Male" : el.gender === "27" ? "Female" : el.gender === "30" ? "Other" : "",
                dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
                maritalStatus: el.maritalStatus === 1871 ? "Single" : el.maritalStatus === 1872 ? "Married" : el.maritalStatus === 1873 ? "Other" : "",
                present_address: el.curAddressLine1 || "",
                present_city: el.curCity || "",
                present_state: el.curStateName || "",
                permanent_address: el.perAddressLine1 || "",
                permanent_city: el.perCity || "",
                permanent_state: el.perStateName || "",
                emergencyContactNo: el.emergencyContact || "",
                qualification: el.qualification || "",
                bankDetails: el.bankDetailsFilled ? "Yes" : "No",
                workExperience: (el.totalExpYear || el.totalExpMonth) ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${el.totalExpMonth ? el.totalExpMonth : 0} months` : "",
                branch: el.facilityName || "",
                city: el.facilityCity || "",
                state: el.facilityState || "",
                jobRole: el.designation || "",
                date_of_joining: el.dateOfJoining ? moment(el.dateOfJoining).format("DD/MM/YYYY") : "",
                offboardedOn: el.offboardingOn ? moment(el.offboardingOn).format("DD/MM/YYYY") : "",
                blacklisted: el.blacklisted === 1 ? "Yes" : "No ",
                addedBy: `${el.workerInsertedByName ? el.workerInsertedByName : ""} ${addedEmail}`,
                addedOn: el.workerInsertedOn ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a") : "",

            }
            extractedData.push(temp)

            return true;
        });

        return extractedData;
    }
    const updateMyData = () => {
        getExternalWorkerList(perPage, currentPage);
    }
    const handleEditPhoneNumberService = (contact, userId) => {
        Axios.post(`${urlLinks.baseUrl}${urlLinks.workerUrls.editContact}?userId=${userId}&value=${contact}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Contact number updated successfully");
                    handleEditContactClose();
                    updateMyData();
                }
                else {
                    setEditContactData({ ...editContactData, error: response.data.response.error })
                }
            })
            .catch(error => {
                setEditContactData({ ...editContactData, error: error.message })
            })


    }
    const createAllRecordExcel = () => {
        setAllRecordLoading(true)
        let requestedBy =``
        if(localStorage.getItem("userID")==="1" || localStorage.getItem("userID")==="26"){
            requestedBy =`&requestedById=${localStorage.getItem("userID")}`
        }
        Axios.get(`${urlLinks.baseUrl}${JSON.parse(localStorage.getItem("asaanRole"))?urlLinks.workerUrls.asaanJobWorkerListing: "worker/allRecords"}?exportExcel=1${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}`)
            .then(response => {
                if (response) {
                    setAllRecord(JSON.parse(localStorage.getItem("asaanRole"))?makeAllRecordDataAsaan(response.data.response):makeAllRecordData(response.data.response))
                }
            })
    }
    // const exportCurrentRecords= () =>{
    //     setExportExcelLoading(true)
    //     let list=[]
    //     data.map(el =>{
    //         delete el.userId;
    //         delete el.workerId;
    //         list.push(el)
    //         return true;
    //     })
    //     setExcelData(list)
    // }
    // useEffect(() => {
    //     setExportExcelLoading(false)
    // }, [excelData])
    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getExternalWorkerList = (rows, pageNo) => {
        setLoading(true)
        let requestedBy = ""
        if (localStorage.getItem("roleType") === "1") {
            // console.log(localStorage)
            requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        }
        if (localStorage.getItem("roleType")) {
            Axios.get(`${urlLinks.baseUrl}${JSON.parse(localStorage.getItem("asaanRole"))?urlLinks.workerUrls.asaanJobWorkerListing: urlLinks.workerUrls.workerListing}?page_no=${pageNo - 1}&rows=${rows}${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}`)
                .then(response => {
                    if (response) {
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if (currentPage > response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }
    const handleReactivationDate = () => {
        const postData = {
            userId: reactivationData.userId,
            reActivationDate: moment(reactivationData.date).format("YYYY-MM-DD 00:00:00"),
            // updatedBy: parseInt(localStorage.getItem("userID"))
        }
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.workerReactivation}?reActivationDate=${moment(reactivationData.date).format("YYYY-MM-DD 00:00:00")}&userId=${reactivationData.userId}&updatedBy=${parseInt(localStorage.getItem("userID"))}`)
            .then(response => {
                showToast("success", "Activation date set successfully")
                getExternalWorkerList(perPage, currentPage)
                setReactivationData({
                    open: false,
                    userId: null,
                    date: null
                })
            })
            .catch(error => {
                showToast("error", error.message)
            })
    }
    console.log("filter--> " + filterUrl)
    useEffect(() => {
        console.log("POMM")
        getExternalWorkerList(perPage, 1);

    }, [filterUrl, sortUrl, sortDirectionUrl, localStorage.getItem("roleType")])
    const columns = (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? columnsHrbp : JSON.parse(localStorage.getItem("asaanRole"))? columnsAsaanJobs: columnsOkayGo
    const handlePageChange = page => {
        getExternalWorkerList(perPage, page)
        setCurrentPage(page);
    };
    const handleActions = (event) => {
        if (event.target.value === "uploadWorkerData" && (localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "15" || localStorage.getItem("roleType") === "31")) {
            // new onboarding Model v2
            setOpenOnboardingModalV2(true)
        }
        else if (event.target.value === "uploadWorkerData") {
            setOpenOnboardingModal(true)
        }
        else if (event.target.value === "uploadOffboardingData") {
            setOpenOffboardingModal(true)
        }
        else if (event.target.value === "UploadCasperID") {
            setOpenCasperOnboardingModal(true);
        }
        else if (event.target.value === "UploadGroupFlex") {
            setOpenGroupflexModal(true);
        }
    }
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getExternalWorkerList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const createZipForDocuments = (urls) => {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = "Agreements.zip";
        urls.forEach(async function (url, index) {
            const record = Object.entries(url)
            console.log({ record })
            const filename = `${record[0][0]}`;
            const path = `${record[0][1]}`
            try {
                await JSZipUtils.getBinaryContent(path, function (err, data) {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    console.log({ zip })
                    if (count === urls.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                            setAgreementDownloading(false)
                        });
                    }

                })
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    const handleEmailAgreements = () => {
        setEmailAgreement(true)
        const indexes = Object.keys(selectedRowIds)
        let userIdList = []
        indexes.map(id => {
            userIdList.push(data[id].userId)
        })
        console.log({ userIdList })
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.emailAgreements}${userIdList}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Agreements sent to your registered email id")

                }
                else if (response.data.code === 1001) {
                    showToast("error", response.data.message)
                }

                setEmailAgreement(false)
            })
            .catch(error => {
                setEmailAgreement(false)
                showToast("error", error.message)
            })

    }
    const handleDownloadAgreements = () => {
        setAgreementDownloading(true)
        const rowIds = Object.keys(selectedRowIds)
        // console.log({rowIds})
        let agreementUrls = []
        rowIds.map(row => {
            if (data[row].agreementPath) {
                const fileName = `${data[row].contactNo}_${data[row].name}.pdf`
                agreementUrls.push({
                    [fileName]: data[row].agreementPath
                })
            }
        })
        console.log({ agreementUrls })
        if (agreementUrls.length > 0) {
            createZipForDocuments(agreementUrls)
        }
        else {
            setAgreementDownloading(false)
            showToast("error", "It will take some time to generate agreement.")
        }

    }
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameDb zIndex1080 header" :
                                        // (column.render('Header') === "Delivery status" && ((localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5"))) ? "displayNone" :// not visible to hrbp and super hrbp
                                        // (column.render('Header') === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" : // only visible to okaygo admin annd super admin
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" : "header"}>

                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (

                            <tr {...row.getRowProps()} className={`row ${!row.original.criminalRecord ? "criminalRecord" : ""}`}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={`${cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameDb zIndex540" :
                                                // (cell.column.Header === "Delivery status" && ((localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5"))) ? "displayNone" :
                                                // (cell.column.Header === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"} ${row.original.criminalRecord === "Yes" ? "criminalRecord" : ""}`}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid item sm={12}><span className="fontSize24 fontWeightBold">Worker List</span></Grid>
                <Grid item xs={12} className="flexDisplay alignItemsFlexStart">
                    <Grid item sm={8} className="flexDisplay padding0 paddingTop8 justifySpaceBetween">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder={`Search by  Aadhar No / Phone No / OkayGo id / ${JSON.parse(localStorage.getItem("asaanRole"))?"Employee id / State / Branch":"Casper id / Facility state/ Hub"}`}
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                    <Grid item sm={1} className="flexDisplay padding0 justifySpaceBetween">
                    </Grid>
                    {JSON.parse(localStorage.getItem("asaanRole")) && <Grid item sm={3} className="flexDisplay padding0 justifySpaceBetween">
                        <FormControl variant="outlined" className="margin0" style={{ paddingRight: "18px" }} >
                            <Select
                                id="actions"
                                value={actions}
                                style={{ marginLeft: "", marginTop: "7px" }}
                                classes={{ outlined: styles.actionSelect }}
                                onChange={handleActionsAJ}
                            >
                                <MenuItem value={0} disabled={true}> Actions</MenuItem>
                                <MenuItem value="uploadWorkerData">Upload Onboarding data</MenuItem>
                                <MenuItem value="UploadCasperID">Upload Client IDs</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>}
                    <Grid item sm={3} className="flexDisplay padding0 justifySpaceBetween">
                        {!JSON.parse(localStorage.getItem("asaanRole")) &&  <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel htmlFor="sortBy">Sort by:</InputLabel>
                            <Select
                                id="sortBy"
                                IconComponent={ExpandMoreIcon}
                                value={sortBy}
                                onChange={event => setSortBy(event.target.value)}
                                className={classes.select}
                                label="Sort by:"
                                inputProps={{
                                    name: 'Sort by:',
                                    id: 'sortBy',
                                }}
                            >
                                {sortOptions.map(option => (
                                    <MenuItem key={option[0]} value={option[0]}>
                                        {option[1]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        {sortBy && !JSON.parse(localStorage.getItem("asaanRole")) && <ToggleButton
                            selected={sortDirection}
                            className="recSortOrderButton"
                            // style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                            onChange={handleSortDirection}
                        >
                            {!sortDirection ? <ArrowDownwardSharpIcon /> : <ArrowUpwardSharpIcon />}
                        </ToggleButton>}
                        {(localStorage.getItem("roleType") !== "1") &&
                            <Grid item xs={1} className="buttonList padding8" style={{ paddingLeft: "50px" }} alignItems='flex-start'>
                                <Filters
                                    filters={filters}
                                    applyFilters={ApplyFilters}
                                    // filterDrawer={filterDrawer}
                                    // setFilterDrawer={setFilterDrawer}
                                    resetFilters={resetFilters}
                                    isFilterApplied={isFilterApplied}
                                    setIsFilterApplied={setIsFilterApplied}
                                    pom={setFilters}
                                    asaanRole={JSON.parse(localStorage.getItem("asaanRole"))}
                                // updateFilters={updateFilters}
                                // jobValue={jobValue}
                                />
                            </Grid>}
                    </Grid>
                    {/* <Grid item sm={1} className="flexDisplay paddingTop8" direction="row-reverse" justify="space-between"> */}
                    {/* <IconButton 
                            
                            className="filterButton padding8"
                            onClick={() =>setFilterDrawer(true)}
                        >
                            <img src={filter} alt="img" />
                        </IconButton> */}
                    {/* </Grid> */}

                </Grid>
                {/* <Grid item xs={12} className="flexDisplay paddingTop16 alignItemsFlexStart">
                    {<Grid item xs={4} className=" alignItemsFlexStart">
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={createAllRecordExcel}
                            disabled={allRecordLoading}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading?
                            <span style={{marginLeft: "20px"}}>Please wait ... it might take few minutes. </span>
                            :allRecord.length>0?
                            <CSVLink
                                data={allRecord}
                                filename={`Worker List.csv`}
                                className='btn btn-primary blueColor downloadExcelButton'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    marginLeft:"20px",
                                    fontWeight: '500'
                                }}
                            >
                                Download now
                            </CSVLink>
                        :null}
                    </Grid>}
                    {(localStorage.getItem("roleType")==="1" || localStorage.getItem("roleType")==="5") && <Grid item xs={8} className="flexDisplay" direction="row-reverse" alignItems='flex-start'>
                        <Button
                            variant="outlined"
                            className={styles.outlinedButton}
                            onClick={() =>setOpenOffboardingModal(true)}
                            startIcon={<img src={offboardingIcon} alt="offboarding icon" />}
                        >
                            Upload Offboarding Data
                        </Button>
                        <Button
                            variant="outlined"
                            className={styles.outlinedButton}
                            onClick={() =>setOpenOnboardingModal(true)}
                            startIcon={<img src={onboardingIcon} alt="onboarding icon" />}
                        >
                            Upload Onboarding Data
                        </Button>
                    </Grid>}
                </Grid> */}
                <Grid item xs={12} className="flexDisplay paddingTop16 alignItemsFlexStart">
                    {!JSON.parse(localStorage.getItem("asaanRole")) && <Grid item xs={8} className=" alignItemsFlexStart">
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={handleDownloadAgreements}
                            disabled={Object.keys(selectedRowIds).length === 0 || agreementDownloading}
                            startIcon={agreementDownloading && <ClockLoader
                                size={20}
                                color={"#1c75bc"}
                            />}
                        >
                            Download Agreements
                        </Button>
                        <Button
                            variant="contained"
                            className="dataButton"
                            style={{ marginLeft: "20px" }}
                            onClick={handleEmailAgreements}
                            disabled={Object.keys(selectedRowIds).length === 0 || emailAgreement}
                            startIcon={emailAgreement && <ClockLoader
                                size={20}
                                color={"#1c75bc"}
                            />}
                        >
                            Email Agreements
                        </Button>
                        {(localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "15"
                            || localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && <FormControl variant="outlined" className="margin0" >
                                <Select
                                    id="actions"
                                    value={actions}
                                    style={{ marginLeft: "16px" }}
                                    classes={{ outlined: styles.actionSelect }}
                                    onChange={handleActions}
                                >
                                    <MenuItem value={0} disabled={true}>Upload Actions</MenuItem>
                                    <MenuItem value="uploadWorkerData">Upload Onboarding data</MenuItem>
                                    {localStorage.getItem("roleType") !== "1" && <MenuItem value="uploadOffboardingData">Upload Offboarding data</MenuItem>}
                                    {/* <MenuItem value="uploadOffboardingData">Upload Offboarding data</MenuItem> */}
                                    <MenuItem value="UploadCasperID">Upload Casper IDs</MenuItem>
                                    {/* {(localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6") && <MenuItem value="UploadGroupFlex">Upload Groupflex data</MenuItem>} */}
                                    {groupflexAdmin && <MenuItem value="UploadGroupFlex">Upload Groupflex data</MenuItem>}
                                </Select>
                            </FormControl>}
                    </Grid>}
                    <Grid item xs={4} className={JSON.parse(localStorage.getItem("asaanRole"))?"":styles.itemRowReverse}>
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={createAllRecordExcel}
                            disabled={allRecordLoading}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading ?
                            <span style={{ marginRight: "20px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={`Worker List.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginRight: "20px",
                                        marginLeft: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink>
                                : null}
                    </Grid>
                </Grid>
                {!JSON.parse(localStorage.getItem("asaanRole")) &&  <Grid item xs={12} className="flexDisplay paddingTop16 justifyFlexStart" >
                    <Button
                        //variant="outlined" 
                        onClick={(event) => handleStatuses(event, "all")}
                        className={`statusButtons marginRight20 ${filters.all ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        All
                    </Button>
                    <Button
                        onClick={(event) => handleStatuses(event, "onboardingInitiated")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.onboardingInitiated ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Onboarding stage
                    </Button>
                    <Button
                        onClick={(event) => handleStatuses(event, "active")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.active ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Active
                    </Button>
                    <Button
                        onClick={(event) => handleStatuses(event, "offboardingInitiated")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.offboardingInitiated ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Offboarding initiated
                    </Button>
                    <Button
                        onClick={(event) => handleStatuses(event, "temporarilyInactive")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.temporarilyInactive ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Temporarily inactive
                    </Button>
                    <Button
                        onClick={(event) => handleStatuses(event, "inactive")}
                        // variant="outlined"
                        className={`statusButtons ${filters.inactive ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Inactive
                    </Button>
                    <div className={styles.criminalCheckBox} >
                        <div></div>
                        <span>Candidate with Criminal record</span>
                    </div>
                </Grid>}

                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
                {/* <Drawer anchor="right" open={filterDrawer} onClose={() => setFilterDrawer(false)}>
                    <Filters
                        filters={filters}
                        updateFilters={ApplyFilters}
                        filterDrawer={filterDrawer}
                        setFilterDrawer={setFilterDrawer}
                        resetFilters={resetFilters}
                        isFilterApplied={isFilterApplied}
                        setIsFilterApplied={setIsFilterApplied}
                    />
                                </Drawer> */}
                {openOnboardingModalV2 && <OnboardingModalV2
                    openOnboardingModal={openOnboardingModalV2}
                    setOpenOnboardingModal={setOpenOnboardingModalV2}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openOnboardingModalAJV2 && <OnboardingModalAJV2
                    openOnboardingModal={openOnboardingModalAJV2}
                    setOpenOnboardingModal={setOpenOnboardingModalAJV2}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openOnboardingModal && <OnboardingModal
                    openOnboardingModal={openOnboardingModal}
                    setOpenOnboardingModal={setOpenOnboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openOffboardingModal && <OffboardingModal
                    openOffboardingModal={openOffboardingModal}
                    setOpenOffboardingModal={setOpenOffboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openCasperOnboardingModal && <CasperOnboardingModal
                    openCasperOnboardingModal={openCasperOnboardingModal}
                    setOpenCasperOnboardingModal={setOpenCasperOnboardingModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {openGroupflexModal && <GroupFlexModal
                    openOnboardingModal={openGroupflexModal}
                    setOpenOnboardingModal={setOpenGroupflexModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />}
                {
                    criminalRecordData.open && <CriminalRecord
                        criminalRecordData={criminalRecordData}
                        setCriminalRecordData={setCriminalRecordData}
                    />
                }
                {
                    editContactData.open &&
                    <EditContactModal
                        open={editContactData}
                        handleClose={handleEditContactClose}
                        handleEditPhoneNumberService={handleEditPhoneNumberService}
                    />
                }
                
                {openAJworkerUploadModal && <UploadAJWorkerModal
                    openCasperOnboardingModal={openAJworkerUploadModal}
                    setOpenCasperOnboardingModal={setOpenAJworkerUploadModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                    partners={partners}
                />}
                
                {
                    reactivationData.open &&
                    <ReactivationModal
                        open={reactivationData}
                        handleClose={handleReactivationDateClose}
                        handleReactivationDate={handleReactivationDate}
                        setReactivationData={setReactivationData}
                    />
                }
            </Grid>
        </Styles>
    )
}

export default ExternalWorker;


