import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { numberFormat } from '../../../Utils/CommonFunctions';
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from '../document.svg'
import ResponseModal from './response'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import Download from '../Group 3238.svg'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { openOnboardingModal, setOpenOnboardingModal, showToast, updateMyData , asaanRole} = props
  const [excel, setExcel] = useState(null)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [name, setName] = useState("")
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [invalidData, setInvalidData] = useState()
  const [fileLink, setFileLink] = useState(null)
  const [responseData, setResponseData] = useState()
  const [actions, setActions] = useState(0)
  const [closeFile, setCloseFile] = useState(true)

  // console.log({excel})
  const handleClose = () => {
    setOpenOnboardingModal(false);
  };

  const handleCloseFile =() => {
    setName("");
    setCloseFile(true);
  }

  const handleUpload = (event) => {
    const fileExtension=event.target.files[0].name.split(".").slice(-1)[0];
    const fileName = event.target.files[0].name;
    
    if(fileExtension.toLowerCase()==="xlsx" || fileExtension.toLowerCase()==="xls"){
      setName(fileName)
      setExcel(event.target.files[0]);
      setExcelError(false)
      setCloseFile(false);
    }
    else{
      setExcelError(true)
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target.value = null;
  }
  let newOnboardingExcel = asaanRole? "https://www.okaygo.in/Upload_AJ_Invoice_Template.xlsx":"/payment_excel_upload_template_payout_automation.xlsx"
  const createInvalidData = (records) => {
    console.log(records)
    if (records.response.length > 1) {
      let list = [];
      records.response.map(record => {
        if (record.hasOwnProperty("Worker_payment_detail")) {
          // console.log({record});
          // if(record.WorkerDetail.isWorkerCreated===0){
          //   let firstName=""
          //   let lastName=""
          //   if(record.WorkerDetail.workerName){
          //     var i = record.WorkerDetail.workerName.indexOf(' ');
          //     if(i>0){
          //       firstName = record.WorkerDetail.workerName.slice(0, i).trim();
          //       lastName = record.WorkerDetail.workerName.slice(i + 1, record.WorkerDetail.workerName.length).trim();
          //     }else{
          //       firstName =record.WorkerDetail.workerName;
          //     }
          //   }
          if (record.Worker_payment_detail.status !== "uploaded") {
            list.push({
              "Client Name": record.Worker_payment_detail.invoiceFor || "",
              "Client ID": record.Worker_payment_detail.casperId ||"",
              "OkayGo ID": record.Worker_payment_detail.okaygoId || "",
              "Name": "" + record.Worker_payment_detail.name || "",
              "Contact No": record.Worker_payment_detail.phoneNumber || "",
              "Bill from": moment(record.Worker_payment_detail.billFrom).format("DD/MM/YYYY") || "",
              "Bill to": moment(record.Worker_payment_detail.billto).format("DD/MM/YYYY") || "",
              "Shipment Allocated": "" + record.Worker_payment_detail.shipmentAllocated || "",
              "Shipment Qty": "" + record.Worker_payment_detail.shipmentQuantity || "",
              "Shipment Amount": "" + record.Worker_payment_detail.shipmentAmount || "",
              "MG Contribution": "" + record.Worker_payment_detail.mg_amount || "",
              "Surge Charges": "" + record.Worker_payment_detail.surgeCharges || "",
              "Recovery": "" ,
              "Total Worker Payable Amount": "" + record.Worker_payment_detail.totalAmount || "",
              "OkayGo Margin (to be input for variable margin cases only)": "" + record.Worker_payment_detail.og_margin || "",
              "OkayGo Payout": "" + record.Worker_payment_detail.og_payout || "",
              "Resone of Failure": "" + record.Worker_payment_detail.reason || "",

            })
          }


          return true;
        }
      })
      return list.length > 0 ? list : null;
    }
  }

  const createInvalidDataVaco = (records) => {
    console.log(records)
    if (records.length > 1) {
      let list = [];
      records.map(record => {
        if (record.hasOwnProperty("Worker_payment_detail")) {
          if (record.Worker_payment_detail.status !== "uploaded") {
            list.push({
              "CTS code/Emp code*":record.Worker_payment_detail.cts_code || "",
              "Client Name*":record.Worker_payment_detail.client_name || "",
              "OkayGo ID":record.Worker_payment_detail.okaygoId || "",
              "Name":record.Worker_payment_detail.name || "",
              "Contact No":record.Worker_payment_detail.phoneNumber || "",
              "Invoice cycle (From Date)*":moment(record.Worker_payment_detail.billFrom).format("DD/MM/YYYY") || "",
              "Invoice cycle (To Date)*":moment(record.Worker_payment_detail.billTo).format("DD/MM/YYYY") || "",
              "#Task Allocated":record.Worker_payment_detail.shipment_allocated || "",
              "#Task Delivered":record.Worker_payment_detail.shipmentQuantity || "",
              "Service Fees*":record.Worker_payment_detail.shipmentAmount || "",
              "Surge Charges":record.Worker_payment_detail.surgeCharges || "",
              "Lease Charges":record.Worker_payment_detail.leaseCharges || "",
              "Deduction (TDS amount)":record.Worker_payment_detail.deduction || "",
              "Total Payable Amount*":record.Worker_payment_detail.totalAmount || "",
              "Resone of Failure": "" + record.Worker_payment_detail.reason || "",
            })
          }


          return true;
        }
      })
      return list.length > 0 ? list : null;
    }
  }
  const handleExcel = () => {
    setIsLoading(true)
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(`${UrlLinks.baseUrl}${asaanRole?UrlLinks.workerUrls.uploadVacoWorkerInvoice :UrlLinks.workerUrls.uploadPayoutAutomation}${localStorage.getItem("userID")}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(response => {
        console.log({response});
        if (response.data) {
          setInvalidData(asaanRole? createInvalidDataVaco(response.data):createInvalidData(response.data));
          console.log(asaanRole?response.data.slice(-1)[0]:response.data.response.slice(-1)[0])
          const summary = asaanRole?response.data.slice(-1)[0]:response.data.response.slice(-1)[0];
          // console.log({summary})
          setResponseData(summary);
          updateMyData();
          setIsLoading(false)
        }
        else {
          setResponseData([]);
        }
      })
      .catch(error => {
        // console.log({error})
        setIsLoading(false)
        showToast("error", error.message);
      })
  }
  // useEffect(() => {
  //   Axios.post(`${UrlLinks.baseUrl}${UrlLinks.newOnboardingExcel}`,null , {timeout:60*5*1000})
  //     .then(response =>{
  //       // console.log({response})
  //       setFileLink(response.data)
  //     })

  // }, [])

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData])
  let dialogData =
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Invoice Data</span> <a
          style={{ paddingLeft: '1rem',paddingRight: '2.5rem' }}
          href={newOnboardingExcel}
          download
          target="_blank"
          className={Styles.downloadTemplate}
        >
          <span style={{fontSize:"smaller", color:"#1c75bc"}}>Download File Template</span><img style={{  paddingLeft: '1rem', paddingRight: '0.5rem' }} src={Download} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12} className="padding0">

          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={<img src={document} alt={"upload"} style={{ color: "white" }} />}
            >
              Browse File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>only .xls and .xlsx are allowed</span>
        </Grid>
        <Grid item xs={12} className="padding0" style={{marginTop:"10px"}}>
          {name &&
            <span className={Styles.fileName} >{name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`} 
              <span>
                <IconButton
                  aria-label="close"
                  style={{color:"white", paddingRight:"0"}}
                  onClick={handleCloseFile}
                  >
                    <CloseIcon />
                </IconButton>
              </span>
            </span>}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? <span>Please wait... it may take few minutes</span> : <Button variant="contained" onClick={handleExcel} className={`${!excel ? "disabledButton" : "blueButtonContained"}`} disabled={!excel}>
          Upload
        </Button>}
      </DialogActions>
      {openResponseModal && <ResponseModal
        openResponseModal={openResponseModal}
        setOpenResponseModal={setOpenResponseModal}
        responseData={responseData}
        setOpenOnboardingModal={setOpenOnboardingModal}
        showToast={showToast}
        invalidData={invalidData}
        updateMyData={updateMyData}
        agreement={true}
        type="Onboarding"
      />}
    </Dialog>



  return dialogData;

}
