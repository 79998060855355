import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios'
import { Popover, Button, Divider, Grid, IconButton, Badge, FormControl, Select } from '@material-ui/core';
import SelectFilter from '../../Utils/FilterComponents/SelectFilter'
// import SliderFilter from '../../../utils/filters/SliderFilter'
import DateFilter from '../../Utils/FilterComponents/DateFilter'
import LocationFilter from '../../Utils/FilterComponents/locationFilter'
import FilterIcon from '../../assets/filter.svg'
import GroupedSelectFilter from './groupedSelectFilter'
import AutoSelectFilter from './components/AutoSelectFilter'
import InputTextFilter from '../../Utils/FilterComponents/inputTextFilter'
import urlLinks from '../../UrlLinks'
import { InputLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function SimplePopover(props) {
    const classes = useStyles();
    const { isFilterApplied, setIsFilterApplied, jobValue } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = useState(props.filters)
    const [workerPartners, setWorkerPartners] = useState([])
    const [hubstateList, sethubstateList] = useState([])
    const [hubCityList, sethubCityList] = useState([])
    const [hubsList, setHubsList] = useState([])
    const [facilityStateList, setFacilityStateList] = useState([])
    const makeWorkerPartnerData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.partner_id,
                name: el.company_name
            })
            return true
        })
        return list
    }
const makeHubCityData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    const makeHubStateData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    const makeHubList = data => {
        let list = []
        let count =0;
        if (data?.length > 0) {
            data.map(skill => {
                count++

                if (skill !== null) {
                    let list2 = 
                        {
                            name: skill.facilityName,
                            id: count
                        }
                    
                    list.push(list2)
                }
            })
        }
        return list;
    }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`)
                .then(response =>{
                    let clientData = response.data.response;
                    let list = [];
                    clientData.map(data => {
                        if(data.is_aj_client !== null && data.is_aj_client === 1){
                            list.push(data);
                        }
                    })
                    console.log(list);
                    setWorkerPartners(makeWorkerPartnerData(list))
                })
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubCity}`)
                .then(response =>{
                    console.log(response)
                    sethubCityList(makeHubCityData(response.data.response))
                })
    
                Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubState}`)
                .then(response =>{
                    console.log(response)
                    sethubstateList(makeHubStateData(response.data.response))
                })
      
    }, [])
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}configmaster/getActiveHubs`)
            .then(response => {
                setHubsList(makeHubList(response.data.response))
            })
    }, [])
    const makeFacilityStateList = data => {
        let list = []
        let count =0;
        if (data?.length > 0) {
            data.map(skill => {
                count++

                if (skill !== null) {
                    let list2 = 
                        {
                            name: skill,
                            id: count
                        }
                    
                    list.push(list2)
                }
            })
        }
        return list;
    }
    console.log({filters})
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}/configmaster/getFacilityStates`)
            .then(response => {
                setFacilityStateList(makeFacilityStateList(response.data.response))
            })
    }, [])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    console.log({ filters })
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleResetFilters = () => {
        const newFilters = props.resetFilters()
        setFilters(newFilters);
        setIsFilterApplied(false)

    }
    const handleApplyFilters = () => {
        props.applyFilters(filters);
        handleClose();
    }
    const handleCancelFilters = () => {
        setFilters(props.filters);
        handleClose();
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let popoverData =
        props.asaanRole?
        <Grid container>
            <Grid container>
                <Grid item xs={5} className="padding20 margin20" style={{ width: "150px" }}>
                    <AutoSelectFilter 
                        filters={filters}
                        setFilters={setFilters}
                        multipleFlag={false}
                        placeholder="Clients"
                        id="client"
                        options={workerPartners}
                    />
                </Grid>
                <Grid item sm className="padding20 margin20" style={{ width: "150px" }}>
                    <AutoSelectFilter 
                        filters={filters}
                        setFilters={setFilters}
                        multipleFlag={false}
                        placeholder="State"
                        id="state"
                        options={hubstateList}
                    />
                </Grid>
                <Grid item sm className="padding20  margin20">
                    <AutoSelectFilter 
                        filters={filters}
                        setFilters={setFilters}
                        multipleFlag={false}
                        placeholder="City"
                        id="city"
                        options={hubCityList}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} className="padding20 margin20">
                    <SelectFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="PAN Verification Status"
                        id="panStatus"
                    />
                </Grid>
                
                <Grid item xs={6} className="padding20 margin20">
                    <SelectFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Worker App Status"
                        id="vacoAppStatus"
                    />
                </Grid>
                <Grid item xs={6} className="padding20">
                    <DateFilter
                        filters={filters}
                        setFilters={setFilters}
                        labelTo="Added On to"
                        labelFrom="Added On from"
                        id="addedOn"
                    />
                </Grid>
            </Grid>
        </Grid>:
        <Grid container style={{width:"600px"}}>
            <Grid item sm className="padding20 margin20">
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Zone"
                    id="zone"
                />
            </Grid>
            <Grid item sm className="padding20 margin20">
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Partner"
                    id="partnerId"
                />
            </Grid>
        <Grid container>
            <Grid item sm className="padding20 margin20" style={{ width: "150px" }}>
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Facility Hubs"
                    id="facilityName"
                    data={hubsList}
                    multipleFlag={false}
                    style={{ width: "150px" }}
                />
            </Grid>
            <Grid item sm className="padding20 margin20" style={{ width: "150px" }}>
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Facility State"
                    id="facilityState"
                    data={facilityStateList}
                    multipleFlag={false}
                    style={{ width: "150px" }}
                />
            </Grid>
        </Grid>
      {/*
            <Grid item sm className="padding20">
                <LocationFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="City"
                    id="newCity"
                />
            </Grid>
            <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Job role"
                    id="jobRole"
                />
            </Grid> */}
          
            {/* <Grid item xs={6} className="padding20">
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="Last Activity on to"
                    labelFrom="Last Activity from"
                    id="last_activity"
                />
            </Grid> */}
        </Grid>


    return (
        <div>
            <Badge color="secondary" variant="dot" invisible={!isFilterApplied}>
                <IconButton
                    className="filterButton marginLight20"
                    onClick={handleClick}
                >
                    <img src={FilterIcon} alt="img" />
                </IconButton>
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container className="buttonList" >
                    <Grid item xs={4} style={{padding: "10px"}}>
                        <span className="FilterHeading">All worker filters</span>
                    </Grid>
                    <Grid item xs={8} style={{ padding: "20px" }} className="flexRowReverse">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px", backgroundColor:"#1c75bc" }}
                            onClick={handleResetFilters}
                        >Reset</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px", backgroundColor:"#1c75bc" }}
                            onClick={handleApplyFilters}
                        >Apply</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px", backgroundColor:"#1c75bc" }}
                            onClick={handleCancelFilters}
                        >Cancel</Button>
                    </Grid>
                </Grid>
                <Divider />
                {popoverData}
            </Popover>
        </div>
    );
}
