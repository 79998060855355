import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
//import { Divider , Badge } from '@material-ui/core';
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../../UrlLinks'
import Axios from 'axios'
import ErrorIcon from '@material-ui/icons/Error';
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
import Divider from '@material-ui/core/Divider';
import { numberFormat } from '../../../Utils/CommonFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import filter from '../../assets/filter.svg';
//import Filters from './FilterBase';
import Filters from './filterModal';
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import Calendar from '../../../assets/calendar.svg'
import style from './index.module.scss';
import { Link } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createVerificationStatus } from '../../../services/CommonServices'
import { AuthContext } from '../../../services/AuthProvider';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import { useParams } from 'react-router-dom';
import EditableDropDown from '../../../Utils/functions/EditableDropdown'
import Decrypt from '../../../Utils/decrypt'

const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            width:1280px !important;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const AppliedWorkers = (props) => {
    const { id } = useParams();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const { showToast } = useContext(AuthContext);
    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRecord, setAllRecord] = useState([]);
    const [disablePayments, setDisablePayments] = useState(true);
    // const [selectedRows, setSelectedRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);
    const [sortDirectionUrl, setSortDirectionUrl] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [sortUrl, setSortUrl] = useState("");
    const [financialAdmin, setFinancialAdmin] = useState(false)
    const [filterUrl, setFilterUrl] = useState("");
    const [filterDrawer, setFilterDrawer] = useState(false);
    const [searchByAuto, setsearchByAuto] = useState("-");
    const [shouldDisable, setShouldDisable] = useState(false);
    const [selectionValue, setSelectionValue] = useState("Hey");
    const [allDocumentDownload, setAllDocumentDownload] = useState(null)
    const handleSelectionValue = (e) => {
        setSelectionValue(e.target.value)
    }
    console.log(selectionValue)
    useEffect(() => {
        ApplyFilters(filters);
        // ApplySort();
        // setSortDirection(false);
        // setSortDirectionUrl('');
    }, [searchByAuto]);
    const [filters, setFilters] = useState({
        searchBy: "",
        billDate: [null, null],
        zone: "",
        facility_state: "",
        facility_name: "",
        payment_status: "",
        worker_status: ""
    });
    const [jobData, setJobData] = useState({
        jobRole: null,
        jobId: null,
        partnerName: null,
        noOfOpenings: null,
        jobStatus: null,
        facility_name: null,
        facility_zone: null,
        facility_city: null,
        factility_state: null,
        interviewLocation: null,
        interviewDays: null,
        interviewStartTime: null,
        interviewEndTime: null,
        spocName: null,
        spocNumber: null,
        clusterName: null,
        clusterZone: null,
        clusterCity: null,
        clusterState: null,
        partnerId: null
    });
    const sortOptions = [
        ["swiggy_id", "Swiggy"],
        ["flipkart_id", "Flipkart"]
    ];
    const ApplySort = () => {
        let url = '';
        switch (sortBy) {
            case "swiggy_id":
                url = '&partnerId=101';
                break;
            case "flipkart_id":
                url = '&partnerId=100';
                break;
            default:
                url = ''
                break;
        };
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url);
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false);
        setSortDirectionUrl('');
    }, [sortBy]);
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)
        // ApplyFilters(filters);
    }
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
        setFilterUrl('')
    }
    // useEffect(() => {
    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.paymentUrls.financeAdmin}${localStorage.getItem('userID')}`)
    //         .then(response => {
    //             if (response.data.financeAdmin === 1) {
    //                 setFinancialAdmin(true)
    //             }
    //             else {
    //                 setFinancialAdmin(false)
    //             }
    //         })
    //         .catch(error => {
    //             showToast("error", error.message)

    //         })
    // }, [])
    const midFunction = (prop) => {
        console.log("I WAS CALLED")
        ApplyFilters(prop);
    }
    const ApplyFilters = () => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`;
        if (filters["billDate"][0] && !filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=2019-01-01&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["zone"]) {
            url = `${url}&zone=${filters["zone"]}`
        }
        if (filters["facility_state"]) {
            url = `${url}&facility_state=${filters["facility_state"].name}`
        }
        if (filters["facility_name"]) {
            url = `${url}&facility_name=${filters["facility_name"].name}`
        }
        if (filters["payment_status"]) {
            url = `${url}&payment_status=${filters["payment_status"]}`
        }
        if (filters["worker_status"]) {
            url = `${url}&worker_status=${filters["worker_status"]}`
        }
        setFilterUrl(url)
        setFilters(filters)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            searchBy: "",
            billDate: [null, null],
            zone: "",
            facility_state: "",
            facility_Name: "",
            payment_status: "",
            worker_status: ""

        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    // useEffect(() => {
    //     ApplyFilters();
    // }, [filters])
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }

    const columns = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </div>
                ),
            },

            {
                Header: "Name",
                accessor: "name",

                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return (<Grid container style={{width:"160px"}}>{row.row.original.name}
                    </Grid>)
                }
            },
            {
                Header: "Application Date",
                accessor: "applicationDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return (<Grid container style={{width:"160px"}}>{row.row.original.applicationDate}
                    </Grid>)
                }
            },
            {
                Header: "Phone No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return (<Grid container style={{width:"160px"}}>{row.row.original.contactNo}
                    </Grid>)
                }
            },
            {
                Header: "Profile completed",
                accessor: "profileCompleted",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return (<Grid container style={{width:"160px"}}>{row.row.original.profileCompleted}
                    </Grid>)
                }
            },
            {
                Header: "Hub Name",
                accessor: "hubName",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return (<Grid container style={{width:"160px"}}>{row.row.original.hubName}
                    </Grid>)
                }
            },
            {
                Header: "Selection Status",
                // Cell: row => {
                //     // return <button className={styles.workerName} type="button" style={{ fontSize: '16px !important' }} onClick={() => getDocumentList(row.row.original.userId,null)} >Download</button>
                //     return <Select
                //         // id="dataForRecruitment"
                //         value={selectionValue}
                //         disableUnderline
                //         // disabled={selectDisableTwo}
                //         onChange={handleSelectionValue}
                //     // inputProps={{
                //     //     classes: {
                //     //         icon: "selectIcon",
                //     //     },
                //     // }}
                //     >
                //         <MenuItem value="Hey" disabled>
                //             <em>Data Source</em>
                //         </MenuItem>
                //         <MenuItem value={'Selected'}>Selected</MenuItem>
                //         <MenuItem value={'Rejected'}>Rejected</MenuItem>
                //         {/* <MenuItem value={'BOTH'}>Both</MenuItem> */}


                //     </Select>
                // },
                Cell:EditableDropDown,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Documents",
                Cell: row => {
                    return (<Grid container style={{width:"160px"}}><button className={styles.workerName} type="button" style={{ fontSize: '16px !important',width:"100px",backgroundColor:"#1c75bc", borderRadius:"0px",color:"white",height: "fit-content !important" }} onClick={() => getDocumentList(row.row.original.userId, null)} >Download</button>
                    </Grid>)
                },
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Lead Source",
                accessor: "leadSource", 
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return (<Grid container style={{width:"160px"}}>{row.row.original.leadSource}
                    </Grid>)
                }
            },
            // {
            //     Header: "Bill From",
            //     accessor: "billFrom",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            // remove tableContainer width if yo add more cols
        ],
        []
    );
    const createDocumentList = (documents, profilePhoto) => {
        let list = []
        let aadharBackError = 'Aadhar Back,';
        let aadharFrontError = ' Aadhar Front,';
        // let profilePicError = ' Profile pic';
        let errorText = ' not Found!'
        documents.map(doc => {
            // if (doc.document_type_id === 1929 || doc.document_type_id === 1867) {
            //     if (doc.document_type_id === 1929)
            //         aadharBackError = '';
            //     if (doc.document_type_id === 1867)
            //         aadharFrontError = '';
                if (doc.file_path.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

                    list.push('https' + doc.file_path.substring(4, doc.file_path.length))
                } 
                    // list.push(doc.file_path)


            // }
        })
        console.log(list)
        // if (profilePhoto !== null && profilePhoto !== '') {
        //     // profilePicError = '';
        //     if (profilePhoto.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss

        //         list.push('https' + profilePhoto.substring(4, profilePhoto.length))
        //     } else
        //         list.push(profilePhoto)
        // }
        // if (aadharBackError.length !== 0 || aadharFrontError.length !== 0) {
        //     errorText = aadharBackError + aadharFrontError + errorText;
        //     showToast("error", errorText);
        // }
        // console.log(list)

        return list.forEach(path => {
            window.open(path, '_blank');
            // sleep(3000)
        });


    }
    const getDocumentList = (userId, profilePhoto) => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(userId)}`)
            .then(response => {
                setAllDocumentDownload(createDocumentList(response.data.response.content, profilePhoto))
            })
    }
    const handleFilterDates = (date, index, type) => {
        let dates = filters.billDate;
        dates[index] = date;
        setFilters({ ...filters, [type]: dates });
        ApplyFilters(filters);
    }

    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        response.content.map(el => {
            extractedData.push({

                // name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                name: el.fullName,
                contactNo: Decrypt(el.phoneNumber) || "",
                applicationDate: el.applicationDate ? moment(el.applicationDate).format("DD/MM/YYYY") : "",
                // profileCompleted:el.docsUploadedUser!==null?(el.docsUploadedUser===1?"YES":"NO"):"-",
                profileCompleted: el.profileStatus === "4" ? "Completed" : el.profileStatus?(el.profileStatus+"/4"):"",
                hubName: el.hubName || "",
                userId: el.userId || "",
                leadSource: el.leadSource || "",
                jobId:id,
                disable:jobData.jobStatus==="Active"?false:true,
                selectionStatus: el.selectionStatus?el.selectionStatus: "-",
            });
            return true;
        });

        return extractedData;
    }
    const createWorkerStatus =(status) =>{
        let text=""
        switch (status) {
            case 1:
                text="Selected";
                break;
            case 2:
                text="Rejected";
                break;
                       
            default:
                
                break;
        }
        return text;
    }
    
    const makeJobData = (response) => {
        // let extractedData = [];
        // response.content.map(el => {
        //     extractedData.push
        console.log(response)
        setJobData({
            jobRole: response.content[0].jobRole || "",
            jobId: response.content[0].jobId || "",
            partnerName: response.content[0].partnerName || "",
            noOfOpenings: response.content[0].noOfOpenings || "",
            jobStatus: response.content[0].jobStatus === 1 ? "Active" : "Closed",
            facility_name: response.content[0].hubName || "",
            facility_zone: response.content[0].zone || "",
            facility_city: response.content[0].city || "",
            factility_state: response.content[0].hubState || null,

            interviewLocation: response.content[0].interviewLocation || "",
            interviewDays: response.content[0].interviewDays || "",
            interviewStartTime: response.content[0].interviewStartTime ? moment(response.content[0].interviewStartTime, ["HH:mm"]).format("hh:mm A") : "",
            // moment("12:15 AM", ["h:mm A"]).format("HH:mm");
            interviewEndTime: response.content[0].interviewEndTime ? moment(response.content[0].interviewEndTime, ["HH:mm"]).format("hh:mm A") : null,

            spocName: response.content[0].spocName || "",
            spocNumber: response.content[0].spocNumber || "",

            clusterName: response.content[0].clusterName || "",
            clusterZone: response.content[0].clusterZone || "",
            clusterCity: response.content[0].clusterCity || "",
            clusterState: response.content[0].clusterState || null,
            partnerId: response.content[0].partnerId || "",
        });
        //     return true;
        // });

        // return extractedData;
    }
    console.log(jobData)
    //Function to create All record export data

    // const columns=columnsHrbp:columnsOkayGo
    const updateMyData = () => {
        getPaymentListing(perPage, currentPage);
    }
    
    // const shouldDisable=false;
    useEffect(() => {

        Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.jobsdbListing}?job_id=` + id)
            .then(response => {
                if (response) {
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    // if(currentPage>response.data.response.totalPages)
                    // setCurrentPage(totalPages)
                    makeJobData(response.data.response)
                    if(jobData.jobStatus===2){
                        setShouldDisable(true)
                    }
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })


    }, [shouldDisable])

    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getPaymentListing = (rows, pageNo) => {
        setLoading(true)
        // let requestedBy=""
        // if(localStorage.getItem("roleType")==="1"){
        //     requestedBy=`&requestedById=${localStorage.getItem("userID")}`
        // }
        // if(localStorage.getItem("roleType")){
        Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.workerApplied}?page_no=${pageNo - 1}&rows=${rows}${filterUrl}${sortUrl}` + "&job_id=" + id)
            .then(response => {
                if (response) {
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if (currentPage > response.data.response.totalPages)
                        setCurrentPage(1)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
        // }
    }
    useEffect(() => {
        getPaymentListing(perPage, 1);

    }, [filterUrl,
        sortUrl,
        localStorage.getItem("roleType")])

    const handlePageChange = page => {
        getPaymentListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getPaymentListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    useEffect(() => {
        // console.log({isAllRowsSelected})
        rows.map((record, index) => {
            // console.log({record})
            // console.log(record, selectedRowIds[index])
            if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
                selectedRowIds[index] = false
                // console.log(selectedRowIds[index])
            }
        })
        // console.log(selectedRowIds)
    }, [isAllRowsSelected])

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisablePayments(disableButton);
            // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    const makeAllRecordData= (response) =>{
        let extractedData = [];
        response.map(el => {
            // console.log({el})
            // console.log(parseFloat(el.surgeCharges))
            // console.log(parseFloat(el.surgeCharges).toFixed(2))
            let temp={
                // name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                name: el.fullName,
                applicationDate: el.applicationDate ? moment(el.applicationDate).format("DD/MM/YYYY") : "",
                contactNo: Decrypt(el.phoneNumber) || "",
                // profileCompleted:el.docsUploadedUser!==null?(el.docsUploadedUser===1?"YES":"NO"):"-",
                profileCompleted: el.profileStatus === "4" ? "Completed" : el.profileStatus ? "(" + el.profileStatus + "/4)" : "",
                hubName: el.hubName || "",
                // userId: el.userId || "",
                leadSource: el.leadSource || "",
                selectionStatus: el.selectionStatus?createWorkerStatus(el.selectionStatus): "-",

            };

            // if(localStorage.getItem("roleType")==="1" || localStorage.getItem("roleType")==="5"){
            //     delete temp["paymentStatus"]
            //     delete temp["paymentRemarks"]
            // }
            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    console.log(localStorage.getItem("roleType"))
    const createAllRecordExcel= () =>{
        setAllRecordLoading(true)
        let requestedBy=""
        if(localStorage.getItem("roleType")==="1"){
            requestedBy=`&requestedById=${localStorage.getItem("userID")}`
        }
        Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.workerApplied}?${filterUrl}${sortUrl}` + "&job_id=" + id+"&exportExcel=1")
            .then(response =>{
                if(response){
                    setAllRecord(makeAllRecordData(response.data.response))
                }
            })
    }
    console.log({ allRecord })
    // const handlePayments =() =>{
    //     // console.log({data})
    //     const indexes= Object.entries(selectedRowIds);
    //     // console.log({indexes})
    //     const idList=[]
    //     indexes.map((id, index) =>{
    //         if(id[1]){
    //             // console.log({index})
    //             idList.push(data[parseFloat(id[0])].invoiceId);
    //         }
    //         return true;
    //     })
    //     // console.log({idList});
    //     Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.triggerPayment}?requested_by=${localStorage.getItem("userID")}&invoice_ids=${idList.toString()}`)
    //         .then(response =>{
    //             if (response.data.code === 1000) {
    //                 getPaymentListing(perPage, currentPage);
    //                 showToast("success", "Payments triggered successfully");
    //             } 
    //             else {
    //                 showToast("error","Payments triggering failed") ;
    //             }
    //         })
    //         .catch(error => {
    //             showToast("error",error.message) 
    //         })
    // }
    const handleClose = () => {
        Axios.post(`${urlLinks.baseUrl}${urlLinks.jobs.jobEditSave}?updated_by=${localStorage.getItem('userID')}&job_id=${id}&field=closeJob&value=2`)
            .then(response => {
                setShouldDisable(true)
                // getPaymentListing(1,1)
                alert("This job is closed. Please close the window.");
            })
            .catch(error => {
                showToast("error", error.message)
                
            })

    }
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer" style={{ width: '1030px' }}>
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameMatchNew zIndex1080 header" :
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" : "header"}>

                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="row">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameMatchNew zIndex540" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>
console.log(jobData.jobStatus)
    const jobDetailsBox = <Grid container xs={12} style={{ backgroundColor: "#ffffff", height: '200px', marginBottom: '22px' }}>

        <Grid item xs={2} style={{marginLeft:"30px",marginTop:"30px",borderRight:"#707070 1px solid",marginBottom: "30px",marginRight:"20px"}}>

            <Grid item xs={12}  >
                <Grid style={{fontWeight:"700",fontSize:"18px"}}>
                    {jobData.jobRole}
                </Grid>
                <Grid style={{marginTop:"7px"}}>
                    {jobData.partnerId === 100 ? jobData.facility_name : jobData.clusterName}
                </Grid>
                <Grid style={{marginTop:"7px"}}>
                    Job ID - {jobData.jobId}
                </Grid>

            </Grid>
        </Grid>
        <Grid item xs={3} style={{marginTop:"30px",borderRight:"#707070 1px solid",marginRight:"20px",marginBottom: "30px" }}>
            {/* <div> */}
            {/* <Divider variant="middle" orientation="vertical" /> */}
            {/* </div> */}
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={7}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>Partner Name</span>
                </Grid>
                <Grid item xs={5} className="paddingBottom16">
                    <span>{jobData.partnerName}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={7}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>Number of openings</span>
                </Grid>
                <Grid item xs={5} className="paddingBottom16">
                    <span>{jobData.noOfOpenings}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={7}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>Job status</span>
                </Grid>
                <Grid item xs={5} className="paddingBottom16">
                    <span>{jobData.jobStatus}</span>
                </Grid>
            </Grid>
            {jobData.partnerId === 100 && <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={7}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>Zone</span>
                </Grid>
                <Grid item xs={5} className="paddingBottom16">
                    <span>{jobData.facility_zone}</span>
                </Grid>
            </Grid>}
            {/* <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Partner Name</span>
                </Grid>         
                <Grid item xs={8} className="paddingBottom16">
                    <span>{jobData.partnerName}</span>    
                </Grid>                
            </Grid> */}

        </Grid>
        <Grid item xs={4} style={{marginTop:"30px", marginRight:"20px"}}>
            {/* <Divider orientation="vertical" /> */}
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={5}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>Facility city, state</span>
                </Grid>
                {jobData.partnerId === 100 ?
                    <Grid item xs={7} className="paddingBottom16">
                        <span>{jobData.facility_city}{jobData.facility_state ? ", " + jobData.facility_state : ""}</span>
                    </Grid> : <Grid item xs={8} className="paddingBottom16">
                        <span>{jobData.clusterCity}{jobData.clusterState ? "," + jobData.clusterState : ""}</span>
                    </Grid>}
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={5}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>Interview location</span>
                </Grid>
                <Grid item xs={7} className="paddingBottom16">
                    <span>{jobData.interviewLocation}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={5}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>Interview time</span>
                </Grid>
                <Grid item xs={7} className="paddingBottom16">
                    <span>{jobData.interviewDays} {jobData.interviewStartTime}  {jobData.interviewEndTime ? " to " + jobData.interviewEndTime : ""}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={5}>
                    <span className="fontWeightBold" style={{marginRight:"10px"}}>SPOC details</span>
                </Grid>
                <Grid item xs={7} className="paddingBottom16">
                    <span>{jobData.spocName}{jobData.spocNumber ? "," + jobData.spocNumber : ""}</span>
                </Grid>
            </Grid>


            {/* <Divider orientation="vertical" /> */}
        </Grid>
        <Grid xs={2} style={{marginTop:"30px",marginLeft:"15px"}}>
           {jobData.jobStatus==="Active"? <Button variant="outlined" color="secondary" onClick={handleClose}>
                CLOSE JOB
            </Button>:<Button variant="outlined" color="secondary"  disabled={true}>
                CLOSED
            </Button>}
        </Grid>

    </Grid>

    
    return (
        <Styles>
            <Grid container className="padding2percent">
                {jobDetailsBox}
                <Grid item sm={12}><span className="fontSize24 fontWeightBold">Worker Leads</span></Grid>
                <Grid item xs={12} className="flexDisplay" justify='space-between'>
                    <Grid item sm={7} className="flexDisplay padding0 paddingTop8" direction="row" justify="space-between">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder="Search by Phone number"
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="flexDisplay paddingTop8" direction="row" alignItems='flex-end'>
                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled={allRecordLoading}
                        onClick={createAllRecordExcel}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading ?
                            <span style={{ marginLeft: "20px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={`jobId_${jobData.jobId}_${jobData.jobRole}.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginLeft: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink>
                                : null}
                    </Grid>



                </Grid>

                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
                {/* <Drawer anchor="right" open={filterDrawer} onClose={() => setFilterDrawer(false) }>
                    <Filters 
                        filters={filters}
                        updateFilters={ApplyFilters}
                        filterDrawer={filterDrawer}
                        setFilterDrawer={setFilterDrawer}
                        resetFilters={resetFilters}
                        isFilterApplied={isFilterApplied}
                        setIsFilterApplied={setIsFilterApplied}
                    />
                </Drawer> */}
            </Grid>
        </Styles>
    )
}

export default AppliedWorkers;


