const UrlLinks = {
  // baseUrl:'https://qaapinew.okaygo.in/', // vapt backend url
  // baseUrl:'https://qaapieflex.okaygo.in/',
  baseUrl:'https://Uatapi.okaygo.in/',
  // baseUrl:'https://apieflex.okaygo.in/',
  checkAdmin: "user/checkAdminUser",
  checkAdminUserName: "user/checkAdminUserName",
  saveAdminUserName:"user/saveAdminUserName",
  getEmail:"user/getEmailHRBP?email=",
  sendOtp:"/user/sendOTP",
  verifyOtp:"/user/verifyOTP",
  verifyHubName:"/user/isHubValid",
  registerHubLocation:"/user/registerHubLocation",
  messageTemplates:"popups/getTemplates",
  getMessageBody:"popups/getMessageBody",
  sendMsg:"/popups/sendMessages",
  sendCustomMsg:"/popups/sendCustomNotification",
  sendMsgToAll:"/popups/sendMessage/all",
  getPartner:"/configmaster/getPartners",
  // popups/sendMessage/all
  BGVListing: "bgv/listing",
  BGVDownloadReport: "bgv/getBgvReport?userId=",
  verificationAPIs:{
    listing:'docsVerification/getVerificationListing',
    verificationProfile:'docsVerification/getVerificationDataByUserId',
    updateVerification:'docsVerification/manualDocsVerification'
  },
  workerUrls: {
    workerLedgerListing:'ledger/getWorkerLedger',
    particularWorkerLedgerListing:'ledger/getWorkerLedgerInvoiceList',
    updatePennyTestingStatus: "worker/recheckBankDetail",
    lastAppliedHistory: "workerdata/getJobAppliedHistory?userId=",
    bgvStatusList: "bgv/getStatus?userId=",
    triggerBGV: "bgv/createProfile?userId=",
    bgvReverify: `bgv/reverify?insertedBy=${localStorage.getItem("userID")}&userId=`,
    asaanJobWorkerListing: "workerdata/getVacoWorkerListing",
    asaanJobPaymentListing:"workerdata/paymentDataListing",
    leadCities:"leads/getLeadCities?key=",
    reteriveLastSms:"worker/getLastSmsOfUser",
    editEntity:'workerdata/editEntities?',
    // Invoicedetails: '/upload/uploadWorkerInvoiceDetailsV3?insertedBy=',
    Invoicedetails: '/uploadData/uploadWorkerInvoiceDetailsV3?insertedBy=',
    uploadPayoutAutomation: '/uploadData/uploadPayoutAutomation?insertedBy=',
    workerListing: 'worker/listing',
    workerListingV3: '/worker/listing/v3',
    workerDataListing: "/workerdata/listing",
    leadDataListing: "/workerdata/getLeadDataListing",
    workerDataListingv3: "/workerdata/listingV2",
    leadDataListingv3: "/workerdata/getLeadDataListingV2",
    workerView: 'worker/listing?phone_number=',
    workerViewV3: 'workerdata/listing?phone_number=',
    allWorkerListing: 'worker/allRecords',
    editWorkerDetails: 'worker/addEditUserDetail',
    editWorkerHRBPDetails: "workerdata/editHRBPSection",
    editWorkerBankDetails: "worker/addEditBankDetails",
    docList: 'user/getUserDoc?phone_number=',
    activateWorker: 'worker/activateWorker',
    hubBasedFacilityList:  "user/getHubs?userId=",
    facilityList: "worker/getFacilityByKey?key=",
    rateCardList: "worker/getDistinctRateCardByZone?zone=",
    uploadVacoOnboardingExcel: "/uploadData/uploadVacoWorker?insertedBy=",
    uploadVacoWorkerInvoice: "/uploadData/uploadVacoWorkerInvoice?insertedBy=",
    uploadOnboardingExcel: "uploadData/uploadExcelForOkaygoIdCreation?insertedBy=",
    uploadOnboardingExcelV2: "uploadData/uploadExcelForOkaygoIdCreation/V2?insertedBy=",
    uploadOffboardingExcel: "uploadData/offBoardWorkersV2?userId=",
    uploadOffboardingSwiggyExcel: "upload/offBoardSwiggyWorkersV2?userId=",
    updateLeader:"worker/leaderfunctionality",
    generateWeeklyInvoice:"/worker/generateWeeklyInvoice",
    uploadDocLink: "user/getDocLink?user_id=",
    uploadDocument: "user/uploadDocument?inserted_by=",
    // new added
    uploadExcelForMultiplePartner:"/upload/uploadExcelForMultiplePartner?insertedBy=",
    offBoardWorkers:'uploadData/V3/offBoardWorkers?userId=',
    //integrate these
    uploadZomatoIds:'/upload/zomatoIds?insertedBy=',
    uploadJiomartIds:'/upload/uploadJiomartIds?insertedBy=',
    uploadDelhiveryIds:'/upload/uploadDelhiveryIds?insertedBy=',
    uploadSwiggyIdsV2:'/upload/swiggyIds/v2?insertedBy=',
    getExistingLeadDetails:"workerdata/getLeadDataListing?phone_number=",
    uploadHubs:"/hub/add",
    // here
    uploadGroupFlexExcel:"/uploadData/uploadExcelForOkaygoIdCreationWithGroupflexId?insertedBy=",
    bankDetails: "worker/getBankDetails?phone_number=",
    editContact: "worker/editWorkerContacts",
    workerReactivation: "worker/changeWorkerStatusFromAdmin",
    workerWithoutCasperId: "worker/allWorkerCasper",
    workerDataPopupSwiggy:"/worker/V2/notFilledSwiggyIds",
    workerWithoutDelhiveryId:"/worker/notFilledDelhiveryIds",
    workerWithoutJiomartId:"/worker/notFilledJiomartIds",
    workerDataPopupZomato:"/worker/notFilledZomatoIds",
    uploadCasperOnboardingData: "upload/uploadWorkerCasperId",
    downloadAgreementOnResponse: "worker/createWorkerAgreementCopy?batchNo=",
    swiggyIds: "/upload/swiggyIds",
    swiggyShipmentIds: "/upload/swiggyShipmentData",
    swiggyOffboarding:"",
    swiggyListing: "/worker/swiggyListing",
    swiggyListingView: "/worker/swiggyListing?user_id=",
    emailAgreements: `worker/sendAgreementEmail?inserted_by=${localStorage.getItem('userID')}&user_ids=`,
    allSwiggyList: "worker/swiggyAllRecords",
    workerDataHubs:"/configmaster/getActiveHubs",
    workerDataHubCity:"/configmaster/getFacilityCity",
    leadSourceList: 'leads/getLeadSourceList',
    workerDataHubState:"/configmaster/getFacilityStates",
    recruiterList:"/recruiter/getAllRecruiter?noPaging=1",
    workerWithoutClientCreatedIds:`workerdata/notFilledClientWorkerDataIds?partnerName=`,
    uploadExcelForMultiplePartner2:"/uploadData/uploadExcelForMultiplePartner?insertedBy=",
    uploadCasperOnboardingData2: "uploadData/uploadClientWorkerDataIds?partnerName=",
    uploadOnboardingExcelV22: "uploadData/uploadExcelForOkaygoIdCreation/V2?insertedBy=",
    uploadOnboardingExcel2: "uploadData/uploadExcelForOkaygoIdCreation?insertedBy=",
    hubListing:"/hub/getHubsByClient"

  },
  clients:{
    addNewCompany:"clients/saveNewClient"
  },
  paymentUrls: {
    paymentListing: "payments/listing",
    paymentListingMultiClient: "workerdata/paymentDataListing",
    paymentListingMultiClientV2: "workerdata/paymentDataListingV2",
    triggerPayment: "payments/triggerBulkPayments",
    financeAdmin:  "user/checkFinanceUser?phone_number=",
    paymentOnHold:"payments/holdPayments",
    paymentunHold:"payments/undoHoldPayments",
    updatePaymentStage:"payments/updateStage",
    updateRetriggerPaymentStage:"payments/retriggerInvoicePayments",
  },
  jobs:{
    jobsListing:"job/jobListing/v2",
    jobsdbListing:"job/jobListing/admin",
    jobCities:"job/cities",
    jobAreas:"job/areas",
    workerApplied:"job/workerApplied",
    jobUpload:"upload/uploadJobs/v2",
    jobEditSave:"job/updateJobFields",
    updateJobSelection:"job/updateSelectionStatus"
  },
  retentionCalls:{
    retentionCallListing:"workerdata/retentionCall/listing",
    statusUpdate: "workerdata/retentionCall/update",
    AssignRecruter: "workerdata/retentionCall/assign"
  },
  referral:{
    referralListing:"referral/dashboard",
    openClaimsListing:"referral/dashboard/openClaims",
    processedClaimsListing:"/referral/dashboard/ProcessedClaims",
    updateReferralFields:"/referral/updateFields"
  },
  bankVerification: {
    listing: "worker/bankDetailsVarification/listing",
    DetailsVerificationInitial: "worker/getBankDetailsForVerification?phone_number=",
    DetailsVerificationNext: "worker/getBankDetailsForVerification?currentPhoneNumber=",
    DetailsVerificationNextV2: "worker/getBankDetailsForVerificationv2?currentPhoneNumber=",
    submitDetails: "worker/verifiyBankDetails"
  },
  shifts:{
    shiftPlanning: "workershift/getWorkerShiftPlanning",
    shiftData: "workershift/listing",
    workerPartners: "workershift/getWorkerPartners",
    uploadingShiftingPlan: "upload/uploadWorkersShiftDetails?insertedBy=",
    shiftStatusList: "configmaster/?category_sub_type=worker_shift_status",
    shiftDetailExcel: "user/getSignedUrlForS3Upload?filePath=document/Templates/Shift_Planner_Template.xlsx",
    newOnboardingExcel:"user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_Multiple_Client_Template.xlsx",
    newOffboardingExcel:"user/getSignedUrlForS3Upload?filePath=document/templates/Offboarding_Multiple_Client_Template.xlsx",
    updateShiftDetails: "workershift/addEditWorkerShift",
    updateEffectiveDate:"workershift/editEffectiveDate",
    uploadEarningFlipkart: "upload/flipkartShipmentData?insertedBy=",
    uploadEarningSwiggy: "upload/swiggyShipmentData?insertedBy=",
    uploadEarningZomato: "upload/zomatoShipmentData?insertedBy=",
    uploadEarningDelhivery: "upload/delhiveryShipmentData?insertedBy=",
    uploadEarningJiomart: "upload/jiomartShipmentData?insertedBy=",
    uploadEarningOther: "uploadData/newClientEarningUpload?insertedBy="
  },
  recruiter:{
    dashboard:"recruiter/getCountCallRecord",
    assignToRecruiter:"recruiter/assignWorkerToRecruiter",
    assignToRecruiterLead: "recruiter/assignLeadToRecruiterV2",
    activeAndBlockAPI:"recruiter/setActiveAndBlock"
  },
  documentTypeList: "configmaster/?category_type=document_upload",
  entityList: "configmaster/entityList",
  onBoardingExcel: "user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_data.xlsx",
  newOnboardingExcel:"user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_Multiple_Client_Template.xlsx",
  onBoardingExcelV2: "user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_Data_Admin.xlsx",
  offBoardingExcel: "user/getSignedUrlForS3Upload?filePath=document/Templates/Updated_Offboarding_file_template.xlsx",
  //add here
  // groupFlexExcel:"user/getSignedUrlForS3Upload?filePath=document/Templates/Groupflex_Onboarding_file_template.xlsx",
  groupFlexExcel:"user/getSignedUrlForS3Upload?filePath=document/templates/Groupflex_Onboarding_Template.xlsx",
  uploadSwiggyIdExcel:"user/getSignedUrlForS3Upload?filePath=document/templates/upload_swiggy_id_template.xlsx",
  offBoardingSwiggyExcel: "user/getSignedUrlForS3Upload?filePath=document/templates/offboarding_swiggy_template.xlsx",
  earningSwiggyExcel: "user/getSignedUrlForS3Upload?filePath=document/templates/swiggy_shipment_data_template.xlsx",
  earningFlipkartExcel: "user/getSignedUrlForS3Upload?filePath=document/templates/flipkart_shipment_data_template.xlsx",
  earningZomatoExcel: "user/getSignedUrlForS3Upload?filePath=document/templates/zomato_shipment_data_template.xlsx",
  jobdbExcel:"user/getSignedUrlForS3Upload?filePath=document/templates/upload_job_template.xlsx"
}
export default UrlLinks;