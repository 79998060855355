import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
//import { Divider , Badge } from '@material-ui/core';
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel, Switch, Typography } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Upload from '../EmployerData/components/DelhiveryEarningAdmin'
import Axios from 'axios'
import Modal from '@material-ui/core/Modal';
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
import { numberFormat } from '../../Utils/CommonFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import filter from '../../assets/filter.svg';
//import Filters from './FilterBase';
import Filters from './filterModal';
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import { createVerificationStatus } from '../../services/CommonServices'
import { AuthContext } from '../../services/AuthProvider';
import Decrypt from '../../Utils/decrypt';

const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }

    }, paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const ExternalWorker = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [dataBalnce, setDataBalance] = useState();
    const [actions, setActions] = useState(0)
    const { showToast } = useContext(AuthContext);
    const [openSendMsg, setOpenSendMsg] = useState(false)
    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRecord, setAllRecord] = useState([]);
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false)
    const [openCasperOnboardingModal, setOpenCasperOnboardingModal] = useState(false)
    const [disablePayments, setDisablePayments] = useState(true);
    // const [selectedRows, setSelectedRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [openUploadModal,setOpenUploadModal] = useState(false);
    const [sortBy, setSortBy] = useState(100);
    const [sortDirection, setSortDirection] = useState(false);
    const [sortDirectionUrl, setSortDirectionUrl] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [sortUrl, setSortUrl] = useState("&partnerId=100");
    const [financialAdmin, setFinancialAdmin] = useState(false)
    const [filterUrl, setFilterUrl] = useState("");
    const [filterDrawer, setFilterDrawer] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState("")
    const [searchByAuto, setsearchByAuto] = useState("");
    const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false)
    const [partner, setPartner] = useState([])
    const [partnerFilter, setPartnerFilter] = useState({})
    const [tab, setTab] = useState({
        invoiceTab: false,
        transactionTab: true
    })
    useEffect(() => {
        ApplyFilters(filters);
        // ApplySort();
        // setSortDirection(false);
        // setSortDirectionUrl('');
    }, [searchByAuto]);
    const [filters, setFilters] = useState({
        searchBy: "",
        billDate: [null, null],
        zone: "",
        facility_state: "",
        facility_name: "",
        payment_status: "",
        worker_status: "",
        worker_type: "",
        payment_type: "",
        advanceTaken: "",
        bankDetails: "",

    });

    const sortOptions = [
        ["101", "Swiggy"],
        ["101", "Flipkart"]
    ];
    const ApplySort = () => {
        if (sortBy)
            setSortUrl("&partnerId=" + sortBy);
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false);
        setSortDirectionUrl('');
        setCurrentPage(1);
    }, [sortBy]);
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)
        setCurrentPage(1);
        // ApplyFilters(filters);
    }
    console.log(filters)
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
        setsearchByAuto()
        setIsFilterApplied(false)
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const body = (
        <div className={classes.paper}>
            <h2 id="simple-modal-title">Text in a modal</h2>
            <p id="simple-modal-description">
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </p>
            <Modal />
        </div>
    );
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.getPartner}`)
            .then(response => {
                let a = []
                response.data.response.map(e => {
                    if(e.is_active){
                        a.push([e.partner_id, e.company_name])
                    }
                })
                setPartner(a)
                setData2(makeData2(response.data.response))

            

            })

    }, [])

    const midFunction = (prop) => {
        console.log("I WAS CALLED")
        ApplyFilters(prop);
    }
    const ApplyFilters = (filters) => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`;
        if (filters["billDate"][0] && !filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=2019-01-01&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["zone"]) {
            url = `${url}&zone=${filters["zone"]}`
        }
        if (filters["facility_state"]) {
            url = `${url}&facility_state=${filters["facility_state"].name}`
        }
        if (filters["facility_name"]) {
            url = `${url}&facility_name=${filters["facility_name"].name}`
        }
        if (filters["payment_status"]) {
            url = `${url}&payment_status=${filters["payment_status"]}`
        }
        if (filters["worker_status"]) {
            url = `${url}&worker_status=${filters["worker_status"]}`
        }
        if (filters["worker_type"] || filters["worker_type"] === 0) {
            url = `${url}&isGold=${filters["worker_type"]}`
        }
        if (filters["payment_type"]) {
            url = `${url}&paymentType=${filters["payment_type"]}`
        }
        if (filters["advanceTaken"] || filters["advanceTaken"] === 0) {
            url = `${url}&advanceTaken=${filters["advanceTaken"]}`
        }
        if (filters["bankDetails"] || filters["bankDetails"] === 0) {
            url = `${url}&bankdetail=${filters["bankDetails"]}`
        }
        // payment_type:"",
        // worker_type:""
        setFilterUrl(url)
        setFilters(filters)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            // searchBy: "",
            billDate: [null, null],
            zone: "",
            facility_state: "",
            facility_Name: "",
            payment_status: "",
            worker_status: "",

            worker_type: "",
            payment_type: "",
            advanceTaken: "",
            bankDetails: "",

        }
        setFilters(filterList)
        if (filterUrl.indexOf("searchBy") !== -1) {
            setFilterUrl('&searchBy=' + searchBy)
        } else
            setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        // setSearchBy("");
        return filterList;
    }
    // useEffect(() => {
    //     ApplyFilters();
    // }, [filters])

    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }
    console.log(financialAdmin)
    const columnsOkayGo = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         (!(row.original.queued !== 0 || !row.original.isInvoiceCreated) && <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.queued !== 0 || !row.original.isInvoiceCreated) ? {} : { color: "#1c75bc" }} disabled={row.original.queued !== 0 || !row.original.isInvoiceCreated} />
            //         </div>)
            //     ),
            // },
            {
                Header: "Hub Name",
                accessor: "facilityName",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Hub City",
                accessor: "facilityCity",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Hub State",
                accessor: "facilityState",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            
            {
                Header: "Hub Address",
                accessor: "address",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            
            {
                Header: "Earning Per Hour",
                accessor: "earning_per_hour",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Zone",
                accessor: "zone",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Hub Type",
                accessor: "facilityType",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Interview location",
                accessor: "interview_location",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Spocs",
                accessor: "spoc_name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Coordinates",
                accessor: "hubLatitude",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Is Active",
                accessor: "isActive",
                defaultCanFilter: false,
                defaultCanSort: false
            }
            // isActive
        ],
        []
    );
    const handleFilterDates = (date, index, type) => {
        let dates = filters.billDate;
        dates[index] = date;
        setFilters({ ...filters, [type]: dates });
        ApplyFilters(filters);
    }

    const makeData2 = (response) => {
        let extractedData = [];
        response.map(el => {
            extractedData.push({
                partnerId: el.partner_id || "",
                partnerName: el.company_name || "",
                logo: el.logo || "",
                insertedOn: el.inserted_on ? moment(el.inserted_on).format("DD/MM/YYYY") : "",
                paymentConfiguration: el.paymentConfiguration === 1 ? "Shipment Basis" : el.paymentConfiguration === 2 ? "Hourly Basis" : el.paymentConfiguration === 3 ? "Daily Basis" : "",
                isActive: el.is_active === 1 ? "Yes" : "No",
            });
            return true;
        });

        return extractedData;
    }

    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        response.content.map(el => {
            if (sortBy === 100) {
                extractedData.push({
                    facilityName: el.facilityName || "",
                    facilityCity: el.facilityCity || "",
                    facilityState: el.facilityState ? el.pincode ? el.facilityState + ", " + el.pincode : el.facilityState : "",
                    facilityType: el.facilityType || "",
                    hubLatitude: el.hubLatitude ? el.hubLatitude + "," + el.hubLongitude : "",
                    rateCard: el.rateCard || null,
                    spoc_name: el.spoc_name ?el.spoc_contact?el.spoc_name +" - "+Decrypt(el.spoc_contact):el.spoc_name||null:Decrypt(el.spoc_contact)||null,  
                    zone: el.zone || null,
                    address: el.address || null,
                    earning_per_hour: el.earning_per_hour || null,
                    isActive:el.cluster_active===1?"Yes":"No",
                    interview_location:el.interview_location||""

                });
            } else {
                extractedData.push({
                    facilityName: el.cluster_name || "",
                    facilityCity: el.city || "",
                    facilityState: el.state ?  el.state : "",
                    facilityType: el.facilityType || "",
                    hubLatitude: el.hub_latitude ? el.hub_latitude + "," + el.hub_longitude : "",
                    rateCard: el.rateCard || null,
                    // spoc_contact: el.spoc_contact || null,
                    spoc_name: el.spoc_name ?el.spoc_contact?el.spoc_name +" - "+Decrypt(el.spoc_contact):el.spoc_name||null:Decrypt(el.spoc_contact)||null,
                    zone: el.cluster_zone || null,
                    address: el.address || null,
                    earning_per_hour: el.earning_per_hour || null,
                    isActive:el.cluster_active===1?"Yes":"No",
                    interview_location:el.interview_location||""

                });
            }
            // lastupdatedon.push(el.lastUpdatedOn)
            return true;
        });

        return extractedData;
    }

    //Function to create All record export data

    const columns = columnsOkayGo

    const updateMyData = () => {
        getPaymentListing(perPage, currentPage);
    }


    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getPaymentListing = (rows, pageNo) => {
        setLoading(true)
        let requestedBy = ""
        if (localStorage.getItem("roleType") === "1") {
            requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        }
        // if (sortUrl) {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.hubListing}?page_no=${pageNo - 1}${requestedBy}&rows=${rows}${filterUrl}${sortUrl}`)
            .then(response => {
                if (response) {
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if (currentPage > response.data.response.totalPages)
                        setCurrentPage(1)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })

    }
    useEffect(() => {
        getPaymentListing(perPage, 1);

    }, [filterUrl,
        sortUrl,
        localStorage.getItem("roleType"), tab])

    const handlePageChange = page => {
        getPaymentListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getPaymentListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    useEffect(() => {
        // console.log({isAllRowsSelected})
        rows.map((record, index) => {
            // console.log({record})
            // console.log(record, selectedRowIds[index])
            if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
                selectedRowIds[index] = false
                // console.log(selectedRowIds[index])
            }
        })
        // console.log(selectedRowIds)
    }, [isAllRowsSelected])

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisablePayments(disableButton);
            // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    const makeAllRecordData = (response) => {
        let extractedData = [];
        response.map(el => {
            console.log({ el })
            console.log(parseFloat(el.surgeCharges))
            console.log(parseFloat(el.surgeCharges).toFixed(2))
            let temp = {
                casperId: el.casperId || "",
                okaygoId: el.okaygoId || "",
                name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber)|| "",
                bankDetailsVerification: createVerificationStatus(el.verificationStatus),
                bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                billNo: el.billNumber || "",
                shipmentAmount: el.shipmentAmount ? (parseFloat(el.shipmentAmount)).toFixed(2) : "",
                surgeCharges: el.surgeCharges ? (parseFloat(el.surgeCharges)).toFixed(2) : "",
                adjustments: el.recovery ? (parseFloat(el.recovery)).toFixed(2) : "",
                // leaseCharges: el.leaseCharges ? (parseFloat(el.leaseCharges)).toFixed(2) : "",
                advancePayment: el.advancePayment ? numberFormat(parseFloat(el.advancePayment)) : "",
                totalAmount: el.netPayableAmount ? parseFloat(el.netPayableAmount).toFixed(2) : "",
                netPayable: el.totalAmount ? parseFloat(el.totalAmount).toFixed(2) : "",
                paymentDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                paymentStatus: el.paymentStatus || "",
            };

            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    const makeAllTransactionRecordData = (response) => {
        let extractedData = [];
        response.map(el => {
            // console.log({ el })
            // console.log(parseFloat(el.surgeCharges))
            // console.log(parseFloat(el.surgeCharges).toFixed(2))
            let temp = {
                casperId: el.casperId || "",
                okaygoId: el.okaygoId || "",
                name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                // bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                InvoiceNo: el.billNumber || "",
                billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                okaygoCharges: el.okaygoCharges ? numberFormat(el.okaygoCharges) : "",
                paymentType: el.okaygoCharges !== null ? "Advance Payout" : "15 Day payout",
                // billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                TransactionDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                totalAmount: el.totalAmount ? numberFormat(el.totalAmount) : "",
                TransactionStatus: el.paymentStatus || "",
                TransactionMode: el.okaygoCharges ? "IMPS" : (el.paymentStatus ? "NEFT" : ""),
                TransactionRemarks: el.paymentRemarks || "",
                transactionId: el.transactionId || "",


            };

            if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") {
                delete temp["paymentStatus"]
                delete temp["paymentRemarks"]
            }
            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    console.log(localStorage.getItem("roleType"))
    // const createAllRecordExcel = () => {
    //     setAllRecordLoading(true)
    //     let requestedBy = ""
    //     if (localStorage.getItem("roleType") === "1") {
    //         requestedBy = `&requestedById=${localStorage.getItem("userID")}`
    //     }
    //     if (!tab.invoiceTab) {
    //         Axios.get(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListing}?pageRequest=0${requestedBy}${filterUrl}`)
    //             .then(response => {
    //                 if (response) {
    //                     if (!tab.invoiceTab) {

    //                         setAllRecord(makeAllRecordData(response.data.response))
    //                     } else {
    //                         setAllRecord(makeAllTransactionRecordData(response.data.response))

    //                     }
    //                 }
    //             })
    //     } else if (tab.invoiceTab) {
    //         Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListing}/transactions?pageRequest=0${requestedBy}${filterUrl}`)
    //             .then(response => {
    //                 if (response) {
    //                     if (!tab.invoiceTab) {

    //                         setAllRecord(makeAllRecordData(response.data.response))
    //                     } else {
    //                         setAllRecord(makeAllTransactionRecordData(response.data.response))

    //                     }
    //                 }
    //             })
    //     }
    //     // transactions
    // }
    // console.log({ allRecord })


    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdHub zIndex1080 header" :
                                    column.render('Header') === "Hub Name" ? "stickWorkerNameHub zIndex1080 header" :
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" :
                                            column.render('Header') === "Hub Address" ? "width270 header" :
                                                (column.id === "Mark onHold") ? (financialAdmin ? "" : "displayNone") : "header"}>


                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="row">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Hub Name" ? "stickWorkerNameHub zIndex540" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" :
                                                    (cell.column.Header === "Mark onHold") ? (financialAdmin ? "" : "displayNone") : "cell"}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    console.log(process.env)
    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid container xs={12}  >  <Grid><span className="fontSize24 fontWeightBold">Hub List</span>
            
                </Grid>

                </Grid>

                <Grid item xs={12} className="flexDisplay" alignItems='center'>
                    <Grid item sm={6} className="flexDisplay padding0 paddingTop8" direction="row" justify="space-between">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder={"Hub Name & City"}
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                    <Grid item sm={6} className="flexDisplay padding0" direction="row" justify="flex-start" style={{gap: "10px"}} alignItems='baseline'>
                        <FormControl variant="outlined" style={{minWidth: "100px"}} margin="dense">
                            <Select
                                id="sortBy"
                                IconComponent={ExpandMoreIcon}
                                value={sortBy}
                                style={{ opacity: sortBy === 0 ? "0.5" : "1" }}
                                onChange={event => { setSortBy(event.target.value) }}
                                className={classes.select}
                                inputProps={{
                                    name: 'Sort by:',
                                    id: 'sortBy',
                                }}
                            >
                                <MenuItem key="fitlerByPartner" value={0} disabled={true}>
                                    Filter by Partner
                                </MenuItem>
                                {partner.map(option => (

                                    <MenuItem key={option[0]} value={option[0]} >
                                        {option[1]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <button 
                            className={styles.uplaodHubButton} 
                            onClick={()=>{setOpenUploadModal(true)}}
                        >
                            Upload Hubs
                        </button>
                        {!loading && data.length !== 0 && <CSVLink
                            data={data}
                            filename={`hub listing.csv`}
                            className={styles.downloadExcel}
                            target='_blank'
                        >
                            Export Excel 
                        </CSVLink>}
                    </Grid>

                </Grid>
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
                {openUploadModal && <Upload
                 openOnboardingModal={openUploadModal}
                 setOpenOnboardingModal= {setOpenUploadModal}
                 showToast={showToast}
                 updateMyData={updateMyData}
                 data={data2}
                ></Upload>}

              
               

            </Grid>
        </Styles>
    )
}

export default ExternalWorker;


