import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Grid, Button, Tabs, Tab } from '@material-ui/core'
import style from './VerificationDetails.scss';
import './VerificationDetails.css';
import UrlLinks from '../../UrlLinks';
import { useParams } from 'react-router-dom'
import axios from 'axios';
// import { useLocation, useParams } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import VerifiedSVG from './verified15x15.svg'
import NotUploadSVG from './Not uploaded.svg'
import RejectedSVG from './Rejected.svg'
import FlagSVG from './Flag.svg'
import PendingSVG from './Pend.svg'
import moment from 'moment';
import swal from 'sweetalert';
import RejectModal from './ReasonModal';
import NoImg from './no-img.png';

import { Backdrop, CircularProgress } from '@mui/material';
import Decrypt from '../../Utils/decrypt';
function VerificationDetails(props) {
    const params = useParams()
    const { rowData, fn, bucket } = params

    console.log("check--->",{"rowDtata===>":rowData,"fn==>":fn,"bucket==>":bucket})

    //console.log(props)
    // let id =  rowData.userId
    // const { id } = useParams();
    const [data, setData] = useState({})
    const [openReasonModal, setOpenReasonModal] = useState(["", false])
    const [currentImage, setCurrentImage] = useState(0);
    const [mainImage, setMainImage] = useState("");
    const [currentPhoneNo, setCurrentPhoneNo] = useState(rowData);
    const [initialFilter, setInitialFilter] = useState(fn);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    console.log(currentPhoneNo);

    const handleNext = () => {
        setOpenBackDrop(true)
        axios.get(`${UrlLinks.baseUrl}${UrlLinks.verificationAPIs.verificationProfile}?currentPhoneNumber=${currentPhoneNo}&verificationStatus=${initialFilter}`)
            .then(response => {
                if (response.data.response.length === 0) {
                    swal("No more in " + bucket + " bucket", "", "warning");
                    setOpenBackDrop(false)

                } else {
                    setData(makeData(response.data.response))
                    setCurrentPhoneNo(response.data.response.user_id)
                    setOpenBackDrop(false)
                }

            })
    }

    const bringData = () => {
        setOpenBackDrop(true)
        axios.get(`${UrlLinks.baseUrl}${UrlLinks.verificationAPIs.verificationProfile}?phone_number=${currentPhoneNo}`)
            .then(response => {
                setData(makeData(response.data.response))
                setOpenBackDrop(false)

            })
    }
    const handleCloseBackDrop = () => {
        setOpenBackDrop(false);
    };

    useEffect(() => {
        if (!rowData) {
            props.history.push("/document-verification");
        } else {
            bringData()
        }
    }, [])
    const makeStatus = (status) => {
        switch (status) {
            case 0:
                return <div className='verificationStatusInHeader'><div style={{ paddingTop: "4px" }}><img src={NotUploadSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {'Not Filled'}</div></div>
            case 1:
                return <div className='verificationStatusInHeader'><div style={{ paddingTop: "4px" }}><img src={VerifiedSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {'Verfied'}</div></div>
            case 2:
                return <div className='verificationStatusInHeader'><div style={{ paddingTop: "4px" }}><img src={FlagSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {'Flagged'}</div></div>
            case 3:
                return <div className='verificationStatusInHeader'><div style={{ paddingTop: "4px" }}><img src={RejectedSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {'Rejected'}</div></div>
            case 4:
                return <div className='verificationStatusInHeader'><div style={{ paddingTop: "4px" }}><img src={PendingSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {'Pending'}</div></div>

            default:
                break;
        }

    }
    const updateVerificationStatus = (doc, status, reason) => {
        //approve -1
        // reject -3
        setOpenBackDrop(true)
        let otherParam = []
        switch (doc) {
            case "aadhar":
                otherParam = ['&docTypeId='+1867, '&aadharStatus=' + status];
                break;
            case "pan":
                otherParam = ['&docTypeId='+1866, '&panStatus=' + status];
                break;
            case "dl":
                otherParam = ['&docTypeId='+1870, '&dlStatus=' + status];
                break;
            case "profile":
                otherParam = ['', '&profilePicStatus=' + status];
                break;
            case "address":
                otherParam = ['&docTypeId='+1869, '&addressStatus=' + status];
                break;
            default:
                break;
        }
        const rejectReason = reason !== null ? "&doc_rejection_reason=" + reason : ""


        axios.post(`${UrlLinks.baseUrl}${UrlLinks.verificationAPIs.updateVerification}?verifiedBy=${localStorage.getItem("userID")}${otherParam[0]}${otherParam[1]}&phone_number=${currentPhoneNo}${rejectReason}`)
            .then(response => {
                // setData(makeData(response.data.response))
                bringData()
                setOpenBackDrop(false)

            })

    }
    const openImageViewer = useCallback((index) => {
        // console.log(index)
        // setCurrentImage(index);
        setMainImage(index)
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    console.log(data)
    const makeData = (res) => {
        let temp = {
            name: `${res.first_name ? res.first_name : ""} ${res.last_name ? res.last_name : ""}`,
            aadhar_dob_status: res.aadhar_dob_status !== null ? res.aadhar_dob_status : 0,
            aadhar_name_status: res.aadhar_name_status !== null ? res.aadhar_name_status : 0,
            aadhar_number: res.aadhar_number !== null ? res.aadhar_number : "",
            aadhar_verification_status: res.aadhar_verification_status !== null ? res.aadhar_verification_status : 0,
            aadhar_verification_status_show: res.aadhar_verification_status !== null ? makeStatus(res.aadhar_verification_status) : makeStatus(0),
            aadhar_verified_on: res.aadhar_verified_on !== null ? `${res.aadhar_verified_on !== null ? moment(res.aadhar_verified_on).format("h:mm a, DD MMM YY") : ""}` : "",
            aadhar_verified_by: res.aadhar_verified_by !== null ? `By ${res.aadhar_verified_by}` : null,
            address_image: res.address_image !== null ? res.address_image : "",
            address_status: res.address_status !== null ? res.address_status : 0,
            address_status_show: res.address_status !== null ? makeStatus(res.address_status) : makeStatus(0),
            address_verified_on: res.address_verified_on !== null ? `${res.address_verified_on !== null ? moment(res.address_verified_on).format("h:mm a, DD MMM YY") : ""}` : "",
            address_verified_by: res.address_verified_by !== null ? `By ${res.address_verified_by}` : null,
            cur_address: res.cur_address !== null ? res.cur_address : "",
            back_image_aadhar: res.back_image_aadhar !== null ? res.back_image_aadhar : "",
            dl_dob_status: res.dl_dob_status !== null ? res.dl_dob_status : 0,
            dl_name_status: res.dl_name_status !== null ? res.dl_name_status : 0,
            dl_number: res.dl_number !== null ? res.dl_number : "",
            dl_number_unverified: res.dl_number_unverified !== null ? res.dl_number_unverified : "",
            dl_type: res.dl_type !== null ? res.dl_type : "",
            dl_validity_status: res.dl_validity_status !== null ? res.dl_validity_status : 0,
            dl_verification_status: res.dl_verification_status !== null ? res.dl_verification_status : 0,
            dl_verification_status_show: res.dl_verification_status !== null ? makeStatus(res.dl_verification_status) : makeStatus(0),
            dl_verified_on: res.dl_verified_on !== null ? `${res.dl_verified_on !== null ? moment(res.dl_verified_on).format("h:mm a, DD MMM YY") : ""}` : "",
            dl_verified_by: res.dl_verified_by !== null ? `By ${res.dl_verified_by}` : null,
            dl_vlidity: res.dl_validity !== null ? res.dl_validity : "",
            dob_on_aadhar: res.dob_on_aadhar !== null ? res.dob_on_aadhar : "",
            dob_on_dl: res.dob_on_dl !== null ? res.dob_on_dl : "",
            dob_on_pan: res.dob_on_pan !== null ? res.dob_on_pan : "",
            fathername_on_aadhar: res.fathername_on_aadhar !== null ? res.fathername_on_aadhar : "",
            fathername_on_pan: res.fathername_on_pan !== null ? res.fathername_on_pan : "",
            front_image_aadhar: res.front_image_aadhar !== null ? res.front_image_aadhar : "",
            gurdianname_on_dl: res.gurdianname_on_dl !== null ? res.gurdianname_on_dl : "",
            image_dl: res.image_dl !== null ? res.image_dl : "",
            image_pan: res.image_pan !== null ? res.image_pan : "",
            lead_id: res.lead_id !== null ? res.lead_id : "",
            name_on_aadhar: res.name_on_aadhar !== null ? res.name_on_aadhar : "",
            name_on_dl: res.name_on_dl !== null ? res.name_on_dl : "",
            name_on_pan: res.name_on_pan !== null ? res.name_on_pan : "",
            okaygo_id: res.okaygo_id !== null ? res.okaygo_id : "",
            pan_dob_status: res.pan_dob_status !== null ? res.pan_dob_status : 0,
            pan_name_status: res.pan_name_status !== null ? res.pan_name_status : 0,
            pan_number: res.pan_number !== null ? res.pan_number : "",
            pan_number_unverified: res.pan_number_unverified !== null ? res.pan_number_unverified : "",
            pan_verification_status: res.pan_verification_status !== null ? res.pan_verification_status : 0,
            pan_verification_status_show: res.pan_verification_status !== null ? makeStatus(res.pan_verification_status) : makeStatus(0),
            pan_verified_on: res.pan_verified_on !== null ? `${res.pan_verified_on !== null ? moment(res.pan_verified_on).format("h:mm a, DD MMM YY") : ""}` : "",
            pan_verified_by: res.pan_verified_by !== null ? `By ${res.pan_verified_by}` : null,
            phone_number: res.phone_number !== null ? res.phone_number : "",
            profile_pic: res.profile_pic !== null ? res.profile_pic : "",
            profile_status: res.profile_status !== null ? res.profile_status : 0,
            profile_status_show: res.profile_status !== null ? makeStatus(res.profile_status) : makeStatus(0),
            profile_pic_verified_on: res.profile_pic_verified_on !== null ? `${res.profile_pic_verified_on !== null ? moment(res.profile_pic_verified_on).format("h:mm a, DD MMM YY") : ""}` : "",
            profile_verified_by: res.profile_verified_by !== null ? `By ${res.profile_verified_by}` : null,

            tag: res.tag !== null ? res.tag : "",
            user_id: res.user_id !== null ? res.user_id : "",

            pan_rejected_reason: res.pan_rejected_reason !== null ? res.pan_rejected_reason : null,
            aadhar_rejected_reason: res.aadhar_rejected_reason !== null ? res.aadhar_rejected_reason : null,
            dl_rejected_reason: res.dl_rejected_reason !== null ? res.dl_rejected_reason : null,
            profile_pic_rejected_reason: res.profile_pic_rejected_reason !== null ? res.profile_pic_rejected_reason : null,
            address_rejected_reason: res.address_rejected_reason !== null ? res.address_rejected_reason : null,
        }

        return temp;
    }
    console.log(data.aadhar_verified_by)
    return (
        // <div style={{padding: "50px 40px"}}>
        <>
            <div className='buttionDiv'> <button
                className="nextButton1"
                onClick={handleNext}
            >
                {`Next Record >`}
            </button></div>
            <Grid container className="flexDisplay" direction="column" style={{ padding: "30px 40px" }}>
                <Grid item xs={12} className="flexDisplay height40" direction="row" style={{ backgroundColor: "white", padding: "20px 20px 10px 20px", marginBottom: "10px" }}>
                    <Grid item xs={4}>
                        <span>Name -</span>
                        <span className="fontWeightBold"> {data.name}</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>OkayGo ID -</span>
                        <span className="fontWeightBold"> {data.okaygo_id}</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>Phone No -</span>
                        <span className="fontWeightBold"> {Decrypt(data.phone_number)}</span>
                    </Grid>
                </Grid>
                <div fullWidth style={{ backgroundColor: "#F8F8F8", padding: "15px 30px", fontWeight: "600", display: "flex" }}>Pan Card Details {data.pan_verification_status_show} <span style={{ fontWeight: "400", color: "#767676", marginLeft: '12px' }}>{data.pan_verified_by}</span> &nbsp;<span style={{ fontWeight: "400", color: "#1C75BC" }}>{data.pan_verified_on ? `  on ` + data.pan_verified_on : ''}</span></div>
                <Grid item xs={12} className="flexDisplay height40" direction="row" style={{ backgroundColor: "white", padding: "0 30px" }}>
                    <Grid item xs={6}>
                        <div className='outerDiv'><table>
                            <tbody>
                                <tr>
                                    <td className="removeBorders" style={{ width: "260px" }} >Pan Number</td>
                                    <td className="removeBorders" >:</td>
                                    <td className="removeBorders" >{Decrypt(data.pan_number)}</td>

                                </tr>
                                <tr>
                                    <td className='width260 removeBorders'>Name (as per PAN)</td>
                                    <td className="removeBorders" >:</td>
                                    <td className="removeBorders" ><span style={{ color: `${data.pan_name_status === 2 ? `#FF6363` : ``}` }}>{data.name_on_pan}</span></td>

                                </tr>
                                <tr>
                                    <td className='width260 removeBorders'>DOB</td>
                                    <td className="removeBorders" >:</td>
                                    <td className="removeBorders" ><span style={{ color: `${data.pan_dob_status === 2 ? `#FF6363` : ``}` }}>{data.dob_on_pan}</span></td>

                                </tr>
                                <tr>
                                    <td className='width260 removeBorders'>Father's Name</td>
                                    <td className="removeBorders" >:</td>
                                    <td className="removeBorders" >{data.fathername_on_pan}</td>

                                </tr>

                                {data.pan_rejected_reason ? <tr>
                                    <td className='width260 removeBorders'>Reason</td>
                                    <td className="removeBorders" >:</td>
                                    <td className="removeBorders" >{data.pan_rejected_reason}</td>

                                </tr> : null}
                            </tbody>
                        </table></div>
                        {data.pan_verification_status === 2 && <div className='buttonMargin'>
                            <Button
                                style={{ marginLeft: '12px', width: "150px" }}
                                variant="outlined"
                                onClick={() => { setOpenReasonModal(["pan", true]) }}

                            // className="dataButton"
                            >
                                Reject
                            </Button>
                            <Button
                                style={{ marginLeft: '60px', width: "150px" }}
                                variant="contained"
                                className="dataButton"
                                onClick={() => { updateVerificationStatus("pan", 1, null) }}
                            >
                                Approve
                            </Button>
                        </div>}
                    </Grid>
                    <Grid item xs={6}>

                        <div style={{ margin: "34px" }}>
                            <img
                            crossorigin="anonymous"
                                // src={data.image_pan}
                                // onClick={() => openImageViewer(data.image_pan)}
                                src={data.image_pan !== "" ? data.image_pan : NoImg}

                                onClick={() => {
                                    if (data.image_pan !== "") {
                                        openImageViewer(data.image_pan)
                                    } else {
                                        // DO NOTHING
                                    }
                                }
                            }
                                // width="500"
                                key={0}
                                className="imgVerifictaionDL"
                                // style={{ margin: '2px' }}
                                alt=""
                            />

                        </div>
                    </Grid>
                </Grid>
                <div fullWidth style={{ backgroundColor: "#F8F8F8", padding: "15px 30px", fontWeight: "600", display: "flex" }}>Aadhaar Card Details {data.aadhar_verification_status_show} <span style={{ fontWeight: "400", color: "#767676", marginLeft: '12px' }}>{data.aadhar_verified_by}</span> &nbsp;<span style={{ fontWeight: "400", color: "#1C75BC" }}>{data.aadhar_verified_on ? `  on ` + data.aadhar_verified_on : ''}</span></div>
                <Grid item xs={12} className="flexDisplay height40" direction="row" style={{ backgroundColor: "white", padding: "0 30px" }}>
                    <Grid item xs={6}>
                        <div className='outerDiv'><table>
                            <tbody>
                                <tr>
                                    <td className="removeBorders" style={{ width: "260px" }}>Aadhaar Number</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders">{Decrypt(data.aadhar_number)}</td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders" >Name (as per Aadhaar)</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders"><span style={{ color: `${data.aadhar_name_status === 2 ? `#FF6363` : ``}` }}>{data.name_on_aadhar}</span></td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders">DOB</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders"><span style={{ color: `${data.aadhar_dob_status === 2 ? `#FF6363` : ``}` }}>{data.dob_on_aadhar}</span></td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders">Father's Name</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders">{data.fathername_on_aadhar}</td>

                                </tr>
                                {data.aadhar_rejected_reason ? <tr>
                                    <td className="removeBorders width260 removeBorders">Reason</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders">{data.aadhar_rejected_reason}</td>

                                </tr> : null}
                            </tbody>
                        </table></div>
                        {data.aadhar_verification_status === 2 &&
                            <div className='buttonMargin'>
                                <Button
                                    style={{ marginLeft: '12px', width: "150px" }}
                                    variant="outlined"
                                    // className="dataButton"
                                    onClick={() => { setOpenReasonModal(["aadhar", true]) }}
                                >
                                    Reject
                                </Button>
                                <Button
                                    style={{ marginLeft: '60px', width: "150px" }}
                                    variant="contained"
                                    className="dataButton"
                                    // onClick={handleActions}
                                    onClick={() => { updateVerificationStatus("aadhar", 1, null) }}
                                >
                                    Approve
                                </Button>
                            </div>}
                    </Grid>
                    <Grid item xs={6}>

                        <div className='adhaarDiv'>
                            <img
                                // src={data.front_image_aadhar}
                                // onClick={() => openImageViewer(data.front_image_aadhar)}
                                // width="300"
                                className="imgVerifictaionAadhar"
                                src={data.front_image_aadhar !== "" ? data.front_image_aadhar : NoImg}

                                onClick={() => {
                                    if (data.front_image_aadhar !== "") {
                                        openImageViewer(data.front_image_aadhar)
                                    } else {
                                        // DO NOTHING
                                    }
                                }
                            }
                                key={0}
                                // style={{ margin: '2px' }}
                                alt=""
                            />
                            <img
                                // src={data.back_image_aadhar}
                                // onClick={() => openImageViewer(data.back_image_aadhar)}
                                src={data.back_image_aadhar !== "" ? data.back_image_aadhar : NoImg}

                                onClick={() => {
                                    if (data.back_image_aadhar !== "") {
                                        openImageViewer(data.back_image_aadhar)
                                    } else {
                                        // DO NOTHING
                                    }
                                }
                            }
                                // width="300"
                                className="imgVerifictaionAadhar"
                                key={0}
                                // style={{ margin: '2px' }}
                                alt=""
                            />

                        </div>
                    </Grid>
                </Grid>
                <div fullWidth style={{ backgroundColor: "#F8F8F8", padding: "15px 30px", fontWeight: "600", display: "flex" }}>Driving License {data.dl_verification_status_show} <span style={{ fontWeight: "400", color: "#767676", marginLeft: '12px' }}>{data.dl_verified_by}</span> &nbsp;<span style={{ fontWeight: "400", color: "#1C75BC" }}>{data.dl_verified_on ? `  on ` + data.dl_verified_on : ''}</span></div>
                <Grid item xs={12} className="flexDisplay height40" direction="row" style={{ backgroundColor: "white", padding: "0 30px" }}>
                    <Grid item xs={6}>
                        <div className='outerDiv'><table>
                            <tbody>
                                <tr>
                                    <td className="removeBorders" style={{ width: "260px" }}>DL Number</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders">{Decrypt(data.dl_number_unverified)}</td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders">Name (as per DL)</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders"><span style={{ color: `${data.dl_name_status === 2 ? `#FF6363` : ``}` }}>{data.name_on_dl}</span></td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders">DOB</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders"><span style={{ color: `${data.dl_dob_status === 2 ? `#FF6363` : ``}` }}>{data.dob_on_dl}</span></td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders">DL Type</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders">{data.dl_type}</td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders">Validity Date</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders"><span style={{ color: `${data.dl_validity_status === 2 ? `#FF6363` : ``}` }}>{data.dl_vlidity}</span></td>

                                </tr>
                                <tr>
                                    <td className="removeBorders width260 removeBorders">Guardian Name</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders">{data.gurdianname_on_dl}</td>

                                </tr>

                                {data.dl_rejected_reason ? <tr>
                                    <td className="removeBorders width260 removeBorders">Reason</td>
                                    <td className="removeBorders">:</td>
                                    <td className="removeBorders">{data.dl_rejected_reason}</td>

                                </tr> : null}
                            </tbody>
                        </table></div>
                        {data.dl_verification_status === 2 &&
                            <div className='buttonMargin2'>
                                <Button
                                    style={{ marginLeft: '12px', width: "150px" }}
                                    variant="outlined"
                                    // className="dataButton"
                                    onClick={() => { setOpenReasonModal(["dl", true]) }}

                                >
                                    Reject
                                </Button>
                                <Button
                                    style={{ marginLeft: '60px', width: "150px" }}
                                    variant="contained"
                                    className="dataButton"
                                    onClick={() => { updateVerificationStatus("dl", 1, null) }}

                                // onClick={handleActions}
                                >
                                    Approve
                                </Button>
                            </div>}
                    </Grid>
                    <Grid item xs={6}>

                        <div style={{ margin: "34px" }}>
                            <img

                                // src={data.image_dl}
                                className="imgVerifictaionDL"
                                // onClick={() => openImageViewer(data.image_dl)}
                                
                                src={data.image_dl !== "" ? data.image_dl : NoImg}

                                onClick={() => {
                                    if (data.image_dl !== "") {
                                        openImageViewer(data.image_dl)
                                    } else {
                                        // DO NOTHING
                                    }
                                }
                            }
                                // width="500"
                                key={0}
                                // style={{ margin: '2px' }}
                                alt=""
                            />

                        </div>
                    </Grid>
                </Grid>
                <Grid container columns={12}>
                    <Grid item xs={4} container className="flexDisplay height40" direction="row">
                        <Grid item xs={12} style={{ backgroundColor: "#F8F8F8", padding: "15px 30px", fontWeight: "600", display: "flex" }}>Photo {data.profile_status_show} <span style={{ fontWeight: "400", color: "#767676", marginLeft: '12px' }}>{data.profile_verified_by}</span> &nbsp;<span style={{ fontWeight: "400", color: "#1C75BC" }}>{data.profile_pic_verified_on ? `  on ` + data.profile_pic_verified_on : ''}</span> </Grid>
                        <Grid item xs={12} style={{ backgroundColor: "white", padding: "30px 0", height: "320px" }}>
                            <div style={{ margin: "20px" }}>
                                <img
                                    className="imgVerifictaionProfile"
                                    // src={data.profile_pic}
                                    // src={data.profile_pic}
                                    src={data.profile_pic !== "" ? data.profile_pic : NoImg}

                                    onClick={() => {
                                        if (data.profile_pic !== "") {
                                            openImageViewer(data.profile_pic)
                                        } else {
                                            // DO NOTHING
                                        }
                                    }
                                }
                                // onClick={() => openImageViewer(data.profile_pic)}
                                    // width="250"
                                    key={0}
                                    // style={{ marginLeft: '95px' }}
                                    alt=""
                                />

                            </div>
                            {data.profile_pic_rejected_reason != null && <div className='profileReason'><b>Reason</b> : {data.profile_pic_rejected_reason}</div>}
                            {/* <Grid item sx={12} style={{ backgroundColor: "#ECECEC", margin: "0 auto 30px auto", width: "50%", height: "150px" }}>image</Grid> */}
                            <Grid item sx={12} style={{ margin: "auto", width: "50%", display: "flex", justifyContent: "space-between" }}>
                                {data.profile_status === 4 && <> <Button
                                    variant="outlined"
                                    onClick={() => { setOpenReasonModal(["profile", true]) }}

                                // className="dataButton"
                                >
                                    Reject
                                </Button>
                                    <Button
                                        variant="contained"
                                        className="dataButton"
                                        // onClick={handleActions}
                                        onClick={() => { updateVerificationStatus("profile", 1, null) }}

                                    >
                                        Approve
                                    </Button></>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} container className="flexDisplay height40" direction="row">
                        <Grid item xs={12} style={{ backgroundColor: "#F8F8F8", padding: "15px 30px", fontWeight: "600", display: "flex" }}>Current Address {data.address_status_show}  <span style={{ fontWeight: "400", color: "#767676", marginLeft: '12px' }}>{data.address_verified_by}</span> &nbsp;<span style={{ fontWeight: "400", color: "#1C75BC" }}>{data.address_verified_on ? `  on ` + data.address_verified_on : ''}</span> </Grid>
                        <Grid item xs={12} style={{ backgroundColor: "white", paddingTop: "30px", height: "320px" }}>
                            <table className={`${style.table}`}>
                                {/* <Divider orientation="vertical"></Divider> */}
                                <tbody>
                                    <tr>
                                        <td className="removeBorders" style={{ width: "295px" }}><div style={{ marginLeft: "20px" }}>Address (entered by the user)</div></td>
                                        <td className="removeBorders"><div>:  {data.cur_address}</div></td>
                                    </tr>
                                    <tr>
                                        <td className="removeBorders" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", position: "relative", height: "150px", }}>
                                            <div style={{ marginLeft: "20px" }}>Address (Uploaded by the user)</div>
                                            <Grid style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", width: "90%", position: "absolute", bottom: "0px" }}>
                                                {data.address_status === 4 && <>
                                                    <Button
                                                        style={{ marginRight: '12px' }}
                                                        variant="outlined"
                                                        onClick={() => { setOpenReasonModal(["address", true]) }}

                                                    >
                                                        Reject
                                                    </Button>
                                                    <Button
                                                        style={{ marginRight: '12px' }}
                                                        variant="contained"
                                                        className="dataButton"
                                                        // onClick={handleActions}
                                                        onClick={() => { updateVerificationStatus("address", 1, null) }}

                                                    >
                                                        Approve
                                                    </Button>
                                                </>}
                                            </Grid>
                                        </td>
                                        <td className="removeBorders">
                                            <div style={{}}>
                                                <img
                                                    className="imgVerifictaionAddress"
                                                    // src={NoImg}/
                                                    src={data.address_image !== "" ? data.address_image : NoImg}
                                                    // src={"https://picsum.photos/seed/picsum/200/300"}
                                                    onClick={() => {
                                                        if (data.address_image !== "") {
                                                            openImageViewer(data.address_image)
                                                        } else {
                                                            // DO NOTHING
                                                        }
                                                    }
                                                    }
                                                    // onClick={() => openImageViewer("https://picsum.photos/seed/picsum/200/300")}
                                                    // width="250"
                                                    key={0}
                                                    // style={{ marginLeft: '95px' }}
                                                    alt=""
                                                />

                                            </div>
                                            {/* <Grid item sx={12} style={{ backgroundColor: "#ECECEC", margin: "0 auto 30px auto", width: "50%", height: "150px" }}>image</Grid> */}
                                        </td>
                                    </tr>
                                    {data.address_rejected_reason ? <tr>
                                        {/* {true?<tr> */}
                                        <td className="removeBorders" ><div style={{ marginLeft: "20px" }}><b>Reason</b></div></td>
                                        <td className="removeBorders"><div>:  {data.address_rejected_reason}</div></td>
                                    </tr> : null}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Grid>
                {isViewerOpen && (
                    <ImageViewer
                        src={[mainImage]}
                        currentIndex={0}
                        disableScroll
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                )}


            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {openReasonModal[1] && <RejectModal
                open={openReasonModal[1]}
                doc={openReasonModal[0]}
                setOpen={setOpenReasonModal}
                updateVerificationStatus={updateVerificationStatus}

            ></RejectModal>}
            <div className='endPage'></div>
        </>
    )
}

export default VerificationDetails
