import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
//import { Divider , Badge } from '@material-ui/core';
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel, Switch, Typography, Divider, OutlinedInput, ListItemText } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import swal from 'sweetalert2'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Axios from 'axios'
import Modal from '@material-ui/core/Modal';
import OnboardingModal from '../WorkerData/components/onboardingModal'
import OnboardingModalV2 from './components/onboardingModalV2'
import GroupFlexModal from '../WorkerData/components/GroupFlexModal'
import CasperOnboardingModal from '../WorkerData/components/CasperOnboardingModal'
import OffboardingModal from '../WorkerData/components/offboardingModal'
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
// import MenuItem from "@material-ui/core/MenuItem";
import { SelectChangeEvent } from '@mui/material/Select';
import { numberFormat } from '../../Utils/CommonFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import filter from '../../assets/filter.svg';
//import Filters from './FilterBase';
import Filters from './filterModal';
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import Calendar from '../../assets/calendar.svg'
import style from './index.module.scss';
import { Link } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createVerificationStatus } from '../../services/CommonServices'
import { AuthContext } from '../../services/AuthProvider';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import GenerateInvoiceModal from './components/GenerateInvoiceModal'
import { ToastContainer } from 'react-toastify'
import SendMsg from './components/SendMsg'
import LastSentMsgs from '../WorkerData/components/LastSentMsgs'
import { number } from 'yup'
import { InboxOutlined } from '@material-ui/icons'
import { display } from '@mui/system'
import ConfirmTriggerPaymentModal from './ConfirmTriggerPaymentModal'
import Multiselect from 'multiselect-react-dropdown'
import Demo from './demo.tsx'
import RejectModal from './RejectModal'
import OnHoldRemarkModal from './OnHoldRemarkModal'
import CancelModal from './CancelModal'
import WarningIcon from '@mui/icons-material/Warning';
import './indeterminateCheckbox.css'
import Decrypt from '../../Utils/decrypt'

const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    menuPaper: {
        // maxHeight: 1000
        height: 250
    },
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }

    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "80px 100px"
        }
    },

}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} />
            </React.Fragment>
        )
    }
)
const ExternalWorker = () => {
    // type MyType = string[];
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataBalnce, setDataBalance] = useState();
    const [actions, setActions] = useState(0)
    const { showToast } = useContext(AuthContext);
    const [openSendMsg, setOpenSendMsg] = useState(false)
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRecord, setAllRecord] = useState([]);
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false)
    const [openCasperOnboardingModal, setOpenCasperOnboardingModal] = useState(false)
    const [disablePayments, setDisablePayments] = useState(true);
    // const [selectedRows, setSelectedRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);
    const [sortDirectionUrl, setSortDirectionUrl] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [searchByUrl, setSearchByUrl] = useState("")
    const [sortUrl, setSortUrl] = useState("");
    const [financialAdmin, setFinancialAdmin] = useState(false)
    const [filtersUrl, setFiltersUrl] = useState("")
    const [filterDrawer, setFilterDrawer] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState("")
    const [selectedOGIds, setSelectedOGIds] = useState("")
    const [totalNetPayableAmount, setTotalNetPayableAmount] = useState("")
    const [searchByAuto, setsearchByAuto] = useState("");
    const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false)
    const [openTriggerPaymentPopup, setOpenTriggerPaymentPopup] = useState([false, false]);
    const [openRejectInvoicePopup, setOpenRejectInvoicePopup] = useState(false);
    const [openOnHoldPopup, setOpenOnHoldPopup] = useState(false);
    const [partner, setPartner] = useState([])
    const [partnerFilter, setPartnerFilter] = useState({})
    const [counter, setCounter] = useState(0)
    // const [disFilter, setDisFilter] = React.useState<string[]>([]);
    const [disFilter, setDisFilter] = React.useState([]);
    const [disFilterCount, setDisFilterCount] = React.useState('Discrepancies Cases');
    const [disFilterUrl, setDisFilterUrl] = React.useState('');
    const [clearFilter, setClearFilter] = React.useState(false);
    const [mainFilterOption, setMainFilterOption] = useState(-1);
    const [actionOption, setActionOption] = useState(0);
    const [searchError, setSearchError] = React.useState(false);

    const [tab, setTab] = useState({
        invoiceTab: false,
        transactionTab: true
    })
    useEffect(() => {
        ApplyFilters(filters);
        // ApplySort();
        // setSortDirection(false);
        // setSortDirectionUrl('');
    }, [searchByAuto]);
    const [filters, setFilters] = useState({
        searchBy: "",
        billDate: [null, null],
        zone: "",
        facility_state: "",
        facility_name: "",
        payment_status: "",
        payment_stage: null,
        worker_status: "",
        worker_type: "",
        payment_type: "",
        advanceTaken: "",
        bankDetails: "",
        bankDetailsVerified: "",
        panStatus: "",
        modalFlag: "",
        partnerId: "",
        clientId: "",
        verification_type: ""
    });

    const sortOptions = [
        ["101", "Swiggy"],
        ["101", "Flipkart"]
    ];
    const ApplySort = () => {
        let url = '';

        if (sortBy !== 0)
            setSortUrl('&partnerId=' + sortBy);
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false);
        setSortDirectionUrl('');
        // // console.log({ sortBy })
    }, [sortBy]);
    const handleSearch = () => {
        // // console.log(searchBy.split(',').length)
        // if(searchBy.split(',').length>5){
        //     setSearchError(true)
        // }
        // else if(searchBy.split(',').length<=5){
        //     setSearchError(false)
        // }
        setSearchByUrl(`&searchBy=${searchBy}`)
        setCurrentPage(1)
        // ApplyFilters(filters);
    }
    // // console.log(filters)
    const handleSearchClose = () => {
        setSearchBy("");
        setSearchByUrl("");
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.getPartner}`)
            .then(response => {
                // // console.log(response.data.response)
                let a = []
                response.data.response.map(e => {
                    a.push([e.partner_id, e.company_name])
                })
                setPartner(a)

                // partnersArray=response.data.response;

            })
        Axios.get(`${urlLinks.baseUrl}${urlLinks.paymentUrls.financeAdmin}${localStorage.getItem('phoneNumber')}`)
            .then(response => {
                if (response.data.financeAdmin === 1) {
                    setFinancialAdmin(true)
                }
                else {
                    setFinancialAdmin(false)
                }

            })
            .catch(error => {
                showToast("error", error.message)

            })
    }, [])

    // useEffect(() => {
    //     Axios.get(`${urlLinks.baseUrl}${localStorage.getItem("asaanRole")?'advance/getAJAccountBalance':'advance/getAccountBalance'}`)
    //         .then(response => {

    //             setDataBalance(response.data.response)

    //         })
    //         .catch(error => {
    //             showToast("error", error.message)

    //         })
    // }, [])
    // localStorage.getItem("asaanRole")
    const midFunction = (prop) => {
        // console.log("I WAS CALLED")
        ApplyFilters(prop);
    }
    const ApplyFilters = () => {
        let url = '';
        if (filters["searchBy"])
        url = `${url}&searchBy=${filters["searchBy"]}`;
        if (filters["billDate"][0] && !filters["billDate"][1])
        url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["billDate"][0] && filters["billDate"][1])
        url = `${url}&billFrom=2019-01-01&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["billDate"][0] && filters["billDate"][1])
        url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["zone"]) {
            url = `${url}&zone=${filters["zone"]}`
        }
        if (filters["facility_state"]) {
            url = `${url}&facility_state=${filters["facility_state"].name}`
            console.log(url);
        }
        if (filters["payment_stage"] !== undefined && filters["payment_stage"] !== null) {
            url = `${url}&payment_stage=${filters["payment_stage"]}`
        }
        if (filters["facility_name"]) {
            url = `${url}&facility_name=${filters["facility_name"].name}`
        }
        if (filters["payment_status"]) {
            url = `${url}&payment_status=${filters["payment_status"]}`
        }
        if (filters["worker_status"]) {
            url = `${url}&worker_status=${filters["worker_status"]}`
        }
        if (filters["worker_type"] || filters["worker_type"] === 0) {
            url = `${url}&isGold=${filters["worker_type"]}`
        }
        if (filters["payment_type"]) {
            url = `${url}&paymentType=${filters["payment_type"]}`
        }
        if (filters["advanceTaken"] || filters["advanceTaken"] === 0) {
            url = `${url}&advanceTaken=${filters["advanceTaken"]}`
        }
        if (filters["bankDetails"] || filters["bankDetails"] === 0) {
            url = `${url}&bank_details=${filters["bankDetails"]}`
        }
        if (filters["bankDetailsVerified"] || filters["bankDetailsVerified"] === 0) {
            url = `${url}&verification_status=${filters["bankDetailsVerified"]}`
        }
        if (filters["panStatus"] || filters["panStatus"] === 0) {
            url = `${url}&isPanVerified=${filters["panStatus"]}`
        }
        if (filters["modalFlag"] || filters["modalFlag"] === 0) {
            url = `${url}&is_lma_worker=${filters["modalFlag"]}`
        }
        if (filters["partnerId"] || filters["partnerId"] === 0) {
            url = `${url}&partnerId=${filters["partnerId"]}`
        }
        if (filters["clientId"]) {
            url = `${url}&partnerId=${filters["clientId"]}`
        }
        if (filters["verification_type"]) {
            if(filters["verification_type"]==0 )
            {
                url=`${url}&verificationType=${filters["verification_type"]}&verificationSubType=0`
            }
            else if(filters["verification_type"]==6)
            {
                url=`${url}&verificationType=0&verificationSubType=1`
            }
            else
            {
                url = `${url}&verificationType=${filters["verification_type"]}`
            }
        }
        
        // payment_type:"",
        // worker_type:""
        // // console.log({ url })
        setFiltersUrl(url)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            billDate: [null, null],
            zone: "",
            facility_state: "",
            facility_Name: "",
            payment_status: "",
            worker_status: "",
            worker_type: "",
            payment_stage: null,
            payment_type: "",
            advanceTaken: "",
            bankDetails: "",
            bankDetailsVerified: "",
            panStatus: "",
            modalFlag: "",
            partnerId: "",
            clientId: "",
            verification_type: ""
        }
        setFilters(filterList)
        setFiltersUrl('')
        setIsFilterApplied(false)
        setCurrentPage(1)
        return filterList;
    }
    useEffect(() => {
        // console.log({ filtersUrl })
    }, [filtersUrl])
    useEffect(() => {
        ApplyFilters();
        // console.log({ filters })
    }, [filters])

    const handleActions = () => {
        setOpenOnboardingModalV2(true)
        // if (event.target.value === "uploadWorkerData" /* &&(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6")*/) {
        //     // new onboarding Model v2

        // }
        // else if (event.target.value === "uploadWorkerData") {
        //     setOpenOnboardingModal(true)
        // }
        // else if (event.target.value === "uploadOffboardingData") {
        //     setOpenOffboardingModal(true)
        // }
        // else if (event.target.value === "UploadCasperID") {
        //     setOpenCasperOnboardingModal(true);
        // }

    }
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }
    const handleOnHold = (onHold, remark) => {
        let InvoiceId = makeInvoiceIdsList()
        if (onHold) {
            // // console.log("API 1")
            Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentunHold}?requested_by=${localStorage.getItem("userID")}&invoice_ids=${InvoiceId}${remark ? '&remark=' + remark : ""}`)
                .then(response => {
                    if (response) {
                        // showToast("success", "Payment status updated")
                        // // console.log({ filtersUrl })
                        swal.fire({
                            title: `Successfully ${makePaymentStage(101)}`,
                            icon: 'success',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Okay'
                        })
                        updateMyData()
                    }
                })
                .catch(error => {
                    showToast("error", error.message);
                })
        }
        else {
            // // console.log("API 2")
            Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentOnHold}?requested_by=${localStorage.getItem("userID")}&invoice_ids=${InvoiceId}${remark ? '&remark=' + remark : ""}`)
                .then(response => {
                    if (response) {
                        // showToast("success", "Payment status updated")
                        swal.fire({
                            title: `Successfully ${makePaymentStage(10)}`,
                            icon: 'success',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Okay'
                        })
                        updateMyData()
                    }
                })
                .catch(error => {
                    showToast("error", error.message)
                })
        }
    }

    const columnsOkayGo = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => {
                    // return (
                    //     <>{
                    //         ((mainFilterOption === 1 || mainFilterOption === 8) && (localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "26")) ?

                    //             <div>{!(row.original.queued !== 0 || !row.original.isInvoiceCreated) && <div>
                    //                 <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.queued !== 0 || !row.original.isInvoiceCreated) ? {} : { color: "#1c75bc" }} disabled={row.original.queued !== 0 || !row.original.isInvoiceCreated} />
                    //             </div>}</div>

                    //             : <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{ color: "#1c75bc" }} />}</>
                    // )
                    return (
                        <>
                            {
                                ((mainFilterOption === 1 || mainFilterOption === 8) && (localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "26")) ?

                                    <div>{row.original.invoiceFilePath !== "" && <div>
                                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.invoiceFilePath === "") ? {} : { display:"flex", justifyItems:"center" }} disabled={row.original.invoiceFilePath === ""} />
                                    </div>}</div>

                                    : <div><IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{ display:"flex", justifyItems:"center" }} /></div>}
                        </>
                    )

                },
            },
            // <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{ color: "#1c75bc" }} />
            {
                Header: "Client id(s)",
                Cell: row => {
                    return (
                        <div>
                            {/* // {row.row.original.casperId && <div><b>Flipkart: </b>{row.row.original.casperId}</div>}
                      {<br/> }
                      {row.row.original.swiggyId && <div><b>Swiggy: </b>{row.row.original.swiggyId}</div>}
                         {row.row.original.delhiveryId && <div><b>Delhivery: </b>{row.row.original.delhiveryId}</div>}
                         {row.row.original.jiomartId && <div><b>JioMart: </b>{row.row.original.jiomartId}</div>}
                        <br/> */}
                            {row.row.original.clientId && <div><b>{row.row.original.source} </b>{row.row.original.clientId}</div>}
                        </div>
                    );
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client",
                accessor: "source",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Mark onHold",
            //     Cell: row => {
            //         return (row.row.original.paymentStatus === "on hold" || row.row.original.paymentStatus === "") &&
            //             // <Grid container className={styles.HoldSwitchContainer}>
            //             //     <button 
            //             //         className={`${row.row.original.paymentStatus === "on hold"?styles.onHold:""} ${styles.holdButton}`} 
            //             //         onClick={() => handleOnHold(row.row.original.paymentStatus === "on hold", row.row.original.invoiceId)}
            //             //         >
            //             //             {row.row.original.paymentStatus === "on hold"?"UnHold":"Hold"}
            //             //     </button>
            //             // </Grid>
            //             <FormControlLabel
            //                 control={<Switch
            //                     checked={row.row.original.paymentStatus === "on hold"}
            //                     onClick={() => handleOnHold(row.row.original.paymentStatus === "on hold", row.row.original.invoiceId)}
            //                     color="primary"
            //                 />}
            //                 label="onHold"
            //             />


            //     },
            //     // defaultCanFilter: false,
            //     // defaultCanSort: false
            // },
            {
                Header: "Invoice Status",
                accessor: "payment_stage",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Remark",
                accessor: "remark",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Modal",
                accessor: "lmaEflexGroupFlex",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Name",
                accessor: "name",
                // Cell: row =>{
                //     return <Link to={{pathname:`/workerview/${row.row.original.userId}`}}   className="nameLink">{row.row.original.name}</Link>
                // },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Last SMS sent",
                // accessor: "zone",
                Cell: LastSentMsgs,

                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank Details filled",
                accessor: "bankDetailsFilled",
                defaultCanFilter: false,
                defaultCanSort: false
            },


            {
                Header: "Bank details verification",
                accessor: "pennyStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Invoice From",
                accessor: "billFrom",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice To",
                accessor: "billTo",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: false
            },
            {
                Header: "Invoice Date",
                accessor: "billDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Invoice No",
                accessor: "billNo",
                Cell: row => {
                    return row.row.original.invoiceFilePath ? 
                    <a
                        href={row.row.original.invoiceFilePath}
                        download
                        className={style.downloadTemplate}
                        >
                        <span style={{ color: "#1c75bc" }}>{row.row.original.billNo}</span>
                    </a>
                    : <span></span>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Shipment Qty (from daily data)",
                accessor: "calc_shipment_qty",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Shipment Qty (Uploaded)",
                accessor: "shipmentQuantity",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Rate Card",
                accessor: "rateCard",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Unit rate applicable (calc)",
                accessor: "unitPrice",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Shipment Amount (Calc)",
                accessor: "calc_shipment_amount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Shipment Amount (Upload)", // (uploaded)
                accessor: "shipmentAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "MG Contribution",
                accessor: "mg_amount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Surge Charges",
                accessor: "surgeCharges",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Recovery",
                accessor: "adjustments",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Worker Payable Amount (calc)",
                accessor: "calc_net_payable_amount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Worker Payable Amount (Upload)",
                accessor: "netPayableAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Net Payable Amount",
                accessor: "totalAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Advance Taken",
                accessor: "advancePayment",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "OkayGo Payout (uploaded)",
                accessor: "og_payout",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Margin Value",
                accessor: "og_margin",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Margin %",
                accessor: "marginPercent",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Margin Type (as per database)",
                accessor: "margin_type",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Expected Margin (as per database)",
                accessor: "expected_margin",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Failed/Reversed Reason",
                accessor: "razorpayReason",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Payment Date",
                accessor: "paymentDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            // {
            //     Header: "Long Distance",
            //     accessor: "longDistance",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Long Distance Amount",
            //     accessor: "longDistanceAmount",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Penalty",
            //     accessor: "penalty",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },




            // {
            //     Header: "Lease Charges",
            //     accessor: "leaseCharges",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },


            // {
            //     Header: "Payment Remarks",
            //     accessor: "paymentRemarks",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            // {
            //     Header: "Payment Mode",
            //     accessor: "paymentMode",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            // {
            //     Header: "Transaction No",
            //     accessor: "transactionNo",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // }
            // {
            //     Header: "Documents",
            //     accessor: "documents",
            //     Cell: row =>{
            //         return <Button onClick={() => getDocumentList(row.row.original.userId)} >Download</Button>
            //     },
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
        ],
        [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage, mainFilterOption]
    );
    console.log(mainFilterOption, localStorage.getItem("roleType"))
    const columnsAsaanJobs = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <>
                            {
                                ((mainFilterOption === 1 || mainFilterOption === 8) && (localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "26")) ?

                                    <div>{row.original.invoiceFilePath !== "" && <div>
                                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.invoiceFilePath === "") ? {} : { display:"flex", justifyItems:"center"}} disabled={row.original.invoiceFilePath === ""} />
                                    </div>}</div>

                                    : <div><IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{ display:"flex", justifyItems:"center" }} /></div>}
                        </>
                    )


                },
            },
            {
                Header: "Employee id",
                accessor: "employeeId",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Mark onHold",
            //     Cell: row => {
            //         return (row.row.original.paymentStatus === "on hold" || row.row.original.paymentStatus === "") &&
            //             <FormControlLabel
            //                 control={<Switch
            //                     checked={row.row.original.paymentStatus === "on hold"}
            //                     onClick={() => handleOnHold(row.row.original.paymentStatus === "on hold", row.row.original.invoiceId)}
            //                     color="primary"
            //                 />}
            //                 label="onHold"
            //             />


            //     },
            //     // defaultCanFilter: false,
            //     // defaultCanSort: false
            // },
            {
                Header: "Client",
                accessor: "client",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Invoice Status",
                accessor: "payment_stage",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Remark",
                accessor: "remark",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client Emp ID",
                accessor: "clientEmpId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                // Cell: row =>{
                //     return <Link to={{pathname:`/workerview/${row.row.original.userId}`}}   className="nameLink">{row.row.original.name}</Link>
                // },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "PAN Number",
                accessor: "panNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "PAN Card Verification",
                accessor: "panVerification",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank Details filled",
                accessor: "bankDetailsFilled",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank details verification",
                accessor: "pennyStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Invoice Cycle (from)",
                accessor: "billFrom",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice Cycle (to)",
                accessor: "billTo",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: false
            },
            {
                Header: "Invoice No",
                accessor: "billNo",
                Cell: row => {
                    return row.row.original.invoiceFilePath ? 
                    <a
                        href={row.row.original.invoiceFilePath}
                        download
                        className={style.downloadTemplate}
                        >
                        <span style={{ color: "#1c75bc" }}>{row.row.original.billNo}</span>
                    </a> 
                    : <span></span>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Service Charges",
                accessor: "shipmentAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Surge Charges (incentives etc.)",
                accessor: "surgeCharges",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Deductions (TDS etc.)",
                accessor: "adjustments",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Net payable Amount",
                accessor: "totalAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Date",
                accessor: "paymentDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Failed/Reversed Reason",
                accessor: "razorpayReason",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction Id",
                accessor: "transactionId",
                defaultCanFilter: false,
                defaultCanSort: false
            }
        ],
        [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage, mainFilterOption]
    );
    //columns list
    const transactionCols = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         (!(row.original.queued !== 0 || !row.original.isInvoiceCreated) && <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.queued !== 0 || !row.original.isInvoiceCreated) ? {} : { color: "#1c75bc" }} disabled={row.original.queued !== 0 || !row.original.isInvoiceCreated} />
            //         </div>)
            //     ),
            // },
            {
                Header: "Client id",
                accessor: "casperId",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return row.row.original.casperId ? <div><b>{`${row.row.original.source ? row.row.original.source + " : " : ""}`} </b> &nbsp;{row.row.original.casperId} </div> : <span></span>
                },
            },
            // {
            //     Header: "Swiggy id",
            //     accessor: "swiggyId",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            // {
            //     Header: "Partner",
            //     accessor: "source",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Name",
                accessor: "name",
                // Cell: row =>{
                //     return <Link to={{pathname:`/workerview/${row.row.original.userId}`}}   className="nameLink">{row.row.original.name}</Link>
                // },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Bank details verification",
            //     accessor: "verificationStatus",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Bank Details filled",
            //     accessor: "bankDetailsFilled",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Invoice No",
                accessor: "billNo",
                Cell: row => {
                    return row.row.original.invoiceFilePath ? 
                    <a
                        href={row.row.original.invoiceFilePath}
                        download
                        className={style.downloadTemplate}
                        >
                        <span style={{ color: "#1c75bc" }}>{row.row.original.billNo}</span>
                    </a>
                    : <span></span>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bill From",
                accessor: "billFrom",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Bill To",
                accessor: "billTo",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: false
            },
            {
                Header: "Okaygo Charges",
                accessor: "okaygoCharges",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: false
            },
            {
                Header: "Paid Amount",
                accessor: "totalAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Type",
                accessor: "paymentType",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction Date",
                accessor: "paymentDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction Status",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction Mode",
                accessor: "paymentMode",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction Remarks",
                accessor: "paymentRemarks",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Transaction ID",
                accessor: "transactionNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Shipment Amount",
            //     accessor: "shipmentAmount",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Surge Charges",
            //     accessor: "surgeCharges",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Adjustments",
            //     accessor: "adjustments",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // {
            //     Header: "Net Payable    ",
            //     accessor: "netPayableAmount",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            // // {
            // //     Header: "Lease Charges",
            // //     accessor: "leaseCharges",
            // //     defaultCanFilter :false,
            // //     defaultCanSort:false
            // // },
            // // {
            // //     Header: "Net payable",
            // //     accessor: "netPayable",
            // //     defaultCanFilter :false,
            // //     defaultCanSort:false
            // // },

            // // {
            // //     Header: "Transaction No",
            // //     accessor: "transactionNo",
            // //     defaultCanFilter :false,
            // //     defaultCanSort:false
            // // }
            // // {
            // //     Header: "Documents",
            // //     accessor: "documents",
            // //     Cell: row =>{
            // //         return <Button onClick={() => getDocumentList(row.row.original.userId)} >Download</Button>
            // //     },
            // //     defaultCanFilter :false,
            // //     defaultCanSort:false
            // // },
        ],
        []
    );
    const columnsHrbp = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}  />
            //         </div>
            //     ),
            // },
            // {
            //     Header: "Client id(s)",

            //     Cell: row => {
            //         return (<div>{row.row.original.casperId && <div><b>Flipkart: </b>{row.row.original.casperId}</div>}
            //             {/* <br/> */}
            //             {row.row.original.swiggyId && <div><b>Swiggy: </b>{row.row.original.swiggyId}</div>}
            //             {/* <br/> */}
            //             {/* {row.row.original.zomatoId && <div><b>Zomato: </b>{row.row.original.zomatoId}</div>} */}
            //         </div>);
            //     },
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Casper id",
                accessor: "casperId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Swiggy id",
                accessor: "swiggyId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Partner",
                accessor: "source",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",

                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank details verification",
                accessor: "pennyStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank Details filled",
                accessor: "bankDetailsFilled",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bill From",
                accessor: "billFrom",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Bill To",
                accessor: "billTo",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: false
            },
            {
                Header: "Bill Date",
                accessor: "billDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bill No",
                accessor: "billNo",
                Cell: row => {
                    return row.row.original.invoiceFilePath ? <Link to={{ pathname: `${row.row.original.invoiceFilePath}` }}  className="nameLink">{row.row.original.billNo}</Link> : <span></span>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Shipment Amount",
                accessor: "shipmentAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Surge Charges",
                accessor: "surgeCharges",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Adjustments",
                accessor: "adjustments",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Long Distance",
                accessor: "longDistance",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Long Distance Amount",
                accessor: "longDistanceAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Penalty",
                accessor: "penalty",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Advance Payout",
                accessor: "advancePayment",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Amount",
                accessor: "netPayableAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Net payable",
                accessor: "totalAmount",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Date",
                accessor: "paymentDate",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Total Amount",
            //     accessor: "totalAmount",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            // {
            //     Header: "Lease Charges",
            //     accessor: "leaseCharges",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            // {
            //     Header: "Net payable",
            //     accessor: "netPayable",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },

            // {
            //     Header: "Payment Mode",
            //     accessor: "paymentMode",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            // {
            //     Header: "Transaction No",
            //     accessor: "transactionNo",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // }
            // {
            //     Header: "Documents",
            //     accessor: "documents",
            //     Cell: row =>{
            //         return <Button onClick={() => getDocumentList(row.row.original.userId)} >Download</Button>
            //     },
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
        ],
        []
    );
    const handleFilterDates = (date, index, type) => {
        let dates = filters.billDate;
        dates[index] = date;
        setFilters({ ...filters, [type]: dates });
        setCurrentPage(1)
    }
    const paymentStageFilterHandle = (e) => {
        // let dates = filters.billDate;
        // dates[index] = date;
        // if (e === -1) {
        //     setFilters({ ...filters, payment_stage: null });

        // } else {
        //     setFilters({ ...filters, payment_stage: e });
        // }
    }


    //Function to create table data
    const makeData = (response) => {
        
        let extractedData = [];
        let lastupdatedon = [];
        console.log("res",response)
        response.content.map(el => {
            // console.log({ el })
            const totalAmount = el.totalAmount;
            const recovery = el.recovery ? parseFloat(el.recovery) : 0;
            const arrears = el.arrears ? parseFloat(el.arrears) : 0;
            const netPayable = totalAmount - recovery + arrears;
            extractedData.push({
                lastSmsTemplate: el.lastSmsTemplate || "",
                lastSmsTime: el.lastSmsTime ? (moment(el.lastSmsTime).format("hh:mm a, DD/MM/YYYY")) : "",
                lastSmsMessage: el.lastSmsMessage || "",
                casperId: el.casperId || "",
                swiggyId: el.swiggyId || "",
                okaygoId: el.okaygoId || "",
                source: el.invoiceFor || "",
                payment_stage: el.payment_stage,
                remark: el.remarks || "",
                has_desc: el.has_desc ? el.has_desc !== null ? el.has_desc : 0 : 0,
                employeeId: el.clientId || "",
                client: el.invoiceFor || "",
                clientEmpId: el.clientEmpId || "",
                clientId: el.invoiceFor === "Flipkart" ? el.casperId : el.clientId,
                delhiveryId: el.delhiveryId || null,
                jiomartId: el.jiomartId || null,
                panNumber: Decrypt(el.panNumber) || "",
                panVerification: el.panVerificationStatus === 1 ? "Yes" : el.panVerificationStatus === 0 ? "No" : "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                billNo: el.billNumber || "",
                netPayableAmount: el.netPayableAmount ? numberFormat(el.netPayableAmount) : "",
                netPayableAmountRaw: el.netPayableAmount ? el.netPayableAmount : 0,
                totalAmountRaw: el.totalAmount ? (el.totalAmount) : "",
                totalAmount: el.totalAmount ? numberFormat(el.totalAmount) : "",
                shipmentAmount: el.shipmentAmount ? numberFormat(parseFloat(el.shipmentAmount)) : "",
                surgeCharges: el.surgeCharges ? numberFormat(parseFloat(el.surgeCharges)) : "",
                leaseCharges: el.leaseCharges ? numberFormat(parseFloat(el.leaseCharges)) : "",
                advancePayment: el.advancePayment ? "- " + numberFormat(parseFloat(el.advancePayment)) : "",
                advancePaymentRaw: el.advancePayment ? el.advancePayment : null,
                netPayable: numberFormat(netPayable),
                paymentDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                paymentStatus: el.paymentStatus || "",
                paymentRemarks: el.paymentRemarks || "",
                paymentMode: el.okaygoCharges ? "IMPS" : (el.paymentStatus ? "NEFT" : ""),
                transactionNo: el.transactionId || "",
                invoiceFilePath: el.invoiceFilePath || "",
                verificationStatus: createVerificationStatus(el.verificationStatus),
                status: el.verificationStatus,
                pennyStatus: el.penny_testing_status!==null ?(el.penny_testing_status==="Blank" || el.penny_testing_status==="not verified" )?"-":el.penny_testing_status:"-",
                adjustments: el.recovery ? `- ${numberFormat(parseFloat(el.recovery))}` : "",
                longDistance: el.longDistance ? `${el.longDistance} km` : "",
                longDistanceAmount: el.longDistanceAmount ? numberFormat(parseFloat(el.longDistanceAmount)) : "",
                penalty: el.penalty ? `- ${numberFormat(parseFloat(el.penalty))}` : "",
                accountNumber: el.accountNumber,
                lmaEflexGroupFlex: el.invoiceFor === "Flipkart" ? (el.is_lma_worker ? "LMA" : 'Eflex') : "",
                rateCard: (el.is_lma_worker && el.invoiceFor === "Flipkart") ? el.lmaRateCard : (!el.is_lma_worker && el.invoiceFor === "Flipkart") ? el.rateCard : "",
                payoutToOkayGo: el.okaygoPayout ? `${numberFormat(parseFloat(el.okaygoPayout))}` : "",
                marginPercent: el.margin ? `${el.margin.toFixed(2)} %` : "",
                queued: el.queued,
                userId: el.userId,
                workerId: el.workerId,
                invoiceId: el.invoiceId || "",
                isInvoiceCreated: el.isInvoiceCreated,
                transactionId: el.transactionId || "",
                paymentType: el.okaygoCharges !== null ? "Advance Payout" : (el.weeklyPayment !== null ? "Weekly Payout" : "15 Day Payout"),
                okaygoCharges: el.okaygoCharges ? numberFormat(el.okaygoCharges) : "",
                calc_shipment_qty: el.calc_shipment_qty || "",
                unitPrice: el.unitPrice ? numberFormat(el.unitPrice) : "",
                calc_shipment_amount: el.calc_shipment_amount ? numberFormat(el.calc_shipment_amount) : "",
                mg_amount: el.mg_amount ? numberFormat(el.mg_amount) : "",
                calc_net_payable_amount: el.calc_net_payable_amount ? numberFormat(el.calc_net_payable_amount) : "",
                og_margin: el.og_margin ? numberFormat(el.og_margin) : "",
                margin_type: el.margin_type !== null ? (el.margin_type === 0 ? "Fixed Amount" : (el.margin_type === 1 ? "Fixed %" : "Variable")) : "",
                og_payout: el.og_payout ? numberFormat(el.og_payout) : "",
                expected_margin: el.expected_margin !== null ? (el.margin_type !== null ? (el.margin_type === 1 ? parseFloat(el.expected_margin).toFixed(2) + " %" : numberFormat(el.expected_margin)) : el.expected_margin) : "",
                shipmentQuantity: el.shipmentQuantity ? el.shipmentQuantity : "",
                razorpayReason: el.razorpayReason ? el.razorpayReason : "",


            });
            lastupdatedon.push(el.lastUpdatedOn)
            return true;
        });

        return extractedData;
    }

    //Function to create All record export data

    const columns = JSON.parse(localStorage.getItem("asaanRole")) ? columnsAsaanJobs : (!tab.invoiceTab ? (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "11") ? columnsHrbp : columnsOkayGo : transactionCols)
    const updateMyData = () => {
        // // console.log({filters, filterUrl})
        getPaymentListing(perPage, currentPage);
    }


    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getPaymentListing = (rows, pageNo) => {
        setAllRecord([])
        setLoading(true)
        let requestedBy = ""
        if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "26" || localStorage.getItem("roleType") === "30") {
            requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        }
        let onlyFlipkartInvoice = "";
        if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "11") {
            onlyFlipkartInvoice = "&onlyFlipkartInvoice=1"
        }
        // console.log(disFilterUrl)
        if (!tab.invoiceTab) {
            Axios.get(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListingMultiClientV2}?page_no=${pageNo - 1}${requestedBy}&rows=${rows}${filtersUrl}${sortUrl}${onlyFlipkartInvoice}${searchByUrl}${disFilterUrl}${mainFilterOption !== -1 ? "&payment_stage=" + mainFilterOption : ""}&roleType=${localStorage.getItem("roleType")}`)
                .then(response => {
                    if (response) {
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if (currentPage > response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        } else if (tab.invoiceTab) {
            Axios.post(`${urlLinks.baseUrl}/payments/listing/transactions?page_no=${pageNo - 1}${requestedBy}&rows=${rows}${filtersUrl}${sortUrl}${searchByUrl}`)
                .then(response => {
                    if (response) {
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if (currentPage > response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error => {
                    showToast("error", error.message)

                })
        }
    }
    useEffect(() => {
        getPaymentListing(perPage, 1);

    }, [filtersUrl, sortUrl, localStorage.getItem("roleType"), tab, searchByUrl, clearFilter, mainFilterOption])

    const handlePageChange = page => {
        getPaymentListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    console.log(selectedRowIds)
    const handlePerRowsChange = (newPerPage) => {
        getPaymentListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    useEffect(() => {
        // // // console.log({isAllRowsSelected})
        rows.map((record, index) => {
            // // // console.log({record})
            // // // console.log(record, selectedRowIds[index])
            if (((mainFilterOption === 1 || mainFilterOption === 8) && (localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "26"))) {

                if (record.original.invoiceFilePath === "") {
                    selectedRowIds[index] = false
                    // // // console.log(selectedRowIds[index])
                }
            }
        })
        // // // console.log(selectedRowIds)
    }, [isAllRowsSelected])


    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // // // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisablePayments(disableButton);
            // // // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    const makeAllRecordData = (response) => {

        let extractedData = [];
        response.map(el => {
            // // console.log(parseFloat(el.surgeCharges))
            // // console.log(parseFloat(el.surgeCharges).toFixed(2))
            let temp = {

                clientId: el.casperId ? `${el.casperId}` : "",
                okaygoId: el.okaygoId || "",
                client: el.invoiceFor || "",
                Invoice_statue: el.payment_stage || "",
                remark: el.remarks || "",
                Model: el.invoiceFor === "Flipkart" ? (el.is_lma_worker ? "LMA" : 'Eflex') : "",
                name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                bankDetailsVerification:  el.penny_testing_status!==null ?el.penny_testing_status==="Blank"?"-":el.penny_testing_status:"-",
                // bankDetailsVerification: createVerificationStatus(el.verificationStatus),
                
                
                InvoiceFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                InvoiceTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                InvoiceDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                InvoiceNo: el.billNumber || "",
                "Shipment Qty (from daily data)": el.calc_shipment_qty || "",
                "Shipment Qty (Uploaded)": el.shipmentQuantity ? el.shipmentQuantity : "",
                rateCard: (el.is_lma_worker && el.invoiceFor === "Flipkart") ? el.lmaRateCard : (!el.is_lma_worker && el.invoiceFor === "Flipkart") ? el.rateCard : "",
                "Unit rate applicable (calc)": el.unitPrice ? numberFormat(el.unitPrice) : "",
                "Shipment Amount (Calc)": el.calc_shipment_amount ? numberFormat(el.calc_shipment_amount) : "",
                "Shipment Amount (Upload)": el.shipmentAmount ? (parseFloat(el.shipmentAmount)).toFixed(2) : "",
                "MG Contribution": el.mg_amount ? numberFormat(el.mg_amount) : "",
                surgeCharges: el.surgeCharges ? (parseFloat(el.surgeCharges)).toFixed(2) : "",
                recovery: el.recovery ? (parseFloat(el.recovery)).toFixed(2) : "",
                "Total Worker Payable Amount (calc)": el.calc_net_payable_amount ? numberFormat(el.calc_net_payable_amount) : "",
                "Total Worker Payable Amount (Upload)": el.netPayableAmount ? numberFormat(el.netPayableAmount) : "",
                "Net Payable Amount": el.totalAmount ? numberFormat(el.totalAmount) : "",
                "Advance Taken": el.advancePayment ? "- " + numberFormat(parseFloat(el.advancePayment)) : "",
                "OkayGo Payout (uploaded)": el.og_payout ? numberFormat(el.og_payout) : "",
                "Margin Value": el.og_margin ? numberFormat(el.og_margin) : "",
                "Margin %": el.margin ? `${el.margin.toFixed(2)} %` : "",
                "Margin Type (as per database)": el.margin_type !== null ? (el.margin_type === 0 ? "Fixed Amount" : (el.margin_type === 1 ? "Fixed %" : "Variable")) : "",
                "Expected Margin (as per database)": el.expected_margin !== null ? (el.margin_type !== null ? (el.margin_type === 1 ? parseFloat(el.expected_margin).toFixed(2) + " %" : numberFormat(el.expected_margin)) : el.expected_margin) : "",
                paymentStatus: el.paymentStatus || "",
                "Faiiled/Reversed Reason": el.razorpayReason ? el.razorpayReason : "",
                paymentDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
            };

            // if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") {
            //     delete temp["paymentStatus"]
            //     // delete temp["paymentRemarks"]
            // }
            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    const makeAllRecordDataAsaan = (response) => {
        let extractedData = [];
        response.map(el => {
            extractedData.push({
                'Employee Id': el.clientId || "",
                "OkayGo Id": el.okaygoId || "",
                "Client": el.invoiceFor || "",
                Invoice_statue: el.payment_stage || "",
                remark: el.remarks || "",
                "Client Emp Id": el.clientEmpId || "",
                "Name": `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                "Contact No": Decrypt(el.phoneNumber) || "",
                "PAN Number": Decrypt(el.panNumber) || "",
                "Pan Verification Status": el.panVerificationStatus === 1 ? "Yes" : el.panVerificationStatus === 0 ? "No" : "",
                "Bank Details filled": !el.accountNumber ? "No" : "Yes",
                "Invoice Cycle (from)": el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                "Invoice Cycle (to)": el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                "Invoice No": el.billNumber || "",
                "Service Charges": el.shipmentAmount ? numberFormat(parseFloat(el.shipmentAmount)) : "",
                "Surge Charges (incentives etc.)": el.surgeCharges ? numberFormat(parseFloat(el.surgeCharges)) : "",
                "Deductions (TDS etc.)": el.recovery ? `- ${numberFormat(parseFloat(el.recovery))}` : "",
                "Net payable Amount": el.totalAmount ? numberFormat(el.totalAmount) : "",
                "Payment Date": el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                "Payment Status": el.paymentStatus || "",
                "Faiiled/Reversed Reason": el.razorpayReason ? el.razorpayReason : "",
                "Transaction Id": el.transactionId || "",
            })
            return true;
        });

        return extractedData;
    }
    const makeAllTransactionRecordData = (response) => {
        let extractedData = [];
        // console.log("HEYYYYYYYYYYYYYYYYYYYY")
        response.map(el => {
            // // // console.log({ el })
            // // // console.log(parseFloat(el.surgeCharges))
            // // // console.log(parseFloat(el.surgeCharges).toFixed(2))
            let temp = {
                // clientId: el.invoiceFor? `${el.invoiceFor}: ${el.casperId}` :"",
                clientId: el.casperId ? el.invoiceFor ? `${el.invoiceFor}: ${el.casperId}` : el.casperId : "",
                okaygoId: el.okaygoId || "",
                name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                // bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                InvoiceNo: el.billNumber || "",
                billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                okaygoCharges: el.okaygoCharges ? numberFormat(el.okaygoCharges) : "",
                paymentType: el.okaygoCharges !== null ? "Advance Payout" : "15 Day payout",
                // billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                TransactionDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                totalAmount: el.totalAmount ? numberFormat(el.totalAmount) : "",
                TransactionStatus: el.paymentStatus || "",
                TransactionMode: el.okaygoCharges ? "IMPS" : (el.paymentStatus ? "NEFT" : ""),
                TransactionRemarks: el.paymentRemarks || "",
                transactionId: el.transactionId || "",



                // totalAmount: el.totalAmount ? parseFloat(el.totalAmount).toFixed(2) : "",
                // shipmentAmount: el.shipmentAmount ? (parseFloat(el.shipmentAmount)).toFixed(2) : "",
                // surgeCharges: el.surgeCharges ? (parseFloat(el.surgeCharges)).toFixed(2) : "",
                // adjustments: el.recovery ? (parseFloat(el.recovery)).toFixed(2) : "",
                // leaseCharges: el.leaseCharges ? (parseFloat(el.leaseCharges)).toFixed(2) : "",
                // netPayable: el.netPayableAmount ? parseFloat(el.netPayableAmount).toFixed(2) : "",

            };

            if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") {
                delete temp["paymentStatus"]
                delete temp["paymentRemarks"]
            }

            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    // // console.log(localStorage.getItem("roleType"))
    const createAllRecordExcel = () => {
        setAllRecordLoading(true)
        let requestedBy = ""
        if (localStorage.getItem("roleType") === "1" || JSON.parse(localStorage.getItem("asaanRole"))) {
            requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        }
        let onlyFlipkartInvoice = ""
        if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "11") {
            onlyFlipkartInvoice = "&onlyFlipkartInvoice=1"
        }
        if (!tab.invoiceTab) {
            Axios.get(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListingMultiClientV2}?exportExcel=1${requestedBy}${filtersUrl}${onlyFlipkartInvoice}${sortUrl}${disFilterUrl}${mainFilterOption !== -1 ? "&payment_stage=" + mainFilterOption : ""}&roleType=${localStorage.getItem("roleType")}`)
                .then(response => {
                    if (response) {
                        if (!tab.invoiceTab) {

                            setAllRecord(JSON.parse(localStorage.getItem("asaanRole")) ? makeAllRecordDataAsaan(response.data.response) : makeAllRecordData(response.data.response))
                        } else {
                            setAllRecord(makeAllTransactionRecordData(response.data.response))

                        }
                    }
                })
        } else if (tab.invoiceTab) {
            Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListing}/transactions?pageRequest=0${requestedBy}${filtersUrl}`)
                .then(response => {
                    if (response) {
                        if (!tab.invoiceTab) {

                            setAllRecord(makeAllRecordData(response.data.response))
                        } else {
                            // console.log("DELL")
                            setAllRecord(makeAllTransactionRecordData(response.data.response))

                        }
                    }
                })
        }
        // transactions
    }
    // console.log({ allRecord })
    const handlePayments = () => {
        // // console.log({data})
        const indexes = Object.entries(selectedRowIds);
        // // console.log({indexes})
        const idList = []
        indexes.map((id, index) => {
            if (id[1]) {
                // // console.log({index})
                idList.push(data[parseFloat(id[0])].invoiceId);
            }
            return true;
        })
        // // console.log({idList});
        Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.triggerPayment}?requested_by=${localStorage.getItem("userID")}&invoice_ids=${idList.toString()}`)
            .then(response => {
                if (response.data.code === 1000) {
                    getPaymentListing(perPage, currentPage);
                    showToast("success", "Payments triggered successfully");
                }
                else {
                    showToast("error", "Payments triggering failed");
                }
            })
            .catch(error => {
                showToast("error", error.message)
            })
    }
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1060 header" :
                                    column.render('Header') === "Name" ? "stickPaymentNameV2 zIndex1060 header" :
                                        column.id === "selection" ? "stickCheckbox zIndex1060 header" :
                                            column.render('Header') === "Last SMS sent" ? "width200 header" :
                                                (column.id === "Mark onHold") ? ((financialAdmin || JSON.parse(localStorage.getItem("asaanRole"))) ? "" : "displayNone") : "header"}>


                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className={`row ${row.original.has_desc === 1 ? "criminalRecord" : ""}`}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "Worker Id" ? `stickWorkerIdnewMatch  zIndex540` :
                                            cell.column.Header === "Name" ? `stickPaymentNameV2 zIndex540 ${row.original.has_desc === 1 && localStorage.getItem("roleType") !== "26" && localStorage.getItem("roleType") !== "30" ? "criminalRecord" : ""}` :
                                                cell.column.id === "selection" ? `stickCheckbox zIndex540 ${row.original.has_desc === 1 && localStorage.getItem("roleType") !== "26" && localStorage.getItem("roleType") !== "30" ? "criminalRecord" : ""}` :
                                                    (cell.column.Header === "Mark onHold") ? ((financialAdmin || JSON.parse(localStorage.getItem("asaanRole"))) ? "" : "displayNone") : `cell ${row.original.has_desc === 1 && localStorage.getItem("roleType") !== "26" && localStorage.getItem("roleType") !== "30" ? "criminalRecord" : ""}`}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    // console.log(process.env)

    const handleTab = (event, name) => {
        if (name === "2") {

            // getTransactionListing()
            // // updateMyData()
            setTab({ ...tab, invoiceTab: true, transactionTab: false })
            setDisFilter([])
            setDisFilterUrl('')
            // setisLeadData("&leadRecords=1")

             setCurrentPage(1)
            // updateMyData();
        }
        else if (name === "1") {
            // getPaymentListing(perPage, 1)
            // if (!filters[name]) {
            // updateMyData()
            setTab({ ...tab, invoiceTab: false, transactionTab: true })
            // setisLeadData("")
            setCurrentPage(1)
            // updateMyData();
        }
    }
    const handleSendMsg = () => {
        const indexes = Object.entries(selectedRowIds);
        // console.log({ indexes })
        let idList = ""
        indexes.map((id, index) => {
            if (id[1]) {
                // console.log(data[parseFloat(id[0])].userId)
                idList += data[parseFloat(id[0])].userId + ",";
            }

            // console.log(idList)
            return true;
        })
        idList = idList.substr(0, idList.length - 1)
        // console.log(idList)
        setSelectedUserIds(idList)
        setOpenSendMsg(!openSendMsg)
        setActionOption(0)
    }

    //handling Cancel Modal

    const handleCancelModal = () => {
        const indexes = Object.entries(selectedRowIds);
        let idList = []
        let okayGoIdList = []
        indexes.map((id) => {
            if (id[1] && data[parseFloat(id[0])].advancePaymentRaw === null) {
                // console.log(data[parseFloat(id[0])].userId)
                idList.push(data[parseFloat(id[0])].invoiceId);
            } else {
                okayGoIdList.push(data[parseFloat(id[0])].okaygoId)

            }

            // console.log(idList)
            return true;
        })
        // idList = idList.substr(0, idList.length - 1)
        // idList= idList.trim()
        // console.log(idList.split(",").length,',=====>')
        // okayGoIdList = okayGoIdList.substr(0, okayGoIdList.length - 1)

        setSelectedUserIds(idList)
        // okayGoIdList = new Set(okayGoIdList)
        setSelectedOGIds(okayGoIdList)
        setOpenCancelModal(!openCancelModal)
        setActionOption(0)
    }

    const listNetPaybleAmt = () => {
        const indexes = Object.entries(selectedRowIds);
        // console.log({ indexes })
        let idList = 0
        indexes.map((id, index) => {
            if (id[1]) {
                // // console.log(data[parseFloat(id[0])].userId)
                // console.log(data[parseFloat(id[0])].totalAmountRaw)
                idList += parseInt(data[(id[0])].totalAmountRaw);
            }

            // // console.log(idList)
            return true;
        })
        // idList = idList.substr(0, idList.length - 1)
        setTotalNetPayableAmount(idList)

        const indexes2 = Object.entries(selectedRowIds);
        // console.log({ indexes })
        let idList2 = ""
        indexes2.map((id, index) => {
            if (id[1]) {
                // // console.log(data[parseFloat(id[0])].userId)
                idList2 += data[parseFloat(id[0])].invoiceId + ",";
            }

            // // console.log(idList)
            return true;
        })
        idList2 = idList2.substr(0, idList2.length - 1)
        // console.log(idList)
        setSelectedUserIds(idList2)
        // setOpenSendMsg(!openSendMsg)
    }

    const names = [
        'Shipment Qty Discrepancies',
        'Worker Payout Discrepancies',
        'Margin Discrepancies',
    ];
    // useEffect(() => {
    //     const updatedMenuItems: any = props.options?.map((option) => <MenuItem key={option.key} value={option.value}> {option.value} </MenuItem> );
    //     setMenuItems(updatedMenuItems);
    // }, [props.options]);
    const handleChange = (event) => {
        // const handleChange = (event: SelectChangeEvent<typeof disFilter>) => {
        // console.log(event.target.value.includes(undefined))
        // if (event.target.value.includes(undefined)) {
        //     setDisFilterUrl('')
        //     setDisFilter([]);
        //     return;
        // }
        const {
            target: { value },
        } = event;
        console.log(value)
        let disFilter = ''
        value.map((e) => {

            switch (e) {
                case "Shipment Qty Discrepancies":
                    disFilter += ('&quantity_flag=1')
                    break;
                case "Worker Payout Discrepancies":
                    disFilter += ('&amount_flag=1')
                    break;
                case "Margin Discrepancies":
                    disFilter += ('&margin_flag=1')
                    break;
                default:
                    break;
            }
        })
        if (value)
            // console.log(disFilter)
            setDisFilterUrl(disFilter)
        setDisFilter(value
            // [disFilter]
            // On autofill we get a stringified value.
            // [value.length+" Filter Applied"]
            // typeof value === 'string' ? value.split(',') : value,
        );
    };
    // console.log(disFilter)
    const clearDiscrepanciesFilter = () => {
        // console.log("HEYY")
        setDisFilter([])
        setDisFilterUrl('')
        // setDisFilterOpen(false)
        // updateMyData()
        setClearFilter(!clearFilter)
    }
    // const applyDiscrepanciesFilter = () => {
    //     // console.log("HEYY")
    //     // setDisFilter([])
    //     updateMyData()
    //     // console.log(disFilterUrl)
    //     // setFiltersUrl(disFilterUrl)
    // }
    const menuOptionList = [
        [-1, 'ALL INVOICES'],
        [0, 'RAISED'],
        [1, 'VALIDATED'],
        [2, 'REQUESTED FOR APPROVAL'],
        [3, 'APPROVED / QUEUED'],
        [4, 'PROCESSING'],
        [5, 'PROCESSED'],
        [6, 'FAILED / REVERSED'],
        [7, 'ACCOUNT DETAILS NOT VALID'],
        [8, 'REJECTED'],
        [9, 'CANCELLED'],
        [10, 'ON-HOLD'],
    ]
    const makePaymentStage = (stage) => {
        let stageName = ''
        switch (stage) {
            case 0:
                stageName = "Raised"
                break;
            case 1:
                stageName = "Validated"
                break;
            case 2:
                stageName = "Requested For Approval"
                break;
            case 3:
                stageName = "Approved/queued"
                break;
            case 4:
                stageName = "Processing"
                break;
            case 5:
                stageName = "Processed"
                break;
            case 6:
                stageName = "Failed/Reversed"
                break;
            case 8:
                stageName = "Rejected"
                break;
            case 9:
                stageName = "Cancelled"
                break;
            case 10:
                stageName = "On hold"
                break;
            case 101:
                stageName = "Un hold"
                break;
            default:
                break;
        }
        return stageName;
    }
    // console.log(selectedUserIds)
    const makeInvoiceIdsList = () => {
        // making invoiceIds comma sep
        const indexes = Object.entries(selectedRowIds);
        let idList = ""
        indexes.map((id, index) => {
            if (id[1]) {
                idList += data[parseFloat(id[0])].invoiceId + ",";
            }
            return true;
        })
        idList = idList.substr(0, idList.length - 1)
        ////////////
        return idList;
    }
    const makeAdvanceNotTakenInvoiceIdsList = () => {
        // making invoiceIds comma sep
        const indexes = Object.entries(selectedRowIds);
        let idList = ""
        indexes.map((id, index) => {
            if (id[1] && data[parseFloat(id[0])].advancePaymentRaw === null) {
                idList += data[parseFloat(id[0])].invoiceId + ",";
            }
            return true;
        })
        idList = idList.substr(0, idList.length - 1)
        ////////////
        return idList;
    }
    const updatePaymentStatus = (stage, remark) => {
        let idList;
        if (stage === 9) {
            idList = makeAdvanceNotTakenInvoiceIdsList();

        } else {

            idList = makeInvoiceIdsList();
        }
        // console.log(remark)
        let queryParams = remark === null ? `?Stage=${stage}&invoice_ids=${idList}&requestedBy=${localStorage.getItem("userID")}`
            : `?Stage=${stage}&invoice_ids=${idList}&remarks=${remark}&requestedBy=${localStorage.getItem("userID")}`
        Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.updatePaymentStage}${queryParams}`)
            .then(response => {
                // console.log(response)
                swal.fire({
                    title: `Successfully ${makePaymentStage(stage)}`,
                    // text: "You won't be able to revert this!",
                    icon: 'success',
                    // showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Okay'
                })
                updateMyData()
            })
            .catch(error => {
                swal.fire(
                    'Error Occured',

                    'error'
                )
            })

    }
    const getActionMenuItems = () => {
        switch (mainFilterOption) {
            case -1:
                return [[1, 'Send Message']]
            case 0:
                return [
                    [1, 'Send Message'],
                    [2, 'Cancel Invoice'],
                    // [3, 'Validated']
                ]
            case 1:
                return [
                    [1, 'Send Message'],
                    [2, 'Cancel Invoice'],
                    [8, 'On-Hold']
                ]
            case 2:
                return [
                    [1, 'Send Message'],
                ]
            case 3:
                return [[1, 'Send Message']]
            case 4:
                return [[1, 'Send Message']]
            case 5:
                return [[1, 'Send Message']]
            case 6:
                return [
                    [1, 'Send Message'],
                    // [6, 'Re-Trigger']
                ]
            case 7:
                return [[1, 'Send Message']]
            case 8:
                return [[1, 'Send Message'],
                [2, 'Cancel Invoice'],
                [8, 'On-Hold']

                ]
            case 10:
                return [[1, 'Send Message'],
                [2, 'Cancel Invoice'],
                [9, 'Un-Hold']
                ]
            case 9:
                return [[1, 'Send Message']]
            default:
                return [1, 'Send Message']
        }
    }
    // return [
    //     [1, 'ALL INVOICES'],
    //     [2, 'RAISED'],
    //     [3, 'VALIDATED'],
    //     [4, 'REQUESTING'],
    //     [5, 'APPROVED / QUEUED'],
    //     [6, 'PROCESSING'],
    //     [7, 'PROCESSED'],
    //     [8, 'FAILED / REVERSED'],
    //     [9, 'ACCOUNT DETAILS NOT VALID'],
    //     [10, 'ON-HOLD'],
    //     [11, 'CANCELLED']
    // ]

    const handleMainMenuChange = (e) => {
        // console.log(e.target.value, e.target.value === 100)
        setMainFilterOption(e.target.value)
        // paymentStageFilterHandle(e.target.value)
        setActionOption(0)
        setCurrentPage(1)

    }
    const notApplicablePopup = (txt) => {
        swal.fire({
            title: txt,
            icon: 'error',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Okay'
        })
        setActionOption(0)
    }
    const handleActionChange = (e) => {
        // console.log(e.target.value)
        let action = e.target.value
        setActionOption(e.target.value)
        switch (action) {
            case 1: {
                handleSendMsg()
                break;
            }
            case 8: {
                setOpenOnHoldPopup(true)
                // handleOnHold(false, makeInvoiceIdsList())
                setActionOption(0)
                break;
            }
            case 9: {
                handleOnHold(true, null)
                setActionOption(0)
                break
            }
            case 2: {
                if (localStorage.getItem("roleType") === "2" && mainFilterOption === 1) {
                    notApplicablePopup("Selected Invoices are already Validated")

                } else {
                    handleCancelModal()

                }
                // else if()
                break;
            }
            // case 6: {
            //     listNetPaybleAmt();
            //     setOpenTriggerPaymentPopup([true, true])
            //     setActionOption(0)
            //     break;
            // }
            default: {
            }
        }

    }
    useEffect(() => {
        let searchByVar = removeExtraSpaces(searchBy)
        console.log(searchByVar)
        let count = 0;
        count += (searchByVar.match(/ /g) || []).length
        count += (searchByVar.match(/,/g) || []).length
        console.log(count)
        count++;
        if (count > 100) {
            setSearchError(true)
        }
        else if (count <= 100) {
            setSearchError(false)
        }
    }, [searchBy])
    function removeExtraSpaces(string) { return string.replace(/\s{2,}/g, ' '); }

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid container xs={12}  >  <Grid><span className="fontSize24 fontWeightBold">Payment List V2</span>
                    {(localStorage.getItem("roleType") !== "5" && localStorage.getItem("roleType") !== "1") && <>  <Button
                        style={{
                            marginLeft: '15px',
                            marginBottom: '8px'
                        }}
                        //variant="outlined" 
                        onClick={(event) => { if (tab.invoiceTab === true) { handleTab(event, "1") } }}
                        className={`statusButtons marginRight20 ${tab.transactionTab ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Invoices
                    </Button>
                        {!JSON.parse(localStorage.getItem("asaanRole")) && <Button
                            style={{
                                marginBottom: '8px'
                            }}
                            onClick={(event) => { if (tab.transactionTab === true) { handleTab(event, "2") } }}
                            // variant="outlined"
                            className={`statusButtons marginRight20 ${tab.invoiceTab ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Transactions
                        </Button>}
                    </>}</Grid>

                </Grid>

                <Grid item xs={12} className="flexDisplay" alignItems='flex-start'>
                    <Grid item sm={7} className="flexDisplay padding0 paddingTop8" direction="row" justify="space-between">

                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder={localStorage.getItem("roleType") === "11" ? "Client id / OkayGo id" : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? "Casper id / OkayGo id / Contact No" : (localStorage.getItem("roleType") === "26" || localStorage.getItem("roleType") === "30") ? "Search by Employee id / OkayGo id / Contact No / Invoice No" : "Client id/Contact No/OkayGo id"}
                            fullWidth
                            // style={{width:"200px"}}
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            // onBlur={(event) => {


                            // }}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !searchError) {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />

                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                                disabled={searchError}
                                style={{ background: searchError ? "darkgray" : "" }}
                            >
                                Search
                            </button>
                        </div>



                    </Grid>
                    <Grid item xs={5} className="flexDisplay paddingTop8" direction="row" alignItems='flex-start'>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id="billDateFrom"
                                placeholder="Bill date from"
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                className={style.datePicker}
                                clearable
                                value={filters.billDate[0]}
                                onChange={date => handleFilterDates(date, 0, "billDate")}
                                keyboardIcon={<img src={Calendar} alt="calendar" width="18px" height="18px" />}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id="billDateTo"
                                placeholder="Bill date to"
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                className={style.datePicker}
                                clearable
                                value={filters.billDate[1]}
                                onChange={date => handleFilterDates(date, 1, "billDate")}
                                keyboardIcon={<img src={Calendar} alt="calendar" width="18px" height="18px" />}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item sm={3} className="flexDisplay padding0" direction="row" justify="space-between" >
                        {!tab.invoiceTab && (localStorage.getItem("roleType") === "31" || localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6") &&

                            <Demo
                                disFilter={disFilter}
                                clearDiscrepanciesFilter={clearDiscrepanciesFilter}
                                updateMyData={updateMyData}
                                setDisFilter={setDisFilter}
                                setDisFilterUrl={setDisFilterUrl}
                                clearFilter={clearFilter}
                                setClearFilter={setClearFilter}
                            ></Demo>

                        }
                        {
                            localStorage.getItem("roleType") !== "1" && <div style={{ margin: '7px' }}><Filters
                                filters={filters}
                                midFunction={midFunction}
                                setFilters={setFilters}
                                asaanRole={JSON.parse(localStorage.getItem("asaanRole"))}
                                partner={partner}
                                // filterDrawer={filterDrawer}
                                // setFilterDrawer={setFilterDrawer}
                                resetFilters={resetFilters}
                                isFilterApplied={isFilterApplied}
                                setIsFilterApplied={setIsFilterApplied}
                            /></div>}
                        {/* <Grid item xs={1} className="buttonList padding8" alignItems='flex-start'>
                            {
                                localStorage.getItem("roleType") !== "1" && <Filters
                                    filters={filters}
                                    midFunction={midFunction}
                                    setFilters={setFilters}
                                    asaanRole={JSON.parse(localStorage.getItem("asaanRole"))}
                                    partner={partner}
                                    // filterDrawer={filterDrawer}
                                    // setFilterDrawer={setFilterDrawer}
                                    resetFilters={resetFilters}
                                    isFilterApplied={isFilterApplied}
                                    setIsFilterApplied={setIsFilterApplied}
                                />}
                        </Grid> */}

                    </Grid>
                    {/* <Grid item sm={1} className="flexDisplay paddingTop8" direction ="row-reverse" justify="space-between">
                        <IconButton 
                            
                            className="filterButton padding8"
                            onClick={() =>setFilterDrawer(true)}
                        >
                            <img src={filter} alt="img" />
                        </IconButton>
                    </Grid> */}

                </Grid>
                {/* <Grid item xs={12} className="flexDisplay paddingTop16" direction="row" alignItems='flex-start'>

                    <Grid item xs={5} className="" alignItems='flex-start'>

                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled={allRecordLoading}
                            onClick={createAllRecordExcel}
                            style={{ marginRight: "25px" }}
                        >
                            Export to Excel
                        </Button>
                        { (localStorage.getItem("roleType")!=="5"&& localStorage.getItem("roleType")!=="1")  && <Button
                            variant="contained"
                            className="dataButton"
                            // disabled={allRecordLoading}
                            onClick={() => { setOpenGenerateInvoice(true) }}
                        >
                            Generate Weekly Invoices
                        </Button>}
                        {openGenerateInvoice &&
                            <GenerateInvoiceModal
                                // toast ={}
                                showToast={showToast}
                                updateMyData={updateMyData}
                                open={openGenerateInvoice}
                                setOpen={setOpenGenerateInvoice}
                            />
                        }

                        {allRecordLoading ?
                            <span style={{ marginLeft: "20px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={!tab.invoiceTab ? `InvoiceList_${moment().format("DD/MM/YYYY HH:mm")}.csv` : `TransactionList_${moment().format("DD/MM/YYYY HH:mm")}.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginLeft: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink>
                                : null}
                    </Grid>

                    <Grid item xs={9} className="textAlignRight" >
                        {(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6") && <Button
                            style={{ marginRight: '12px' }}
                            variant="contained"
                            className="dataButton"
                            // style={{ marginLeft: "16px" }}
                            onClick={() => handleSendMsg()}
                            disabled={disablePayments}
                        >
                            Send Message
                        </Button>}
                        <FormControl variant="outlined" className="margin1" >
                            <div>   {financialAdmin && !JSON.parse(localStorage.getItem("asaanRole")) &&
                                //   <div style={{    paddingLeft: '760px'}}>
                                <Button
                                    style={{ marginRight: '12px' }}
                                    variant="contained"
                                    className="dataButton"
                                    onClick={handleOpen}
                                >
                                    Check Balance
                                </Button>
                                // </div>
                            }<Button
                                style={{ marginRight: '12px' }}
                                variant="contained"
                                className="dataButton"

                                onClick={handleActions}
                            >
                                    Upload Invoice
                                </Button>
                                {financialAdmin && <Button

                                    variant="contained"
                                    className="dataButton"
                                    disabled={disablePayments}
                                    onClick={()=>{ listNetPaybleAmt(); setOpenTriggerPaymentPopup(true)}}
                                >
                                    Trigger payment
                                </Button>}</div>
                        </FormControl>
                    </Grid>

                </Grid> */}
                {!searchError ? <div className='searchInstruction'>{`Use comma or space as delimiter to search multiple IDs. Max limit 100.`}</div> :
                    <div className='searchInstructionError' style={{ display: "flex", margin: '6px' }}><WarningIcon></WarningIcon> &nbsp;{` Max limit reached. Search 100 IDs max.`}</div>}
                {!tab.invoiceTab ?
                    <div style={{ display: "flex", justifyContent: 'space-between', width: '2000px', marginTop: "10px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "25px", marginTop: '6px' }}>
                                <FormControl >
                                    <Select
                                        style={{ width: "250px", height: '40px' }}
                                        variant='outlined'
                                        value={mainFilterOption}
                                        onChange={handleMainMenuChange}
                                    >
                                        {menuOptionList.map(option => {
                                            return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
                                        })}
                                    </Select>

                                </FormControl>
                            </div>
                            {((localStorage.getItem("roleType") !== "30") && (localStorage.getItem("roleType") !== "31")) &&
                                <div style={{ marginRight: "25px", marginTop: '6px' }}>
                                    <FormControl >
                                        <Select
                                            style={{ width: "200px", height: '40px', color: actionOption === 0 ? 'grey' : '' }}
                                            variant='outlined'
                                            value={actionOption}
                                            disabled={disablePayments}
                                            onChange={handleActionChange}
                                        >
                                            <MenuItem key={0} disabled value={0}>{'Action'}</MenuItem>
                                            {getActionMenuItems().map(option => {
                                                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
                                            })}
                                        </Select>

                                    </FormControl>
                                </div>
                            }
                            <div >
                                <Button
                                    variant="contained"
                                    className="dataButton2"
                                    disabled={allRecordLoading || !data.length > 0}
                                    onClick={createAllRecordExcel}
                                    style={{ marginRight: "20px" }}

                                >
                                    Export to Excel
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    className="dataButton2"
                                    onClick={handleActions}
                                >
                                    Upload Invoice
                                </Button>
                            </div>
                            {allRecordLoading ?
                                <span style={{ marginLeft: "20px" }}>Please wait ... it might take few minutes. </span>
                                : allRecord.length > 0 ?
                                    <CSVLink
                                        data={allRecord}
                                        filename={!tab.invoiceTab ? `InvoiceList_${moment().format("DD/MM/YYYY HH:mm")}.csv` : `TransactionList_${moment().format("DD/MM/YYYY HH:mm")}.csv`}
                                        className='btn btn-primary blueColor downloadExcelButton'
                                        target='_blank'
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'none',
                                            marginLeft: "20px",
                                            fontWeight: '500'
                                        }}
                                    >
                                        Download now
                                    </CSVLink>
                                    : null}
                        </div>



                        <div style={{ display: "flex" }}>
                            {mainFilterOption === 0 && (localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "26") && <div style={{ marginLeft: "25px" }}>
                                <Button
                                    variant="contained"
                                    className="dataButton2"
                                    disabled={disablePayments}
                                    onClick={() => { updatePaymentStatus(1, null) }}
                                // onClick={handleActions}
                                >
                                    VALIDATE
                                </Button>

                            </div>}
                            {(mainFilterOption === 1 || mainFilterOption === 8) && (localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "26") && <div style={{ marginLeft: "25px" }}>
                                <Button
                                    variant="contained"
                                    className="dataButton2"
                                    disabled={disablePayments}
                                    onClick={() => { updatePaymentStatus(2, null) }}
                                >
                                    SEND FOR APPROVAL
                                </Button>

                            </div>}

                            {mainFilterOption === 2 && (localStorage.getItem("roleType") === "30" || localStorage.getItem("roleType") === "31") && <div style={{ marginLeft: "25px" }}>
                                <Button
                                    variant="contained"
                                    className="dataButton2"
                                    onClick={() => { setOpenRejectInvoicePopup(true) }}
                                    // onClick={handleActions}
                                    disabled={disablePayments}
                                    style={{ marginRight: "20px" }}
                                >

                                    REJECT
                                </Button>

                                <Button
                                    variant="contained"
                                    className="dataButton2"
                                    onClick={() => { listNetPaybleAmt(); setOpenTriggerPaymentPopup([true, false]) }}
                                    disabled={disablePayments}
                                >
                                    APPROVE
                                </Button>

                            </div>}
                            {mainFilterOption === 6 && (localStorage.getItem("roleType") === "30" || localStorage.getItem("roleType") === "31" || localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "26")
                                &&
                                <Button
                                    variant="contained"
                                    className="dataButton2"
                                    onClick={() => {
                                        listNetPaybleAmt();
                                        setOpenTriggerPaymentPopup([true, true])
                                        setActionOption(0)
                                    }}
                                    disabled={disablePayments}
                                >
                                    RI-TRIGGER PAYMENT
                                </Button>
                            }


                        </div>
                    </div> : <div >
                        <Button
                            variant="contained"
                            className="dataButton2"
                            disabled={allRecordLoading || !data.length > 0}
                            onClick={createAllRecordExcel}
                            style={{ marginRight: "20px" }}

                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading ?
                            <span style={{ marginLeft: "20px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={!tab.invoiceTab ? `InvoiceList_${moment().format("DD/MM/YYYY HH:mm")}.csv` : `TransactionList_${moment().format("DD/MM/YYYY HH:mm")}.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginLeft: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink>
                                : null}
                    </div>}

                <RejectModal
                    open={openRejectInvoicePopup}
                    setOpen={setOpenRejectInvoicePopup}
                    updatePaymentStatus={updatePaymentStatus}
                    stage={8}
                    text={"Reject Invoice Remark"}
                />
                <OnHoldRemarkModal
                    open={openOnHoldPopup}
                    setOpen={setOpenOnHoldPopup}
                    handleOnHold={handleOnHold}
                    // stage={9}
                    text={"On Hold Remark"}
                />

                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}

                    />
                </Grid>}

                {openOnboardingModalV2 && <OnboardingModalV2
                    openOnboardingModal={openOnboardingModalV2}
                    setOpenOnboardingModal={setOpenOnboardingModalV2}
                    showToast={showToast}
                    updateMyData={updateMyData}
                    asaanRole={JSON.parse(localStorage.getItem("asaanRole"))}
                />}

                <Modal
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className={classes.paper}>
                        <h2 id="simple-modal-title">Balance</h2>
                        <table>
                            <tr>
                                <td>Total Balance:</td>
                                <td> {dataBalnce}</td>
                            </tr>


                        </table>
                    </div>
                </Modal>
                {
                    openSendMsg &&
                    <SendMsg
                        filtersUrl={filtersUrl}
                        open={openSendMsg}
                        setOpen={setOpenSendMsg}
                        userIds={selectedUserIds}
                        updateMyData={updateMyData}
                    />
                }
                {
                    openCancelModal &&
                    <CancelModal
                        selectedOGIds={selectedOGIds}
                        open={openCancelModal}
                        setOpen={setOpenCancelModal}
                        userIds={selectedUserIds}
                        updatePaymentStatus={updatePaymentStatus}
                    />
                }
                <ConfirmTriggerPaymentModal
                    open={openTriggerPaymentPopup[0]}
                    retrigger={openTriggerPaymentPopup[1]}
                    setOpen={setOpenTriggerPaymentPopup}
                    updateMyData={updateMyData}
                    userIds={selectedUserIds}
                    totalAmountToBePaid={totalNetPayableAmount}
                    showToast={showToast}


                />

            </Grid>
        </Styles>
    )
}

export default ExternalWorker;


