import React, { useContext, useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import WorkerListIcon from "./worker list.svg";
import PaymentListIcon from "./payment list.svg";
import ChangePassword from "./Change password.svg";
import MenuIcon from "@material-ui/icons/Menu";
import { AuthContext } from "../../services/AuthProvider";
import styles from "./index.module.scss";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
// import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import VersionPackage from "../../../package.json";
import artImage from "./art.svg";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ShiftIcon from "./shift planning icon.svg";
import { Link } from "react-router-dom";
import {
  Collapse,
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
const Header = () => {
  const { logout } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const handleClick = () => {
    setDropdown(!dropdown);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    console.log("clicking")
    setOpen(false);
  };
  const getRole = () => {
    switch (localStorage.getItem("roleType")) {
      case "1":
        return "HRBP Admin";
      case "2":
        return "OkayGo Admin";
      case "5":
        return "Super HRBP";
      case "6":
        return "Super Admin";
      case "11":
        return "Swiggy HRBP";
      case "15":
        return "Telecaller";
      case "25":
        return "AJ Admin";
      case "26":
        return "AJ Admin";
      case "30":
        return "AJ Finance Admin";
      case "31":
        return "OkayGo Finance Admin";
      case "27":
        return "BP Analytics";
      case "30":
        return "VACO Admin";

      default:
        return "No Role specified";
    }
  };
  useEffect(() => {
    setUserDetails(`${localStorage.getItem("fullName")} (${getRole()})`);
  }, [localStorage.getItem("roleType")]);
  return (
    <div className={styles.root}>
      <AppBar position="fixed" className={styles.NavbarBG}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img src={artImage} alt="logo" className={styles.Artboard3} />
          </Link>
          <span className={styles.logout}>{userDetails}</span>
          <Button color="inherit" onClick={logout} className={styles.logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {localStorage.getItem("roleType") === "25" ||
      localStorage.getItem("roleType") === "26" || localStorage.getItem("roleType") === "30" ? (
        <Drawer
          className={styles.drawer}
          // variant="persistent"
          anchor="left"
          open={open}
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
          classes={{
            paper: styles.drawerPaper,
          }}
          onClose={handleDrawerClose}
        >
          <div className={styles.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List style={{padding:"0"}}>
            {localStorage.getItem("roleType") === "25" && (
              <Link to="/recruiter-data" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="paymentList"
                  className={
                    window.location.pathname === "/recruiter-data"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <Icon style={{ color: "#fffff !important" }}>
                      <GroupIcon></GroupIcon>
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary="Recruiter's Data" />
                </ListItem>
              </Link>
            )}
            {(localStorage.getItem("roleType") === "26" || localStorage.getItem("roleType") === "30")&& (
              <Link to="/workerDb" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="WorkerList"
                  className={
                    window.location.pathname === "/workerDb"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <img src={WorkerListIcon} alt="Worker list icon" />
                  </ListItemIcon>
                  <ListItemText primary="Worker List" />
                </ListItem>
              </Link>
            )}
            {(localStorage.getItem("roleType") === "26"|| localStorage.getItem("roleType") === "30") && (
              <Link to="/paymentslisting" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="paymentList"
                  className={
                    window.location.pathname === "/paymentslisting"
                      ? styles.activeRoute
                      : null
                  }
                >
                  <ListItemIcon>
                    <img src={PaymentListIcon} alt="Payment list icon" />
                  </ListItemIcon>
                  <ListItemText primary="Payment List" />
                </ListItem>
              </Link>
            )}
          </List>
          <List style={{padding:"0"}}>
            <Link to="/changepassword" className={styles.routeLink}>
              <ListItem
                style={{padding:"16px"}}
                button
                key="changePassword"
                className={
                  window.location.pathname === "/changepassword"
                    ? styles.activeRoute
                    : null
                }
                onClick = {handleDrawerClose}
              >
                <ListItemIcon>
                  <img src={ChangePassword} alt="change password icon" />
                </ListItemIcon>
                <ListItemText primary="Change password" />
              </ListItem>
            </Link>
          </List>
        </Drawer>
      ) : 
      localStorage.getItem("roleType") === "27" ? (
        <Drawer
          className={styles.drawer}
          // variant="persistent"
          anchor="left"
          open={open}
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
          classes={{
            paper: styles.drawerPaper,
          }}
          onClose={handleDrawerClose}
        >
          <div className={styles.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List style={{padding:"0"}}>
            <Link to="/workerdatav3" className={styles.routeLink}>
              <ListItem
                style={{padding:"16px"}}
                button
                key="WorkerList"
                className={
                  window.location.pathname === "/workerdatav3"
                    ? styles.activeRoute
                    : null
                }
                onClick = {handleDrawerClose}
              >
                <ListItemIcon>
                  <img src={WorkerListIcon} alt="Worker list icon" />
                </ListItemIcon>
                <ListItemText primary="Worker Data" />
              </ListItem>
            </Link>
          </List>
          <List style={{padding:"0"}}>
            <Link to="/changepassword" className={styles.routeLink}>
              <ListItem
                style={{padding:"16px"}}
                button
                key="changePassword"
                className={
                  window.location.pathname === "/changepassword"
                    ? styles.activeRoute
                    : null
                }
                onClick = {handleDrawerClose}
              >
                <ListItemIcon>
                  <img src={ChangePassword} alt="change password icon" />
                </ListItemIcon>
                <ListItemText primary="Change password" />
              </ListItem>
            </Link>
          </List>
        </Drawer>
      ) : (
        <Drawer
          className={styles.drawer}
          // variant="persistent"
          anchor="left"
          open={open}
          
          onKeyDown={handleDrawerClose}
          classes={{
            paper: styles.drawerPaper,
          }}
          onClose={handleDrawerClose}
        >
          <div className={styles.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/employer-data" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="WorkerList"
                  className={
                    window.location.pathname === "/employer-data"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <img src={WorkerListIcon} alt="Worker list icon" onClick={handleDrawerClose}/>
                  </ListItemIcon>
                  <ListItemText primary="Employer Data" onClick={handleDrawerClose}/>
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/hub-listing" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="WorkerList"
                  className={
                    window.location.pathname === "/hub-listing"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  {/* <ListItemIcon><img src={WorkerListIcon} alt="Worker list icon"/></ListItemIcon> */}
                  <ListItemIcon style={{ color: "#000000b8" }} onClick={handleDrawerClose}>
                    <Icon>
                      <AddBusinessIcon></AddBusinessIcon>
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary="Hub Listing" onClick={handleDrawerClose}/>
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || 
            localStorage.getItem("roleType") === "5" ||
            localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/workerDb" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="WorkerList"
                  className={
                    window.location.pathname === "/workerDb"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <img src={WorkerListIcon} alt="Worker list icon" onClick={handleDrawerClose} />
                  </ListItemIcon>
                  <ListItemText primary="Worker List" onClick={handleDrawerClose}/>
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {/* <List className={styles.padding0}>
          <Link to="/workerdatav2" className={styles.routeLink}>
            <ListItem button key="WorkerList" className={window.location.pathname==="/workerdatav2"?styles.activeRoute:null}>
              <ListItemIcon><img src={WorkerListIcon} alt="Worker list icon"/></ListItemIcon>
              <ListItemText primary= {(localStorage.getItem("roleType")==="5" || localStorage.getItem("roleType")==="1")? "Worker List": "Worker Data V2"} />
            </ListItem>
          </Link>
        </List> */}
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/workerdatav3" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="WorkerList"
                  className={
                    window.location.pathname === "/workerdatav3"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <img src={WorkerListIcon} alt="Worker list icon"onClick={handleDrawerClose} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      localStorage.getItem("roleType") === "5" ||
                      localStorage.getItem("roleType") === "1"
                        ? "Worker List"
                        : "Worker Data V3"
                    }
                    onClick={handleDrawerClose}
                  />
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/retentioncalls" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="retentionCalls"
                  className={
                    window.location.pathname === "/retentioncalls"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon onClick={handleDrawerClose}>
                    <Icon style={{ color: "#fffff !important" }}>
                      <GroupIcon></GroupIcon>
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary="Retention Calls" onClick={handleDrawerClose}/>
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {/* {(localStorage.getItem("roleType")!=="1" && localStorage.getItem("roleType")!=="5") && localStorage.getItem("roleType")!=="15"&& <List className={styles.padding0}>
          <Link to="/WorkerListSwiggy" className={styles.routeLink}>
            <ListItem button key="WorkerListSwiggy" className={window.location.pathname==="/WorkerListSwiggy"?styles.activeRoute:null}>
              <ListItemIcon><img src={WorkerListIcon} alt="Worker list Swiggy icon"/></ListItemIcon>
              <ListItemText primary={localStorage.getItem("roleType")!=="11"?"WorkerList Swiggy":"WorkerList"} />
            </ListItem>
          </Link>
        </List>} */}
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
              <List className={styles.padding0}>
                <Link to="/paymentslisting" className={styles.routeLink}>
                  <ListItem
                    button
                    key="paymentList"
                    className={
                      window.location.pathname === "/paymentslisting"
                        ? styles.activeRoute
                        : null
                    }
                  >
                    <ListItemIcon>
                      <img src={PaymentListIcon} alt="Payment list icon" onClick={handleDrawerClose}/>
                    </ListItemIcon>
                    <ListItemText primary="Payment List"onClick={handleDrawerClose} />
                  </ListItem>
                </Link>
              </List>
            )}
            {localStorage.getItem("roleType") !== "11" &&
            localStorage.getItem("roleType") !== "15" && (
              <List style={{padding:"0"}}>
                <Link to="/paymentslistingV2" className={styles.routeLink}>
                  <ListItem
                    style={{padding:"16px"}}
                    button
                    key="paymentList"
                    className={
                      window.location.pathname === "/paymentslistingV2"
                        ? styles.activeRoute
                        : null
                    }
                    onClick = {handleDrawerClose}
                  >
                    <ListItemIcon>
                      <img src={PaymentListIcon} alt="Payment list icon" onClick={handleDrawerClose}/>
                    </ListItemIcon>
                    <ListItemText primary="Payment List V2"onClick={handleDrawerClose} />
                  </ListItem>
                </Link>
              </List>
            )}
            <Divider />
            {(localStorage.getItem("roleType") === "2" ||
              localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
                <List style={{padding:"0"}}>
                  <ListItemButton onClick={handleClick} style={{padding:"16px"}}>
                    <ListItemIcon><img src={WorkerListIcon} alt="Verification icon" /></ListItemIcon>
                    <ListItemText primary="Verification details" />
                    {dropdown ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={dropdown} timeout="auto" >

                    <List style={{ padding: '0' }}>
                      <Link to="/document-verification" className={styles.routeLink}>
                        <ListItem
                          style={{padding:"16px 16px 16px 35px"}}
                          button
                          key="DocumentVerification"
                          className={
                            window.location.pathname === "/document-verification"
                              ? styles.activeRoute
                              : null
                          }
                          onClick = {handleDrawerClose}
                        >
                          <ListItemText primary="Document Verification" onClick={handleDrawerClose} />
                        </ListItem>
                      </Link>
                    </List>
                    {(localStorage.getItem("roleType") === "2" ||
                      localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31" ||
                      localStorage.getItem("roleType") === "15") && (
                        <List style={{padding:"0"}}>
                          <Link to="/bankverificationlisting" className={styles.routeLink}>
                            <ListItem
                              // sx={{ pl: 4 }}
                              style={{padding:"16px 16px 16px 35px"}}
                              button
                              key="Bank Verification"
                              className={
                                window.location.pathname === "/bankverificationlisting"
                                  ? styles.activeRoute
                                  : null
                              }
                              onClick = {handleDrawerClose}
                            >

                              <ListItemText primary="Bank Verification"  onClick={handleDrawerClose}/>
                            </ListItem>
                          </Link>
                        </List>)}
                  </Collapse>
                </List>
              )}
            <Divider />
            {localStorage.getItem("roleType") !== "11" &&
            localStorage.getItem("roleType") !== "15" && (
              <List style={{padding:"0"}}>
                <Link to="/shiftdata" className={styles.routeLink}>
                  <ListItem
                    style={{padding:"16px"}}
                    button
                    key="shiftdata"
                    className={
                      window.location.pathname === "/shiftdata"
                        ? styles.activeRoute
                        : null
                    }
                    onClick = {handleDrawerClose}
                  >
                    <ListItemIcon>
                      <img
                        src={ShiftIcon}
                        alt="shift data list icon"
                        className={styles.listIcon}
                        onClick={handleDrawerClose}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Shift Data" onClick={handleDrawerClose} />
                  </ListItem>
                </Link>
              </List>
            )}
            <Divider />
          {/* {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" ||
            localStorage.getItem("roleType") === "15" || localStorage.getItem("roleType") === "31") && (
            <List className={styles.padding0}>
              <Link to="/bankverificationlisting" className={styles.routeLink}>
                <ListItem
                  button
                  key="bankverificationlisting"
                  className={
                    window.location.pathname === "/bankverificationlisting"
                      ? styles.activeRoute
                      : null
                  }
                >
                  <ListItemIcon>
                    <img src={PaymentListIcon} alt="Payment list icon" />
                  </ListItemIcon>
                  <ListItemText primary="Bank Details Verification" />
                </ListItem>
              </Link>
            </List>
          )} */}
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/bgvverificationlisting" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="bgvverificationlisting"
                  className={
                    window.location.pathname === "/bgvverificationlisting"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <img src={PaymentListIcon} alt="Payment list icon" onClick={handleDrawerClose}/>
                  </ListItemIcon>
                  <ListItemText primary="BGV Verification List"onClick={handleDrawerClose} />
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/recruiter-data" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="paymentList"
                  className={
                    window.location.pathname === "/recruiter-data"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <Icon style={{ color: "#fffff !important" }}onClick={handleDrawerClose}>
                      <GroupIcon></GroupIcon>
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary="Recruiter's Data" onClick={handleDrawerClose}/>
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/jobsdb" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="jobsDB"
                  className={
                    window.location.pathname === "/jobsdb"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <img src={PaymentListIcon} alt="Jobs DB icon" onClick={handleDrawerClose}/>
                  </ListItemIcon>
                  <ListItemText primary="Jobs DB"onClick={handleDrawerClose} />
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/shiftplan" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="shiftplan"
                  className={
                    window.location.pathname === "/shiftplan"
                      ? styles.activeRoute
                      : null
                  }
                >
                  <ListItemIcon>
                    <img
                      src={ShiftIcon}
                      alt="shift plan list icon"
                      className={styles.listIcon}
                      onClick={handleDrawerClose}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Shift Planning" onClick={handleDrawerClose}/>
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          
          {(localStorage.getItem("roleType") === "2" ||
            localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && (
            <List style={{padding:"0"}}>
              <Link to="/referral" className={styles.routeLink}>
                <ListItem
                  style={{padding:"16px"}}
                  button
                  key="Referral"
                  className={
                    window.location.pathname === "/referral"
                      ? styles.activeRoute
                      : null
                  }
                  onClick = {handleDrawerClose}
                >
                  <ListItemIcon>
                    <img src={WorkerListIcon} alt="Referral icon" onClick={handleDrawerClose} />
                  </ListItemIcon>
                  <ListItemText primary="Referral" onClick={handleDrawerClose}/>
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          
           <List style={{padding:"0"}}>
            <Link to="/workerLedger" className={styles.routeLink}>
              <ListItem
                style={{padding:"16px"}}
                button
                key="workerLedger"
                className={
                  window.location.pathname === "/workerLedger"
                    ? styles.activeRoute
                    : null
                }
                onClick = {handleDrawerClose}
              >
                <ListItemIcon onClick={handleDrawerClose}>
                <Icon style={{ color: "#fffff !important" }}>
                      <AutoStoriesIcon></AutoStoriesIcon>
                    </Icon>
                  {/* <img src={AutoStoriesIcon} alt="change password icon" /> */}
                </ListItemIcon>
                <ListItemText primary="Worker Ledger" onClick={handleDrawerClose} />
              </ListItem>
            </Link>
          </List>
          {/* {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6" || localStorage.getItem("roleType")==="15") && 
        <List className={styles.padding0}>
          <Link to="/workerdata" className={styles.routeLink}>
            <ListItem button key="workerData" className={window.location.pathname==="/workerdata"?styles.activeRoute:null}>
            <ListItemIcon><img src={PaymentListIcon} alt="Worker Data icon"/></ListItemIcon>
              <ListItemText primary="Old Worker Data" />
            </ListItem>
          </Link>
        </List>} */}
        <Divider />
          <List style={{padding:"0"}}>
            <Link to="/changepassword" className={styles.routeLink}>
              <ListItem
                style={{padding:"16px"}}
                button
                key="changePassword"
                className={
                  window.location.pathname === "/changepassword"
                    ? styles.activeRoute
                    : null
                }
                onClick = {handleDrawerClose}
              >
                <ListItemIcon>
                  <img src={ChangePassword} alt="change password icon" onClick={handleDrawerClose} />
                </ListItemIcon>
                <ListItemText primary="Change password" onClick={handleDrawerClose}/>
              </ListItem>
            </Link>
          </List>
          <div className={styles.version}>
            <span>Version: {VersionPackage.version}</span>
          </div>
        </Drawer>
      )}
      <main className={styles.content}></main>
    </div>
  );
};

export default Header;