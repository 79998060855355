import React, { createContext, useCallback, useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './UserPool';
import UserService from './UserService';
import { toast, ToastContainer } from 'react-toastify';
import Axios from 'axios';
import Decrypt from '../Utils/decrypt';
export const AuthContext = createContext({});

const AuthProvider = (props) => {

  const [session, setSession] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const showToast =(type, message) =>{
    if(type==="success")    
        toast.success(message);
    else if (type==="error")
        toast.error(message);
  }
  const initSession = useCallback(() => new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    // console.log({user})
    if (user) {
      user.getSession((err, userSession) => {
        setSession(err ? null : userSession);
        resolve();
      });
    } else {
      setSession(null);
      resolve();
    }
  }), []);

  const logout = useCallback(() => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      localStorage.clear();
      sessionStorage.clear();
      setSession(null);
    }
  }, []);

  const forgotPassword = useCallback(async (Username, setOtpSent) => await new Promise((resolve, reject) => {
    // console.log({Username})
    const user = new CognitoUser({ Username, Pool });
    user.forgotPassword({
      onSuccess: function(data) {
        if(setOtpSent){
          setOtpSent(true);
        }
        showToast("success", "Verification code sent successfully");
        // console.log('CodeDeliveryData from forgotPassword: ' + data);
      },
      
      onFailure: function(err) {
        showToast("error", err.message)
        if(setOtpSent){
          setOtpSent(false);
        }
        // console.log(err.message || JSON.stringify(err));
      }
    });
  }), []);

  const validateResetPasswordCode = useCallback(async (Username, newPassword, verificationCode, setWrongCode) => await new Promise((resolve, reject) => {
    // console.log({Username, setWrongCode})
    const user = new CognitoUser({ Username, Pool });
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        showToast("success", "Password updated successfully")
        logout();
        if(setWrongCode){
          setWrongCode("")
        }
        window.location.replace("/");
        // console.log('Password confirmed!');
      },
      onFailure(err) {
        // console.log({err, setWrongCode})
        if(err.message==="PostConfirmation failed with error HandlerDemo.ResponseFound:Resource is Redirecting to qa-eflex.okaygo.in."){
          showToast("success", "Password updated successfully");
          if(setWrongCode){
            setWrongCode("")
            // console.log("Hello")
            window.location.replace("/");
          }
          logout();
        }
        else if(err.message==="Invalid verification code provided, please try again."){
          if(setWrongCode){
            setWrongCode("Entered verification code is incorrect. Please check and enter again");
          }
          else{
            showToast("error", "Entered verification code is incorrect. Please check and enter again")
          }
        }
        else{
          showToast("error", err.message)
        }
      },
    });
  }), []);

  const login = useCallback(async (Username, Password) => await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });
    const userService = new UserService();
    Axios.defaults.headers.common['Authorization'] = localStorage.getItem("accessToken")
    user.authenticateUser(authDetails, {
      onSuccess: data => {
        // // console.log('AuthProvider:login:onSuccess:', data);
        // // console.log(data.accessToken.jwtToken)
        localStorage.setItem("accessToken", data.accessToken.jwtToken)
        // // console.log(sessionStorage.getItem("accessToken"))
        userService.loginToPortal(Username, data.accessToken.jwtToken).then(async response => {
          
          if(response.ok) {
            let data = await response.text();
            // console.log("data=========>",data)
            let mainData = Decrypt(data)
            // console.log("Decrypt=========>",mainData)
            // console.log("AuthProvider:login:onSuccess:response: " + JSON.stringify(data));
            
            setUser(mainData);
            initSession().then(resolve);
          } else {
            // console.log({response});
            logout();
            // console.log("AuthProvider:login:onSuccess: API failure");
            reject("AuthProvider:login:onSuccess: loginToPortal API Failure");
            logout();
          }
        }, err => {
          logout();
          // console.error('AuthProvider:login:onFailure:', err);
          reject(err);
        }).catch(error => {
          logout();
          // console.error('AuthProvider:login:onFailure:', error);
          reject(error);
        });
      },

      onFailure: err => {
        // console.error('AuthProvider:login:onFailure:', err);
        reject(err);
      },

      newPasswordRequired: data => {
        // // console.log('AuthProvider:login:newPasswordRequired:', data);
        resolve();
      }
    });
  }), [initSession]);

  

  const setUser = useCallback((response) => {
     
      let data = JSON.parse(response)
    
      // console.log("data in setuser====>",data)
      localStorage.setItem("userName", data.userName)
      localStorage.setItem("fullName", data.fullName)
      localStorage.setItem("userID", data.userID)
      localStorage.setItem("roleType", data.roleType)
      localStorage.setItem("phoneNumber", data.phoneNumber)
      localStorage.setItem("asaanRole", data.roleType===25 || data.roleType=== 26 || data.roleType=== 30)
      setUserDetails({
        fullName: data.fullName,
        userID: data.userID,
        roleType: data.roleType,
        userName: data.userName,
        phoneNumber: data.phoneNumber
      });
      // window.location.replace("/");
  

  }, []);

  return (
    <AuthContext.Provider value={{
      initSession,
      login,
      logout,
      session,
      setUser,
      userDetails,
      forgotPassword,
      validateResetPasswordCode,
      showToast
    }}>
      <div>
        <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={10000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        ></ToastContainer>
      {props.children}
      </div>
    </AuthContext.Provider>
  );
};

export default AuthProvider;