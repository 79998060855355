import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Axios from 'axios'
import urlLinks from '../../../../UrlLinks';
import { useDebounce } from "use-debounce";

export default function Asynchronous(props) {
    const [options, setOptions] = React.useState([]);
    const {facilityData, setFacilityData} = props
  

  
  const getOptionsData =() =>{
      Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.hubBasedFacilityList}${localStorage.getItem("userID")}`)
      .then(response =>{
        // console.log(response.data)
        setOptions(response.data)
      })
  }
  useEffect(() => {
    getOptionsData()
  }, [])

  return (
    <Autocomplete
      id="FacilitySearch"
      value={facilityData}
      onChange={(event, value) => setFacilityData(value)}
      getOptionLabel={(option) => option.facilityName}
      options={options}
      renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search and select facility"
              InputProps={{
                  ...params.InputProps,
                  style:{ width: 240, borderRadius: 0, padding:0, marginTop: "-6px"}
              }}
            />
      )}
    />
  );
}
