import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
//import { Divider , Badge } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel, Switch, Typography } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import Modal from '@material-ui/core/Modal';
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
import { numberFormat } from '../../Utils/CommonFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StatusCell from './components/status'
// import filter from '../../assets/filter.svg';
//import Filters from './FilterBase';
import Filters from './filters';
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import AssignRecruiter from './components/ReassignModal'
import filterIcon from './filterIcon.png'
import style from './index.module.scss';
import qs from 'querystring'
import { Link } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createVerificationStatus } from '../../services/CommonServices'
import { AuthContext } from '../../services/AuthProvider';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import GenerateInvoiceModal from './components/GenerateInvoiceModal'
import { ToastContainer } from 'react-toastify'
import SendMsg from './components/SendMsg'
import LastSentMsgs from '../WorkerData/components/LastSentMsgs'
import Autocomplete from '@material-ui/lab/Autocomplete';
import './indeterminateCheckbox.css'
import Decrypt from '../../Utils/decrypt';

const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }

    }, paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} />
            </React.Fragment>
        )
    }
)

const ExternalWorker = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataBalnce, setDataBalance] = useState();
    const [openReassignModal, setOpenReassignModal] = useState(false)
    const [actions, setActions] = useState(0)
    const { showToast } = useContext(AuthContext);
    const [openSendMsg, setOpenSendMsg] = useState(false)
    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [allRecord, setAllRecord] = useState([]);
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false)
    const [openCasperOnboardingModal, setOpenCasperOnboardingModal] = useState(false)
    const [disablePayments, setDisablePayments] = useState(true);
    const [recruiterList, setRecruiterList] = useState([])
    const [partner, setPartner] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(0);
    const [searchBy, setSearchBy] = useState("");
    const [recruiterAssign, setRecruiterAssign] = useState("")
    const [searchByUrl, setSearchByUrl] = useState("")
    const [disableReAssign, setDisableReAssign] = useState(true)
    const [sortUrl, setSortUrl] = useState("");
    const [filtersUrl, setFiltersUrl] = useState("")
    const [status, setStatus] = useState([])
    const [filters, setFilters] = useState({
        state: "",
        client:"",
        city: "",
        assignedTo: "",
        lastEarningDate:[null, null],
        status:""

    })
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.getPartner}`)
            .then(response => {
                // console.log(response.data.response)
                let a = []
                response.data.response.map(e => {
                    a.push([e.partner_id, e.company_name])
                })
                setPartner(a)

                // partnersArray=response.data.response;

            })
    }, [])
    const makeRecruiterData =(data) =>{
        let list=[];
        data.Content.map(el =>{
            list.push({
                id: el.recruiterId,
                name:` ${el.firstName} ${el.lastName ?" "+el.lastName:""}` ,
            })
            return true
        })
        console.log(list)
        return list
    }
    const ApplyFilters = () => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`;
            if (filters["state"]) {
                url = `${url}&state=${filters["state"].name}`
        }
        if (filters["client"]) {
            url = `${url}&partnerId=${filters["client"].id}`
        }
        if (filters["city"]) {
            url = `${url}&city=${filters["city"].name}`
        }
        if (filters["assignedTo"]) {
            url = `${url}&recruiterId=${filters["assignedTo"].id}`
        }
        if (filters["lastEarningDate"][0] && !filters["lastEarningDate"][1])
            url = `${url}&lastEarningDateFrom=${moment(filters["lastEarningDate"][0]).format("YYYY-MM-DD")}&lastEarningDateTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["lastEarningDate"][0] && filters["lastEarningDate"][1])
            url = `${url}&lastEarningDateFrom=2019-01-01&lastEarningDateTo=${moment(filters["lastEarningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["lastEarningDate"][0] && filters["lastEarningDate"][1])
            url = `${url}&lastEarningDateFrom=${moment(filters["lastEarningDate"][0]).format("YYYY-MM-DD")}&lastEarningDateTo=${moment(filters["lastEarningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["status"]) {
            url = `${url}&status=${filters["status"].id}`
        }
        setFiltersUrl(url)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            state: "",
            client:"",
            city: "",
            assignedTo: "",
            lastEarningDate:[null, null],
            status:""

        }
        setFilters(filterList)
        setFiltersUrl('')
        setIsFilterApplied(false)
        setCurrentPage(1)
        return filterList;
    }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}configmaster/?category_sub_type=retention_call_status&category_type=retention_call_status`)
            .then(async responseStatus =>{
                await setStatus(makeStatusData(responseStatus.data.response.content))
            })
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.recruiterList}`)
            .then(response =>{
                setRecruiterList(makeRecruiterData(response.data.response)) 
            })
    }, [])
    
    const handleSearch =() =>{
        if(searchBy){
            setSearchByUrl(`&searchBy=${searchBy}`)
        }
        setCurrentPage(1)
    }
    // console.log(financialAdmin)
    const columns = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{ display:"flex", justifyItems:"center" }} />
                    </div>
                ),
            },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                // Cell: row =>{
                    //     return <Link to={{pathname:`/workerview/${row.row.original.userId}`}}  target="_blank" className="nameLink">{row.row.original.name}</Link>
                    // },
                    defaultCanFilter: false,
                    defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client Name",
                accessor: "clientName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Hub Name",
                accessor: "hubName",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "City",
                accessor: "city",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "State",
                accessor: "state",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Last Earning Date ",
                accessor: "lastEarningDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: false
            },
            {
                Header: "Assigned To",
                accessor: "assignedTo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Assigned On",
                accessor: "assignedOn",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Call Status",
                accessor: "callStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Worker Status",
                accessor: "workerStatus",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Dropped Status",
                accessor: "dropReason",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Low earning reason",
                accessor: "low_earning_reason",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Follow Up Date & Time",
                accessor: "followUpLead",
                Cell: row => {
                    return <div style={{width: "0px"}}>{row.row.original.followUpLead}</div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Status Remark",
                accessor: "reason",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Status",
            //     Cell: StatusCell,
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Last Called On",
                accessor: "lastStatusUpdate",
                defaultCanFilter: false,
                defaultCanSort: false
            }
        ],
        [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage]
    );

    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        response.content.map(el => {
            // let leftReasonStatus= (el.leftReasonStatus && el.reasonId===5)?  ` - ${el.leftReasonStatus}`:"" 
            // let reasonStatus= (el.reason && el.reasonId===4)?  ` - ${el.reason}`:"" 
            extractedData.push({
                okaygoId: el.okaygoId || "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                clientName: el.clientName || "",
                hubName: el.facilityName || "",
                city: el.facilityCity|| "",
                state: el.facilityState || "",
                lastEarningDate: el.lastEarningDate? moment(el.lastEarningDate).format("DD/MM/YYYY hh:mm a") : "",
                assignedToId: el.assignedTo || "",
                assignedTo: el.recruiterName ||"",
                assignedOn: el.assignedOn? moment(el.assignedOn).format("DD/MM/YYYY hh:mm a") : "",
                reasonId: el.reasonId || "",
                // status: el.status? `${el.status}${leftReasonStatus}${reasonStatus}`: "No update",
                lastStatusUpdate: el.statusUpdatedOn? moment(el.statusUpdatedOn).format("DD/MM/YYYY hh:mm a") : "",
                leftReason: el.leftReason || "",
                retentionCallId: el.retentionCallId || "",
                workerId: el.workerId,
                low_earning_reason:el.low_earning_reason||"",
                reason:el.reason||"",
                callStatus:el.call_status||"",
                workerStatus:el.worker_status||"",
                dropReason:el.drop_reason||"",
                followUpLead: `${el.follow_up_date ? moment(el.follow_up_date).format("DD/MM/YYYY") : "-"} ${el.follow_up_time ? moment(el.follow_up_time)._i : "-"}`,

            });
            return true;
        });
        
        console.log({extractedData})
        return extractedData;
    }

    //Function to create All record export data

    const updateMyData = () => {
        // console.log({filters, filterUrl})
        getPaymentListing(perPage, currentPage);
    }

    useEffect(() => {
      console.log({data})
    }, [data])
    const makeStatusData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.typeKey,
                name: el.typeValue
            })
            return true
        })
        return list
    }
    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getPaymentListing = (rows, pageNo) => {
        setLoading(true)
        console.log({sortUrl, filtersUrl})
        let requestedBy="&admin=1"
        Axios.get(`${urlLinks.baseUrl}${urlLinks.retentionCalls.retentionCallListing}?page_no=${pageNo - 1}${requestedBy}&rows=${rows}${filtersUrl}${sortUrl}${searchByUrl}&forDashBoard=1`)
            .then(response => {
                if (response) {
                    console.log({response})
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if (currentPage > response.data.response.totalPages)
                        setCurrentPage(1)
                    setData(makeData(response.data.response))
                    setLoading(false)
                    
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }
    useEffect(() => {
        if(status.length>0){
            getPaymentListing(perPage, 1);
        }

    }, [filtersUrl, sortUrl, localStorage.getItem("roleType"),  searchByUrl, status])

    const handlePageChange = page => {
        getPaymentListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected,toggleAllRowsSelected,  state: { selectedRowIds } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, status, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getPaymentListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisablePayments(disableButton);
            // // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    // console.log(localStorage.getItem("roleType"))
    const createExcelData =(records) =>{
        let list= []
        records.map(el =>{
            console.log(el)
            // let leftReasonStatus= (el.leftReasonStatus && el.reasonId===5)?  ` - ${el.leftReasonStatus}`:"" 
            // let reasonStatus= (el.reason && el.reasonId===4)?  ` - ${el.reason}`:"" 
            list.push({
                okaygoId: el.okaygoId || "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                clientName: el.clientName || "",
                hubName: el.facilityName || "",
                city: el.facilityCity|| "",
                state: el.facilityState || "",
                lastEarningDate: el.lastEarningDate? moment(el.lastEarningDate).format("DD/MM/YYYY hh:mm a") : "",
                assignedTo: el.recruiterName ||"",
                assignedOn: el.assignedOn? moment(el.assignedOn).format("DD/MM/YYYY hh:mm a") : "",
                // status: el.status? `${el.status}${leftReasonStatus}${reasonStatus}`: "No update",
                lastCalledOn: el.statusUpdatedOn? moment(el.statusUpdatedOn).format("DD/MM/YYYY hh:mm a") : "",
                reason:el.reason||"",
                callStatus:el.call_status||"",
                workerStatus:el.worker_status||"",
                dropReason:el.drop_reason||"",
                low_earning_reason:el.low_earning_reason||"",
                follow_up_date:el.follow_up_date||"",
                follow_up_time:el.follow_up_time||"",
            })
            return true
        })
        console.log("all records", {list})
        return list;
    }
    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const createAllRecordExcel = () => {
        setAllRecordLoading(true)
        let requestedBy="&admin=1"
        Axios.get(`${urlLinks.baseUrl}${urlLinks.retentionCalls.retentionCallListing}?page_no=${currentPage - 1}${requestedBy}&excelExport=1&rows=${perPage}${filtersUrl}${sortUrl}${searchByUrl}&forDashBoard=1`)
            .then(response => {
                if (response) {
                    console.log({response})
                    setAllRecord(createExcelData(response.data.response))
                }
            })
            .catch(error => {
                setLoading(false)
            })
        
    }

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisableReAssign(disableButton);
            // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    // console.log({ allRecord })
    const handleReAssign =(recruiter) =>{
        
        setRecruiterAssign("")
        const indexes = Object.keys(selectedRowIds)
        let userIdList = []
        indexes.map(id => {
            userIdList.push(data[id].retentionCallId)
        })
        let postData={
            recruiterId : recruiter.id,
            retentionCallIds : userIdList
        }
        Axios.post(`${urlLinks.baseUrl}${urlLinks.retentionCalls.AssignRecruter}`, qs.stringify(postData))
        .then(response => {
            if (response.data.code === 1000) {
                    showToast("success", `Users are assigned to ${recruiter.name}`)
                    toggleAllRowsSelected(false)
                    updateMyData();
                }
                else if (response.data.code === 1001) {
                    showToast("error", response.data.message)
                }
            })
            .catch(error => {
                showToast("error", error.message)
            })

    

    }
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameV2 zIndex1080 header" :
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" :
                                            column.render('Header') === "Last SMS sent" ? "width200" : "header"}>


                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="row">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameV2 zIndex540" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid container xs={12}  >  
                    <Grid><span className="fontSize24 fontWeightBold">Retention Calls</span>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="flexDisplay" alignItems='flex-start'>
                    <Grid item sm={7} className="flexDisplay padding0 paddingTop8" direction="row" justify="space-between">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder={"Search by OkayGo Id / Phone number"}
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={() => {
                                    setSearchBy("")
                                    setSearchByUrl("")
                                }}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="flexDisplay paddingTop8" direction="row-reverse" alignItems='flex-end'>
                    <button
                            className={styles.filterButton}
                            style={{ marginLeft: "16px" }}
                            onClick={() => setOpenFilterDrawer(true)}
                        >
                            <img src={filterIcon} alt="filterIcon" /> Filters
                        </button>
                    </Grid>

                </Grid>
                <Grid item xs={12} className="flexDisplay paddingTop16" direction="row" alignItems='flex-start'>
                    <Grid item xs={6} className="" justifyContent='flex-end'>
                        
                        <button
                            className={styles.reassignButton}
                            onClick={() =>setOpenReassignModal(true)}
                            disabled={disableReAssign}
                        >
                            RE-ASSIGN
                        </button>
                    </Grid>
                    <Grid item xs={6} className="" justifyContent='flex-end' style={{direction: "rtl"}}> 

                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled={allRecordLoading}
                            onClick={createAllRecordExcel}
                            style={{ marginLeft: "25px" }}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading ?
                            <span style={{ marginLeft: "20px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={`RetentionCallList_${moment().format("DD/MM/YYYY HH:mm")}.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginLeft: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink>
                                : null}
                    </Grid>
                </Grid>
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
                <Drawer anchor="right" open={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                    <Filters
                        handleClose={() => setOpenFilterDrawer(false)}
                        filters={filters}
                        setFilters={setFilters}
                        applyFilters={ApplyFilters}
                        resetFilters={resetFilters}
                    />
                </Drawer>
                {
                    <AssignRecruiter
                        open={openReassignModal}
                        close ={setOpenReassignModal}
                        recruiterList={recruiterList}
                        handleReAssign={handleReAssign}
                    />
                }
            </Grid>
        </Styles>
    )
}

export default ExternalWorker;


