import React, { useState, useEffect } from 'react'
import { FormControl, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'
import * as moment from "moment"
import Axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';
import style from './index.module.scss';
import DateFilter from './FilterComponents/DateFilter';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import AutoSelectFilter from './FilterComponents/AutoSelectFilter';
import ScaleLoader from "react-spinners/ScaleLoader";
import SelectFilter from './FilterComponents/SelectFilter'
import DateFnsUtils from '@date-io/date-fns';
import FormLabel from '@material-ui/core/FormLabel';
import urlLinks from '../../UrlLinks'
import LeadCitySearch from './components/LeadCitySearch'

const FilterDrawer = (props) => {
    const { handleClose, resetFilters, applyFilters, filters, setFilters } = props;
    const [workerPartners, setWorkerPartners] = useState([])
    const [hubList, setHubList] = useState([])
    const [hubFacilityList, sethubFacilityList] = useState([])
    const [recruiterList, setRecruiterList] = useState([])
    const [hubstateList, sethubstateList] = useState([])
    const [hubCityList, sethubCityList] = useState([])
    const [shiftStatusList, setShiftStatusList] = useState([])
    const [leadCityList, setLeadCityList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const handleCancelFilters =() =>{
        // resetFilters();
        handleClose();
    }
    const workerStatusData=[
        {
            id: 10,
            name: "1 employer"
        },
        {
            id: 0,
            name: "more than one"
        }
    ] 

    const yesNo=[
        {
            id: 1,
            name: "Yes"
        },
        {
            id: 0,
            name: "No"
        }
    ] 
    const lastEarningOtions=[
        {
            id: "last3days",
            name: "Last 3 days"
        },{
            id: "last5days",
            name: "Last 5 days"
        },{
            id: "last7days",
            name: "Last 7 days"
        },
    ] 

    const workerStatus = [
        { id:100, name:"Not Onboarded"},
        { id:1, name:"Active"},
        { id:2, name:"Partially Onboarded"},
        { id:3, name:"Inactive"},
        { id:4, name:"Offboarding Initiated"},
        { id:5, name:"Onboarding Completed"},
        { id:6, name:"Temporarily Inactive"},
        { id:7, name:"Temporary Inactivation Initiated"},
        { id:8, name:"Reactivation Initiated"}
    ]
    const clientPartners=[
        {
            id: 100,
            name: "Flipkart"
        },
        {
            id: 101,
            name: "Swiggy"
        },{
            id: 103,
            name: "Zomato"
        },
        {
            id: 104,
            name: "Delhivery"
        },{
            id: 105,
            name: "JioMart"
        }
        // Delhivery
    ]
    const leadSource=[
        {
            id: "Website",
            name: "Website"
        },
        {
            id: "Facebook",
            name: "Facebook"
        },{
            id: "Apna Website",
            name: "Apna Website"
        },
        {
            id: "app",
            name: "App"
        },{
            id: "Recruiter",
            name: "Recruiter"
        },{
            id: "Referral",
            name: "Referral"
        },
        
        
    ]
    const trainingStatus=[
        {
            id: "Start Training",
            name: "Training Started"
        },
        {
            id: "1st day training done",
            name: "1st day training done"
        },{
            id: "2nd day training done",
            name: "2nd day training done"
        }
        ,{
            id: "3rd day training done",
            name: "3rd day training done"
        },
        {
            id: "Left during training",
            name: "Left during training"
        },
        {
            id: "Dropped",
            name: "Dropped"
        },
    ]
    const interviewStatus=[
        {
            id: "Interview Done",
            name: "Interview Done"
        },
        {
            id: "Dropped",
            name: "Dropped"
        },
        {
            id: "Rejected",
            name: "Rejected"
        },
        {
            id: "Start Training",
            name: "Start Training"
        },
        // Rejected
    ]
    const callStatus=[
        {
            id: "Interested",
            name: "Interested"
        },
        {
            id: "Call Again",
            name: "Call Again"
        },
        {
            id: "Not Interested",
            name: "Not Interested"
        }
        // ,{
        //     id: "Select",
        //     name: "Select"
        // },
        // {
        //     id: "Joined",
        //     name: "Joined"
        // },
        // {
        //     id: "Dropped",
        //     name: "Dropped"
        // },{
        //     id: "Concern Raised",
        //     name: "Concern Raised"
        // },{
        //     id: "Start Training",
        //     name: "Start Training"
        // }
        
    ]
    const psfOptions=[
        {
            id: 0,
            name: "0/4"
        },
        {
            id: 1,
            name: "1/4"
        },
        {
            id: 2,
            name: "2/4"
        },
        {
            id: 3,
            name: "3/4"
        },
        {
            id: 4,
            name: "Completed"
        }
    ]

    console.log({filters})
    const loader=<div style={{margin:"130px auto"}}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}    
        />
    </div>
    const makeWorkerPartnerData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.partner_id,
                name: el.company_name
            })
            return true
        })
        return list
    }
    const makeShiftStatusData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.typeValue,
                name: el.typeDesc
            })
            return true
        })
        return list
    }
    const makeHubData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el.facilityName,
                name: el.facilityName
            })
            return true
        })
        return list
    }

    const makeRecruiterData =(data) =>{
        let list=[];
        data.Content.map(el =>{
            list.push({
                id: el.recruiterId,
                name:` ${el.firstName} ${el.lastName ?" "+el.lastName:""}` ,
            })
            return true
        })
        console.log(list)
        return list
    }

    const makeHubCityData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    const makeLeadCityData =(data) =>{
        let list=[];
        data.map(el =>{
            if(el!==null &&el!==""){
            list.push({
                id: el,
                name: el
            })}
            return true
        })
        return list
    }
    const makeHubStateData =(data) =>{
        let list=[];
        data.map(el =>{
            list.push({
                id: el,
                name: el
            })
            return true
        })
        return list
    }
    const handleWorkingFor =(event) =>{
        setFilters({...filters, [event.target.name]: event.target.value})
    }
    useEffect(() => {
        if(workerPartners.length>0 && shiftStatusList.length>0){
            setIsLoading(false)
        }
    }, [workerPartners, shiftStatusList])
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`)
            .then(response =>{
                console.log(response)
                setWorkerPartners(makeWorkerPartnerData(response.data.response))
            })
        
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubs}`)
        .then(response =>{
            sethubFacilityList(makeHubData(response.data.response)) 
        })
        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.recruiterList}`)
        .then(response =>{
            setRecruiterList(makeRecruiterData(response.data.response)) 
        })

        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubCity}`)
        .then(response =>{
            sethubCityList(makeHubCityData(response.data.response))
        })

        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubState}`)
        .then(response =>{
            sethubstateList(makeHubStateData(response.data.response))
        })

        Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.shiftStatusList}`)
        .then(response =>{
            setShiftStatusList(makeShiftStatusData(response.data.response.content))
        })

        Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.leadCities}`)
        .then(response =>{
            setLeadCityList(makeLeadCityData(response.data.response.content))
        })
        
    }, [])
    const output=isLoading?<Grid container style={{ width: "520px", marginTop: '20px' }}>{loader}</Grid>:<Grid container style={{ width: "520px", marginTop: '20px' }}>
        <Grid container className={`${style.FilterHeadingContainer}`}>
            <div className={style.flexBaseLine}>
                <Button onClick={handleClose} className={style.filterCloseArrow}>
                    <ArrowForwardIcon />
                </Button>
                <span className={style.filterHeading}>Filters</span>
            </div>
            <div>
                <Button
                    onClick={resetFilters}
                    className={style.resetFiltersButton}
                >
                    Reset
                </Button>
            </div>
        </Grid>
        <div className={style.filterHeadingDivider}></div>
        <Grid container className={style.filtersContainer}>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Lead City
                </span>
                <LeadCitySearch
                 filters={filters}
                 setFilters={setFilters}
                 id="leadCity"
            //  facilityData={facilityData} setFacilityData={setFacilityData}
             />
                {/* <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="leadCity"
                    options={leadCityList}
                /> */}
            </Grid>
          
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Source
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="source"
                    options={workerPartners}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Lead Source
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="leadSource"
                    options={leadSource}
                />
            </Grid>

            
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Client
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="partnerId"
                    options={clientPartners}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="workerStatus"
                    options={workerStatus}
                />
            </Grid>
            
          
            
              {/* <Grid item xs={12} className={`padding0 ${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Working for
                </span>
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="workFor" value={filters["workFor"]} onChange={handleWorkingFor}>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="1 Employer" />
                        <FormControlLabel value="2" control={<Radio color="primary" />} label="More than one" />
                    </RadioGroup>
                </FormControl>
            </Grid>   */}

            {/* <Grid item xs={12} className={style.singleFilterContainer}>
                <span className={style.filterTitle}>
                    Shift status
                </span>
                <SelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multiple={false}
                    id="shiftStatus"
                    optionList={shiftStatusList}
                />
            </Grid> */}
            {/* <Grid item xs={12} className={style.singleFilterContainer}>
                <span className={style.filterTitle}>
                    Working for
                </span>
                <SelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multiple={false}
                    id="workerStatus"
                    optionList={workerStatusData}
                />
            </Grid> */}
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Hub
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="facilityName"
                    options={hubFacilityList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Hub State
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="facilityState"
                    options={hubstateList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Hub City
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="facilityCity"
                    options={hubCityList}
                />
            </Grid>
          
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Profile Completion
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="psf"
                    options={psfOptions}
                />
            </Grid>

            
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Call Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="callStatus"
                    options={callStatus}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Interview Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="interviewStatus"
                    options={interviewStatus}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Training Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="trainingUpdate"
                    options={trainingStatus}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Preferred Hub
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="preferedHub"
                    options={hubFacilityList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Recruiter assigned
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="recruiterId"
                    options={recruiterList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Registered on app
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="appLead"
                    options={yesNo}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Bank details filled
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="bankDetails"
                    options={yesNo}
                />
            </Grid>
            <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>    
                <span className={style.filterTitle}>
               Interview Date
                </span>
                </Grid>
                <Grid  xs={7}>
                 <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="interviewDate"
                />
                   </Grid>
            </Grid>
            <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>    
                <span className={style.filterTitle}>
               First Earning Date
                </span>
                </Grid>
                <Grid  xs={7}>
                 <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="firstEarningDate"
                />
                   </Grid>
            </Grid>
            <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>    
                <span className={style.filterTitle}>
               Lead Added on
                </span>
                </Grid>
                <Grid  xs={7}>
                 <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="leadAddedDate"
                />
                   </Grid>
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Not Earning From
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="lastEarning"
                    options={lastEarningOtions}
                />
            </Grid>
            {/* firstEarningTo */}
            {/* <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Recruiter assigned
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="recruiterId"
                    options={recruiterList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Recruiter assigned
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="recruiterId"
                    options={recruiterList}
                />
            </Grid> */}
        </Grid>
        <Grid container className={style.filterButtonDividerContainer}>
            <div className={style.filterHeadingDivider}></div>
            <Grid container style={{ marginTop: '30px' }} className='padding0'>
                <div style={{
                    paddingRight: '16px',
                    paddingLeft: '300px'
                }}>  
                    <Button variant="outlined" onClick={handleCancelFilters} size="medium" color="primary" >
                        Cancel
                    </Button>
                </div>
                <Button variant="contained" size="medium" color="primary" onClick={()=>{applyFilters(); handleClose()}}>Apply</Button>
                
            </Grid>
        </Grid>
    </Grid >
    return output;
}

export default FilterDrawer;