import React, { useState, useEffect } from 'react'
import EditableDropdownSubComponent from './EditableDropdownSubComponent'
const EditableDropdown =( props) =>{
    const {row, column, updateMyData, showToast, interviewAddress, setLoading}= props
    console.log(row.original.leader )
    const dropdown= 
        <EditableDropdownSubComponent 
            row={row.original}
            columnId={column.id}
            showToast={showToast}
            // initialValue={row.original[column.id]?row.original[column.id]:column.id}
            initialValue={column.id==="Status"?row.original.workerStatus:row.original.leader}
            updateMyData={updateMyData}
            setLoading={setLoading}
            interviewAddress={interviewAddress}
        />
    return ( 
        dropdown
    )
}

export default EditableDropdown;