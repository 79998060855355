import React, { useState, useEffect, useMemo, useContext} from 'react'
import styled from 'styled-components'
import {Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import styles from './index.module.scss'
import { CSVLink } from 'react-csv';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import SearchIcon from "@material-ui/icons/Search";
import {dateFormat, createWorkerStatus } from  '../../services/CommonServices'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import {  makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
import NoRecord from './noRecord.svg'
import ToggleButton from '@material-ui/lab/ToggleButton';
// import filter from '../../assets/filter.svg'
import ScaleLoader from "react-spinners/ScaleLoader";
import LastSentMsgs from '../WorkerData/components/LastSentMsgs'
// import JSZip from 'jszip';
// import JSZipUtils from 'jszip-utils';
import {AuthContext} from '../../services/AuthProvider'
import SendMsg from './components/SendMsg'
import pendingIcon from './Pending_icon1.svg'
import verifyIcon from './Verified_icon.svg'
import mismatchIcon from './Mismatch_icon.svg'
import failedIcon from './Failed_icon.svg'
// import pendingIcon from './Pending_icon.svg'
// import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom'
import swal from 'sweetalert2'
import UrlLinks from '../../UrlLinks'
import './indeterminateCheckbox.css'
import Decrypt from '../../Utils/decrypt'

const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            width: 95vw;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-root": {
        //  border:"1px solid grey",
        marginLeft:'0',
        "&.Mui-focused fieldset": {
          border: "1px solid grey"
        }
      }
    },
    input: {
      // padding:0,
      '& [class*="MuiOutlinedInput-input"]': {
        padding: "8px",
        borderLeft: "1px solid grey",
        borderRadius: "0px"
      }
    },
    select:{
        "& .MuiOutlinedInput-inputMarginDense":{
            padding: "8px 10px"
        }
    }
  }));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <React.Fragment>
          <Checkbox ref={resolvedRef} {...rest} />
        </React.Fragment>
      )
    }
  )
  
const BankDetailsList =(props) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [allRecordLoading, setAllRecordLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allRecord, setAllRecord] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [selectedUserIds, setSelectedUserIds] = useState("")
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [openSendMsg, setOpenSendMsg] = useState(false)
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState("")
    const [sortDirection, setSortDirection] = useState(false)
    const [sortDirectionUrl, setSortDirectionUrl] = useState('')
    const [searchBy, setSearchBy] = useState("")
    const [disablePayments, setDisablePayments] = useState(true);
    const [sortUrl, setSortUrl] = useState("")
    const [filterUrl, setFilterUrl] = useState("")
    const [filters, setFilters] = useState({
        searchBy:"",
        all: true,
        // notFilled: false,
        // toBeVerified: false,
        verified: false,
        mismatch: false,
        mismatchConfirmed: false,
        failed:false,
        inprocess:false,
        invoiceGen:"-1",
        notfilled:false

        
    })
    const {showToast} = useContext(AuthContext);
    const sortOptions= [
        ["active_date", "Activation date"],
        ["offboarding_date", "offboarding initiate date"],
    ]
    const handleSearch =() =>{
        setFilters({...filters, searchBy: searchBy })
    }
    const handleSearchClose =() =>{
        setFilters({...filters, searchBy: "" });
        setSearchBy("");
    }
    console.log(selectedUserIds)
    const ApplySort = () =>{
        let url=''
        switch (sortBy) {
            case "casper_id":
                url='&orderBy=wm.casper_id'
                break;
            case "profile_id":
                url='&orderBy=wm.worker_id'
                break;
            case "okaygo_id":
                url='&orderBy=wm.okaygo_id'
                break;
            case "onboarding_date":
                url='&orderBy=wm.onboarding_initiation_date'
                break;
            case "active_date":
                url='&orderBy=wm.active_date'
                break;
            case "offboarding_date":
                url='&orderBy=wm.offboarding_initiation_date'
                break;
            case "inactive_date":
                url='&orderBy=wm.inactive_date'
                break;
            default:
                url=''
                break;
        }
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url)
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false)
        setSortDirectionUrl('')
    }, [sortBy])

    // const handleStatuses =(event, name) =>{
    //     if (name==="all"){
    //         if(!filters[name]){
    //             setFilters({...filters, notFilled: false, toBeVerified: false, mismatchConfirmed: false, mismatch: false, verified:false, all: true})
    //         }
    //     }
    //     else if (name==="notFilled"){
    //         if(!filters[name]){
    //             setFilters({...filters, notFilled: true, toBeVerified: false, mismatchConfirmed: false, mismatch: false, verified:false, all: false})
    //         }
    //     }
    //     else if (name==="toBeVerified"){
    //         if(!filters[name]){
    //             setFilters({...filters, notFilled: false, toBeVerified: true, mismatchConfirmed: false, mismatch: false, verified:false, all: false})
    //         }
    //     }
    //     else if (name==="mismatchConfirmed"){
    //         if(!filters[name]){
    //             setFilters({...filters, notFilled: false, toBeVerified: false, mismatchConfirmed: true, mismatch: false, verified:false, all: false})
    //         }
    //     }
    //     else if (name==="mismatch"){
    //         if(!filters[name]){
    //             setFilters({...filters, notFilled: false, toBeVerified: false, mismatchConfirmed: false, mismatch: true, verified:false, all: false})
    //         }
    //     }
    //     else if (name==="verified"){
    //         if(!filters[name]){
    //             setFilters({...filters, notFilled: false, toBeVerified: false, mismatchConfirmed: false, mismatch: false, verified:true, all: false})
    //         }
    //     }

    // }   

    // Penny testing 
    const handlePennyTestingStatuses =(event, name) =>{
        if (name==="all"){
            if(!filters[name]){
                setFilters({...filters, failed: false, inprocess: false, mismatchconfirmed: false, mismatch: false, verified:false, all: true,notfilled:false})
            }
        }
        else if (name==="inprocess"){
            if(!filters[name]){
                setFilters({...filters,  failed: false, inprocess: true, mismatchconfirmed: false, mismatch: false, verified:false, all: false,notfilled:false})
            }
        }
        else if (name==="verified"){
            if(!filters[name]){
                setFilters({...filters,  failed: false, inprocess: false, mismatchconfirmed: false, mismatch: false, verified:true, all: false,notfilled:false})
            }
        }
        else if (name==="mismatch"){
            if(!filters[name]){
                setFilters({...filters,  failed: false, inprocess: false, mismatchconfirmed: false, mismatch: true, verified:false, all: false,notfilled:false})
            }
        }
        else if (name==="mismatchconfirmed"){
            if(!filters[name]){
                setFilters({...filters, failed: false, inprocess: false, mismatchconfirmed: true, mismatch: false, verified:false, all: false,notfilled:false})
            }
        }
        else if (name==="failed"){
            if(!filters[name]){
                setFilters({...filters,  failed: true, inprocess: false, mismatchconfirmed: false, mismatch: false, verified:false, all: false,notfilled:false})
            }
        }
        else if (name==="notfilled"){
            if(!filters[name]){
                setFilters({...filters,  failed: false, inprocess: false, mismatchconfirmed: false, mismatch: false, verified:false, all: false,notfilled:true})
            }
        }

    }
    const ApplyFilters= () => {
        let url='';
        setAllRecord([]);
        
        if(filters["searchBy"])
            url=`${url}&searchBy=${filters["searchBy"]}`
        if(filters["notFilled"]){
            url=`${url}&bank_details=0`
        }
        // if(filters["toBeVerified"]){
        //     url=`${url}&verification_status=0`
        // }
        // if(filters["mismatchConfirmed"]){
        //     url=`${url}&verification_status=3`
        // }
        // if(filters["mismatch"]){
        //     url=`${url}&verification_status=2`
        // }
        // if(filters["verified"]){
        //     url=`${url}&verification_status=1`
        // }
        if(filters["inprocess"]){
            url=`${url}&pennyTestingStatus=2`
        }
        if(filters["mismatchconfirmed"]){
            url=`${url}&pennyTestingStatus=5`
        }
        if(filters["mismatch"]){
            url=`${url}&pennyTestingStatus=4`
        }
        if(filters["verified"]){
            url=`${url}&pennyTestingStatus=1`
        }
        if(filters["failed"]){
            url=`${url}&pennyTestingStatus=3`
        }
        if(filters["notfilled"]){
            url=`${url}&bank_details=0`
        }
        
        if(filters["invoiceGen"]==="0" || filters["invoiceGen"]==="1"){
            url=`${url}&invoiceGen=${filters["invoiceGen"]}`
        }


        setFilterUrl(url)
        setFilters(filters)
        setCurrentPage(1)
      }
    const handleInvoiceGen =(e) =>{
        console.log(e.target.value)
        setFilters({...filters, invoiceGen: e.target.value})
    }
    const resetFilters =() =>{
        const filterList={
            searchBy:"",
        }
        setFilters(filterList)
        setFilterUrl('')
        setCurrentPage(1)
        return filterList;
    }
    useEffect(() => {
        ApplyFilters();
    }, [filters])
    const handleSortDirection =() =>{
        if(!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }
    //columns list
    const handleSendMsg = () => {
        const indexes = Object.entries(selectedRowIds);
        console.log({ indexes })
        let idList = ""
        indexes.map((id, index) => {
            if (id[1]) {
                // console.log(data[parseFloat(id[0])].userId)
                idList += data[parseFloat(id[0])].userId + ",";
            }

            // console.log(idList)
            return true;
        })
        idList = idList.substr(0, idList.length - 1)
        console.log(idList)
        setSelectedUserIds(idList)
        setOpenSendMsg(!openSendMsg)
    }
    const columns = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => (
                    (<div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{display:"flex", justifyItems:"center"}}/>
                    </div>)
                ),
            },
            {
                Header: "Client id(s)",
                accessor: "casperId",
                Cell: row => {
                    return (<div>
                        {row.row.original.facilityData.map(el => {
                            return el.client_id && <div><b>{el.clientName}: </b>{el.client_id}</div>
                        })}
                    </div>);
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: row =>{
                    return (row.row.original.verification!==4 && row.row.original.bankDetailsFilled==="Yes")?<Link to={{pathname:`/bankdetailsverification/${row.row.original.contactNo_E}/${row.row.original.verification}` }} target="_blank" className="nameLink" >{row.row.original.name}</Link>:<span>{row.row.original.name}</span>
                },
                defaultCanFilter :false,
                defaultCanSort:false
            },
            // {
            //     Header: "Worker status",
            //     accessor: "status",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            {
                Header: "Penny Testing Status",
                // accessor: "pennyTestingStatus",
                Cell: row =>{
                    return (makePennyStatus(row.row.original.pennyTestingStatus,row.row.original))
                },
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Last SMS sent",
                // accessor: "zone",
                Cell: LastSentMsgs,

                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact Number",
                accessor: "contactNo",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            // {
            //     Header: "Aadhar Number",
            //     accessor: "aadharNumber",
            //     defaultCanFilter :false,
            //     defaultCanSort:false
            // },
            {
                Header: "Clients",
                accessor: "clients",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Bank Details Filled",
                accessor: "bankDetailsFilled",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Verification status",
                accessor: "verificationStatus",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Verified by",
                accessor: "verifiedBy",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Verified on",
                accessor: "verifiedOn",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Worker added on",
                accessor: "workerAddedOn",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Last updated on",
                accessor: "lastUpdatedOn",
                defaultCanFilter :false,
                defaultCanSort:false
            }
        ], 
        []
    );

    const createVerificationStatus =(status) =>{
        let text=""
        switch (status) {
            case 0:
                text="To be Verified";
                break;
            case 1:
                text="Verified";
                break;
            case 2:
                text="Mismatch";
                break;
            case 3:
                text="Mismatch confirmed";
                break;
            case 4:
                text="Not filled";
                break;      
            default:
                
                break;
        }
        return text;
    }
    const makePennyStatus = (status,row) => {
        console.log("======> "+ status)
        if (status===null){
            return "-";

        }
        switch (status) {
            case 0:
                return <div className={styles.pannyStatusDiv}>-</div>;
           case 1:
                return <div className={styles.pannyStatusDiv}><img width={'15px'} src={verifyIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}> Verified </div> <Link to={{pathname:`/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}` }} target="_blank"  className="nameLink" >{"View Details"}</Link></div>;
            case 2:
                // return "In process";
                return <div className={styles.pannyStatusDiv}><img width={'15px'} src={pendingIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  Pending  </div></div>;
            case 3:
                // return "Failed";
                return <div className={styles.pannyStatusDiv}><img width={'15px'} src={failedIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  Failed </div> <Link to={{pathname:`/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}` }} target="_blank"   className="nameLink" >{"View Details"}</Link></div>;
            case 4:
                // return "Mismatch";
                return <div className={styles.pannyStatusDiv}><img width={'15px'} src={mismatchIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  Mismatch </div> <Link to={{pathname:`/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}` }} target="_blank"   className="nameLink" >{"View Details"}</Link></div>;
            case 5:
                // return "Mismatch";
                return <div className={styles.pannyStatusDiv}><img width={'15px'} src={mismatchIcon} alt=""></img><div style={{marginRight:'15px',marginLeft:'15px'}}>  Mismatch Confirmed</div> <Link to={{pathname:`/pennyTestingverification/${row.userId}/${row.pennyTestingStatus}` }} target="_blank"  className="nameLink" >{"View Details"}</Link></div>;
            default:
                return <div className={styles.pannyStatusDiv}><Link to={{pathname:`/pennyTestingverification/${row.userId}/${row.verification}` }} className="nameLink"  >{"View Details"}</Link></div>;
        }
    }
    const makePennyStatusForExport = (status,row) => {
        console.log("======> "+ status)
        if (status===null){
            return "-";

        }
        switch (status) {
            case 0:
                return "-";
           case 1:
                return  "Verified" ;
            case 2:
                // return "In process";
                return " Pending  "
            case 3:
                // return "Failed";
                return " Failed" 
            case 4:
                // return "Mismatch";
                return "Mismatch" 
            case 5:
                // return "Mismatch";
                return "Mismatch Confirmed"
            default:
                return "";
        }
    }

    //Function to create table data
    const makeData= (response) =>{
        let extractedData = [];
        console.log({response})
        response.content.map(el => {
            extractedData.push({
                facilityData: el.clientList || [],
                casperId: el.casperId ||"",
                okaygoId: el.okaygoId|| "",
                swiggyId: el.swiggyId|| "",
                name: `${el.firstName?el.firstName:""} ${el.lastName?el.lastName:""}`,
                status: createWorkerStatus(el.workerStatus),
                pennyTestingStatus:el.pennyTestingStatus!==null?el.pennyTestingStatus:0,
                workerStatus: el.workerStatus,
                contactNo: Decrypt(el.phoneNumber) ||"",
                contactNo_E: el.phoneNumber.replaceAll('/', '%2F').replaceAll('+', '%2B')|| "",
                clients: makeMultiClient(el.clientList),
                aadharNumber: Decrypt(el.aadhaarNumber) ||"",
                bankDetailsFilled: el.bankDetails==="0"?"No":el.bankDetails==="1"?"Yes":"",
                verificationStatus: createVerificationStatus(el.verification),
                verifiedBy: el.verifiedBy || "",
                verifiedOn: dateFormat(el.verifiedOn),
                userId: el.userId,
                verification: el.verification,
                workerAddedOn: el.workerInsertedOn ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a") : "",
                lastUpdatedOn: el.lastUpdatedOn ? moment(el.lastUpdatedOn).format("DD/MM/YYYY hh:mm a") : "", 
            });
            
            return true;
        });
        console.log({extractedData})
        return extractedData;
    }
    const makeMultiClient = (clients) => {
        let str = " ";
        clients.map(el => {
            str += el.clientName + ","
        })
        return str.substring(0, str.length - 1);
    }
    const handleClientList = (el) => {
        let clientData = {}
        el.clientList.map(e => {
            if (e.clientName) {
                clientData = {
                    ...clientData,
                    [`${e.clientName} Id`]: e.client_id || ""
                }
            }


        })
        return clientData;
    }
    //Function to create All record export data
    const makeAllRecordData= (response) =>{
        let extractedData = [];
        console.log({response})
        response.content.map(el => {
            const temp={
                okaygoId: el.okaygoId|| "",
                name: `${el.firstName?el.firstName:""} ${el.lastName?el.lastName:""}`,
                // status: createWorkerStatus(el.workerStatus),
                contactNo: Decrypt(el.phoneNumber) ||"",
                // aadharNumber: el.aadhaarNumber ||"",
                clients: makeMultiClient(el.clientList),
                bankDetailsFilled: el.bankDetails==="0"?"No":el.bankDetails==="1"?"Yes":"",
                pennyTestingStatus:el.pennyTestingStatus?makePennyStatusForExport(el.pennyTestingStatus):"-",
                verificationStatus: createVerificationStatus(el.verification),
                verifiedBy: el.verifiedBy || "",
                verifiedOn: dateFormat(el.verifiedOn),
                ...handleClientList(el) 
            }
            if(localStorage.getItem("roleType")==="1" || localStorage.getItem("roleType")==="5"){
                delete temp["smsCount"]
            }
            console.log({temp})
            extractedData.push(temp)
            
            return true;
        });
        
        return extractedData;
    }
    const updateMyData = () => {
        getBankVerificationDetailsList(perPage, currentPage);
    }
    
    const createAllRecordExcel= () =>{
        setAllRecordLoading(true)
        Axios.get(`${urlLinks.baseUrl}${urlLinks.bankVerification.listing}?page_no=${currentPage-1}&rows=${10000}${filterUrl}${sortUrl}${sortDirectionUrl}`)
            .then(response =>{
                if(response){
                    setAllRecord(makeAllRecordData(response.data.response))
                }
            })
    }
    useEffect(() => {
        console.log("records created")
        setAllRecordLoading(false)
    }, [allRecord])
    const getBankVerificationDetailsList =(rows, pageNo) =>{
        // window.swal.cancelAlert = function() {
        //     closeModal();
        //     };
        swal.close() 
        setLoading(true)
        Axios.get(`${urlLinks.baseUrl}${urlLinks.bankVerification.listing}?page_no=${pageNo-1}&rows=${rows}${filterUrl}${sortUrl}${sortDirectionUrl}`)
            .then(response =>{
                if(response){
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if(currentPage>response.data.response.totalPages)
                        setCurrentPage(1)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
            })
            .catch(error =>{
                setLoading(false)
            })

    }
    
    useEffect(() => {
        getBankVerificationDetailsList(perPage, 1);
        
    }, [filterUrl, sortUrl,sortDirectionUrl, localStorage.getItem("roleType")])
    const handlePageChange = page => {
        getBankVerificationDetailsList(perPage, page)
        setCurrentPage(page);
    };
    // useEffect(() => {
    //     // console.log({isAllRowsSelected})
    //     rows.map((record, index) => {
    //         // console.log({record})
    //         // console.log(record, selectedRowIds[index])
    //         if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
    //             selectedRowIds[index] = false
    //             // console.log(selectedRowIds[index])
    //         }
    //     })
    //     // console.log(selectedRowIds)
    // }, [isAllRowsSelected])
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,isAllRowsSelected, state: { selectedRowIds } }  = useTable({columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true,  }, useFilters, useSortBy,  useRowSelect)
    const handlePerRowsChange =  (newPerPage) => {
        getBankVerificationDetailsList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const loader=<div style={{margin:"130px auto"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
    const table=<div className="tableContainer">
                <table {...getTableProps()}  className="table">
                    <thead className="thead">
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (  
                            <th {...column.getHeaderProps()} 
                                className={column.render('Header')==="Worker Id"?"stickWorkerIdnewMatch zIndex1080 header":
                                    column.render('Header')==="Name"?"stickWorkerNameV2 zIndex1080 header":
                                        column.id==="selection"?"stickCheckbox zIndex1080 header":"header"}>
                                
                                <span {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                </span></th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    {
                        <tbody {...getTableBodyProps()}> 
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    
                                    <tr {...row.getRowProps()} className={`row ${row.original.criminalRecord?"criminalRecord":""}`}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}   
                                            className={`${cell.column.Header==="Worker Id"?"stickWorkerIdnewMatch  zIndex540":
                                            cell.column.Header==="Name"?"stickWorkerNameV2 zIndex540":
                                                cell.column.id==="selection"?"stickCheckbox zIndex540":"cell"} ${row.original.criminalRecord==="Yes"?"criminalRecord":""}`}>{cell.render('Cell')}</td>
                                    })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    }
                </table>
            </div>
    

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisablePayments(disableButton);
            // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid item sm={12}><span className="fontSize24 fontWeightBold">Bank Details Verification</span></Grid>
                <Grid item xs={12} className="flexDisplay alignItemsFlexStart">
                    <Grid item sm={8} className="flexDisplay padding0 paddingTop8 justifySpaceBetween">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder="Enter  Aadhar No / Phone No / Casper ID/ OkayGo ID"
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress= {(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                            className: classes.input,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton 
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy?"":styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                        <Grid item sm={1} className="flexDisplay padding0 justifySpaceBetween">
                    </Grid>
                    {/* <Grid item sm={3} className="flexDisplay padding0 justifySpaceBetween">
                        
                        {sortBy && <ToggleButton
                            selected={sortDirection}
                            className = "recSortOrderButton"
                            // style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                            onChange={handleSortDirection}
                        >
                            {!sortDirection?<ArrowDownwardSharpIcon />:<ArrowUpwardSharpIcon />}
                        </ToggleButton>}
                        
                    </Grid> */}
                    
                </Grid>
                <Grid item xs={12} className="flexDisplay paddingTop16 alignItemsFlexStart">
                    {<Grid item xs={4} className=" alignItemsFlexStart">
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={createAllRecordExcel}
                            disabled={allRecordLoading}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading?
                            <span style={{marginLeft: "20px"}}>Please wait ... it might take few minutes. </span>
                            :allRecord.length>0?
                            <CSVLink
                                data={allRecord}
                                filename={`Worker List.csv`}
                                className='btn btn-primary blueColor downloadExcelButton'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    marginLeft:"20px",
                                    fontWeight: '500'
                                }}
                            >
                                Download now
                            </CSVLink>
                        :null}
                    {(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6" || localStorage.getItem("roleType") === "31") && <Button
                            style={{ marginLeft: '12px' }}
                            variant="contained"
                            className="dataButton"
                            // style={{ marginLeft: "16px" }}
                            onClick={() => handleSendMsg()}
                            disabled={disablePayments}
                            >
                            Send Message
                        </Button>}
                            </Grid>}
                </Grid>
                {/* <Grid item xs={9} className="textAlignRight" > */}
                        {/* {(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6") && <Button
                            style={{ marginRight: '12px' }}
                            variant="contained"
                            className="dataButton"
                            // style={{ marginLeft: "16px" }}
                            onClick={() => handleSendMsg()}
                            // disabled={disablePayments}
                        >
                            Send Message
                        </Button>} */}
                        {/* <FormControl variant="outlined" className="margin1" >
                            <div>   {financialAdmin &&
                                //   <div style={{    paddingLeft: '760px'}}>
                                <Button
                                    style={{ marginRight: '12px' }}
                                    variant="contained"
                                    className="dataButton"
                                    onClick={handleOpen}
                                >
                                    Check Balance
                                </Button>
                                // </div>
                            }<Button
                                style={{ marginRight: '12px' }}
                                variant="contained"
                                className="dataButton"

                                onClick={handleActions}
                            >
                                    Upload Invoice
                                </Button>
                                {financialAdmin && <Button

                                    variant="contained"
                                    className="dataButton"
                                    disabled={disablePayments}
                                    onClick={handlePayments}
                                >
                                    Trigger payment
                                </Button>}</div>
                        </FormControl> */}
                    {/* </Grid> */}
                {!loading && <Grid item xs={12} className="flexDisplay paddingTop16 justifyFlexStart" >
                    {/* <Button
                        //variant="outlined" 
                        onClick={(event) =>handleStatuses(event, "all")}
                        className={`statusButtons marginRight20 ${filters.all?"blueColorBorder":"greyColorBorder"}`}
                    >
                        All
                    </Button>
                    <Button
                        onClick={(event) =>handleStatuses(event, "notFilled")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.notFilled?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Not filled
                    </Button>
                    <Button
                        onClick={(event) =>handleStatuses(event, "toBeVerified")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.toBeVerified?"blueColorBorder":"greyColorBorder"}`}
                    >
                        To be verified
                    </Button>
                    <Button
                        onClick={(event) =>handleStatuses(event, "verified")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.verified?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Verified
                    </Button>
                    <Button
                        onClick={(event) =>handleStatuses(event, "mismatch")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.mismatch?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Mismatch
                    </Button>
                    <Button
                        onClick={(event) =>handleStatuses(event, "mismatchConfirmed")}
                        // variant="outlined"
                        className={`statusButtons ${filters.mismatchConfirmed?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Mismatch confirmed
                    </Button> */}
                    
                    {/* penny testing filter chip */}
                    <Button
                        //variant="outlined" 
                        onClick={(event) =>handlePennyTestingStatuses(event, "all")}
                        className={`statusButtons marginRight20 ${filters.all?"blueColorBorder":"greyColorBorder"}`}
                    >
                        All
                    </Button>
                    <Button
                        onClick={(event) =>handlePennyTestingStatuses(event, "inprocess")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.inprocess?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Pending
                    </Button>
                    <Button
                        onClick={(event) =>handlePennyTestingStatuses(event, "notfilled")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.notfilled?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Not Filled
                    </Button>
                    <Button
                        onClick={(event) =>handlePennyTestingStatuses(event, "verified")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.verified?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Verified
                    </Button>
                    <Button
                        onClick={(event) =>handlePennyTestingStatuses(event, "mismatch")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.mismatch?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Mismatch
                    </Button>
                    <Button
                        onClick={(event) =>handlePennyTestingStatuses(event, "mismatchconfirmed")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.mismatchconfirmed?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Mismatch Confirmed
                    </Button>
                    <Button
                        onClick={(event) =>handlePennyTestingStatuses(event, "failed")}
                        // variant="outlined"
                        className={`statusButtons ${filters.failed?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Failed
                    </Button>

                    <FormControl variant="outlined" className={styles.invoiceGeneratedDropdown} >
                            <InputLabel htmlFor="invoice-generated">Invoice Generated</InputLabel>
                            <Select
                                id="invoice-generated"
                                IconComponent= {ExpandMoreIcon}
                                value={filters.invoiceGen}
                                onChange={handleInvoiceGen}
                                className={classes.select}
                                label="Invoice Generated"
                                inputProps={{
                                    name: 'Invoice Generated',
                                    id: 'invoiceGenerated',
                                }}
                                >
                                    <MenuItem key={"All"} value="-1">
                                    All
                                    </MenuItem>
                                    <MenuItem key={"Yes"} value="1">
                                    Yes
                                    </MenuItem>
                                    <MenuItem key={"No"} value="0">
                                    No
                                    </MenuItem>
                            </Select>
                        </FormControl>
                </Grid>}
                {loading?loader:data.length===0?<div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div>:<Grid item xs={12} className= "paddingTop16">
                    {table}
                    <Pagination 
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
                {
                    openSendMsg &&
                    <SendMsg
                        // isLeadData={isLeadData}
                        filterUrl={filterUrl}
                        open={openSendMsg}
                        setOpen={setOpenSendMsg}
                        userIds={selectedUserIds}

                    // handleReactivationDate={handleReactivationDate}
                    // setReactivationData={setReactivationData}
                    />
                }
            </Grid>
        </Styles>
    )
}


export default BankDetailsList;


