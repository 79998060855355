import './App.css';
import WorkerDb from './containers/WorkerDB';
import WorkerView from './containers/WorkerView';
import WorkerViewV2 from './containers/WorkerData/WorkerView';
import LeadWorkerView from './containers/WorkerData/LeadWorkerView';
import PaymentListing from './containers/PaymentListingV2';
import PaymentListingV2 from './containers/PaymentListingV2';
import 'react-toastify/dist/ReactToastify.css';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter,  Redirect,  Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './services/AuthenticatedRoute';
import AuthProvider, { AuthContext } from './services/AuthProvider';
import Login from './containers/Login';
import Register from './containers/Register';
import RegistrationSuccess from './containers/Register/RegistrationSuccess'
import BankVerificationListing from './containers/BankDetailsVerificationListing'
import BankDetailsVerification from './containers/BankDetailsVerification'
import PennyTestingVerification from './containers/PennyTestingVerification'
import ChangePassword from './containers/Settings/ChangePassword'
import Jobsdb from './containers/JobsDB';
import AppliedWorkers from './containers/JobsDB/AppliedWorkers';
import ForgotPassword from './containers/Settings/ForgotPassword'
import WorkerData from './containers/WorkerData'
import WorkerDataV3 from './containers/WorkerDataV3'
import ShiftPlan from './containers/ShiftPlan'
import ShiftData from './containers/ShiftData'
import EmployerData from './containers/EmployerData/index'
import HubListing from './containers/HubListing/index'
import AddCompany from './containers/EmployerData/AddCompany/index'
import Axios from 'axios'
import WorkerListSwiggy from './containers/WorkerListSwiggy'
import WorkerViewSwiggy from  './containers/WorkerViewSwiggy'
import Referral from  './containers/Referral'
import HubLocation from './containers/HubLocation/HubLocation'
import HubLocationV2 from './containers/HubLocationV2/HubLocation'
import RecuiterDashboard from './containers/RecruiterDashboard/index'
import RetentionCalls from './containers/RetentionCalls'
import Map from './containers/HubLocation/map'
import BGVListing from './containers/BGVListing'
import WorkerLedger from './containers/WorkerLedger'
import ParticularLedger from './containers/ParticularLedger'
import DocumentVerification from './containers/DocumentVerification'
import VerificationDetails from './containers/DocumentVerification/VerificationDetails'

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const { initSession, logout, showToast } = useContext(AuthContext);
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem("accessToken")
  useEffect(() => {
    initSession().then(() => setInitialized(true));
  }, [initSession]);
  Axios.interceptors.response.use(response =>{
    if(response?.data?.code===1005){
      logout();
      showToast("error", "Your session has expired. Please login again")
    }
    else{
      return response;

    }
  }, error =>{
    // console.log({error});
    return Promise.reject(error);
  })

  return initialized && (
      <BrowserRouter>
        {
          (localStorage.getItem("roleType")==="25" || localStorage.getItem("roleType")==="26" || localStorage.getItem("roleType")==="30" )?
          <Switch>
            <Route exact path="/login" component={Login} /> 
            <Route path="/register" component={Register} />
            <Route exact path="/registrationsuccess" component={RegistrationSuccess} />
            <Route exact path="/resetpassword/:username" component={ForgotPassword} />
            {localStorage.getItem("roleType")==="25" && <AuthenticatedRoute exact path="/recruiter-data" component={RecuiterDashboard} />}
            <AuthenticatedRoute exact path="/Workerview/v2/:id" component={WorkerViewV2} />
            {(localStorage.getItem("roleType")==="26" || localStorage.getItem("roleType")==="30") && <AuthenticatedRoute exact path="/paymentslisting" component={PaymentListing} />}
            <AuthenticatedRoute exact path="/changepassword" component={ChangePassword} />
            {(localStorage.getItem("roleType")==="26" || localStorage.getItem("roleType")==="30") && <AuthenticatedRoute exact path="/workerDb" component={WorkerDb} />}
            {(localStorage.getItem("roleType")==="26" || localStorage.getItem("roleType")==="30") && <AuthenticatedRoute exact path="/" component={WorkerDb} />}
            {localStorage.getItem("roleType")==="25" && <AuthenticatedRoute exact path="/" component={RecuiterDashboard} />}
            <Redirect to ="/" />
          </Switch>:
          (localStorage.getItem("roleType")==="27")?
          <Switch>
            <Route exact path="/login" component={Login} /> 
            <Route path="/register" component={Register} />
            <AuthenticatedRoute exact path="/changepassword" component={ChangePassword} />
            <Route exact path="/registrationsuccess" component={RegistrationSuccess} />
            <Route exact path="/resetpassword/:username" component={ForgotPassword} />
            {localStorage.getItem("roleType")==="25" && <AuthenticatedRoute exact path="/recruiter-data" component={RecuiterDashboard} />}
            <AuthenticatedRoute exact path="/Workerview/v2/:id" component={WorkerViewV2} />
            <AuthenticatedRoute exact path="/" component={WorkerDataV3} />

            <Redirect to ="/" />
          </Switch>
          :<Switch>
            {/* <Redirect exact from="/" to="/WorkerView" /> */} 
            <Route exact path="/login" component={Login} /> 
            <Route path="/register" component={Register} />
            <Route exact path="/registrationsuccess" component={RegistrationSuccess} />
            <Route exact path="/resetpassword/:username" component={ForgotPassword} />
            <AuthenticatedRoute exact path="/Workerview/:id" component={WorkerView} /> 
            <Route exact path="/register-hub-location" component={HubLocation} /> 
            <Route exact path="/v2/register-hub-location" component={HubLocationV2} /> 
            <AuthenticatedRoute exact path="/map" component={Map} /> 
            <AuthenticatedRoute exact path="/Workerview/v2/:id" component={WorkerViewV2} />
            <AuthenticatedRoute exact path="/LeadWorkerview/v2/:id" component={LeadWorkerView} />
            <AuthenticatedRoute exact path="/employer-data" component={EmployerData} />
            <AuthenticatedRoute exact path="/hub-listing" component={HubListing} />
            <AuthenticatedRoute exact path="/employer-data/company" component={AddCompany} />          
            <AuthenticatedRoute exact path="/recruiter-data" component={RecuiterDashboard} />
            {(localStorage.getItem("roleType")==="11" || localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/WorkerviewSwiggy/:id" component={WorkerViewSwiggy} />}
            <AuthenticatedRoute exact path="/paymentslisting" component={PaymentListing} />
            <AuthenticatedRoute exact path="/paymentslistingV2" component={PaymentListingV2} />
            <AuthenticatedRoute exact path="/jobsdb" component={Jobsdb} />
            <AuthenticatedRoute exact path="/jobview/:id" component={AppliedWorkers} /> 
            {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6"  || localStorage.getItem("roleType")==="15") && <AuthenticatedRoute exact path="/bankverificationlisting" component={BankVerificationListing} />}
            {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6" || localStorage.getItem("roleType")==="15") && <AuthenticatedRoute exact path="/bankdetailsverification/:userId/:status" component={BankDetailsVerification} />}
            {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6" || localStorage.getItem("roleType")==="15") && <AuthenticatedRoute exact path="/pennyTestingverification/:userId/:pennyStatus" component={PennyTestingVerification} />}
            <AuthenticatedRoute exact path="/changepassword" component={ChangePassword} />
            {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/shiftplan" component={ShiftPlan} />}
            {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/workerdata" component={WorkerData} />}
            <AuthenticatedRoute exact path="/shiftdata" component={ShiftData} />
            <AuthenticatedRoute exact path="/retentioncalls" component={RetentionCalls} />
            {localStorage.getItem("roleType")!=="11" && <AuthenticatedRoute exact path="/WorkerListSwiggy" component={WorkerListSwiggy} />}
            <AuthenticatedRoute exact path="/referral" component={Referral} />
            <AuthenticatedRoute exact path="/workerDb" component={WorkerDb} />
            <AuthenticatedRoute exact path="/workerLedger" component={WorkerLedger} />
            <AuthenticatedRoute exact path="/particularLedger/:okaygoId" component={ParticularLedger} />
            {/* {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/workerdatav2" component={WorkerDataV2} />} */}
            {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/" component={WorkerDataV3} />}
            {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/bgvverificationlisting" component={BGVListing} />}
            {(localStorage.getItem("roleType")==="11") && <AuthenticatedRoute exact path="/" component={WorkerListSwiggy} />}
            {/* { (localStorage.getItem("roleType")!=="5"&& localStorage.getItem("roleType")!=="1") && <AuthenticatedRoute exact path="/" component={WorkerDataV2} />} */}
            { (localStorage.getItem("roleType")!=="5"&& localStorage.getItem("roleType")!=="1") && <AuthenticatedRoute exact path="/" component={WorkerDataV3} />}
            { (localStorage.getItem("roleType")==="5" || localStorage.getItem("roleType")==="1") && <AuthenticatedRoute exact path="/" component={WorkerDb} />}

            { (localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/document-verification" component={DocumentVerification} />}
            { (localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6") && <AuthenticatedRoute exact path="/verificationdetails/:rowData/:fn/:bucket" component={VerificationDetails} />}
            <Redirect to ="/" />
          </Switch>}
        </BrowserRouter>
  );
};

  const appWithAuthentication = () => (
    <AuthProvider>
      <App />
    </AuthProvider>
  );

export default appWithAuthentication;
