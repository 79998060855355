import React, { useState,  useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import styles from './index.module.scss';
import artImage from './art.svg';
import logo from './logo.svg';
import UrlLinks from '../../UrlLinks';
import Axios from 'axios';
import qs from 'querystring';
import {CognitoUserAttribute} from 'amazon-cognito-identity-js';
import { toast, ToastContainer } from 'react-toastify';
// import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
// import { Visibility, VisibilityOff } from '@material-ui/icons';
import UserPool from '../../services/UserPool';
import UserService from '../../services/UserService';
import { IconButton, Zoom, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Success from './success_tick.svg'
import Warning from './warning_tick.svg';
import IButton from './information.svg';

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  console.log(window.location.href)
  const { from } = location.state || { from: { pathname: '/registrationsuccess' } };
  const [formData, setFormData] = useState({
    userName:"",
    userNameError:false,
    userNameVerifiedSuccess:false,
    userNameVerifiedFail:false,
    email:"",
    password:"",
    confirmPassword:"",
    passwordError:null,
    confirmPasswordError:null,
    emailError:null,
    showPassword: false,
    showVerifyButton: false,
    isVerified:false
  })
  useEffect(() => {
    const link=window.location.href.split("/register/")
    console.log({link})
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.getEmail}${encodeURIComponent(link[1])}`)
      .then(response =>{
        setFormData({...formData, email: response.data.response, emailError: false})
      })
      .catch(error =>{
        setFormData({...formData, emailError: true})
      })
  }, [])
const [disabledButton, setDisabledButton] = useState(false)
  useEffect(() => {
    if(!formData.userName || !formData.userNameVerifiedSuccess || formData.userNameVerifiedFail || formData.userNameError || !formData.password || !formData.confirmPassword || formData.passwordError || formData.confirmPasswordError || !formData.isVerified ){
        setDisabledButton(true)
    }
    else{
        setDisabledButton(false)
    }
}, [formData])
  const showToast =(type, message) =>{
    if(type==="success")    
        toast.success(message);
    else if (type==="error")
        toast.error(message);
  }
  const handleClickShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };
  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };
  const verifyUsername= () =>{
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.checkAdminUserName}`, {user_name: formData.userName})
      .then(response =>{
        setFormData({...formData, userNameVerifiedSuccess:false , userNameVerifiedFail:true, showVerifyButton:false, isVerified:false })
      })
      .catch(err =>{
        setFormData({...formData, userNameVerifiedSuccess:true , userNameVerifiedFail:false, showVerifyButton:false, isVerified:true })
      })
  }
  const onSubmit = event => {
    event.preventDefault();
    
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.checkAdmin}`, {user_email: formData.email})
    .then(response =>{
      const postData={
          "user_email": formData.email,
          "user_name": formData.userName
        }
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.saveAdminUserName}`, postData)
        .then(response =>{
          const attributeList=[];
          const dataEmail={
            Name: 'email',
            Value: formData.email,
          }
          var emailAttribute= new CognitoUserAttribute(dataEmail)
          attributeList.push(emailAttribute)
          UserPool.signUp(formData.userName, formData.password, attributeList, null, (error, data) => {
            if(error){
              showToast("error", error.message)
              // console.error('Failed to login!', error);
            }
            else{
              history.replace(from);
            }
          })
          
        })
        .catch(error =>{
          showToast("error", error.message);
        })
      
      })
      .catch(err =>{
        showToast("error", "Email not authorized to register.")
      });
  }
  // // console.log({link})


  const handleUserName =(event) =>{
    if(event.target.value){
      if(/^[0-9a-zA-Z.]+$/.test(event.target.value) && event.target.value.length>7 && event.target.value.length<=20){
        setFormData({...formData, 
          showVerifyButton:true, 
          userName:event.target.value,
          userNameVerifiedFail:false,
          userNameVerifiedSuccess:false,
          userNameError:false
        })
      }
      else{
        setFormData({...formData, 
          showVerifyButton:false, 
          userName:event.target.value,
          userNameVerifiedFail:false,
          userNameVerifiedSuccess:false,
          userNameError:true
        })
      }
    }
    else{
      setFormData({...formData, userName:event.target.value, showVerifyButton: false, userNameVerifiedSuccess:false, userNameVerifiedFail:false, userNameError: false})
    }
  }
  const handlePassword =(event) =>{
    // console.log(event.target)
    let passwordError=""
    if(event.target.value){
        if(event.target.id==="confirmPassword"){
            if(event.target.value!==formData.password){
                setFormData({...formData, 
                    confirmPasswordError:"Password and confirm password does not match.", 
                    confirmPassword:event.target.value
                })
            }
            else{
                setFormData({...formData, 
                    confirmPassword:event.target.value, 
                    confirmPasswordError:null 
                })
            }
        }
        else{
            if(event.target.value.length<8){
                passwordError=passwordError+"Password must be 8 character long.";
            }
            if(!event.target.value.match(/[A-Z]/g)){
                if(passwordError){
                    passwordError=passwordError+"\n";
                }
                passwordError=passwordError+"Password must contain an uppercase letter.";
            }
            if(!event.target.value.match(/[!@#$%^&*]/g)){
                if(passwordError){
                    passwordError=passwordError+"\n";
                }
                passwordError=passwordError+"Password must contain a special character.";
            }
            if(!event.target.value.match(/[a-z]/g)){
                if(passwordError){
                    passwordError=passwordError+"\n";
                }
                passwordError=passwordError+"Password must contain a lowercase letter.";
            }
            if(!event.target.value.match(/[0-9]/g)){
                if(passwordError){
                    passwordError=passwordError+"\n";
                }
                passwordError=passwordError+"Password must contain a number.";
            }
            setFormData({...formData, 
                password: event.target.value, 
                passwordError: passwordError
            })
        }

    }
    else{
        setFormData({...formData,
            [event.target.id]:event.target.value,  
            passwordError: null, 
            confirmPassword: null
        })
    }
    
  }
  

  return (
    <div className={styles.page}>
      <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={3000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
      ></ToastContainer>
      <img src={artImage} alt="loginSide" className={styles.artImage} />
      <div className={styles.loginSection}>
        <div className={styles.logoHolder}>
          <img src={logo} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.loginForm}>
          <div className={styles.heading}>Create account</div>
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.formElement}>
              <label htmlFor="userName">
                Username 
                <Tooltip
                  // className={styles.tooltipContainer}
                  TransitionComponent={Zoom} 
                  arrow
                  enterDelay={300} 
                  leaveDelay={200}
                  interactive
                  classes={{arrow: styles.tooltipArrow, tooltip: styles.tooltip}}
                  title={
                    <div className={styles.tooltipContainer}>
                      <ul>
                        <li>Allowed characters: a-z, A-Z, 0-9, .</li>
                        <li>Length must be 8 - 20 characters</li>
                      </ul>
                    </div>
                  }
                >
                  <IconButton 
                  >
                    <img src={IButton} alt="Ibutton" />
                  </IconButton>
                </Tooltip>
              </label>
              <div style={{display: "flex"}}>
                <input
                    id="userName"
                    type="text"
                    placeholder="Create a username"
                    value={formData.userName}
                    onChange={handleUserName}
                    className={`${formData.userNameVerifiedSuccess?styles.successGreen:""} ${formData.userNameVerifiedFail?styles.warningRed:""}`}
                />
                {formData.showVerifyButton && <button
                  type="button"
                  onClick={verifyUsername}
                  disabled={formData.userName.length<8 || formData.userName.length>20}
                  className={styles.verifyButton}
                >
                  Check availability 
                </button>}
              </div>
            </div>
            {formData.userNameVerifiedSuccess && <div className={styles.userMessageContainer} >
              <img src={Success} alt="success" style={{marginRight: "10px"}} /><span style={{color: "#5cb85c"}}>username is available </span>
            </div>
            }
            {formData.userNameVerifiedFail && <div className={styles.userMessageContainer} >
              <img src={Warning} alt="fail" style={{marginRight: "10px"}}  /><span style={{color: "#d9534f"}}>Username already exists. Try a different username</span>
            </div>
            }
            {formData.userNameError && <div>
              <span style={{color: "red", whiteSpace: "pre-wrap"}}>This username is invalid. Please select a valid username that meets our username policy.</span>
            </div>
            }
            <div className={styles.formElement}>
              <label htmlFor="password">
                Password
                <Tooltip
                  // className={styles.tooltipContainer}
                  TransitionComponent={Zoom} 
                  arrow
                  enterDelay={300} 
                  leaveDelay={200}
                  interactive
                  classes={{arrow: styles.tooltipArrow, tooltip: styles.tooltip}}
                  title={
                    <div className={styles.tooltipContainer}>
                      <div className={styles.passwordText}>
                        <span>Password must be at least 8 characters long and must contain </span>
                      </div>
                      <div className={styles.passwordPolicy}>
                        <ul>
                          <li>1 upper case alphabet</li>
                          <li>1 lower case alphabet</li>
                          <li>1 numeric character</li>
                          <li>1 special character</li>
                        </ul>
                      </div>
                    </div>
                  }
                >
                  <IconButton 
                  >
                    <img src={IButton} alt="Ibutton" />
                  </IconButton>
                </Tooltip>
              </label>
              <div className={styles.showPassword}>
                <input
                    id="password"
                    type={formData.showPassword?"text":"password"}
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handlePassword}
                />
                <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                >
                    {formData.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </div>
            </div>
            {formData.passwordError && <div>
              <span style={{color: "red", whiteSpace: "pre-wrap"}}>This password is invalid. Please select a valid password that meets our password policy.</span>
            </div>
            }
            <div className={styles.formElement}>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                  id="confirmPassword"
                  type="password"
                  placeholder="Retype password"
                  value={formData.confirmPassword}
                  onChange={handlePassword}
              />
            </div>
            {formData.confirmPasswordError && <div>
              <span style={{color: "red"}}>{formData.confirmPasswordError}</span>
            </div>
            }

            <div className={styles.loginButtonHolder}>
              <button disabled={disabledButton} type="submit" className={styles.loginButton}>Register</button>
            </div>
            <div className={styles.loginButtonHolder}>
              <a
                href="/login" 
                className={styles.redirectButton}
              >
                Already have an account? Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
