import React, { useState, useEffect } from 'react'
import EditableDropdownSubComponent from './EditableDropdownSubComponent'
const EditableDropdown =( props) =>{
    const {row, column, updateMyData, showToast, interviewAddress, setLoading}= props
    const dropdown= 
        <EditableDropdownSubComponent 
            row={row.original}
            columnId={column.id}
            showToast={showToast}
            initialValue={row.original[column.id]?row.original[column.id]:column.id}
            updateMyData={updateMyData}
            setLoading={setLoading}
            interviewAddress={interviewAddress}
        />
    return (
        dropdown
    )
}

export default EditableDropdown;