import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from '../document.svg'
import ResponseModal from './ResponseModal'
import * as moment from 'moment';
import Typography from "@material-ui/core/Typography";
import Download from '../Group 3238.svg'
import { FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
    const {open, handleClose , handleEditPhoneNumberService} = props
    const [contact, setContact] = useState({
        text:open.contact,
        error:false
    })
    const [error, setError] = useState(open.error)
    const handleContact =(event) =>{
      if(event.target.value){
        if(event.target.value.length===10 &&  /^\d+$/.test(event.target.value)){
            setContact({
                text: event.target.value,
                error:false
            })
        }
        else{
          // console.log(event.target.value)
            setContact({
                text: event.target.value,
                error:true
            })
        }
      }
      else {
        setContact({
          text: event.target.value,
          error:false
      })
      }
      setError("")

    }
    useEffect(() => {
      setError(open.error)
    }, [open.error])
  
    let dialogData=
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open.open}
            className={Styles.onboardingDialog}
            classes={{paper: Styles.paper}}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <span>Edit contact number</span> 
            </DialogTitle>
            <DialogContent dividers>
              <Grid item xs className="padding0">
                <TextField
                    variant="outlined"
                    value={contact.text}
                    onChange={handleContact}
                    error={contact.error}
                    fullWidth
                    classes={{}}
                />
                {contact.error && <span className={"fontSize13 redColor"}>Enter a valid phone number</span>}
                {error && <span className={"fontSize13 redColor"}>{error}</span>}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" disabled={contact.error || error || !contact.text} onClick={() =>handleEditPhoneNumberService(contact.text, open.userId)} className={"blueButtonContained"} >
                  Submit
              </Button>
            </DialogActions>
        </Dialog>
    return dialogData; 
  
}
