import React from 'react'
import { Divider, Grid } from '@material-ui/core'
import Decrypt from '../../../Utils/decrypt'

function Documents(props) {
    const {data, documentList, bankDetails}=props
    // console.log({bankDetails})
    return (
        <Grid container className="flexDisplay" direction ="column">
            <Grid item xs={12} className="marginBottom40 flexDisplay" direction ="row">
                <span className="fontSize1_5rem fontWeightBold">Document</span>

            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">ID proof</span>
                </Grid>         
                <Grid item xs={8}>
                    {documentList ?
                        documentList.idProof?<a
                            href={documentList.idProof.file}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {documentList.idProof.name}
                        </a>:<span>Not Available</span>:<span>Not Available</span>
                    }     
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Driving license</span>
                </Grid>         
                <Grid item xs={8}>
                    {documentList ?
                        documentList.drivingLicense?<a
                            href={documentList.drivingLicense.file}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {documentList.drivingLicense.name}
                        </a>:<span>Not Available</span>:<span>Not Available</span>
                    }    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Aadhaar number</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{Decrypt(data.aadhaar_number)}</span>    
                </Grid>                
            </Grid>            
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Aadhar card</span>
                </Grid>         
                <Grid item xs={4}>
                    {documentList ?
                        documentList.aadhaarCard?<a
                            href={documentList.aadhaarCard.file}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {documentList.aadhaarCard.name}
                        </a>:<span>Not Available</span>:<span>Not Available</span>
                    }      
                </Grid> 
                <Grid item xs={4}>
                    {documentList ?
                        documentList.aadhaarCardBack?<a
                            href={documentList.aadhaarCardBack.file}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {documentList.aadhaarCardBack.name}
                        </a>:<span>Not Available</span>:<span>Not Available</span>
                    }      
                </Grid>                
            </Grid>
            <Divider  className="marginBottom40" />
            <Grid item xs={12}  className="marginBottom40">
                <span className="fontSize1_5rem fontWeightBold">Bank Details</span>
            </Grid>
            <Grid item xs={12} className="flexDisplay height40">
                <Grid item xs={4}>
                    <span className="fontWeightBold">Account holder name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{bankDetails?Decrypt(bankDetails.accountHolderName):"Not Available"}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Account number</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{bankDetails?Decrypt(bankDetails.accountNumber):"Not Available"}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">IFSC code</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{bankDetails?Decrypt(bankDetails.ifscCode):"Not Available"}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Account proof</span>
                </Grid>         
                <Grid item xs={8}>
                {   (bankDetails && bankDetails.accountProof)?<a
                        href={bankDetails.accountProof}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {`Account proof`}
                    </a>:<span>Not Available</span>
                }
                </Grid>                
            </Grid>
        </Grid>
    )
}

export default Documents
