import React from 'react'
import { Divider, Grid } from '@material-ui/core'

function PersonalDetails(props) {
    const {data, documentList}=props
    return (
        <Grid container className="flexDisplay" direction ="column">
            <Grid item xs={12}  className="marginBottom40">
                <span className="fontSize1_5rem fontWeightBold">Personal Details</span>
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Full name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.fullName}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Phone number</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.phoneNumber}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Date of birth</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.dob}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Father's name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.fathersName}</span>    
                </Grid>                
            </Grid>            
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Gender</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.gender}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Location</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.userGoogleLocation}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay  height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Employment status</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>Unemployed</span>
                    {/* <span>{data.workerStatus!==null &&data.workerStatus!==0 && <div>Employed</div>}</span>    
                    <span>{(data.workerStatus===null ||data.workerStatus===0) && <div>Unemployed</div>}</span>   */}
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay  height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Client(s)</span>
                </Grid>         
                <Grid item xs={8}>
                    <span></span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay  height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Source</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.partnerName}</span>    
                </Grid>                
            </Grid>
            {/* <Divider  className="marginBottom40" /> */}
            
             <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Present Address</span>
                </Grid>         
                <Grid item xs={8} className="paddingBottom16">
                    <span>{data.present_address} {data.present_city} {data.present_state}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Permanent Address</span>
                </Grid>         
                <Grid item xs={8} className="paddingBottom16">
                    <span>{data.permanent_address} {data.permanent_city} {data.permanent_state}</span>    
                </Grid>                
            </Grid>
            {/*
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">State (Present)</span>
                </Grid>         
                <Grid item xs={8} className="paddingBottom16">
                    <span>{data.present_state}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Address (Permanent)</span>
                </Grid>         
                <Grid item xs={8} className="paddingBottom16">
                    <span>{data.permanent_address}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">City (Permanent)</span>
                </Grid>         
                <Grid item xs={8} className="paddingBottom16">
                    <span>{data.permanent_city}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">State (Permanent)</span>
                </Grid>         
                <Grid item xs={8} className="paddingBottom16">
                    <span>{data.permanent_state}</span>    
                </Grid>                
            </Grid> */}
            {/* <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Current address proof</span>
                </Grid>         
                <Grid item xs={8}>
                    {documentList ?
                        documentList.currentAddressProof?<a
                            href={documentList.currentAddressProof.file}
                            
                            target="_blank"
                        >
                            {documentList.currentAddressProof.name}
                        </a>:<span>Not Available</span>:<span>Not Available</span>
                    }   
                </Grid>                
            </Grid> */}
            {/* <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item sm={4}>
                    <span className="fontWeightBold">Emergency contact number</span>
                </Grid>         
                <Grid item sm={8}>
                    <span>{data.emergencyContactNo}</span>    
                </Grid>                
            </Grid> */}
            {/* <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Nominee name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.nomineeName}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Relationship with nominee</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.nomineeRelation}</span>    
                </Grid>                
            </Grid> */}
            {/* <Divider className="marginBottom40" />
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Total work experience</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.totalWorkExperience}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Highest qualification</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.highestQualification}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Job role preference</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.jobRolePreference}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Criminal record</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.criminalRecord}</span>    
                </Grid>                
            </Grid> */}
        </Grid>
    )
}

export default PersonalDetails
