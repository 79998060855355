import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, IconButton } from '@mui/material';
import DateFnsUtils from '@date-io/date-fns'
import * as moment from 'moment'
import CancelIcon from '@mui/icons-material/Cancel';
import Calendar from '../../../assets/calendar.svg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function   BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const{filters,handleFilterDates}=props;
  const [dateOne, setDateOne] = React.useState(filters.billDate[0]);
  const [dateTwo, setDateTwo] = React.useState(filters.billDate[1]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
console.log(dateOne,dateTwo)
  return (
    <div >
      <Button   
       variant="contained"
       className="dataButton"
       onClick={handleOpen}
      >
        Change Dates
      </Button>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
          //   onClose(event, reason);
          handleClose()
          }
        }}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
           
          <Typography id="modal-modal-title" variant="h6" component="h1" style={{paddingBottom:'10px'}}>
            Please select date range
            <span  style={{paddingLeft: '256px'}}>
            <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClose}>
  <CancelIcon />
</IconButton>
            </span>
          </Typography>
       

          <Grid item xs={12} className="flexDisplay" alignItems='flex-start'>
                  
                    <Grid item xs={12} className="flexDisplay paddingTop8" direction="row" alignItems='flex-start'>
                        <div className="fromTo"> FROM:</div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                id="billDateFrom"
                                placeholder="Bill date from"
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                className={style.datePicker}
                                // clearable
                                maxDate={moment().format("YYYY-MM-DD")}
                                value={dateTwo}
                                onChange={date => setDateTwo(moment(date).format("YYYY-MM-DD"))}
                                keyboardIcon={<img src={Calendar} alt="calendar" width="18px" height="18px" />}
                            />
                        </MuiPickersUtilsProvider>
                        <div className="fromTo"> To:</div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id="billDateTo"
                                placeholder="Bill date to"
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                className={style.datePicker}
                                // clearable
                                minDate={dateTwo}
                                maxDate={moment().format("YYYY-MM-DD")}
                                value={dateOne}
                                onChange={date => setDateOne(moment(date).format("YYYY-MM-DD"))}
                                keyboardIcon={<img src={Calendar} alt="calendar" width="18px" height="18px" />}
                            />
                        </MuiPickersUtilsProvider>
                        {/* <DateFilter></DateFilter> */}
                    </Grid>
                </Grid>
                <div style={{ textAlign: 'end',paddingTop: '27px'}}>
                <Button 
                  onClick={()=>{ handleFilterDates(dateOne,dateTwo, 0,1, "billDate"); handleClose(); }}
                  variant="contained"
                  className="dataButton"
                >
                  Change Dates
                </Button>
                </div>
        </Box>
      </Modal>
    </div>
  );
}
