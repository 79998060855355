import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Axios from 'axios';
import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { TimePicker } from '@material-ui/pickers';
// import { TimePicker } from '@mui/lab';
import { Stack } from '@mui/material';

import UrlLinks from '../../../UrlLinks';
import { ConstructionTwoTone } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CancelIcon from '@mui/icons-material/Cancel';
import * as moment from 'moment'
import AssignToRecruiterRes from './AssignToRecruiterRes'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function AssignToRecruiterModal(props) {
    const{open , setOpen,userIds,updateMyData,showToast, tabData}=props;
    //   const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [partner, setPartner] = React.useState(100)
    const [resData, setResData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [recruiter, setrecruiter] = React.useState(null)
    const [openRes, setOpenRes] = React.useState(false)
    const [menuItems, setmenuItems] = React.useState([])
    const [recruiterOptions, setRecruiterOptions] = React.useState([])
    const partnerTiming={flipkart:{startTime:"2014-08-18T07:00:00",endTime:"2014-08-18T16:00:00"},swiggy:{startTime:"2014-08-18T18:00:00",endTime:"2014-08-18T00:00:00"},zomato:{startTime:"2014-08-18T18:00:00",endTime:"2014-08-18T00:00:00"}}
    const [startValue, setStartValue] = React.useState(new Date(partner===100?partnerTiming.flipkart.startTime:(partner===101)?partnerTiming.swiggy.startTime:partnerTiming.zomato.startTime));
    const [endValue, setEndValue] = React.useState(new Date(partner===100?partnerTiming.flipkart.endTime:(partner===101)?partnerTiming.swiggy.endTime:partnerTiming.zomato.endTime));
    // console.log(userIds)
    console.log(userIds)
    const handleChangeStart = (newValue) => {
      setStartValue(newValue);
    };
    const handleChangeEnd = (newValue) => {
    setEndValue(newValue);
  };
  React.useEffect(()=>{     
    
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.recruiterList}`)
      .then(response =>{

        makeRecruiterData(response.data.response)
          })
          Axios.get(`${UrlLinks.baseUrl}${UrlLinks.shifts.workerPartners}`)
          .then(response =>{
              console.log(response.data.response)
              makePartnerData(response.data.response)
              // setWorkerPartners(makeWorkerPartnerData(response.data.response))
          })
    },[])

const makePartnerData =(data) =>{
  let list=[];
  data.map(el =>{
    if(el.is_active){
      list.push(<MenuItem value={el.partner_id}>{el.company_name}</MenuItem>)

    }
      return true
  })
  setmenuItems(list)
  return list
}
  React.useEffect(()=>{    
// console.log(moment(endValue).format("hh:mm:ss a"))

    setStartValue(new Date(partner===100?partnerTiming.flipkart.startTime:(partner===101)?partnerTiming.swiggy.startTime:partnerTiming.zomato.startTime))
    setEndValue(new Date(partner===100?partnerTiming.flipkart.endTime:(partner===101)?partnerTiming.swiggy.endTime:partnerTiming.zomato.endTime))
  },[partner])
  React.useEffect(()=>{    
    if(startValue<endValue ) {
      setError(false)
    }  else{
// console.log(moment(endValue).format("hh:mm:ss"))

      if((moment(endValue).format("hh:mm:ss a"))==="12:00:00 am"){
    setError(false)
    
      }else{
    setError(true)
  }
} 
},[startValue,endValue])
  
console.log(partner)
  const makeRecruiterData =(data) =>{
    let list=[];
    data.Content.map(el =>{
      if(el.isActive){
        list.push(<MenuItem value={el.recruiterId}>{el.firstName} {el.lastName ?" "+el.lastName:""}</MenuItem>)

      }
        return true
    })
    console.log(list)
    setRecruiterOptions(list)
    return list
  }


  const assinAPI =()=>{
    let url =""
    setLoading(true)
    if(tabData){
      url=`${UrlLinks.baseUrl}${UrlLinks.recruiter.assignToRecruiter}?insertedBy=${localStorage.getItem("userID")}&partnerId=${partner}&recruiterId=${recruiter}&userIds=${userIds}&shift_start_time=${moment(startValue, "h:mm:ss A").format("HH:mm:ss")}&shift_end_time=${moment(endValue, "h:mm:ss A").format("HH:mm:ss")}`
    }
    else{
      url= `${UrlLinks.baseUrl}${UrlLinks.recruiter.assignToRecruiterLead}?insertedBy=${localStorage.getItem("userID")}&recruiterId=${recruiter}&userIds=${userIds}`
    }
    Axios.post(url)
    .then(response =>{
        updateMyData()
        // handleClose()
        // showToast("success", "Assigned")
        setResData(response.data.response.content)
        setOpenRes(true)
        setLoading(false)
//   makeRecruiterData(response.data.response)
    })

  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        // onClose={handleClose}
        onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
            //   onClose(event, reason);
            handleClose()
            }
          }}
      >
       <Box sx={style}>
           <p><b>Assign to Recruiter</b>   <IconButton onClick={handleClose} style={{left: '214px', position: "relative"
}}>
        <CancelIcon />
      </IconButton></p>
    {error&&<div style={{color:"red"}}>Error in time</div>}

{/* Partner */}
<Stack spacing={3}>
      {tabData && <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Pitch for</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={partner}
            label="Picth for"
            onChange={(e)=>{setPartner(e.target.value)}}
          >
          {menuItems}
          </Select>
        </FormControl>}
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Recruiter</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Recruiter"
          onChange={(e)=>{setrecruiter(e.target.value)}}
        >
         {recruiterOptions}
        </Select>
      </FormControl>
      {tabData && <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker style={{width:"150px"}}
    //   fullWidth={false}
          label="Start Time"
          value={startValue}
          onChange={handleChangeStart}
          
          renderInput={(params) => <TextField {...params} />}
        />
           <TimePicker style={{width:"150px",marginLeft: '100px'}}
          label="End Time"
          value={endValue}
          onChange={handleChangeEnd}
          minTime={startValue}
          renderInput={(params) => <TextField {...params} />}
        />
        </MuiPickersUtilsProvider>
        </div>}
       </Stack>

       <Button
         variant="contained"
         className="dataButton"
         style={{ marginTop: '30px',marginLeft: '155px' }}
         onClick={assinAPI}
         disabled={(tabData ? (!(recruiter!==null&&startValue!==null&&endValue!==null && !error)) : (!(recruiter!==null&& !error)) ) && loading }
     >
         {loading?"Please wait...":"Assign"} 
     </Button>
       </Box>
 </Modal>
 <AssignToRecruiterRes open={openRes} resData={resData} handleClose={handleClose}></AssignToRecruiterRes>
    </div>
  );
}
