import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, Grid } from '@material-ui/core';
import axios from 'axios';
import UrlLinks from '../../../UrlLinks';
import { useState } from 'react';

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const {open, setOpen,updateMyData,showToast} = props;
  const [disableYesButton,setDisableYesButton] = useState(false)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Are you sure to generate weekly invoice ?</h2>
     <div align="right" > <Button disabled={disableYesButton} variant="outlined"  component="span" style={{  color:'#1c75bc',borderColor: '#1c75bc',marginTop: '10px' ,marginRight: '10px'}} onClick={()=>{generateInvoice()}}>YES</Button> <Button  variant="outlined"  component="span" style={{  color:'#1c75bc',borderColor: '#1c75bc',marginTop: '10px' }} onClick={()=>{handleClose()}} >No </Button></div> 
    </div>
  );
  const generateInvoice =()=>{
    setDisableYesButton(true)
    console.log("response")

axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.generateWeeklyInvoice}`)
.then(response => {
    if (response) {
        console.log(response)
        updateMyData()
        handleClose()
        showToast("success", "Sucessfully Generated");
        // setAllRecord(makeAllRecordData(response.data.response))
    }
})}
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}