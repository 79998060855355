import React from 'react'
import { Grid } from '@material-ui/core'
import * as moment from 'moment'

function HRBPview(props) {
    const {data}=props
    // console.log({data})
    const sourceBy=data.sourceByName?`, ${data.sourceByName}`:""
    return (
        <Grid container className="flexDisplay" direction ="column">
            <Grid item xs={6}  className="marginBottom40" >
                <span className="fontSize1_5rem fontWeightBold">Contract Details</span>
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Casper ID</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.casperId}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Source by</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{`${data.sourceByView}${sourceBy}`}</span>    
                </Grid>                
            </Grid>
                        
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.facilityName}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility city</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.facilityCity}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility state</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.facilityState}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Zone</span>
                </Grid>         
                <Grid item xs={8}>
                    <span >{data.zone}</span>    
                </Grid>                
            </Grid>
            
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Facility type</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.facilityType}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Designation</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.designation}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Rate card</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.rateCard}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Date of joining</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.date_of_joining?moment(data.date_of_joining).format("DD/MM/YYYY"): ""}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" style={{height: "35px"}}>
                <Grid item xs={4}>
                    <span className="fontWeightBold ">Criminal record</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.criminalRecord}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold ">EDAB (physically disabled)</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.edab}</span>    
                </Grid>                
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Cluster Name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.clusterName}</span>    
                </Grid>                
            </Grid>
            
        </Grid>
    )
}

export default HRBPview
