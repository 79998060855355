const s = {
    "code": 1000,
    "message": "",
    "response": {
        "content": [
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "Aman ALL DUMMY DATA" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "Aman" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "ONCE" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "API" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "Gets" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "Alive" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "This" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "will change" ,
                // "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
            {
                "workerId": 449,
                "okaygoId": "OG110071",
                "firstName": "Aman" ,
                "lastName": "Jain",
                "bankVerification": "Yes",
                "invoices": "3",
                "totalCredit": 33,
                "totalDebit": 45,
                "netBalance": 336
            },
           
        ],
        "pageable": {
            "sort": {
                "sorted": false,
                "unsorted": true,
                "empty": true
            },
            "pageNumber": 0,
            "pageSize": 10,
            "offset": 0,
            "paged": true,
            "unpaged": false
        },
        "totalPages": 174,
        "totalElements": 1736,
        "last": false,
        "sort": {
            "sorted": false,
            "unsorted": true,
            "empty": true
        },
        "numberOfElements": 10,
        "first": true,
        "size": 10,
        "number": 0,
        "empty": false
    }
}
export default s;