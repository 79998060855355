/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import UploadDocuments from './uploadDoc'
import Styles from '../index.module.scss'
export default function UploadDocumentsButton(props) {
  const { row, showToast, updateMyData}= props
  const [openUploader, setOpenUploader] = useState(false)
  return (
    <>
      <button
        onClick={() => setOpenUploader(true)}
        className={Styles.docUploadButton}
      >
        {row.original.is_document_detail_filled?"View Documents":"Upload Documents"}
      </button>
      {
        openUploader && 
          <UploadDocuments
            open={openUploader}
            close={() => setOpenUploader(false)}
            data= {row.original} 
            showToast ={showToast}
            updateMyData={updateMyData}
          />
      }
    </>
  );
}
