import { Grid, Checkbox, Drawer, TextField, IconButton, InputAdornment, makeStyles, MenuItem, Select, FormControl, MuiThemeProvider, Tooltip, createMuiTheme } from '@material-ui/core';
import React, {useEffect, useState, useMemo, useContext } from 'react'
import { Button } from '@material-ui/core'
import styles from './index.module.scss'
import styled from 'styled-components'
import {AuthContext} from '../../services/AuthProvider'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import TimeLineHeader from './TimeLineHeader'
import TimeLineColumn from './TimeLineColumn'
import FilterDrawer from './filters'
import Pagination from '../../Utils/Pagination';
import Axios from 'axios'
import Calendar from '../../assets/calendar.svg'
import NoRecord from './noRecord.svg'
import * as moment from 'moment'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import urlLinks from '../../UrlLinks'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ScaleLoader } from 'react-spinners';
import UploadEarningModal from './uploadEarningModal'
import UploadFlipkartDataAdmin from './FlipkartEarningAdmin'
import UploadSwiggyDataAdmin from './SwiggyEarningAdmin'
import UploadZomatoDataAdmin from './ZomatoEarningAdmin'
import UploadDelhiveryDataAdmin from './DelhiveryEarningAdmin'
import UploadJiomartDataAdmin from './JiomartEarningAdmin'
import UploadOtherDataAdmin from './otherEarningAdmin'


const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:0 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color= "primary" />
        </React.Fragment>
    )
    }
)

const ShiftData = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const {showToast} = useContext(AuthContext);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [searchBy, setSearchBy] = useState("")
    const [searchUrl, setSearchUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [shiftdate, setShiftdate] = useState(moment().add(1, 'days'))
    const [shiftDateUrl, setShiftDateUrl] = useState("")
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [openOnboardingModalSwiggy, setOpenOnboardingModalSwiggy] = useState(false)
    
    const [openOnboardingModalZomato, setOpenOnboardingModalZomato] = useState(false)
    const [openOnboardingModalDelhivery, setOpenOnboardingModalDelhivery] = useState(false)
    const [openOnboardingModalJiomart, setOpenOnboardingModalJiomart] = useState(false)
    
    const [openOnboardingModalOther, setOpenOnboardingModalOther] = useState(false)
    const [openOnboardingModalFlipkart, setOpenOnboardingModalFlipkart] = useState(false)
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const [filterUrl, setFilterUrl] = useState("")
    const [actions, setActions] = useState(0)
    const [filters, setFilters] = useState({
        workerPartner:null,
        hub: null,
        hubHRBP:null,
        workingFor: null,
        shiftStatus: [],
        workerStatus:10
    })
    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    width: "139px",
                    height: "47px",
                    color: "black",
                    fontSize: "13px",
                    margin: "30px 38px 20px 29px",
                    padding: "18px 29px 30px 14px",
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                    backgroundColor: "#ffffff"
                }
            }
        }
    });
    const themeEarning = createMuiTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    width: "250px",
                    height: "147px",
                    color: "black",
                    fontSize: "13px",
                    // margin: "30px 38px 20px 29px",
                    // padding: "18px 29px 30px 14px",
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                    backgroundColor: "#ffffff"
                }
            }
        }
    });
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    const handleSearch = ()=>{
        setSearchUrl(`&searchBy=${searchBy}`)
    }
    const handleSearchClose = () => {
        setSearchUrl(``)
        setSearchBy("");
    }
    const resetFilters =() =>{
        setFilters({
            workerPartner:null,
            hub: null,
            hubHRBP:null,
            workingFor: null,
            shiftStatus: [],
            workerStatus:10
        })
        setFilterUrl("")
        setCurrentPage(1);

    }
    const applyFilters =() =>{
        let url='';
        
        if(filters["workerPartner"])
            url=`${url}&partner_id=${filters["workerPartner"].id}`;
        if(localStorage.getItem("roleType")===1){
            if(filters["hubHRBP"].length!==0){
                url=`${url}&facility_name=${filters["hubHRBP"].facilityName}`
            }
        }
        else{
            if(filters["hub"]){
                url=`${url}&facility_name=${filters["hub"].facilityName}`
            }
        }
        if(filters["workingFor"])
            url=`${url}&shift_partners=${filters["workingFor"]}`
        if(filters["shiftStatus"].length!==0)
            url=`${url}&shift_status=${filters["shiftStatus"]}`
        if(filters["workerStatus"]!=="" && filters["workerStatus"]<10)
            url=`${url}&worker_status=${filters["workerStatus"]}`
        setFilterUrl(url)
        setCurrentPage(1)
    }
    const columns = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //         <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         <div>
            //         <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            //         </div>
            //     ),
            // },
            {
                Header: "OkayGo ID",
                accessor: "okaygoId",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Source",
                accessor: "source",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Worker name",
                accessor: "workerName",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Worker status",
                accessor: "workerStatus",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Hub",
                accessor: "hub",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "timeline",
                accessor: "timeline",
                Cell:TimeLineColumn,
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Flipkart earning productivity amount",
                accessor: "flipkartEarning",
                Cell: row =>{
                    console.log({row})
                    return <MuiThemeProvider theme={themeEarning}>
                        <Tooltip interactive 
                            classes={{
                                tooltip: styles.flipkartEarningContainer,
                              }}
                            title={<div >
                                <div className={styles.flipkartEarningDiv}>
                                    <span>Shipments allocated</span>
                                    <span>{row.row.original.shipmentsAllocate?row.row.original.shipmentsAllocate:"-"}</span>
                                </div>
                                <div className={styles.flipkartEarningDiv}>
                                    <span>Shipments delivered</span>
                                    <span>{row.row.original.shipmentsDelivered?row.row.original.shipmentsDelivered:'-'}</span>
                                </div>
                                <div className={styles.flipkartEarningDiv}>
                                    <span>Rate</span>
                                    <span>{row.row.original.rate?row.row.original.rate:"-"}</span>
                                </div>
                                <div className={styles.flipkartEarningDiv} style={{color: "#1c75bc"}}>
                                    <span>Net amount</span>
                                    <span> {row.row.original.flipkartEarning?row.row.original.flipkartEarning:"-"} </span>
                                </div>

                            </div>} arrow>
                            <span> {row.row.original.flipkartEarning?row.row.original.flipkartEarning:"-"} </span>
                        </Tooltip>
                    </MuiThemeProvider>
                },
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Swiggy Earning",
                accessor: "swiggyEarning",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Zomato Earning",
                accessor: "zomatoEarning",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Delhivery Earning",
                accessor: "delhiveryEarning",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Jiomart Earning",
                accessor: "jiomartEarning",
                defaultCanFilter :false,
                defaultCanSort:false
            },
        ],
        []
    );
    const createWorkerStatus =(status) =>{
        let text=""
        switch (status) {
            case 0:
                text="Not Onboarded";
                break;
            case 1:
                text="Active";
                break;
            case 2:
                text="Partially Onboarded";
                break;
            case 3:
                text="Inactive";
                break;
            case 4:
                text="Offboarding Initiated";
                break;
            case 5:
                text="Onboarding Completed";
                break;    
            case 6:
                text="Temporarily Inactive";
                break;
            case 7:
                text="Temporary Inactivation Initiated";
                break; 
            case 8:
                text="Reactivation Initiated";
                break;                
            default:
                
                break;
        }
        return text;
    }
    const makeData =(response) =>{
        let extractedData = [];
        response.content.map(el => {
            extractedData.push({
                okaygoId: el.okaygoId|| "",
                source: el.partnerName || "",
                workerName: `${el.firstName?el.firstName:""} ${el.lastName?el.lastName:""}`,
                hub: el.facilityName || "",
                effectiveFrom: el?.workershifts[0]?.shift_effective_from?moment(el?.workershifts[0]?.shift_effective_from).format("DD/MM/YYYY"):"",
                effectiveDate:el?.workershifts[0]?.shift_effective_from?el?.workershifts[0]?.shift_effective_from:null,
                hourlyShifts: el.workerHourlyShift,
                workerShifts: el.workershifts,
                workerStatus: createWorkerStatus(el.workerStatus),
                flipkartEarning: el.flipkartShipmentDetails?.flipkartEarning?numberFormat(el.flipkartShipmentDetails.flipkartEarning):"-",
                rate: el.flipkartShipmentDetails?.rate || "",
                shipmentsAllocate : el.flipkartShipmentDetails?.shipmentsAllocate || "",
                shipmentsDelivered: el.flipkartShipmentDetails?.shipmentsDelivered || "",
                swiggyEarning: el.swiggyEarning?numberFormat(el.swiggyEarning):"-",
                zomatoEarning: el.zomatoEarning?numberFormat(el.zomatoEarning):"-",
                userId: el.userId,
                workerId: el.workerId,
                clusterName: el.clusterName,
                address: el.address,
                jiomartEarning:el.jiomartEarning?numberFormat(el.jiomartEarning):"-",
                delhiveryEarning:el.delhiveryEarning?numberFormat(el.delhiveryEarning):"-",
            });
            return true;
        });
        
        return extractedData;
    }
    console.log({filters})
    useEffect(() => {
        let url=""
        if(shiftdate){
            url=`&shift_date=${moment(shiftdate).format("YYYY-MM-DD")}`
        }
        setShiftDateUrl(url)
    }, [shiftdate])
    const getShiftPlanningListing =(rows, pageNo) =>{
        setLoading(true)
        let requestedBy=""
        if(localStorage.getItem("roleType")==="1"){
            requestedBy=`&requestedById=${localStorage.getItem("userID")}`
        }
        if(localStorage.getItem("roleType")){
            Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.shiftData}?page_no=${pageNo-1}${requestedBy}&rows=${rows}${filterUrl}${shiftDateUrl}${searchUrl}${localStorage.getItem("roleType") === "30"?"&partner_id=107":""}`)
                .then(response =>{
                    if(response){
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if(currentPage>response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error =>{
                    setLoading(false)
                })
        }
    }
    const updateMyData = () => {
        getShiftPlanningListing(perPage, currentPage);
    }
    useEffect(() => {
        if(shiftDateUrl){
            getShiftPlanningListing(perPage, currentPage)
        }
    }, [filterUrl, shiftDateUrl, searchUrl])
    const handlePageChange = page => {
        getShiftPlanningListing(perPage, page)
        setCurrentPage(page);
    };
    const handlePerRowsChange =  (newPerPage) => {
        getShiftPlanningListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const handleDailyEarnings = () => {
        setOpenOnboardingModal(true)
    };
    const handleFlipkartDataUpload = () => {
        setOpenOnboardingModalFlipkart(true)
    };
    const handleSwiggyDataUpload = () => {
        setOpenOnboardingModalSwiggy(true)
    };
    const handleZomatoDataUpload = () => {
        setOpenOnboardingModalZomato(true)
    };
    const handleDelhiveryDataUpload = () => {
        setOpenOnboardingModalDelhivery(true)
    };
    const handleJiomartDataUpload = () => {
        setOpenOnboardingModalOther(true)
    };
    
    
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsSelected, state: {selectedRowIds}} = useTable({columns, data,  showToast, filters, setFilters, manualSortBy: true  }, useFilters, useSortBy,  useRowSelect)
    const table=<div className="tableContainer">
                <table {...getTableProps()}  className="table">
                    <thead className="thead">
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (  
                            <th {...column.getHeaderProps()} 
                                className={column.render('Header')==="OkayGo ID"?"stickWorkerIdShift zIndex1080 header":
                                    column.render('Header')==="Worker name"?"stickWorkerNameShift zIndex1080 header":
                                        column.id==="selection"?"stickCheckbox zIndex1080 header":
                                        column.render('Header')==="timeline"?`${styles.timelineColumn} header`: "header"}>
                                
                                {column.render('Header')==="timeline"?<TimeLineHeader />:
                                    <span {...column.getSortByToggleProps()} style={{display:"flex"}}>
                                        {column.render('Header')}
                                        {column.Header === "Flipkart earning productivity amount" ?
                                        <MuiThemeProvider theme={theme}>
                                            <Tooltip title="This does not include allowances which will be shown in the invoices on the app" arrow>
                                                <span>  <InfoOutlinedIcon style={{ fontSize: "1.3rem", marginLeft: "8px" }}></InfoOutlinedIcon> </span>
                                            </Tooltip></MuiThemeProvider> :null}
                                    </span>}
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    {
                        <tbody {...getTableBodyProps()}> 
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    
                                    <tr {...row.getRowProps()} className={`row ${row.original.criminalRecord?"criminalRecord":""}`}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}   
                                            className={`${cell.column.Header==="OkayGo ID"?"stickWorkerIdShift  zIndex540":
                                            cell.column.Header==="Worker name"?"stickWorkerNameShift zIndex540":
                                                cell.column.id==="selection"?"stickCheckbox zIndex540":"cell"} ${row.original.criminalRecord==="Yes"?"criminalRecord":""}`}>{cell.render('Cell')}</td>
                                    })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    }
                </table>
            </div>
    const loader=<div style={{margin:"130px auto"}}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}    
        />
    </div>
     const handleActions = (event) => {
        if (event.target.value === "flipkart") {
            handleFlipkartDataUpload()
        }
        else if (event.target.value === "swiggy") {
            handleSwiggyDataUpload()
        }
        else if (event.target.value === "zomato") {
            handleZomatoDataUpload()
        } else if (event.target.value === "delhivery") {
            handleDelhiveryDataUpload()
        } else if (event.target.value === "jiomart") {
            handleJiomartDataUpload()
        } else if (event.target.value === "other") {
            handleJiomartDataUpload()
        }

    }

    return (
        <Styles>
            <Grid container className={styles.ShiftPlanContainer}>
                <Grid item xs={12} className={styles.ShiftPlanHeadingContainer}>
                    <div>
                        <span className={styles.shiftPlanHeading}>Worker Shift Data</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id="shiftDate"
                                placeholder="Shift date"
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                className={styles.datePicker}
                                value={shiftdate}
                                maxDate={(localStorage.getItem("roleType")==="11" || localStorage.getItem("roleType")==="1" || localStorage.getItem("roleType")==="5")?moment().add(1, 'days'):moment().add(3, 'days')}
                                onChange={date => setShiftdate(date)}
                                keyboardIcon={<img src={Calendar} alt="calendar" width="18px" height="18px"/>}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={styles.uploadEarningFilters}>
                        {(localStorage.getItem("roleType")==="11" || localStorage.getItem("roleType")==="1" || localStorage.getItem("roleType")==="5" ) && <Button
                            variant="contained"
                            className="dataButton"
                            onClick={handleDailyEarnings}
                        >
                            {localStorage.getItem("roleType")==="11"?"Upload Daily Earnings":"Upload shipment details"}
                        </Button>}
                        {(localStorage.getItem("roleType")==="2" || localStorage.getItem("roleType")==="6" ) &&<div> 
                       
                        <Grid  >
                        <FormControl variant="outlined" className="margin0" >
                            <Select
                                id="actions"
                                value={actions}
                                style={{ height:'40px' }}
                                classes={{ outlined: styles.actionSelect }}
                                onChange={handleActions}
                            >
                                <MenuItem value={0} disabled={true}>Upload Shipment Data</MenuItem>
                                <MenuItem value="flipkart">Flipkart Data</MenuItem>
                                {/* <MenuItem value="swiggy">Swiggy Data</MenuItem>
                                <MenuItem value="zomato">Zomato Data</MenuItem>
                                <MenuItem value="delhivery">Delhivery Data</MenuItem>
                                <MenuItem value="jiomart">Jiomart Data</MenuItem> */}
                                <MenuItem value="other">Other Clients</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                            {/* <Button
                            variant="contained"
                            style={{marginRight:'10px'}}
                            className="dataButton"
                            onClick={handleFlipkartDataUpload}
                        >
                            Upload Flipkart Data 
                        </Button> 
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={handleSwiggyDataUpload}
                        >
                            Upload Swiggy Data 
                        </Button> */}
                        </div>
                       
                       }
                        <button 
                            className={styles.blueOutlinedButton}
                            style={{marginLeft: "20px"}}
                            onClick={() => setOpenFilterDrawer(true)}
                        >
                            filters
                        </button>
                    </div>
                </Grid>
                <Grid container style={{alignItems: "baseline"}}>
                    <Grid item xs={6} className={styles.ShiftPlanHeadingContainer}>
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder="Search by OkayGo ID"
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>    
                    <Grid item xs={6} className={styles.ShiftLegendContainer}>
                        <div className={styles.legendContainer}>
                            <div className={styles.goingLegend}></div>
                            <span className={styles.legendText}>Going</span>
                            <div className={styles.notGoingLegend}></div>
                            <span className={styles.legendText}>Not going</span>
                            <div className={styles.notMarkedLegend}></div>
                            <span className={styles.legendText}>Not marked</span>
                        </div>
                    </Grid>
                    
                </Grid> 
                {loading?loader:data.length===0?<div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div>:<Grid item xs={12} className= "paddingTop16">
                    {table}
                    <Pagination 
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
            </Grid>
            <Drawer anchor="right" open={openFilterDrawer} onClose={() =>setOpenFilterDrawer(false)}>
                {/* <Drawer anchor="right" open={true} onClose={toggleDrawer("filterDrawer", false)}> */}
                <FilterDrawer
                    handleClose={() => setOpenFilterDrawer(false)}
                    filters={filters}
                    setFilters={setFilters}
                    applyFilters={applyFilters}
                    resetFilters={resetFilters}
                ></FilterDrawer>
            </Drawer>
            {openOnboardingModal && <UploadEarningModal
                openOnboardingModal={openOnboardingModal}
                setOpenOnboardingModal= {setOpenOnboardingModal}
                showToast={showToast}
                updateMyData={updateMyData}
            />
            }
            {openOnboardingModalFlipkart && <UploadFlipkartDataAdmin
                openOnboardingModal={openOnboardingModalFlipkart}
                setOpenOnboardingModal= {setOpenOnboardingModalFlipkart}
                showToast={showToast}
                updateMyData={updateMyData}
            />
            }
            {openOnboardingModalSwiggy && <UploadSwiggyDataAdmin
                openOnboardingModal={openOnboardingModalSwiggy}
                setOpenOnboardingModal= {setOpenOnboardingModalSwiggy}
                showToast={showToast}
                updateMyData={updateMyData}
            />
            }
            {openOnboardingModalZomato && <UploadZomatoDataAdmin
                openOnboardingModal={openOnboardingModalZomato}
                setOpenOnboardingModal= {setOpenOnboardingModalZomato}
                showToast={showToast}
                updateMyData={updateMyData}
            />
            }
            {openOnboardingModalDelhivery && <UploadDelhiveryDataAdmin
                openOnboardingModal={openOnboardingModalDelhivery}
                setOpenOnboardingModal= {setOpenOnboardingModalDelhivery}
                showToast={showToast}
                updateMyData={updateMyData}
            />
            }
            {openOnboardingModalJiomart && <UploadJiomartDataAdmin
                openOnboardingModal={openOnboardingModalJiomart}
                setOpenOnboardingModal= {setOpenOnboardingModalJiomart}
                showToast={showToast}
                updateMyData={updateMyData}
            />
            }
            {openOnboardingModalOther && <UploadOtherDataAdmin
                openOnboardingModal={openOnboardingModalOther}
                setOpenOnboardingModal= {setOpenOnboardingModalOther}
                showToast={showToast}
                updateMyData={updateMyData}
            />
            }
        </Styles>
    )
}


export default ShiftData;