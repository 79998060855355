import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
    textAlign: "center"
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center"
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {openConfirmationDialog, setOpenConfirmationDialog, handleApprove} = props
    // console.log({props})
  const handleYes = () => {
    handleApprove();
    setOpenConfirmationDialog(false);
  };
  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirmationDialog}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Activate 
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure you want to activate this working with criminal record as Yes ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleYes} className="blueButtonContained">
            YES
          </Button>
          <Button variant="outlined" onClick={handleClose} className="blueButtonOutlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
  );
}
