import React from 'react'
import { Grid } from '@material-ui/core'
import * as moment from 'moment'

function HRBPview(props) {
    const {data}=props
    // console.log({data})
    const sourceBy=data.sourceByName?`, ${data.sourceByName}`:""
    return (
        <Grid container className="flexDisplay" direction ="column">
            <Grid item xs={6}  className="marginBottom40" >
                <span className="fontSize1_5rem fontWeightBold">Contract Details</span>
            </Grid>
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Swiggy ID</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.swiggyId}</span>    
                </Grid>                
            </Grid>
                   
            
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Cluster Name</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.clusterName}</span>    
                </Grid>                
            </Grid>
            
            <Grid item xs={12} className="flexDisplay height40" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">Date of joining</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.date_of_joining?moment(data.date_of_joining).format("DD/MM/YYYY"): ""}</span>    
                </Grid>                
            </Grid>
            
            {/* <Grid item xs={12} className="flexDisplay " direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold ">Flipkart Hub</span>
                </Grid>         
                <Grid item xs={8}>
                    <span>{data.flipkartHub}</span>    
                </Grid>                
            </Grid> */}
            
        </Grid>
    )
}

export default HRBPview
