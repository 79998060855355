import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from '../document.svg'
import ResponseModal from './ResponseModal'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import Download from '../Group 3238.svg'
import { ScaleLoader } from "react-spinners";
import { CSVLink } from "react-csv";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {openCasperOnboardingModal, setOpenCasperOnboardingModal, showToast, updateMyData } = props
  const [excel, setExcel] = useState(null)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [name, setName] = useState("")
  const [casperLoading, setCasperLoading] = useState(false)
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [invalidData, setInvalidData] = useState()
  const [fileLink, setFileLink] = useState(null)
  const [responseData, setResponseData] = useState()
  // console.log({excel})
  const handleClose = () => {
    setOpenCasperOnboardingModal(false);
  };
  const handleUpload=(event) =>{
    const fileName=event.target.files[0].name.split(".").slice(-1)[0];
    if(fileName.toLowerCase()==="xlsx" || fileName.toLowerCase()==="xls"){
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name)
      setExcelError(false)
    }
    else{
      setExcelError(true)
      setExcel(null);
      setName("");
    }
    setResponseData();

  }
  const createInvalidData =(records) =>{
    // console.log({records})
    if(records.length>1){
      let list=[];
      records.map(record =>{
        if(record.hasOwnProperty("WorkerDetail")){
          // console.log({record});
          if(record.WorkerDetail.isWorkerCreated===0){
              let firstName=""
              let lastName=""
              if(record.WorkerDetail.workerName){
                var i = record.WorkerDetail.workerName.indexOf(' ');
                if(i>0){
                  firstName = record.WorkerDetail.workerName.slice(0, i).trim();
                  lastName = record.WorkerDetail.workerName.slice(i + 1, record.WorkerDetail.workerName.length).trim();

                }
              }
              list.push({
                "Casper Id": record.WorkerDetail.casperId || "",
                "OkayGo id": record.WorkerDetail.okaygoId || "",
                "First Name": firstName || "",
                "Last Name": lastName || "",
                "Phone No":record.WorkerDetail.mobileNo || "",
                "Reason for failure":record.WorkerDetail.uploadStatus || ""
              })
          }
        }
        return true;
      })
      return list.length>0?list:null;
    }
  }
  const handleExcel = () =>{
    setIsLoading(true)
    let formData = new FormData();
    formData.append("file", excel);
    
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.uploadCasperOnboardingData}`,formData ,{
      headers: {
        "Content-Type": "multipart/form-data",
      }
    } )
    .then(response =>{
      // console.log({response});
      if(response.data.response){
        setInvalidData(createInvalidData(response.data.response));
        const summary=response.data.response.slice(-1)[0];
        // console.log({summary})
        setResponseData(summary);
        updateMyData();
        setIsLoading(false)
      }
      else{
        setResponseData([]);
      }
    })
    .catch(error =>{
      // console.log({error})
      setIsLoading(false)
      showToast("error", error.message);
    })
  }
  const createWorkerWithoutCasperData =(response) =>{
    const list=[]
    response.map(record =>{
      list.push({
        "Casper Id": record.casperId,
        "OkayGo Id": record.okaygoId,
        "First Name": record.firstName,
        "Last Name": record.lastName,
        "Phone No": record.phoneNumber
      })
    })
    return list;

  }
  useEffect(() => {
    setCasperLoading(true)
    let requestedBy=""
    if(localStorage.getItem("roleType")==="1"){
      // console.log(localStorage)
      requestedBy=`?requestedById=${localStorage.getItem("userID")}`
    }
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.workerWithoutCasperId}${requestedBy}`,null , {timeout:60*5*1000})
      .then(response =>{
        console.log({response})
        setFileLink(createWorkerWithoutCasperData(response.data.response))
      })  
      .catch(error =>{
        setFileLink("")
        showToast("error", error.message)
      })
  }, [])
  useEffect(() => {
    if(fileLink===""){
      setCasperLoading(false);
    }
    else if(fileLink!==null){
      setCasperLoading(false)
    }
  }, [fileLink])
  const loader=<div style={{margin:"130px auto", width: "fit-content"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
  useEffect(() => {
    if(responseData){
      setOpenResponseModal(true);
    }
  }, [responseData])
  let dialogData=
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openCasperOnboardingModal}
            className={Styles.onboardingDialog}
            classes={{paper: Styles.paper}}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <span>Upload Casper IDs</span>
            </DialogTitle>
            <DialogContent dividers>
              {casperLoading? loader:<Grid container>
                <Grid item xs={12} className="padding0">
                    {fileLink?.length>0 && <p>Download worker list (.csv) here, to fill and upload Casper IDs</p>}
                    {fileLink?.length>0?<CSVLink
                        data={fileLink}
                        filename={`Worker List without casper Id.csv`}
                        className={Styles.casperDownloadTemplate}
                        target='_blank'
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            fontWeight: '500'
                        }}
                    >
                      <span>Download worker list</span><img src={Download} alt="download" />
                    </CSVLink>:
                    <span>No record without Casper Id found</span>
                    }
                    <p className={Styles.casperInstruction}>
                      Save as (.xls) or (.xlsx) to upload Casper ID data file 
                    </p>
                    <p style={{marginTop: "45px"}}>
                      Upload Casper IDs
                    </p>
                    <input
                        style={{display:"none"}}
                        id="contained-button-file"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleUpload}
                    />
                    <label htmlFor="contained-button-file">
                        <Button 
                            variant="outlined" 
                            className= {Styles.uploadButton}
                            component="span"
                            startIcon= {<img src={document} alt={"upload"} />}
                        >
                          Browse File
                        </Button>
                    </label>
                    {name && <span className={Styles.fileName}><span>File name: </span>{`${name}`}</span>}
                </Grid>
                <Grid item xs={12} className="padding0">
                  <span className={`fontSize13 ${excelError?"redColor":""}`}>only .xls and .xlsx are allowed</span>
                </Grid>
              </Grid>}
            </DialogContent>
            <DialogActions>
              {isLoading?<span>Please wait... it may take few minutes</span>:<Button variant="contained" onClick={handleExcel} className={`${!excel?"disabledButton":"blueButtonContained"}`} disabled={!excel}>
                Upload
              </Button>}
            </DialogActions>
            {openResponseModal && <ResponseModal
              openResponseModal={openResponseModal}
              setOpenResponseModal={setOpenResponseModal}
              responseData={responseData}
              updateMyData={updateMyData}
              setOpenOnboardingModal={setOpenCasperOnboardingModal}
              showToast={showToast}
              invalidData={invalidData}
              type="OnboardingCasper"
            />}
        </Dialog>
  
  

  return dialogData; 
  
}
