import React, { useEffect, useState } from "react";
import Styles from "../index.module.scss";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";
import document from '../UploadButtonIcon.png'

export default function UploadReupload(props) {
    const {name,  type, uploadedDocs, setUploadedDocs, DocData, value} = props;
    const [inputValue, setInputValue] = useState(value || "")
    const [inputError, setInputError] = useState("")
    useEffect(() => {
        if(inputValue?.length === 10 && !inputError){
            let tempData= {...uploadedDocs}
            tempData["panNumber"]= {
                value:inputValue,
                docId: "panNumber"
            }
            setUploadedDocs({...tempData})
        }
        else if(type==="panCard" && uploadedDocs[type]){
            let tempData= { ...uploadedDocs }
            tempData.panNumber={
                value: inputValue
            }
            setUploadedDocs({...tempData})
        }
    }, [inputValue])
    
    const handleUpload = (event ) => {
        console.log(event.target.files)
        const fileName = event.target.files[0].name.split(".").slice(-1)[0];
        let tempData = { ...uploadedDocs };
        let documentUpdate = {
        file: event.target.files[0],
        fileName: event.target.files[0].name,
        error: !(
            fileName.toLowerCase() === "jpeg" ||
            fileName.toLowerCase() === "jpg" ||
            fileName.toLowerCase() === "pdf"
        ),
        };
        if (type === "addressProof") {
        documentUpdate["docId"] = 1869;
        tempData["addressProof"] = documentUpdate;
        }
        if (type === "panCard") {
        documentUpdate["docId"] = 1866;
        tempData["panCard"] = documentUpdate;
        }
        if (type === "aadhaarBack") {
        documentUpdate["docId"] = 1929;
        tempData["aadhaarBack"] = documentUpdate;
        }
        if (type === "aadhaar") {
        documentUpdate["docId"] = 1867;
        tempData["aadhaar"] = documentUpdate;
        }
        if (type === "dl") {
        documentUpdate["docId"] = 1870;
        tempData["dl"] = documentUpdate;
        }
        setUploadedDocs({ ...tempData });
    };
    return (
        <tr>
            <td>{name}</td>
            <td>
                {type=== "panCard" && <div className={Styles.EditableInputDiv}>
                    <input 
                        type="text"
                        value={inputValue}
                        onChange={(e) => {
                            console.log( (/([A-Za-z]{5}[0-9]{4}[A-Za-z])/).test(e.target.value))
                            if((/([A-Za-z]{5}[0-9]{4}[A-Za-z])/).test(e.target.value)){
                                if(inputError){
                                    setInputError("")
                                }
                            }
                            else{
                                if(e.target.value.length>0){
                                    setInputError("Enter a valid Pan Card number")
                                }
                            }
                            setInputValue(e.target.value)
                        }} 
                    />
                    {inputValue && <IconButton
                        aria-label="close"
                        className={Styles.closeButton}
                        onClick={() => setInputValue("")}
                    >
                        <CloseIcon />
                    </IconButton>}
                </div>}
                {inputError && <div style={{color:"red", fontSize:"10px", marginBottom: "15px"}}>{inputError}</div>}
                <div className={Styles.UploadedDocFileNameDiv}>
                    {uploadedDocs[type] && <span>{uploadedDocs[type].fileName} </span>}
                    <input
                        style={{ display: "none" }}
                        id={`${type}UploadButton`}
                        type="file"
                        accept=".png, .jpg, .jpeg, .pdf"
                        onChange={(e) => handleUpload(e)}
                    />
                    <label htmlFor={`${type}UploadButton`}>
                    <Button
                        className={Styles.uploadButtonDocs}
                        component="span"
                        startIcon={!uploadedDocs[type]?
                        <img
                            src={document}
                            alt={"upload"}
                            style={{ color: "white" }}
                        />: null
                        }
                    >
                        {!uploadedDocs[type]?"Upload":"Re-upload"}
                    </Button>
                    </label>
                </div>
            </td>
            <td>
                <a
                href={DocData[type]?.fileUrl}
                disabled={!DocData[type]}
                download
                target="_blank"
                className={`${Styles.downloadTemplate} ${!DocData[type] ? Styles.disabledDownloadButton : ""}`}
                >
                Download
                </a>
            </td>
        </tr>
    );
}
