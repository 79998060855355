import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
//import { Divider , Badge } from '@material-ui/core';
import { Grid, IconButton, Checkbox, TextField, InputAdornment, Button,} from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters,useExpanded } from 'react-table'
import urlLinks from '../../UrlLinks'
import SampleResponse from './SampleResponse'
import Axios from 'axios'
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
import { numberFormat } from '../../Utils/CommonFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Filters from './filterModal';
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import { createVerificationStatus } from '../../services/CommonServices'
import { AuthContext } from '../../services/AuthProvider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link } from 'react-router-dom'
import Decrypt from '../../Utils/decrypt'
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            // width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 1rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }

    }, paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const ExternalWorker = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const { showToast } = useContext(AuthContext);
    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRecord, setAllRecord] = useState([]);
    // const [selectedRows, setSelectedRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);
    const [sortDirectionUrl, setSortDirectionUrl] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [searchByUrl, setSearchByUrl] = useState("")
    const [sortUrl, setSortUrl] = useState("");
    const [financialAdmin, setFinancialAdmin] = useState(false)
    const [filtersUrl, setFiltersUrl] = useState("")
    const [selectedUserIds, setSelectedUserIds] = useState("")
    const [searchByAuto, setsearchByAuto] = useState("");
    // const [filters, setFilters] = useState({
    //     searchBy:"",
    //     Positive: true,
    //     Negative: false,
    //     Zero: false
        
    // })
    useEffect(() => {
        ApplyFilters(filters);
        // ApplySort();
        // setSortDirection(false);
        // setSortDirectionUrl('');
    }, [searchByAuto]);
    const [filters, setFilters] = useState({
        searchBy: "",
        billDate: [null, null],
        zone: "",
        facility_state: "",
        facility_name: "",
        payment_status: "",
        worker_status: "",
        worker_type: "",
        payment_type: "",
        advanceTaken: "",
        bankDetails: "",
        bankDetailsVerified:"",
        panStatus:"",
        modalFlag:"",
        All: true,
        Positive: false,
        Negative: false,
        Zero: false
        
    });

    const sortOptions = [
        ["101", "Swiggy"],
        ["101", "Flipkart"]
    ];
    const ApplySort = () => {
        let url = '';
        
        if (sortBy !== 0)
            setSortUrl('&partnerId=' + sortBy);
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false);
        setSortDirectionUrl('');
        console.log({sortBy})
    }, [sortBy]);
    const handleSearch = () => {
        setSearchByUrl(`&searchBy=${searchBy}`)
        // ApplyFilters(filters);
    } 
    // console.log(filters)
    const handleSearchClose = () => {
        setSearchBy("");
        setSearchByUrl("");
    }

    
    const midFunction = (prop) => {
        // console.log("I WAS CALLED")
        ApplyFilters(prop);
    }
    const ApplyFilters = () => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`;
        if (filters["billDate"][0] && !filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=2019-01-01&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["zone"]) {
            url = `${url}&zone=${filters["zone"]}`
        }
        if (filters["facility_state"]) {
            url = `${url}&facility_state=${filters["facility_state"].name}`
        }
        if (filters["facility_name"]) {
            url = `${url}&facility_name=${filters["facility_name"].name}`
        }
        if (filters["payment_status"]) {
            url = `${url}&payment_status=${filters["payment_status"]}`
        }
        if (filters["worker_status"]) {
            url = `${url}&worker_status=${filters["worker_status"]}`
        }
        if (filters["worker_type"] || filters["worker_type"] === 0) {
            url = `${url}&isGold=${filters["worker_type"]}`
        }
        if (filters["payment_type"]) {
            url = `${url}&paymentType=${filters["payment_type"]}`
        }
        if (filters["advanceTaken"] || filters["advanceTaken"] === 0) {
            url = `${url}&advanceTaken=${filters["advanceTaken"]}`
        }
        if (filters["bankDetails"] || filters["bankDetails"] === 0) {
            url = `${url}&bank_details=${filters["bankDetails"]}`
        }
        if (filters["bankDetailsVerified"] || filters["bankDetailsVerified"] === 0) {
            url = `${url}&verification_status=${filters["bankDetailsVerified"]}`
        }
        if (filters["panStatus"] || filters["panStatus"] === 0) {
            url = `${url}&isPanVerified=${filters["panStatus"]}`
        }
        if (filters["modalFlag"] || filters["modalFlag"] === 0) {
            url = `${url}&is_lma_worker=${filters["modalFlag"]}`
        }
        // payment_type:"",
        // worker_type:""
        console.log({url})
        setFiltersUrl(url)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            billDate: [null, null],
            zone: "",
            facility_state: "",
            facility_Name: "",
            payment_status: "",
            worker_status: "",
            worker_type: "",
            payment_type: "",
            advanceTaken: "",
            bankDetails: "",
            bankDetailsVerified:"",
            panStatus:"",
            modalFlag:""

        }
        setFilters(filterList)
        setFiltersUrl('')
        setIsFilterApplied(false)
        setCurrentPage(1)
        return filterList;
    }
    useEffect(() => {
      console.log({filtersUrl})
    }, [filtersUrl])
    useEffect(() => {
        ApplyFilters();
        console.log({filters})
    }, [filters])
    
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }
    // console.log(financialAdmin)
    const workerLedgerListingCols = useMemo(
        () => [
            // {
            //     // Make an expander cell
            //     Header: () => null, // No header
            //     id: "expander", // It needs an ID
            //     Cell: ({ row }) => (
            //       // Use Cell to render an expander for each row.
            //       // We can use the getToggleRowExpandedProps prop-getter
            //       // to build the expander.
            //       <span {...row.getToggleRowExpandedProps()}>
            //         {row.isExpanded ? <ExpandMoreIcon/> :<ExpandLessIcon/>}
            //       </span>
            //     ),
            //     // We can override the cell renderer with a SubCell to be used with an expanded row
            //     SubCell: () => null // No expander on an expanded row
            //   },
            // {
            //     id: 'selection2',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         (!(row.original.queued !== 0 || !row.original.isInvoiceCreated) && <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.queued !== 0 || !row.original.isInvoiceCreated) ? {} : { color: "#1c75bc" }} disabled={row.original.queued !== 0 || !row.original.isInvoiceCreated} />
            //         </div>)
            //     ),
            // },
            

            {
                Header: "OkayGo ID",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank Verification",
                accessor: "bankVerification",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Invoices",
                // accessor: "invoices",
                Cell: row =>{
                    return <Link to={{pathname:`/particularLedger/${row.row.original.okaygoId}`,rowData:row.row.original}} target="_blank" onClick={()=>{console.log("rowMainData",row.row.original)}} className="invoiceLink" >{row.row.original.invoices}  {'Invoices >'}</Link>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Credit",
                accessor: "totalCredit",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Debit",
                accessor: "totalDebit",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Net Balance",
                accessor: "netBalance",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            
        ],
        [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage]
    );
    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        response.content.map(el => {
            extractedData.push({
                workerId: el.workerId||"",
                okaygoId: el.okaygoId||"",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                bankVerification: el.bankVerification!=null?(el.bankVerification===1?"Yes":"No"):"-",
                
                invoices: el.invoices?el.invoices:"-",
                totalCredit: el.totalCredit?numberFormat(el.totalCredit):"",
                totalDebit: el.totalDebit?numberFormat(el.totalDebit):"",
                netBalance: el.netBalance?numberFormat(el.netBalance):""
            });
            return true;
        });

        return extractedData;
    }

    //Function to create All record export data

    const columns = workerLedgerListingCols
    const updateMyData = () => {
        // console.log({filters, filterUrl})
        getWorkerLedgerListing(perPage, currentPage);
    }


    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getWorkerLedgerListing = (rows, pageNo) => {
        setLoading(true)
        // let requestedBy = ""
        // if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "26") {
        //     requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        // }
        // let onlyFlipkartInvoice = "";
        // if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "11") {
        //     onlyFlipkartInvoice = "&onlyFlipkartInvoice=1"
        // }
        
            Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerLedgerListing}?page_no=${pageNo - 1}&rows=${rows}${!filters.All?`&value=${filters.Zero?0:filters.Positive?1:filters.Negative?-1:""}`:''} ${searchByUrl}`)
                .then(response => {
                    if (response) {
                        console.log("workerListLog===>",response)
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if (currentPage > response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                    // Sample Response
                    const response = SampleResponse
                    setTotalRows(response.totalElements)
                    setTotalPages(response.totalPages)
                    if (currentPage > response.totalPages)
                        setCurrentPage(1)
                    console.log(response.response)
                        setData(makeData(response.response))

                    setLoading(false)
                    ///

                })
        
    }
    useEffect(() => {
        getWorkerLedgerListing(perPage, 1);

    }, [filtersUrl, sortUrl, localStorage.getItem("roleType"), searchByUrl,filters])

    const handlePageChange = page => {
        getWorkerLedgerListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected,visibleColumns, state: { selectedRowIds,expanded } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy,useExpanded, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getWorkerLedgerListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    useEffect(() => {
        // // console.log({isAllRowsSelected})
        rows.map((record, index) => {
            // // console.log({record})
            // // console.log(record, selectedRowIds[index])
            if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
                selectedRowIds[index] = false
                // // console.log(selectedRowIds[index])
            }
        })
        // // console.log(selectedRowIds)
    }, [isAllRowsSelected])

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            // setDisablePayments(disableButton);
            // // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    const makeAllRecordData = (response) => {
        let extractedData = [];
        response.map(el => {
            let temp = {
                // clientId: el.casperId ? `${el.invoiceFor} : ${el.casperId}` : "",
                clientId: el.invoiceFor === "Flipkart" ? el.casperId?`${el.invoiceFor} : ${el.casperId}`:"" : el.clientId?`${el.invoiceFor} : ${el.clientId}`:"",
                okaygoId: el.okaygoId || "",
                client: el.invoiceFor || "",
                name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                lmaEflexGroupFlex: el.invoiceFor==="Flipkart"?(el.is_lma_worker?"LMA": 'Eflex'):"",
                rateCard: (el.is_lma_worker && el.invoiceFor==="Flipkart")?el.lmaRateCard:el.rateCard,
                bankDetailsVerification: createVerificationStatus(el.verificationStatus),
                bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                billNo: el.billNumber || "",
                shipmentAmount: el.shipmentAmount ? (parseFloat(el.shipmentAmount)).toFixed(2) : "",
                surgeCharges: el.surgeCharges ? (parseFloat(el.surgeCharges)).toFixed(2) : "",
                adjustments: el.recovery ? (parseFloat(el.recovery)).toFixed(2) : "",
                longDistance: el.longDistance?`${el.longDistance} km`:"",
                longDistanceAmount: el.longDistanceAmount?numberFormat(parseFloat(el.recovery)):"",
                penalty: el.penalty ? `- ${numberFormat(parseFloat(el.penalty))}` : "",

                shipmentQuantity: el.shipmentQuantity || "",
                // leaseCharges: el.leaseCharges ? (parseFloat(el.leaseCharges)).toFixed(2) : "",
                advancePayment: el.advancePayment ? numberFormat(parseFloat(el.advancePayment)) : "",
                payoutToOkayGo: el.okaygoPayout ? `${numberFormat(parseFloat(el.okaygoPayout))}` : "",
                marginPercent: el.margin? `${el.margin.toFixed(2)} %`:"",
                totalAmount: el.netPayableAmount ? parseFloat(el.netPayableAmount).toFixed(2) : "",
                netPayable: el.totalAmount ? parseFloat(el.totalAmount).toFixed(2) : "",
                paymentDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                paymentStatus: el.paymentStatus || "",
            };

            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    const makeAllRecordDataAsaan = (response) => {
        let extractedData = [];
        response.map(el => {
            extractedData.push({
                'Employee Id': el.clientId || "",
                "OkayGo Id": el.okaygoId || "",
                "Client": el.invoiceFor || "",
                "Client Emp Id": el.clientEmpId || "",
                "Name": `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                "Contact No": Decrypt(el.phoneNumber) || "",
                "PAN Number": Decrypt(el.panNumber) ||"",
                "Pan Verification Status": el.panVerificationStatus===1?"Yes":el.panVerificationStatus===0?"No":"", 
                "Bank Details filled": !el.accountNumber ? "No" : "Yes",
                "Invoice Cycle (from)": el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                "Invoice Cycle (to)": el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                "Invoice No": el.billNumber || "",
                "Service Charges": el.shipmentAmount ? numberFormat(parseFloat(el.shipmentAmount)) : "",
                "Surge Charges (incentives etc.)": el.surgeCharges ? numberFormat(parseFloat(el.surgeCharges)) : "",
                "Deductions (TDS etc.)": el.recovery ? `- ${numberFormat(parseFloat(el.recovery))}` : "",
                "Net payable Amount": el.totalAmount ? numberFormat(el.totalAmount) : "",
                "Payment Date": el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                "Payment Status": el.paymentStatus || "",
                "Transaction Id": el.transactionId || "",
            })
            return true;
        });

        return extractedData;
    }
    // console.log(localStorage.getItem("roleType"))
    const createAllRecordExcel = () => {
        setAllRecordLoading(true)
        
            Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListing}/transactions?pageRequest=0${filtersUrl}`)
                .then(response => {
                    if (response) {
                     

                            setAllRecord( makeAllRecordData(response.data.response))
                       
                    }
                })
        }
        // transactions
    
    // console.log({ allRecord })
    
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    // function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    //     if (loading) {
    //       return (
    //         <tr>
    //           <td />
    //           <td colSpan={visibleColumns.length - 1}>Loading...</td>
    //         </tr>
    //       );
    //     }
      
    //     // error handling here :)
      
    //     return (
    //       <>
    //         {[1,23].map((x, i) => {
    //           return (
    //             <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`}>
    //               {/* {row.cells.map((cell) => { */}
    //               {[1, 24, 4].map((cell) => {
    //                 return (
    //                   <>
    //                     <div>Hey </div>
    //                   </>
                      
    //                 );
    //               })}
    //             </tr>
    //           );
    //         })}
    //       </>
    //     );
    //   }
//     function SubRowAsync({ row, rowProps, visibleColumns }) {
//         const [loading, setLoading] = React.useState(true);
//         const [data, setData] = React.useState([]);
//       console.log(row.original)
//         React.useEffect(() => {
//           const timer = setTimeout(() => {
//             // setData(makeData(3));
//             setLoading(false);
//           }, 500);
      
//           return () => {
//             clearTimeout(timer);
//           };
//         }, []);
      
//         return (
//           <SubRows
//             row={row}
//             rowProps={rowProps}
//             visibleColumns={visibleColumns}
//             // data={data}
//             loading={loading}
//           />
//         );
//       }

// const renderRowSubComponent = React.useCallback(
//     ({ row, rowProps, visibleColumns }) => (
//       <SubRowAsync
//         row={row}
//         rowProps={rowProps}
//         visibleColumns={visibleColumns}
//       />
//     ),
//     []
//   );



    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header')==="OkayGo ID"?"stickWorkerIdShift zIndex1080 header":
                                column.render('Header')==="Name"?"stickWorkerNameShift zIndex1080 header" : "long-header"}>


                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        const rowProps = row.getRowProps();
                        return (
                            <>
                            <tr {...row.getRowProps()} className="row">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "OkayGo ID" ? "stickWorkerIdShift  zIndex540" :
                                        cell.column.Header === "Net Balance" ? "leftAlignLedgerTableRow" :
                                            cell.column.Header === "Name" ? "stickWorkerNameShift zIndex540" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" :
                                                
                                                    (cell.column.Header === "Mark onHold") ? ((financialAdmin || JSON.parse(localStorage.getItem("asaanRole"))) ? "" : "displayNone") : "cell"}>{cell.render('Cell')}</td>
                                })}
                            
                            </tr>
                             {/* {row.isExpanded &&
                                renderRowSubComponent({ row, rowProps, visibleColumns })} */}
                                </>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

     // Penny testing 
     const handleFilterStatus =(event, name) =>{
        if (name==="Positive"){
            if(!filters[name]){
                setFilters({...filters, Positive:true,Negative:false,Zero:false,All:false})
            }
        }
        else if (name==="Negative"){
            if(!filters[name]){
                setFilters({...filters,  Positive:false,Negative:true,Zero:false,All:false})
            }
        }
        else if (name==="Zero"){
            if(!filters[name]){
                setFilters({...filters,  Positive:false,Negative:false,Zero:true,All:false})
            }
        }
        else if (name==="All"){
            if(!filters[name]){
                setFilters({...filters,  Positive:false,Negative:false,Zero:false,All:true})
                // setFilters({...filters,  failed: false, inprocess: false, mismatchconfirmed: false, mismatch: true, verified:false, all: false,notfilled:false})
            }
        }

    }

    // console.log(localStorage.getItem("roleType"))
    return (
        <Styles>
            <Grid container className="padding2percent">
                {/* <Grid container xs={12}  >  */}
                 <Grid><span className="fontSize24 fontWeightBold">Worker Ledger</span>
                 {/* {(localStorage.getItem("roleType") !== "5" && localStorage.getItem("roleType") !== "1") && <>  <Button
                        style={{
                            marginLeft: '15px',
                            marginBottom: '8px'
                        }}
                        //variant="outlined" 
                        onClick={(event) => { if (tab.invoiceTab === true) { handleTab(event, "1") } }}
                        className={`statusButtons marginRight20 ${tab.transactionTab ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Invoices
                    </Button>
                    {!JSON.parse(localStorage.getItem("asaanRole")) && <Button
                        style={{
                            marginBottom: '8px'
                        }}
                        onClick={(event) => { if (tab.transactionTab === true) { handleTab(event, "2") } }}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${tab.invoiceTab ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Transactions
                    </Button>} */}
                    {/* </>} */}
                    </Grid>

                {/* </Grid> */}

                <Grid item xs={12} className="flexDisplay" alignItems='flex-start'>
                    <Grid item sm={5} className="flexDisplay padding0 paddingTop8" direction="row" justify="space-between">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder={"Search by OkayGo ID "}
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                    <Grid item sm={3} className="flexDisplay padding0 paddingTop8" direction="row" >
                    <Button
                        onClick={(event) =>handleFilterStatus(event, "All")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.All?"blueColorBorder":"greyColorBorder"}`}
                    >
                        All
                    </Button>
                    <Button
                        onClick={(event) =>handleFilterStatus(event, "Positive")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.Positive?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Positive
                    </Button>
                    <Button
                        onClick={(event) =>handleFilterStatus(event, "Negative")}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${filters.Negative?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Negative
                    </Button>
                   
                    <Button
                        onClick={(event) =>handleFilterStatus(event, "Zero")}
                        // variant="outlined"
                        className={`statusButtons ${filters.Zero?"blueColorBorder":"greyColorBorder"}`}
                    >
                        Zero
                    </Button>
                </Grid>

                   
                    <Grid item sm={3} className="flexDisplay padding0" direction="row" justify="space-between">
                      
                        {/* <Grid item xs={1} className="buttonList padding8" alignItems='flex-start'>
                            {
                                localStorage.getItem("roleType") !== "1" && <Filters
                                    filters={filters}
                                    midFunction={midFunction}
                                    setFilters={setFilters}
                                    asaanRole={JSON.parse(localStorage.getItem("asaanRole"))}
                                    // filterDrawer={filterDrawer}
                                    // setFilterDrawer={setFilterDrawer}
                                    resetFilters={resetFilters}
                                    isFilterApplied={isFilterApplied}
                                    setIsFilterApplied={setIsFilterApplied}
                                />}
                        </Grid> */}

                    </Grid>
          

                </Grid>
                {/* <Grid item xs={12} className="flexDisplay paddingTop16" direction="row" alignItems='flex-start'>

                    <Grid item xs={5} className="" alignItems='flex-start'>

                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled={allRecordLoading}
                            onClick={createAllRecordExcel}
                            style={{ marginRight: "25px" }}
                        >
                            Export to Excel
                        </Button>
                        {openGenerateInvoice &&
                            <GenerateInvoiceModal
                                // toast ={}
                                showToast={showToast}
                                updateMyData={updateMyData}
                                open={openGenerateInvoice}
                                setOpen={setOpenGenerateInvoice}
                            />
                        }

                        {allRecordLoading ?
                            <span style={{ marginLeft: "20px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <CSVLink
                                    data={allRecord}
                                    filename={!tab.invoiceTab ? `InvoiceList_${moment().format("DD/MM/YYYY HH:mm")}.csv` : `TransactionList_${moment().format("DD/MM/YYYY HH:mm")}.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginLeft: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink>
                                : null}
                    </Grid>

                    <Grid item xs={9} className="textAlignRight" >
                        {(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6") && <Button
                            style={{ marginRight: '12px' }}
                            variant="contained"
                            className="dataButton"
                            // style={{ marginLeft: "16px" }}
                            onClick={() => handleSendMsg()}
                            disabled={disablePayments}
                        >
                            Send Message
                        </Button>}
                        <FormControl variant="outlined" className="margin1" >
                            <div>   {financialAdmin && !JSON.parse(localStorage.getItem("asaanRole")) && 
                                //   <div style={{    paddingLeft: '760px'}}>
                                <Button
                                    style={{ marginRight: '12px' }}
                                    variant="contained"
                                    className="dataButton"
                                    onClick={handleOpen}
                                >
                                    Check Balance
                                </Button>
                                // </div>
                            }<Button
                                style={{ marginRight: '12px' }}
                                variant="contained"
                                className="dataButton"

                                onClick={handleActions}
                            >
                                    Upload Invoice
                                </Button>
                                {financialAdmin && <Button

                                    variant="contained"
                                    className="dataButton"
                                    disabled={disablePayments}
                                    onClick={handlePayments}
                                >
                                    Trigger payment
                                </Button>}</div>
                        </FormControl>
                    </Grid>
                    
                </Grid> */}
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}

               

            </Grid>
        </Styles>
    )
}

export default ExternalWorker;


