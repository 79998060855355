import { useMemo } from "react";

import { useTable } from 'react-table'
export const Table = ({ columns, data, noDataComponent, ...rest }) => {
    const tableColumns = useMemo(() => columns, [columns]);
    const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable({ columns: tableColumns, data });
  
    if (!rows.length) {
      if (noDataComponent) return noDataComponent;
      return <>No data</>;
    }
  
    return (
      <table {...getTableProps()} {...rest} style={{    width: "100%"}}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{outlineStyle: "solid",
                outlineColor: "#ddd",
                outlineWidth:"1px"}}>
                {/* {row.cells.map((cell) => {
                  const { className, style } = cell.column;
                  return (
                    <td {...cell.getCellProps({ className, style })}>
                      {cell.render('Cell')}
                    </td>
                  );
                })} */}

                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}
                        className={cell.column.Header === "Credit" ? "put-border" :cell.column.Header === "Debit" ? "leftAlignLedgerTableRow" 
                        : 
                            "cell"}
                            
                            >{cell.render('Cell')}</td>
                                })}

              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  export default Table;