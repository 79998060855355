import React, { useState } from "react"
import { Grid, Button, Modal, Backdrop, TextField } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    KeyboardTimePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import qs from 'querystring'
// import calender from '../../assets/calender.svg'
// import time from '../../assets/timer.svg'

const flexDisplay = {
    margin: 'auto',
},
    ipBorder = {
        border: 'solid 1px #1c75bc',
        borderRadius: '4px',
        // width: '170px',
        width: "607px",
        height: "147px",
        padding: '0 8px'
    },
    subTitle = {
        objectFit: 'contain',
        fontSize: '14px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left'
    },
    txtArea = {
        border: 'none',
        fontSize: '14px',
        margin: '0 55px',

    },
    confirm = {
        width: '116px',
        height: '40px',
        borderRadius: '4px',
        backgroundColor: '#1c75bc',
        color: '#f4f4f4',
        fontSize: '14px',
        float: 'right',
        marginRight: '25px'
    },
    cancel = {
        width: '111px',
        height: '40px',
        borderRadius: '4px',
        border: 'solid 1px #1c75bc',
        backgroundColor: '#ffffff',
        color: '#1c75bc',
        float: 'right',
        marginRight: "24px"
    };

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '681px',
        height: '368px'
    },
}));

const DatePickerModel = (props) => {
    // const { children, classes, onClose, ...other } = props;
    const classes = useStyles();
    const { openAdminNotes, setOpenAdminNotes, row, showToast, noteData, updateMyData, recruiterMasterId } = props;
    const [reminderData, setReminderData] = useState({
        data: noteData ? noteData : ""

    })
    // debugger
    const [note, setNote] = useState({
        data: noteData == null ? noteData : ""
    })

    let caption = noteData == null ? "Edit No of openings" : "Edit No of openings";
    let dynamicMargin = noteData == null ? "444px" : "452px";
    // const [reminderDataError, setReminderDataError] = useState({
    //     data: "",
    //     date: "",
    //     time: ""
    // })
    console.log(noteData)
    const handleSaveReminder = () => {
        if (reminderData.data) {

            let field = {
                noOfOpenings: ["noOfOpenings",reminderData.data]
            }
             Axios.post(`${urlLinks.baseUrl}${urlLinks.jobs.jobEditSave}?field=${field.noOfOpenings[0]}&job_id=${recruiterMasterId}&updated_by=${localStorage.getItem('userID')}&value=${field.noOfOpenings[1]}`)
                 .then(response => {
                     if (response.data.code === 1000) {
                         updateMyData()
                         //   getAllReminders();
                         //   setCallReminderModal(false);
                         //showToast("success", "Note added successfully.");
                         setOpenAdminNotes(false)
                     } else {
                         // setCallReminderModal(false);
                         showToast("error", "editing no of openings failed");
                     }
                 })
        }
        // else {
        //     if (!reminderData.data) {
        //         setReminderDataError({ ...reminderDataError, data: "Enter reminder note." })
        //     }
        //     if (!reminderData.date) {
        //         setReminderDataError({ ...reminderDataError, date: "Enter reminder date." })
        //     }
        //     if (!reminderData.time) {
        //         setReminderDataError({ ...reminderDataError, time: "Enter reminder time." })
        //     }
        // }
    }
    return (

        <Modal
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openAdminNotes}
            onClose={() => setOpenAdminNotes(false)}
            closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        // timeout: 50,
        // }}
        >
            <div className={classes.paper}>
                <Grid container>
                    {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}> */}
                    <Grid className="padding0" item xs={12} style={flexDisplay} direction="row" >
                        {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}> */}
                        <h1>{caption}
                        <IconButton style={{marginLeft:dynamicMargin}}
          aria-label="close"
        //   className={classes.closeButton}
          onClick={() => setOpenAdminNotes(false)}
        >
          <CloseIcon />
        </IconButton></h1> 
                        {/* <CloseOutlinedIcon fontSize={"large"} style={{marginLeft:"452px"}} ></CloseOutlinedIcon> */}
                    </Grid>

                    <Grid item xs={12} className="padding0" style={{ marginBottom: '15px' }}>
                        <TextField
                            rows={6}
                            multiline
                            fullWidth
                            variant="outlined"
                            style={{
                                width: "94%",
                                height: "159px",
                            }}
                            value={reminderData.data}
                            onChange={(event) => setReminderData({ ...reminderData, data: event.target.value })}
                            placeholder="Notes"
                            required
                        />
                    </Grid>

                    <Grid item xs={12} className="padding0" direction="row">
                        <Button style={confirm} onClick={handleSaveReminder}>SAVE</Button>
                        <Button style={cancel} onClick={() => setOpenAdminNotes(false)}>CANCEL</Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default DatePickerModel;
