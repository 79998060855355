import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton, Button } from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { Link } from "react-router-dom";
// import DashboardRemarkModal from '../../../utils/Modals/dashboardRemarkModal'
import urlLinks from '../../UrlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';
// import { ToastContainer } from 'react-toastify';



const EditableDropdownSubComponent = (props) => {
    const { row, columnId, initialValue, updateMyData, showToast, setLoading,disable} = props

    const [selectDisable, setSelectDisable] = useState(false)
    // const [selectDisableTwo, setSelectDisableTwo] = useState(false)
    // const [selectDisableThree, setSelectDisableThree] = useState(false)
    const [value, setValue] = useState(initialValue);
    // console.log(localStorage.getItem("userId"))
    // useEffect(() => {
    //     console.log(columnId)
    //     // if (columnId === "dataForRecruitment" && row.recruiterMasterId === "-") {
    //     //     setSelectDisableTwo(true)
    //     // }
    //     // else
    //         // setSelectDisableTwo(false)
    //     // if (columnId === "haveLaptopSmartphoneWifi" && row.recruiterMasterId === "-") {
    //     //     setSelectDisableThree(true)
    //     // }
    //     // else
    //         // setSelectDisableThree(false)
    //     // if ((columnId === "Selection Status" )) {
    //     //     setSelectDisable(true)
    //     // } 
    //     // if ((columnId === "Selection Status" && initialValue === "INCOMPLETE REGISTRATION")) {
    //         // setSelectDisable(true)
    //     // } 
    //     // else
    //         // setSelectDisable(false)
    // }, [initialValue])



    console.log(initialValue)
    console.log(value)
    let label = ""
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    // console.log({row})
    const updateStatus = () => {
        setLoading(true)
        console.log("entered", value)
        if (columnId === "Selection Status") {
            const postData = {
                status: value,
                recruiterMasterId: row.recruiterMasterId,
                userId:row.userId,
                jobId:row.jobId

            }


            Axios.post(`${urlLinks.baseUrl}${urlLinks.jobs.updateJobSelection}?updatedBy=${localStorage.getItem("userId")}&user_id=${postData.userId}&status=${postData.status}&job_id=${postData.jobId}`)
                .then(response => {
                    if (response.data.code === 1000) {
                        updateMyData()
                        setLoading(false);
                    } else {
                        showToast(response.data.message);
                        setLoading(false);
                    }
                })
        }
        // if (columnId === "haveLaptopSmartphoneWifi") {
        
        //     console.log(value)
        //     const postData = {
        //         recruiterMasterId: row.recruiterMasterId,
        //         haveLaptopSmartPhoneWifi: value

        //     };


        //     Axios.post(`${urlLinks.baseUrl}/recruiter/adminUpdateRecruiter`, postData)
        //         .then(response => {
        //             if (response.data.code === 1000) {
        //                 updateMyData()
        //                 setLoading(false);
        //             } else {
        //                 showToast(response.data.message);
        //                 setLoading(false);
        //             }
        //         })



        // }
        // if (columnId === "dataForRecruitment") {
        //     const postData = {
        //         recruiterMasterId: row.recruiterMasterId,
        //         dataForRecruitment: value

        //     };


        //     Axios.post(`${urlLinks.baseUrl}/recruiter/adminUpdateRecruiter`, postData)
        //         .then(response => {
        //             if (response.data.code === 1000) {
        //                 updateMyData()
        //                 setLoading(false);
        //             } else {
        //                 showToast(response.data.message);
        //                 setLoading(false);
        //             }
        //         })



        // }


    }
    const handleConfirm = () => {
        setLoading(true)
        updateStatus();
    }
    const handleCancel = () => {
        setValue(initialValue)
        setShowButtons(false)
    }
    // const isDisable = () => {
    //     if (initialValue == 'INCOMPLETE REGISTRATION')
    //         return true;
    //     else
    //         return false;
    // }
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    let dropdown = <div></div>
    // if (columnId === "recruiterStatus")
    dropdown = <div className="index">
        {columnId === "Selection Status" && <Select
            id="Selection Status"
            value={value}
            disableUnderline
            // disabled={disable}
            onChange={onChange}
            inputProps={{
                classes: {
                    icon: "selectIcon",
                },
            }}
        >
            <MenuItem value="-" disabled>
                <em>Selection status</em>
            </MenuItem>
            <MenuItem value={1}>Selected</MenuItem>
            <MenuItem value={2}>Rejected</MenuItem>

        </Select>}
        {/* {columnId === "dataForRecruitment" && <Select
            id="dataForRecruitment"
            value={value}
            disableUnderline
            disabled={selectDisableTwo}
            onChange={onChange}
            inputProps={{
                classes: {
                    icon: "selectIcon",
                },
            }}
        >
            <MenuItem value="-" disabled>
                <em>Data Source</em>
            </MenuItem>
            <MenuItem value={'OKAYGO_DATA'}>OkayGo Data</MenuItem>
            <MenuItem value={'SELF'}>Self</MenuItem>
            <MenuItem value={'BOTH'}>Both</MenuItem>


        </Select>} */}
        {/* {columnId === "haveLaptopSmartphoneWifi" && <Select
            id="haveLaptopSmartphoneWifi"
            value={value}
            disableUnderline
            disabled={selectDisableThree}
            onChange={onChange}
            inputProps={{
                classes: {
                    icon: "selectIcon",
                },
            }}
        >
            <MenuItem value="-" disabled>
                <em>Logistics</em>
            </MenuItem>
            <MenuItem value={1}>Available</MenuItem>
            <MenuItem value={2}>Not Available</MenuItem>



        </Select>} */}

        {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
    </div>


    return (
        <React.Fragment>
            {dropdown}

        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;



