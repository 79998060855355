import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
//import { Divider , Badge } from '@material-ui/core';
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel, Switch } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import Modal from '@material-ui/core/Modal';
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
import { numberFormat } from '../../Utils/CommonFunctions';
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import Swal from 'sweetalert2'
import HelpSection from './components/HelpSection';
import Calendar from '../../assets/calendar.svg'
import style from './index.module.scss';
import { Link } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createVerificationStatus } from '../../services/CommonServices'
import { AuthContext } from '../../services/AuthProvider';
import './index.css'
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import DateFilter from './components/DateFilter'
import './indeterminateCheckbox.css'
import Decrypt from '../../Utils/decrypt'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }

    }, paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} />
            </React.Fragment>
        )
    }
)

const ExternalWorker = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataBalnce, setDataBalance] = useState();
    const [actions, setActions] = useState(0)
    const { showToast } = useContext(AuthContext);

    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRecord, setAllRecord] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);
    // const [sortDirectionUrl, setSortDirectionUrl] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [sortUrl, setSortUrl] = useState("");
    const [financialAdmin, setFinancialAdmin] = useState(false)
    const [filterUrl, setFilterUrl] = useState("");
    // const [filterDrawer, setFilterDrawer] = useState(false);
    const [searchByAuto, setsearchByAuto] = useState("");
    const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false)
    const [tab, setTab] = useState({
        invoiceTab: false,
        transactionTab: true
    })
    useEffect(() => {
        ApplyFilters(filters);
    }, [searchByAuto]);
    const [filters, setFilters] = useState({
        searchBy: "",
        billDate: [moment().add(-1, 'days').format("YYYY-MM-DD"), moment().add(-1, 'days').format("YYYY-MM-DD")],
    });

    const sortOptions = [
        ["swiggy_id", "Swiggy"],
        ["flipkart_id", "Flipkart"]
    ];
    const ApplySort = () => {
        let url = '';
        switch (sortBy) {
            case "swiggy_id":
                url = '&partnerId=101';
                break;
            case "flipkart_id":
                url = '&partnerId=100';
                break;
            default:
                url = ''
                break;
        };
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url);
    }
    
    useEffect(() => {
        ApplySort();
        setSortDirection(false);
        // setSortDirectionUrl('');
    }, [sortBy]);
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)
        // ApplyFilters(filters);
    }
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
        setIsFilterApplied(false)
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const midFunction = (prop) => {
        console.log("I WAS CALLED")
        ApplyFilters(prop);
    }
    const ApplyFilters = () => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`;
        if (filters["billDate"][0] && !filters["billDate"][1])
            url = `${url}&date1=${moment(filters["billDate"][1]).format("YYYY-MM-DD")}&date2=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["billDate"][1] && filters["billDate"][0])
            url = `${url}&date1=2019-01-01&date2=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["billDate"][1] && filters["billDate"][0])
            url = `${url}&date1=${moment(filters["billDate"][1]).format("YYYY-MM-DD")}&date2=${moment(filters["billDate"][0]).format("YYYY-MM-DD")} 23:59:59`
        setFilterUrl(url)
        setFilters(filters)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            searchBy: "",
            billDate: [null, null],

        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }

    // const handleSortDirection = () => {
    //     if (!sortDirection)
    //         setSortDirectionUrl("&sortBy=ascending");
    //     else
    //         setSortDirectionUrl("");
    //     setSortDirection(!sortDirection)

    // }
    // console.log(moment().add(0, 'days').format("YYYY-MM-DD hh:mm:ss"))


    //columns list
    const transactionCols = useMemo(
        () => [
            {
                id: 'selection',
                // Header: ({ getToggleAllRowsSelectedProps }) => (
                //     <div>
                //         <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                //     </div>
                // ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{ display:"flex", justifyItems:"center" }} />
                    </div>
                ),
            },
            {
                Header: "Recruiter Id",
                accessor: "recruiterId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Phone Number",
                accessor: "phoneNumber",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "City",
                accessor: "recruiterCity",
                Cell: (row) => {
                    const match = row.row.original.recruiterCity.match(/^(?:[^,]*,){2}([^,]*)/); // "Hi, this, sentence,"
                    if (match !== null) {

                        let cutstr = row.row.original.recruiterCity;

                        cutstr = (match.slice(0, -1));

                        return (<> {cutstr}  ...</>);
                    } else {
                        return row.row.original.recruiterCity
                    }
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Block - Get Leads",
                Cell: row => {
                    return (

                        <FormControlLabel
                            control={<Switch
                                checked={row.row.original.isExt === 1}
                                onClick={() => handleOnHold(!row.row.original.isExt, row.row.original.recruiterId)}
                                color="primary"
                            />}
                            label={row.row.original.isExt === 1 ? "Blocked" : ""}
                        />
                    )

                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Leads Allocated",
                accessor: "leadsAllocated",
                // className:"bob",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Leads added by recruiter",
                accessor: "leadsAddedByRecruiter",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Leads from OkayGo",
                accessor: "leadsAddedFromOkayGo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Actual Connected Calls",
                accessor: "actualConnectedCalls",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                // Header: "Discrepancies",

                Header: row => {
                    return <div>Discrepancies     <HtmlTooltip title={<div>No. of users marked as interested or not interested but not called during the day. </div>}>
                        <InfoIcon style={{ verticalAlign: 'bottom' }} fontSize='small' /></HtmlTooltip></div>
                },
                accessor: "discrepancies",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Avg Call Time",
                accessor: "avgCallTime",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Call Time",
                accessor: "totalcallTime",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Interviews",
                accessor: "interview",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Under Training",
                accessor: "underTraining",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Joined",
                accessor: "joined",
                defaultCanFilter: false,
                defaultCanSort: false
            },


            {
                Header: "Urgent Follow ups",
                accessor: "urgentFollowUps",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Left before 30 days",
                accessor: "leftBefore30Days",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Interview",
                accessor: "totalInterview",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Under Training",
                accessor: "totalUnderTraining",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Joined",
                accessor: "totalJoined",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Total Client ID",
                accessor: "totalCasperGen",
                defaultCanFilter: false,
                defaultCanSort: false
            },

        ],
        [filterUrl]
    );
    

    const handleFilterDates = (dateOne, dateTwo, indexOne, indexTwo, type) => {
        let dates = filters.billDate;
        dates[indexOne] = dateOne;
        dates[indexTwo] = dateTwo;
        setFilters({ ...filters, [type]: dates });
        ApplyFilters(filters);
    }

    const convertIntoMin = (n) => {

        let hours = Math.floor(n / 3600)
        let minutes = Math.floor(n / 60)
        if (hours !== 0) {
            minutes = minutes - hours * 60
        }
        let seconds = n % 60
        return (hours + " hr " + minutes + " min " + seconds + " sec")

    }
    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        // let lastupdatedon = [];
        console.log(response)
        response.content.map(el => {
            extractedData.push({
                // contactNo: el.phoneNumber || "",
                // isExt:0,
                isExt: el.isExt !== null ? el.isExt : 0,
                totalInterview: el.totalInterview ? el.totalInterview : "",
                totalUnderTraining: el.totalUnderTraining ? el.totalUnderTraining : "",
                totalJoined: el.totalJoined ? el.totalJoined : "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                recruiterId: el.recruiterId ? el.recruiterId : "-",
                leadsAllocated: el.leadsAllocated ? el.leadsAllocated : "-",
                actualConnectedCalls: el.actualConnectedCalls ? el.actualConnectedCalls : "-",
                discrepancies: el.discrepancies ? el.discrepancies : "-",
                avgCallTime: el.avgCallTime ? convertIntoMin(el.avgCallTime) : "-",
                totalcallTime: el.totalcallTime ? convertIntoMin(el.totalcallTime) : "-",
                interview: el.interview ? el.interview : "-",
                underTraining: el.underTraining ? el.underTraining : "-",
                joined: el.joined ? el.joined : "-",
                totalCasperGen: el.total_client_id_gen ? el.total_client_id_gen : "-",
                urgentFollowUps: el.urgentFollowUps ? el.urgentFollowUps : "-",
                leftBefore30Days: el.leftBefore30Days ? el.leftBefore30Days : "-",
                userId: el.userId ? el.userId : "-",
                recruiterCity: el.recruiterCity ? el.recruiterCity : "-",
                gender: el.gender ? el.gender : "-",
                dateOfBirth: el.dateOfBirth ? el.dateOfBirth : "-",
                emailId: el.emailId ? el.emailId : "-",
                phoneNumber: el.phoneNumber ? Decrypt(el.phoneNumber) : "-",
                city: el.city ? el.city : "-",
                state: el.state ? el.state : "-",
                active: el.active ? el.active : "-",
                leadsAddedByRecruiter: el.leadsAddedByRecruiter ? el.leadsAddedByRecruiter : "-",
                // active:el.active ? el.active : "-"
                leadsAddedFromOkayGo: el.leadsAddedFromOkayGo ? el.leadsAddedFromOkayGo : "-",
            });
            // lastupdatedon.push(el.lastUpdatedOn)
            return true;
        });

        return extractedData;
    }

    //Function to create All record export data

    const columns = transactionCols
    const updateMyData = () => {
        getPaymentListing(perPage, currentPage);
    }


    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getPaymentListing = (rows, pageNo) => {
        setLoading(true)
        let ajFlag = ""
        if (JSON.parse(localStorage.getItem("asaanRole")))
            ajFlag = "&isAj=1"
        if (filterUrl.length > 1) {
            Axios.get(`${urlLinks.baseUrl}${urlLinks.recruiter.dashboard}?page_no=${pageNo - 1}&rows=${rows}${filterUrl}${ajFlag}`)
                // Axios.get(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListing}?page_no=${pageNo - 1}${requestedBy}&rows=${rows}${filterUrl}${sortUrl}`)
                .then(response => {
                    console.log(response)
                    if (response) {
                        console.log(response.data.response.content)
                        setData(makeData(response.data.response.content))
                        setTotalRows(response.data.response.content.totalElements)
                        setTotalPages(response.data.response.content.totalPages)
                        if (currentPage > response.data.response.content.totalPages)
                            setCurrentPage(1)
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }
    useEffect(() => {
        getPaymentListing(perPage, 1);

    }, [filterUrl,
        sortUrl,
        localStorage.getItem("roleType"), tab])

    const handlePageChange = page => {
        getPaymentListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getPaymentListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    console.log({ selectedRowIds })
    // const indexes = Object.entries(selectedRowIds);
    // let idList = []

    // indexes.map((id, index) => {
    //     idList.push(data[parseFloat(id[0])].userId)
    //     return true;
    // })
    // useEffect(() => {
    //     // console.log({isAllRowsSelected})
    //     rows.map((record, index) => {
    //         // console.log({record})
    //         // console.log(record, selectedRowIds[index])
    //         if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
    //             selectedRowIds[index] = false
    //             // console.log(selectedRowIds[index])
    //         }
    //     })
    //     // console.log(selectedRowIds)
    // }, [isAllRowsSelected])

    // useEffect(() => {
    //     if (selectedRowIds) {
    //         const indexes = Object.values(selectedRowIds);
    //         // console.log({indexes})
    //         let disableButton = true;
    //         indexes.map(id => {
    //             if (id) {
    //                 disableButton = false;
    //                 return true;
    //             }
    //             return true;
    //         })
    //         setDisablePayments(disableButton);
    //         // console.log({indexes, selectedRowIds });
    //     }
    // }, [selectedRowIds])
    const makeAllRecordData = () => {
        let extractedData = [];
        data.map(el => {
            let temp = {
                recruiterId: el.recruiterId ? el.recruiterId : "-",
                name: el.name,
                phoneNumber: el.phoneNumber ? Decrypt(el.phoneNumber) : "-",
                city: el.city ? el.city : "-",
                leadsAllocated: el.leadsAllocated ? el.leadsAllocated : "-",
                leadsAddedByRecruiter: el.leadsAddedByRecruiter ? el.leadsAddedByRecruiter : "-",
                leadsAddedFromOkayGo: el.leadsAddedFromOkayGo ? el.leadsAddedFromOkayGo : "-",
                actualConnectedCalls: el.actualConnectedCalls ? el.actualConnectedCalls : "-",
                avgCallTime: el.avgCallTime,
                totalcallTime: el.totalcallTime,
                interview: el.interview ? el.interview : "-",
                underTraining: el.underTraining ? el.underTraining : "-",
                joined: el.joined ? el.joined : "-",
                totalClientIdGenerated: el.totalCasperGen ? el.totalCasperGen : "-",
                urgentFollowUps: el.urgentFollowUps ? el.urgentFollowUps : "-",
                leftBefore30Days: el.leftBefore30Days ? el.leftBefore30Days : "-",
                totalInterview: el.totalInterview ? el.totalInterview : "-",
                totalUnderTraining: el.totalUnderTraining ? el.totalUnderTraining : "",
                totalJoined: el.totalJoined ? el.totalJoined : "-"


            };
            extractedData.push(temp)

        });

        return extractedData;
    }
    const makeAllTransactionRecordData = (response) => {
        let extractedData = [];
        response.map(el => {

            let temp = {
                casperId: el.casperId || "",
                okaygoId: el.okaygoId || "",
                name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                // bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                InvoiceNo: el.billNumber || "",
                billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                okaygoCharges: el.okaygoCharges ? numberFormat(el.okaygoCharges) : "",
                paymentType: el.okaygoCharges !== null ? "Advance Payout" : "15 Day payout",
                // billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                TransactionDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                totalAmount: el.totalAmount ? numberFormat(el.totalAmount) : "",
                TransactionStatus: el.paymentStatus || "",
                TransactionMode: el.okaygoCharges ? "IMPS" : (el.paymentStatus ? "NEFT" : ""),
                TransactionRemarks: el.paymentRemarks || "",
                transactionId: el.transactionId || "",
            };

            if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") {
                delete temp["paymentStatus"]
                delete temp["paymentRemarks"]
            }
            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    // console.log(localStorage.getItem("roleType"))
    const createAllRecordExcel = () => {
        setAllRecordLoading(true)
        setAllRecord(makeAllRecordData())
        setAllRecordLoading(false)
        // let requestedBy = ""
        // if (localStorage.getItem("roleType") === "1") {
        //     requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        // }

        //     Axios.post(`${urlLinks.baseUrl}${urlLinks.recruiter.dashboard}${requestedBy}${filterUrl}`)
        //     .then(response => {

        //                 setAllRecord(makeAllRecordData(response.data.response))

        //     })

    }

    const handleInactive = () => {
        const indexes = Object.entries(selectedRowIds);
        let idList = []

        indexes.map((id, index) => {
            idList.push(data[parseFloat(id[0])].recruiterId)
            return true;
        })
        console.log(idList)
        Swal.fire({
            // title: 'Are you sure to inactive  recruiter ?',
            title: 'Are you sure to inactive ' + idList.length + ' recruiter ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {

                console.log(`${urlLinks.baseUrl}${urlLinks.recruiter.activeAndBlockAPI}?recruiter_id=${idList.toString()}&is_active=0`)
                Axios.post(`${urlLinks.baseUrl}${urlLinks.recruiter.activeAndBlockAPI}?recruiter_id=${idList.toString()}&is_active=0`)
                    .then(response => {
                        if (response) {
 console.log(filterUrl)
                            updateMyData()
                            // ApplyFilters()
                        }
                    })

                Swal.fire(
                    'Inactivated',
                    // 'Updated!',
                    "",
                    'success'
                )
            }
        })


    }
    const handleOnHold = (blockValue, recId) => {
        // if (onHold) {
        blockValue = blockValue ? 1 : 0;
        console.log(blockValue)
        Axios.post(`${urlLinks.baseUrl}${urlLinks.recruiter.activeAndBlockAPI}?recruiter_id=${recId}&is_ext=${blockValue}`)
            .then(response => {
                if (response) {
                    // showToast("success", "Payment status updated")
                    // console.log({ response })
                    console.log(filterUrl)
                    showToast("success", `${blockValue?'Blocked':'Unblocked'} Successfully`)
                    updateMyData()
                    // ApplyFilters()
                }
            })
            .catch(error => {
                showToast("error", error.message)

            })

    }

    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>

    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickRecruiterName zIndex1080 header" :
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" : column.render('Header') === "City" ? "fix-width230 header" : "header"}>
                                {/* fix-width230 */}
                                <span >
                                    {column.render('Header')}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="row">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickRecruiterName zIndex540" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid container xs={12}  >  <Grid xs={5}><span className="fontSize24 fontWeightBold">Recruiter's Data</span>

                </Grid>


                </Grid>

                <Grid item xs={12} className="flexDisplay" alignItems='flex-start'>

                    <Grid item xs={10} className="flexDisplay paddingTop8" direction="row" alignItems='flex-start'>
                        <div className="DateRange"> Data from:  <div className="inline"> {moment(filters.billDate[1]).format("DD-MM-YYYY")}</div> to  <div className="inline">{moment(filters.billDate[0]).format("DD-MM-YYYY")}</div></div>
                        <div className="inline2"><DateFilter filters={filters} handleFilterDates={handleFilterDates} ></DateFilter></div> <div className="inline2"><HelpSection></HelpSection></div>
                    </Grid>

                    {<div style={{marginTop:"20px"}}>
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={createAllRecordExcel}
                            disabled={loading}
                        >
                            Export to Excel
                        </Button>
                        {allRecordLoading ?
                            <span style={{ marginLeft: "20px" }}>Please wait ... it might take few minutes. </span>
                            : allRecord.length > 0 ?
                                <p><CSVLink
                                    data={allRecord}
                                    filename={`Recruiter data.csv`}
                                    className='btn btn-primary blueColor downloadExcelButton'
                                    target='_blank'
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        marginLeft: "20px",
                                        fontWeight: '500'
                                    }}
                                >
                                    Download now
                                </CSVLink></p>
                                : null}
                    </div>}
                </Grid>
                <div style={{ margin: "15px 10px 15px 1px" }}> <Button
                    className="dataButton"
                    style={{
                        width: '145px',
                        height: '51px !important',
                        fontSize: '19px'
                    }}
                    onClick={handleInactive}
                    disabled={Object.keys(selectedRowIds).length === 0}
                >
                    Inactive
                </Button></div>
                {/* </Grid> */}
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
            </Grid>
        </Styles>
    )
}

export default ExternalWorker;


