import React, {useState, } from 'react';
import {Button ,Divider , Grid } from '@material-ui/core';
// import Axios from 'axios' 
import SelectInput from '../../Utils/FilterComponents/SelectFilter'
// import InputRangeFilter from '../../Utils/FilterComponents/InputRangeFilter'
// import SliderFilter from '../../Utils/FilterComponents/SliderFilter'
import DateFilter from '../../Utils/FilterComponents/DateFilter'
// import LocationFilter from '../../Utils/FilterComponents/locationFilter'
// import GroupedSelectFilter from '../../Utils/FilterComponents/groupedSelectFilter'
// import InputTextFilter from '../../Utils/FilterComponents/inputTextFilter' 


export default function SimplePopover(props) {
    // const classes = useStyles();
    // const {isFilterApplied, setIsFilterApplied} = props
    const [filters, setFilters] = useState(props.filters)
    // const [jobRoleList, setJobRoleList] = useState([])
    const handleResetFilters =() =>{
        const newFilters=props.resetFilters()
        setFilters(newFilters);
        // setIsFilterApplied(false)
        
    }
    const handleApplyFilters =() =>{
        props.updateFilters(filters);
        props.setFilterDrawer(false)
    }
    const handleCancelFilters =() =>{
        setFilters(props.filters);
        props.setFilterDrawer(false)
    }
    let popoverData= <Grid></Grid>
    
    popoverData= 
        <Grid container className="padding10" style={{width:"350px", display:"flex", flexDirection:"row"}}>
            
            {/* <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="approvalStatus"
                    label="Approval status"
                    setFilters={setFilters}
                    // multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="appliedBy"
                    label="Applied By"
                    setFilters={setFilters}
                    // multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="interviewMode"
                    label="Interview Mode"
                    setFilters={setFilters}
                    // multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="interviewFollowUp"
                    label="Interview Follow up"
                    setFilters={setFilters}
                    // multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="recruiterFeedback"
                    label="Recruiter Feedback"
                    setFilters={setFilters}
                    // multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="joiningStatus"
                    label="Joining status"
                    setFilters={setFilters}
                    // multipleFlag={true}
                />
            </Grid>

            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Profile shared between</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="profile_shared_date"
                />
            </Grid>
            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Selection date</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="selectionDate"
                />
            </Grid>
            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Joining date</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="joiningDate"
                />
            </Grid>
            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Date of leaving</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="dateOfLeaving"
                />
            </Grid>
            <Grid item sm className="padding20">
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Remark Status"
                    id="remark_status"
                    data={remarkStatusOptions}
                />
            </Grid>
            {filters.remark_status.find(o => o.nameId === 'low_salary') && <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Low salary"
                    id="low_salary"
                />
            </Grid>}
            {filters.remark_status.find(o => (o.nameId === 'preferred_job_type_id' || o.nameId === 'skill_mismatch')) && <Grid item sm className="padding20">
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Preferred Job"
                    id="preferred_job_type_id"
                    data={jobRoleList}
                />
            </Grid>}
            {filters.remark_status.find(o => o.nameId === 'preferred_job_location_id') && <Grid item sm className="padding20">
                <LocationFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Preferred Location"
                    id="preferred_job_location_id"
                />
            </Grid>}
             */}
        </Grid>
    return (
        <Grid container className="flexDisplay " direction= "column" style={{ marginTop:"40px"}}>
            <Grid item xs={12} className="flexDisplay padding10" direction ="row">
                <Grid item xs={5} className="padding0">
                    <span className= "FilterHeading">Other Filters</span>
                </Grid>
                <Grid item xs={7}  className="flexRowReverse padding0">      
                    <Button 
                        className= "dataButton"
                        style={{marginLeft: "30px"}}
                        onClick={handleResetFilters}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            
                {popoverData}
            <Divider />
            <Grid container style={{padding: "20px"}}>      
                <Button 
                    className= "dataButton"
                    style={{margin: "0 30px"}}
                    onClick={handleApplyFilters}
                >
                    Apply
                </Button>
                <Button 
                    className= "dataButton"
                    onClick={handleCancelFilters}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
}
