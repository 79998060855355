import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import * as moment from 'moment';
import Typography from "@material-ui/core/Typography";
import Download from '../Group 3238.svg'
import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import FormControl from '@mui/material/FormControl';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);



export default function CustomizedDialogs(props) {
  const { openOffboardingModal, setOpenOffboardingModal, showToast, filters, setFilterUrl, filterUrl, tab,  createAllRecordExcel, updateFilterUrl, setFilters } = props

  const [custom, setCustom] = useState()
  const [defaultRadio, setDefaultRadio] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [sentToAll, setSentToAll] = useState(true)
  const [value, setValue] = React.useState(filters['leadAddedDate']);

  useEffect(() => {
    console.log(filters['leadAddedDate'])
    if (filters['leadAddedDate'][0] !== null || filters['leadAddedDate'][1] !== null) {
      // setCustom(true)
      setDefaultRadio('3')
      handleCustom('3')
    } else {
      // setCustom(false)
      if(!tab.workerDataTab){
      setDefaultRadio('1')
      handleCustom('1')
      }
    }
  }, [])
  // console.log(fromDate,toDate)
  console.log(filters, "**")
  const handleCustom = (e) => {
    var to = moment().format('YYYY-MM-DD')
    setEndDate(to)
    var from = null;
    if (e === "3") {
      setCustom(true)
    } else if (e === "1") {
      setCustom(false)
      from = moment().subtract(1, 'days').format('YYYY-MM-DD');
      setStartDate(from)
      console.log(from, to)
      handleDateChange(from, 1)
      handleDateChange(to, 2)
    }
    else if (e === "2") {
      setCustom(false)
      from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      setStartDate(from)
      console.log(from, to)
      handleDateChange(from, 1)
      handleDateChange(to, 2)

    }
    else if (e === "4") {
      setCustom(false)
      from = moment().subtract(30, 'days').format('YYYY-MM-DD');
      setStartDate(from)
      console.log(from, to)
      handleDateChange(from, 1)
      handleDateChange(to, 2)

    }
  }
  const handleClose = () => {
    setOpenOffboardingModal(false);
  };



  const handleExportButton = () => {
    let url = "";
    url = (updateFilterUrl())

    console.log(filters)
    console.log(url)
    createAllRecordExcel({url:url,sentToAll:!sentToAll})
    handleClose()

  }
  // console.log(filterUrl)
  const handleDateChange = (date, pos) => {

    let dateData = value
    if (pos === 1)
      dateData[0] = date
    if (pos === 2)
      dateData[1] = date

    setValue([...dateData])
    setFilters({ ...filters, ['leadAddedDate']: dateData })
  };
console.log(sentToAll)

  let dialogData =
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOffboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span style={{ fontSize: '17px', paddingLeft: '10px',color:"#1c75bc" }}>DATA EXPORT <span style={{ color: '#D1D1D1' }}>(On Email)</span></span>
       {!tab.workerDataTab && !custom&&<div style={{ fontSize: '14px', paddingLeft: '10px' }}>From: <b>{startDate}</b> - To: <b>{endDate}</b></div>}

      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs className="padding0">
         {!tab.workerDataTab && <FormControl>
            <RadioGroup
              style={{ paddingLeft: '10px' }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={defaultRadio}
              onChange={(e) => { handleCustom(e.target.value) }}

            >
              <FormControlLabel value="1" control={<Radio color="primary" />} label="Yesterday" style={{ paddingRight: "10px" }} />
              <FormControlLabel value="2" control={<Radio color="primary" />} label="Last 7 days" style={{ paddingRight: "10px" }} />
              <FormControlLabel value="4" control={<Radio color="primary" />} label="Last 30 days" style={{ paddingRight: "10px" }} />
              <FormControlLabel value="3" control={<Radio color="primary" />} label="Custom" style={{ paddingRight: "10px" }} />
            </RadioGroup>
          </FormControl>
          }

          {!tab.workerDataTab &&custom && <div>

            <Grid container spacing={2}>

              <Grid item xs={3} style={{ paddingLeft: "26px", paddingTop: '25px' }}><label>Added on :</label>
              </Grid>
              <Grid item xs={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id={`{id}To`}
                    placeholder="From"
                    style={{ width: "150px" }}
                    // label={labelFrom}
                    format="dd-MM-yyyy"
                    clearable
                    className="fromDate"
                    value={filters['leadAddedDate'][0]}
                    maxDate={filters['leadAddedDate'][1]}
                    onChange={date => handleDateChange(date, 1)}
                  />

                  <KeyboardDatePicker
                    id={'leadAddedDate'}
                    placeholder="To"
                    format="dd-MM-yyyy"
                    style={{ width: "150px", paddingLeft: '34px', paddingTop: "10px" }}
                    clearable
                    // label={labelTo}
                    className="toDate"
                    value={filters['leadAddedDate'][1]}
                    minDate={filters['leadAddedDate'][0]}
                    onChange={date => handleDateChange(date, 2)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

          </div>}

          <FormControl style={{ paddingLeft: '10px' }}>
            <FormControlLabel control={<Checkbox color="primary" defaultChecked={sentToAll} onClick={()=>{setSentToAll(!sentToAll)}} />} label="Export to me only" />
          </FormControl>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className={`"blueButtonContained"}`} onClick={handleExportButton}>
          Export
        </Button>
      </DialogActions>

    </Dialog>
  return dialogData;

}
