import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
//import { Divider , Badge } from '@material-ui/core';
import { Grid, IconButton, Checkbox, TextField, InputAdornment,} from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters,useExpanded } from 'react-table'
import urlLinks from '../../UrlLinks'
import InnerTable from './InnerTable'
import UpperTable from './UpperTable'
// import SampleResponse from './SampleResponse'
import Axios from 'axios'
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles } from "@material-ui/core/styles";
import * as moment from 'moment'
import { numberFormat } from '../../Utils/CommonFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Filters from './filterModal';
import NoRecord from './noRecord.svg'
import styles from './index.module.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import { createVerificationStatus } from '../../services/CommonServices'
import { AuthContext } from '../../services/AuthProvider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link ,useHistory, useParams} from 'react-router-dom'
import Decrypt from '../../Utils/decrypt'
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 1rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const Styles2 = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            max-height : 74vh;
           
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            
       
            width: max-content;
            tr {
            :last-child {
                td {
                // border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        // border-left: 0;
                }
             
                background: #E1E1E1;!important;
                
                color: black;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 1rem;
            border: none; 
            // border-collapse: collapse;
            // border-left: thin solid; border-top: thin solid; border-bottom: thin solid;
            // border-bottom: 0px solid #cfcfcf;
            // border-right: 0px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                // border-right: 0;
            } 
            :last-child {
                // border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }

    }, paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const ExternalWorker = (props) => {
    // const {rowData} = props.location
    const history = useHistory();
    // if(rowData === undefined){
    //     history.push("/employer-data");
    // }
    // console.log(props)
    // console.log(rowData)
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const { showToast } = useContext(AuthContext);
    const [allRecordLoading, setAllRecordLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRecord, setAllRecord] = useState([]);
    // const [selectedRows, setSelectedRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);
    const [sortDirectionUrl, setSortDirectionUrl] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [searchByUrl, setSearchByUrl] = useState("")
    const [sortUrl, setSortUrl] = useState("");
    const [financialAdmin, setFinancialAdmin] = useState(false)
    const [filtersUrl, setFiltersUrl] = useState("")
    const [selectedUserIds, setSelectedUserIds] = useState("")
    const [searchByAuto, setsearchByAuto] = useState("");
    const [partner, setPartner] = useState([])
    const [workerId,setWorkerId]=useState("")
    const [okayGoId,setOkayGoId]=useState("")
    const [rowData,setRowData]=useState("")
    const params = useParams()
    console.log("rowData===>",rowData)
    useEffect(() => {
        ApplyFilters(filters);
        // ApplySort();
        // setSortDirection(false);
        // setSortDirectionUrl('');
    }, [searchByAuto]);
    const [filters, setFilters] = useState({
        searchBy: "",
        billDate: [null, null],
        zone: "",
        facility_state: "",
        facility_name: "",
        payment_status: "",
        worker_status: "",
        worker_type: "",
        payment_type: "",
        advanceTaken: "",
        bankDetails: "",
        bankDetailsVerified:"",
        panStatus:"",
        modalFlag:""

    });

    const sortOptions = [
        ["101", "Swiggy"],
        ["101", "Flipkart"]
    ];
    const ApplySort = () => {
        let url = '';
        
        if (sortBy !== 0)
            setSortUrl('&partnerId=' + sortBy);
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false);
        setSortDirectionUrl('');
        console.log({sortBy})
    }, [sortBy]);
    const handleSearch = () => {
        setSearchByUrl(`&searchBy=${searchBy}`)
        // ApplyFilters(filters);
    }
    // console.log(filters)
    const handleSearchClose = () => {
        setSearchBy("");
        setSearchByUrl("");
    }

    
    const midFunction = (prop) => {
        // console.log("I WAS CALLED")
        ApplyFilters(prop);
    }
    const ApplyFilters = () => {
        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`;
        if (filters["billDate"][0] && !filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=2019-01-01&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["billDate"][0] && filters["billDate"][1])
            url = `${url}&billFrom=${moment(filters["billDate"][0]).format("YYYY-MM-DD")}&billTo=${moment(filters["billDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["zone"]) {
            url = `${url}&zone=${filters["zone"]}`
        }
        if (filters["facility_state"]) {
            url = `${url}&facility_state=${filters["facility_state"].name}`
        }
        if (filters["facility_name"]) {
            url = `${url}&facility_name=${filters["facility_name"].name}`
        }
        if (filters["payment_status"]) {
            url = `${url}&payment_status=${filters["payment_status"]}`
        }
        if (filters["worker_status"]) {
            url = `${url}&worker_status=${filters["worker_status"]}`
        }
        if (filters["worker_type"] || filters["worker_type"] === 0) {
            url = `${url}&isGold=${filters["worker_type"]}`
        }
        if (filters["payment_type"]) {
            url = `${url}&paymentType=${filters["payment_type"]}`
        }
        if (filters["advanceTaken"] || filters["advanceTaken"] === 0) {
            url = `${url}&advanceTaken=${filters["advanceTaken"]}`
        }
        if (filters["bankDetails"] || filters["bankDetails"] === 0) {
            url = `${url}&bank_details=${filters["bankDetails"]}`
        }
        if (filters["bankDetailsVerified"] || filters["bankDetailsVerified"] === 0) {
            url = `${url}&verification_status=${filters["bankDetailsVerified"]}`
        }
        if (filters["panStatus"] || filters["panStatus"] === 0) {
            url = `${url}&isPanVerified=${filters["panStatus"]}`
        }
        if (filters["modalFlag"] || filters["modalFlag"] === 0) {
            url = `${url}&is_lma_worker=${filters["modalFlag"]}`
        }
        // payment_type:"",
        // worker_type:""
        console.log({url})
        setFiltersUrl(url)
        if (url) {
            setIsFilterApplied(true)
        }
        setCurrentPage(1)
    }
    const resetFilters = () => {
        const filterList = {
            billDate: [null, null],
            zone: "",
            facility_state: "",
            facility_Name: "",
            payment_status: "",
            worker_status: "",
            worker_type: "",
            payment_type: "",
            advanceTaken: "",
            bankDetails: "",
            bankDetailsVerified:"",
            panStatus:"",
            modalFlag:""

        }
        setFilters(filterList)
        setFiltersUrl('')
        setIsFilterApplied(false)
        setCurrentPage(1)
        return filterList;
    }
    useEffect(() => {
      console.log({filtersUrl})
    }, [filtersUrl])
    useEffect(() => {
        ApplyFilters();
        console.log({filters})
    }, [filters])
    
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }
    // console.log(financialAdmin)
    const workerLedgerListingCols = useMemo(
        () => [
            // {
            //     // Make an expander cell
            //     Header: () => null, // No header
            //     id: "expander", // It needs an ID
            //     Cell: ({ row }) => (
            //       // Use Cell to render an expander for each row.
            //       // We can use the getToggleRowExpandedProps prop-getter
            //       // to build the expander.
            //       <span {...row.getToggleRowExpandedProps()}>
            //         {row.isExpanded ? <ExpandMoreIcon/> :<ExpandLessIcon/>}
            //       </span>
            //     ),
            //     // We can override the cell renderer with a SubCell to be used with an expanded row
            //     SubCell: () => null // No expander on an expanded row
            //   },
            // {
            //     id: 'selection2',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         (!(row.original.queued !== 0 || !row.original.isInvoiceCreated) && <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.queued !== 0 || !row.original.isInvoiceCreated) ? {} : { color: "#1c75bc" }} disabled={row.original.queued !== 0 || !row.original.isInvoiceCreated} />
            //         </div>)
            //     ),
            // },
            

            {
                Header: "OkayGo ID",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Name",
                accessor: "name",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bank Verification",
                accessor: "bankVerification",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Invoices",
                // accessor: "invoices",
                Cell: row =>{
                    return <div>{row.row.original.invoices}</div>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Credit",
                accessor: "totalCredit",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Debit",
                accessor: "totalDebit",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Net Balance",
                accessor: "netBalance",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            
        ],
        [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage]
    );
    const invoiceListCols = useMemo(
        () => [
            // {
            //     // Make an expander cell
            //     Header: () => null, // No header
            //     id: "expander", // It needs an ID
            //     Cell: ({ row }) => (
            //       // Use Cell to render an expander for each row.
            //       // We can use the getToggleRowExpandedProps prop-getter
            //       // to build the expander.
            //       <span {...row.getToggleRowExpandedProps()}>
            //         {row.isExpanded ? <ExpandMoreIcon/> :<ExpandLessIcon/>}
            //       </span>
            //     ),
            //     // We can override the cell renderer with a SubCell to be used with an expanded row
            //     SubCell: () => null // No expander on an expanded row
            //   },
            // {
            //     id: 'selection2',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         (!(row.original.queued !== 0 || !row.original.isInvoiceCreated) && <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={(row.original.queued !== 0 || !row.original.isInvoiceCreated) ? {} : { color: "#1c75bc" }} disabled={row.original.queued !== 0 || !row.original.isInvoiceCreated} />
            //         </div>)
            //     ),
            // },
            

            {
                Header: "Inovice date",
                // accessor: "invoiceDate",
                Cell: ({ row }) => (
                    <div>
                       <div>{row.original.invoiceDate}</div>

                       {row.original.invoiceDetails.slice(1).map((el)=>{
                           // console.log(row.original.invoiceDetails)
                           
                           return <>{el.advance?<div className='advanceStyle UpperXMargin'> Advance</div>:<div className='noAdvanceStyle UpperXMargin'></div>}</>
                        //    return <>{el.advance&&<div className='advanceStyle UpperXMargin'> Advance</div>}</>
                       })}
                      </div>)
               ,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Invoice#",
                // accessor: "invoiceNumber",
                Cell: ({ row }) => (
                             <div>
                                <div><span >{row.original.invoiceFilePath!==""?<a style={{color:"#1C75BC"}} href={row.original.invoiceFilePath} target='_blank' rel="noreferrer">{row.original.invoiceNumber}</a>:row.original.invoiceNumber}</span></div>
                                {row.original.invoiceDetails.slice(1).map((el)=>{
                                    // console.log(row.original.invoiceDetails)
                                    return <div className='Upper10Margin'>Paid on : {el.payment_date}</div>
                                })}
                               </div>)
                        ,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bill From",
                // accessor: "billFrom",
                Cell: ({ row }) => (
                    <div>
                       <div>{row.original.billFrom}</div>
                       {row.original.invoiceDetails.slice(1).map((el)=>{
                        //    console.log(row.original.invoiceDetails)
                           return <div className='Upper10Margin'>UTR : <span>{el.transaction_id}</span></div>
                       })}
                      </div>)
               ,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Bill To",
                accessor: "billTo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client",
                accessor: "client",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Credit",
                // accessor: "totalDebit",
                Cell: ({ row }) => (
                    <div>
                       {row.original.invoiceDetails.slice(0,1).map((el)=>{
                             return <div className=''> {el.credit}</div>
                       })}
                      </div>)
               ,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Debit",
                // accessor: "netBalance",
                Cell: ({ row }) => (
                    <div >
                       <p style={{marginTop:"32px"}}></p>

                       {row.original.invoiceDetails.slice(1).map((el)=>{
                             return <div className='Upper10Margin'> {el.debit}</div>
                       })}
                      </div>)
               ,
                defaultCanFilter: false,
                defaultCanSort: false
            },
            
        ],
        [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage]
    );
    // useEffect(() => {
    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.getPartner}`)
    //         .then(response => {
    //             let a = []
    //             response.data.response.map(e => {
    //                 if(e.is_active){
    //                     a.push([e.partner_id, e.partner_name])
    //                 }
    //             })
    //             setPartner(a)
    //             // setData2(makeData2(response.data.response))

            

    //         })

    // }, [])
    // console.log(partner)
    // const makePartner=(partnerId)=>{
    //     partnerId= parseInt(partnerId)
    //     return partner.map((e)=>{
    //         console.log(e[0],partnerId,e[0]===partnerId)
    //         if(e[0]===partnerId){
    //             return e[1];
    //         }
    //     })
    // }
    //Function to create table data
    const makeData = (rowData) => {
        console.log("rowData===>",rowData)
        let extractedData = [];
            extractedData.push({
                // workerId:rowData.workerId,
                // okaygoId:rowData.okaygoId,
                // name:rowData.name,
                // bankVerification:rowData.bankVerification,
                // invoices:rowData.invoices+" Invoices",
                // totalCredit:rowData.totalCredit,
                // totalDebit:rowData.totalDebit,
                // netBalance:rowData.netBalance
                workerId: rowData.workerId||"",
                okaygoId: rowData.okaygoId||"",
                name: `${rowData.firstName ? rowData.firstName : ""} ${rowData.lastName ? rowData.lastName : ""}`,
                bankVerification: rowData.bankVerification!=null?(rowData.bankVerification===1?"Yes":"No"):"-",
                invoices: rowData.invoices?(rowData.invoices+" Invoices"):"-",
                totalCredit: rowData.totalCredit?numberFormat(rowData.totalCredit):"",
                totalDebit: rowData.totalDebit?numberFormat(rowData.totalDebit):"",
                netBalance: rowData.netBalance?numberFormat(rowData.netBalance):""
             
            });
            // return true;

        return extractedData;
    }
    const makeData2 = (response) => {
        let extractedData = [];
        // let innerInvoiceDataList =[];
        console.log("response===>",response)
        response.map(el => {
            // console.log(el)
            extractedData.push({
                invoiceDate: el.billDate?moment(el.billDate).format("DD-MMM-YYYY") : "",
                invoiceNumber: el.billNumber||"",
                invoiceFilePath:el.invoiceFilePath?el.invoiceFilePath.replace("http","https"):"",
                billFrom: el.billFrom?moment(el.billFrom).format("DD-MMM-YYYY") : "",
                billTo: el.billto?moment(el.billto).format("DD-MMM-YYYY") : "",
                client: el.partnerName||"",
                invoiceDetails:el.invoiceDataList.map(e=>{
                    return({
                        credit:e.credit?numberFormat(e.credit):"",
                        debit:e.debit?numberFormat(e.debit):"",
                        insertedOn:e.insertedOn,
                        inserted_by:e.inserted_by,
                        invoice_id:e.invoice_id,
                        payment_date:e.payment_date?moment(e.payment_date).format("DD-MMM-YYYY") : "",
                        transaction_id:e.transaction_id,
                        updatedOn:e.updatedOn,
                        worker_id:e.worker_id,
                        worker_ledger_id:e.worker_ledger_id,
                        advance:e.advance?e.advance:""
                    })
                    // return innerInvoiceDataList
                }
                )
                
                // totalCredit: el.totalCredit?numberFormat(el.totalCredit):"",
                // totalDebit: el.totalDebit?numberFormat(el.totalDebit):"",
                // netBalance: el.netBalance?numberFormat(el.netBalance):""
            });
            // innerInvoiceDataList=[]
            // return true;
        });
        // console.log(extractedData)
        return extractedData;
    }

    //Function to create All record export data

    const columns = workerLedgerListingCols
    const updateMyData = () => {
        // console.log({filters, filterUrl})
        getWorkerLedgerListing(perPage, currentPage);
    }


    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    
    const getWorkerRowData = (id) => {
        setLoading(true)
        // let requestedBy = ""
        // if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "26") {
        //     requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        // }
        // let onlyFlipkartInvoice = "";
        // if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "11") {
        //     onlyFlipkartInvoice = "&onlyFlipkartInvoice=1"
        // }
        
            Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerLedgerListing}?searchBy=${id}`)
                .then(response => {
                    if (response) {
                        console.log("rowDatata===>",response.data)
                        setRowData(response.data.response.content[0])
                        setLoading(false)
                    }
                })
                .catch(error => {
                    console.log("Error while fetching rowData is",error)
                    setLoading(false)
                    ///
                })
        
    }
    useEffect(() => {
        const okaygoId = params.okaygoId
        getWorkerRowData(okaygoId);
    }, [filtersUrl, sortUrl, localStorage.getItem("roleType"), searchByUrl,params])








    const getWorkerLedgerListing = (rows, pageNo) => {
        setLoading(true)
        // let requestedBy = ""
        // if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "26") {
        //     requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        // }
        // let onlyFlipkartInvoice = "";
        // if (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5" || localStorage.getItem("roleType") === "11") {
        //     onlyFlipkartInvoice = "&onlyFlipkartInvoice=1"
        // }
            if(rowData===undefined){
                history.push("/workerLedger");
                return;
            }
            if(rowData.workerId){
                Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.particularWorkerLedgerListing}?worker_id=${rowData.workerId}`)
                .then(response => {
                    if (response) {
                    //     setTotalRows(response.data.response.totalElements)
                    //     setTotalPages(response.data.response.totalPages)
                    //     if (currentPage > response.data.response.totalPages)
                    //         setCurrentPage(totalPages)
                    console.log("responseData==>",response.data)
                        setData2(makeData2(response.data.response))
                        // setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })

            setData(makeData(rowData))
            setLoading(false)
            }
    }
    console.log(data2)
    useEffect(() => {
        getWorkerLedgerListing(perPage, 1);

    }, [filtersUrl, sortUrl, localStorage.getItem("roleType"), searchByUrl,params,rowData])

    const handlePageChange = page => {
        getWorkerLedgerListing(perPage, page)
        setCurrentPage(page);
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected,visibleColumns, state: { selectedRowIds,expanded } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy,useExpanded, useRowSelect)
    // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected,visibleColumns, state: { selectedRowIds,expanded } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy,useExpanded, useRowSelect)
    // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, isAllRowsSelected,visibleColumns, state: { selectedRowIds,expanded } } = useTable({ columns, data, initialState: { hiddenColumns: localStorage.getItem("roleType") === "11" ? ["casperId"] : (localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5") ? ["swiggyId"] : [""] }, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy,useExpanded, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getWorkerLedgerListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    useEffect(() => {
        // // console.log({isAllRowsSelected})
        rows.map((record, index) => {
            // // console.log({record})
            // // console.log(record, selectedRowIds[index])
            if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
                selectedRowIds[index] = false
                // // console.log(selectedRowIds[index])
            }
        })
        // // console.log(selectedRowIds)
    }, [isAllRowsSelected])

    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            // setDisablePayments(disableButton);
            // // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    const makeAllRecordData = (response) => {
        let extractedData = [];
        response.map(el => {
            let temp = {
                // clientId: el.casperId ? `${el.invoiceFor} : ${el.casperId}` : "",
                clientId: el.invoiceFor === "Flipkart" ? el.casperId?`${el.invoiceFor} : ${el.casperId}`:"" : el.clientId?`${el.invoiceFor} : ${el.clientId}`:"",
                okaygoId: el.okaygoId || "",
                client: el.invoiceFor || "",
                name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                lmaEflexGroupFlex: el.invoiceFor==="Flipkart"?(el.is_lma_worker?"LMA": 'Eflex'):"",
                rateCard: (el.is_lma_worker && el.invoiceFor==="Flipkart")?el.lmaRateCard:el.rateCard,
                bankDetailsVerification: createVerificationStatus(el.verificationStatus),
                bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
                billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
                billNo: el.billNumber || "",
                shipmentAmount: el.shipmentAmount ? (parseFloat(el.shipmentAmount)).toFixed(2) : "",
                surgeCharges: el.surgeCharges ? (parseFloat(el.surgeCharges)).toFixed(2) : "",
                adjustments: el.recovery ? (parseFloat(el.recovery)).toFixed(2) : "",
                longDistance: el.longDistance?`${el.longDistance} km`:"",
                longDistanceAmount: el.longDistanceAmount?numberFormat(parseFloat(el.recovery)):"",
                penalty: el.penalty ? `- ${numberFormat(parseFloat(el.penalty))}` : "",

                shipmentQuantity: el.shipmentQuantity || "",
                // leaseCharges: el.leaseCharges ? (parseFloat(el.leaseCharges)).toFixed(2) : "",
                advancePayment: el.advancePayment ? numberFormat(parseFloat(el.advancePayment)) : "",
                payoutToOkayGo: el.okaygoPayout ? `${numberFormat(parseFloat(el.okaygoPayout))}` : "",
                marginPercent: el.margin? `${el.margin.toFixed(2)} %`:"",
                totalAmount: el.netPayableAmount ? parseFloat(el.netPayableAmount).toFixed(2) : "",
                netPayable: el.totalAmount ? parseFloat(el.totalAmount).toFixed(2) : "",
                paymentDate: el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                paymentStatus: el.paymentStatus || "",
            };

            extractedData.push(temp)
            return true;
        });

        return extractedData;
    }
    const makeAllRecordDataAsaan = (response) => {
        let extractedData = [];
        response.map(el => {
            extractedData.push({
                'Employee Id': el.clientId || "",
                "OkayGo Id": el.okaygoId || "",
                "Client": el.invoiceFor || "",
                "Client Emp Id": el.clientEmpId || "",
                "Name": `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                "Contact No": Decrypt(el.phoneNumber) || "",
                "PAN Number": Decrypt(el.panNumber) ||"",
                "Pan Verification Status": el.panVerificationStatus===1?"Yes":el.panVerificationStatus===0?"No":"", 
                "Bank Details filled": !el.accountNumber ? "No" : "Yes",
                "Invoice Cycle (from)": el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
                "Invoice Cycle (to)": el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
                "Invoice No": el.billNumber || "",
                "Service Charges": el.shipmentAmount ? numberFormat(parseFloat(el.shipmentAmount)) : "",
                "Surge Charges (incentives etc.)": el.surgeCharges ? numberFormat(parseFloat(el.surgeCharges)) : "",
                "Deductions (TDS etc.)": el.recovery ? `- ${numberFormat(parseFloat(el.recovery))}` : "",
                "Net payable Amount": el.totalAmount ? numberFormat(el.totalAmount) : "",
                "Payment Date": el.paymentDate ? moment(el.paymentDate).format("DD/MM/YYYY") : "",
                "Payment Status": el.paymentStatus || "",
                "Transaction Id": el.transactionId || "",
            })
            return true;
        });

        return extractedData;
    }
    // console.log(localStorage.getItem("roleType"))
    const createAllRecordExcel = () => {
        setAllRecordLoading(true)
        
            Axios.post(`${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListing}/transactions?pageRequest=0${filtersUrl}`)
                .then(response => {
                    if (response) {
                     

                            setAllRecord( makeAllRecordData(response.data.response))
                       
                    }
                })
        }
        // transactions
    
    // console.log({ allRecord })
    
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    // function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    //     if (loading) {
    //       return (
    //         <tr>
    //           <td />
    //           <td colSpan={visibleColumns.length - 1}>Loading...</td>
    //         </tr>
    //       );
    //     }
      
    //     // error handling here :)
      
    //     return (
    //       <>
    //         {[1,23].map((x, i) => {
    //           return (
    //             <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`}>
    //               {/* {row.cells.map((cell) => { */}
    //               {[1, 24, 4].map((cell) => {
    //                 return (
    //                   <>
    //                     <div>Hey </div>
    //                   </>
                      
    //                 );
    //               })}
    //             </tr>
    //           );
    //         })}
    //       </>
    //     );
    //   }
//     function SubRowAsync({ row, rowProps, visibleColumns }) {
//         const [loading, setLoading] = React.useState(true);
//         const [data, setData] = React.useState([]);
//       console.log(row.original)
//         React.useEffect(() => {
//           const timer = setTimeout(() => {
//             // setData(makeData(3));
//             setLoading(false);
//           }, 500);
      
//           return () => {
//             clearTimeout(timer);
//           };
//         }, []);
      
//         return (
//           <SubRows
//             row={row}
//             rowProps={rowProps}
//             visibleColumns={visibleColumns}
//             // data={data}
//             loading={loading}
//           />
//         );
//       }

// const renderRowSubComponent = React.useCallback(
//     ({ row, rowProps, visibleColumns }) => (
//       <SubRowAsync
//         row={row}
//         rowProps={rowProps}
//         visibleColumns={visibleColumns}
//       />
//     ),
//     []
//   );



    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={"long-header"}>


                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                </span></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        const rowProps = row.getRowProps();
                        return (
                            <>
                            <tr {...row.getRowProps()} className="row">
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameMatchNew zIndex540" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" :
                                                cell.column.Header === "Net Balance" ? "leftAlignLedgerTableRow" :
                                                    (cell.column.Header === "Mark onHold") ? ((financialAdmin || JSON.parse(localStorage.getItem("asaanRole"))) ? "" : "displayNone") : "cell"}>{cell.render('Cell')}</td>
                                })}
                            
                            </tr>
                             {/* {row.isExpanded &&
                                renderRowSubComponent({ row, rowProps, visibleColumns })} */}
                                </>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    const table2 = <div className="tableContainer">
    <table {...getTableProps()} className="table">
        <thead className="thead">
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}
                            className={"long-header"}>


                            <span {...column.getSortByToggleProps()}>
                                {column.render('Header')}
                            </span></th>
                    ))}
                </tr>
            ))}
        </thead>
        {
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    const rowProps = row.getRowProps();
                    return (
                        <>
                        <tr {...row.getRowProps()} className="row">
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}
                                    className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                        cell.column.Header === "Name" ? "stickWorkerNameMatchNew zIndex540" :
                                            cell.column.id === "selection" ? "stickCheckbox zIndex540" :
                                                (cell.column.Header === "Mark onHold") ? ((financialAdmin || JSON.parse(localStorage.getItem("asaanRole"))) ? "" : "displayNone") : "cell"}>{cell.render('Cell')}</td>
                            })}
                        
                        </tr>
                         {/* {row.isExpanded &&
                            renderRowSubComponent({ row, rowProps, visibleColumns })} */}
                            </>
                    )
                })}
            </tbody>
        }
    </table>
</div>

    // console.log(localStorage.getItem("roleType"))
    return (
        <Grid container className="padding2percent">
              
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {/* {table} */}
                    {/* {table2} */}
                <Styles> 
                    <UpperTable data={data} columns={columns} />
                    </Styles>
                    
                    <Styles2>
                    <InnerTable data={data2} columns={invoiceListCols} />
                    </Styles2>
                    {/* <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    /> */}
                </Grid>}

               

            </Grid>
    )
}

export default ExternalWorker;


