import React, { useState, useEffect } from 'react'
import { Button, Grid, IconButton } from '@material-ui/core'
import Styles from './index.module.scss'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Calendar from '../../assets/calendar.svg'
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Axios from 'axios'
import urlLinks from '../../UrlLinks'
import qs from "querystring" 
import moment from 'moment'
const EffectiveFrom =(props) =>{
    const {row, updateMyData, showToast}= props
    console.log({row})
    const [isEdit, setIsEdit] = useState(false)
    const [date, setDate] = useState(row.original.effectiveDate)
    // const [date, setDate] = useState(null)
    const handleCancel =() =>{
        setIsEdit(false)
        setDate(row.original.effectiveDate)
    }
    const handleConfirm =() =>{
        const postData={
            effectiveDate: moment(date).format("YYYY-MM-DD 00:00:00"),
            workerId : row.original.workerId,
            addedBy: localStorage.getItem("userID")
        }
        Axios.post(`${urlLinks.baseUrl}${urlLinks.shifts.updateEffectiveDate}`, qs.stringify(postData))
            .then(response =>{
                console.log({response})
                showToast("success", "Effective from date updated successfully");
                setIsEdit(false)
                updateMyData();
            })
            .catch(error =>{
                showToast("error", error.message);
            })
    }
    const output= isEdit?<Grid container >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    id="effectiveDate"
                    placeholder="Effective date"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    className={Styles.datePicker}
                    minDate={moment().add(2, 'days')}
                    // clearable
                    value={date}
                    onChange={date => setDate(date)}
                    keyboardIcon={<img src={Calendar} alt="calendar" width="18px" height="18px"/>}
                />
            </MuiPickersUtilsProvider>
            <IconButton color="primary" onClick={handleConfirm}>
                <DoneSharpIcon />
            </IconButton>
            <IconButton color="secondary" onClick={handleCancel} >
                <CloseSharpIcon />
            </IconButton>
        </Grid>:
        <Grid container className={Styles.editableEffectiveDate}>
            <span>{row.original.effectiveFrom}</span>
            {/* <button 
                className= {Styles.editButton}
                onClick={() => setIsEdit(true)}
                // startIcon= {<img src={document} alt={"edit"} />}
            >
                Edit
            </button> */}
        </Grid>
    return output
}
export default EffectiveFrom;