import { Grid, Checkbox, Drawer, InputAdornment, IconButton, TextField, makeStyles } from '@material-ui/core';
import React, {useEffect, useState, useMemo, useContext } from 'react'
import styles from './index.module.scss'
import styled from 'styled-components'
import {AuthContext} from '../../services/AuthProvider'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import TimeLineHeader from './TimeLineHeader'
import FilterDrawer from './filters'
import ShiftModal from './uploadShiftModal'
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination';
import Axios from 'axios'
import * as moment from 'moment'
import urlLinks from '../../UrlLinks'
import { ScaleLoader } from 'react-spinners';
import NoRecord from './noRecord.svg'
import EffectiveFrom from './EffectiveFrom'
import TimeLineColumn from './TimeLineColumn'
import { Link } from 'react-router-dom';

const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:0 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color= "primary" />
        </React.Fragment>
    )
    }
)

const ShiftPlan = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const {showToast} = useContext(AuthContext);
    const [searchBy, setSearchBy] = useState("")
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [searchUrl, setSearchUrl] = useState("")
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [openShiftModal, setOpenShiftModal] = useState(false)
    const [filterUrl, setFilterUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        workerPartner:null,
        hub: null,
        hubHRBP:null,
        workingFor: null,
        shiftStatus: [],
        workerStatus: 10,
    })
    const resetFilters =() =>{
        setFilters({
            workerPartner:null,
            hub: null,
            hubHRBP:null,
            workingFor: null,
            shiftStatus: [],
            workerStatus:10
        })
        setFilterUrl("")
        setCurrentPage(1);

    }
    const handleSearch = ()=>{
        setSearchUrl(`&searchBy=${searchBy}`)
        setCurrentPage(1)
    }
    const handleSearchClose = () => {
        setSearchUrl(``)
        setSearchBy("");
    }
    const applyFilters =() =>{
        let url='';
        
        if(filters["workerPartner"])
            url=`${url}&partner_id=${filters["workerPartner"].id}`;
        if(localStorage.getItem("roleType")===1){
            if(filters["hubHRBP"].length!==0){
                url=`${url}&facility_name=${filters["hubHRBP"].facilityName}`
            }
        }
        else{
            if(filters["hub"]){
                url=`${url}&facility_name=${filters["hub"].facilityName}`
            }
        }
        if(filters["workingFor"])
            url=`${url}&shift_partners=${filters["workingFor"]}`
        if(filters["shiftStatus"].length!==0)
            url=`${url}&shift_status=${filters["shiftStatus"]}`
        if(filters["workerStatus"]!=="" && filters["workerStatus"]<10)
            url=`${url}&worker_status=${filters["workerStatus"]}`
        setFilterUrl(url)
        setCurrentPage(1)
    }
    console.log({filterUrl})
    const columns = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //         <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} />
            //         </div>
            //     ),
            //     Cell: ({ row }) => (
            //         <div>
            //         <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            //         </div>
            //     ),
            // },
            {
                Header: "OkayGo ID",
                accessor: "okaygoId",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Source",
                accessor: "source",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Worker name",
                accessor: "workerName",
                Cell: row =>{
                    return <Link to={{pathname:`/workerview/${row.row.original.contactNo_E}`}}   className="nameLink">{row.row.original.workerName}</Link>
                },
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Worker status",
                accessor: "workerStatus",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Hub",
                accessor: "hub",
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "timeline",
                accessor: "timeline",
                Cell: TimeLineColumn,
                defaultCanFilter :false,
                defaultCanSort:false
            },
            {
                Header: "Effective From",
                accessor: "effectiveFrom",
                Cell: EffectiveFrom,
                defaultCanFilter :false,
                defaultCanSort:false
            }
        ],
        []
    );
    
    const createWorkerStatus =(status) =>{
        let text=""
        switch (status) {
            case 0:
                text="Not Onboarded";
                break;
                
            case 1:
                text="Active";
                break;
            case 2:
                text="Partially Onboarded";
                break;
            case 3:
                text="Inactive";
                break;
            case 4:
                text="Offboarding Initiated";
                break;
            case 5:
                text="Onboarding Completed";
                break;    
            case 6:
                text="Temporarily Inactive";
                break;
            case 7:
                text="Temporary Inactivation Initiated";
                break; 
            case 8:
                text="Reactivation Initiated";
                break;                
            default:
                
                break;
        }
        return text;
    }
    const makeData =(response) =>{
        let extractedData = [];
        response.content.map(el => {
            extractedData.push({
                okaygoId: el.okaygoId|| "",
                contactNo_E : el.phoneNumber.replaceAll('/', '%2F').replaceAll('+', '%2B') || "",
                source: el.partnerName || "",
                workerName: `${el.firstName?el.firstName:""} ${el.lastName?el.lastName:""}`,
                hub: el.facilityName || "",
                effectiveFrom: el?.workershifts[0]?.shift_effective_from?moment(el?.workershifts[0]?.shift_effective_from).format("DD/MM/YYYY"):"",
                effectiveDate:el?.workershifts[0]?.shift_effective_from?el?.workershifts[0]?.shift_effective_from:null,
                hourlyShifts: el.workerHourlyShift,
                workerStatus: createWorkerStatus(el.workerStatus),
                workerShifts: el.workershifts,
                userId: el.userId,
                workerId: el.workerId
            });
            return true;
        });
        
        return extractedData;
    }
    const getShiftPlanningListing =(rows, pageNo) =>{
        setLoading(true)
        let requestedBy=""
        if(localStorage.getItem("roleType")==="1"){
            requestedBy=`&requestedById=${localStorage.getItem("userID")}`
        }
        if(localStorage.getItem("roleType")){
            Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.shiftPlanning}?page_no=${pageNo-1}${requestedBy}&rows=${rows}${filterUrl}${searchUrl}`)
                .then(response =>{
                    if(response){
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if(currentPage>response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error =>{
                    setLoading(false)
                })
        }
    }
    const updateMyData = () => {
        getShiftPlanningListing(perPage, currentPage);
    }
    useEffect(() => {
        getShiftPlanningListing(perPage, currentPage)
    }, [filterUrl, searchUrl])
    const handlePageChange = page => {
        getShiftPlanningListing(perPage, page)
        setCurrentPage(page);
    };
    const handlePerRowsChange =  (newPerPage) => {
        getShiftPlanningListing(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsSelected, state: {selectedRowIds}} = useTable({columns, data,  showToast, updateMyData, filters, setFilters, manualSortBy: true  }, useFilters, useSortBy,  useRowSelect)
    const table=<div className="tableContainer">
                <table {...getTableProps()}  className="table">
                    <thead className="thead">
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (  
                            <th {...column.getHeaderProps()} 
                                className={column.render('Header')==="OkayGo ID"?"stickWorkerIdShift zIndex1080 header":
                                    column.render('Header')==="Worker name"?"stickWorkerNameShift zIndex1080 header":
                                        column.id==="selection"?"stickCheckbox zIndex1080 header":
                                        column.render('Header')==="timeline"?`${styles.timelineColumn} header`:"header"}>
                                
                                {column.render('Header')==="timeline"?<TimeLineHeader />:
                                    <span {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                    </span>}
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    {
                        <tbody {...getTableBodyProps()}> 
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    
                                    <tr {...row.getRowProps()} className={`row ${row.original.criminalRecord?"criminalRecord":""}`}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}   
                                            className={`${cell.column.Header==="OkayGo ID"?"stickWorkerIdShift  zIndex540":
                                                cell.column.Header==="Worker name"?"stickWorkerNameShift zIndex540":
                                                cell.column.id==="selection"?"stickCheckbox zIndex540":"cell"} ${row.original.criminalRecord==="Yes"?"criminalRecord":""}`}>
                                                    {cell.render('Cell')}
                                                    </td>
                                    })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    }
                </table>
            </div>
    
    const loader=<div style={{margin:"130px auto"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>

                
    return (
        <Styles>
            <Grid container className={styles.ShiftPlanContainer}>
                <Grid item xs={12} className={styles.ShiftPlanHeadingContainer}>
                    <span className={styles.shiftPlanHeading}>Shift Planning</span>
                    <div>
                        <button 
                            className={styles.blueContainedButton} 
                            onClick={() =>setOpenShiftModal(true)}
                        >
                            upload shift details
                        </button>
                        <button 
                            className={styles.blueOutlinedButton}
                            style={{marginLeft: "16px"}}
                            onClick={() => setOpenFilterDrawer(true)}
                        >
                            filters
                        </button>
                    </div>
                </Grid>
                <Grid container>
                    <Grid item xs={6} className={styles.ShiftPlanHeadingContainer}>
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder="Search by OkayGo ID"
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                </Grid>
                {loading?loader:data.length===0?<div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div>:<Grid item xs={12} className= "paddingTop16">
                    {table}
                    <Pagination 
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}
            </Grid>
            <Drawer anchor="right" open={openFilterDrawer} onClose={() =>setOpenFilterDrawer(false)}>
                {/* <Drawer anchor="right" open={true} onClose={toggleDrawer("filterDrawer", false)}> */}
                <FilterDrawer
                    handleClose={() => setOpenFilterDrawer(false)}
                    filters={filters}
                    setFilters={setFilters}
                    applyFilters={applyFilters}
                    resetFilters={resetFilters}
                ></FilterDrawer>
            </Drawer>
            {openShiftModal && <ShiftModal 
                openOnboardingModal={openShiftModal}
                setOpenOnboardingModal={setOpenShiftModal}
                showToast={showToast}
                updateMyData={updateMyData}
                data={data}
            />}
        </Styles>
    )
}


export default ShiftPlan;