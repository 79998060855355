var crypto = require('crypto');

var keyBase64 = "T2theUdvRWZsZXg2NzUhIQ==";
var ivBase64  = 'T2theUdvRWZsZXg2NzUhIQ==';

const Decrypt=(messagebase64)=> {
  try{
    const key = Buffer.from(keyBase64, 'base64');
    const iv  = Buffer.from(ivBase64, 'base64');

    const decipher = crypto.createDecipheriv(getAlgorithm(keyBase64), key, iv.slice(0, 16));
    let decrypted  = decipher.update(messagebase64, 'base64');
    decrypted += decipher.final();
    // console.log(decrypted)
    return decrypted;}catch(e){
        return"";
    }
}
function getAlgorithm(keyBase64) {

    var key = Buffer.from(keyBase64, 'base64');
    switch (key.length) {
        case 16:
            return 'aes-128-cbc';
        case 32:
            return 'aes-256-cbc';

    }

    throw new Error('Invalid key length: ' + key.length);
}

export default Decrypt;