import React, { useState, useEffect } from 'react'
import { FormControl, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';
import style from './index.module.scss'
import AutoSelectFilter from './FilterComponents/AutoSelectFilter';
import ScaleLoader from "react-spinners/ScaleLoader";
import DateFilter from './FilterComponents/DateFilter';


const FilterDrawer = (props) => {
    const { handleClose, resetFilters, applyFilters, filters, setFilters } = props;
    const [isLoading, setIsLoading] = useState(false)
    const handleCancelFilters =() =>{
        // resetFilters();
        handleClose();
    }
    const handleApply=()=>{
        applyFilters()
        handleClose();
    }
    const workerStatusData=[
        {
            id: 10,
            name: "1 employer"
        },
        {
            id: 0,
            name: "more than one"
        }
    ] 

    const workerStatus = [
        { id:100, name:"Not Onboarded"},
        { id:1, name:"Active"},
        { id:2, name:"Partially Onboarded"},
        { id:3, name:"Inactive"},
        { id:4, name:"Offboarding Initiated"},
        { id:5, name:"Onboarding Completed"},
        { id:6, name:"Temporarily Inactive"},
        { id:7, name:"Temporary Inactivation Initiated"},
        { id:8, name:"Reactivation Initiated"}
    ]
    const claimStatusOptions=[
        {
            id: 1,
            name: "Approved"
        },
        {
            id: 2,
            name: "Not Approved"
        }
    ]
    // const clientPartners=[
    //     {
    //         id: 100,
    //         name: "Flipkart"
    //     },
    //     {
    //         id: 101,
    //         name: "Swiggy"
    //     },{
    //         id: 103,
    //         name: "Zomato"
    //     }
    // ]
    const candidateStatusOptions=[
        {
            id: "Downloaded",
            name: "Downloaded"
        },
        {
            id: "Selected",
            name: "Selected"
        },{
            id: "Rejected",
            name: "Rejected"
        },{
            id: "Joined",
            name: "Joined"
        }
        ,{
            id: "Active",
            name: "Active"
        }
    ]
    const referralTypeOptions=[
        {
            id: "CONTACT_SHARED",
            name: "Contact Shared"
        },
        {
            id: "APP_DOWNLOAD",
            name: "App Download"
        }
    ]
    console.log({filters})
    const loader=<div style={{margin:"130px auto"}}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}    
        />
    </div>
    // const makeWorkerPartnerData =(data) =>{
    //     let list=[];
    //     data.map(el =>{
    //         list.push({
    //             id: el.partner_id,
    //             name: el.company_name
    //         })
    //         return true
    //     })
    //     return list
    // }
    // const makeShiftStatusData =(data) =>{
    //     let list=[];
    //     data.map(el =>{
    //         list.push({
    //             id: el.typeValue,
    //             name: el.typeDesc
    //         })
    //         return true
    //     })
    //     return list
    // }
    // const makeHubData =(data) =>{
    //     let list=[];
    //     data.map(el =>{
    //         list.push({
    //             id: el,
    //             name: el
    //         })
    //         return true
    //     })
    //     return list
    // }

    // const makeHubCityData =(data) =>{
    //     let list=[];
    //     data.map(el =>{
    //         list.push({
    //             id: el,
    //             name: el
    //         })
    //         return true
    //     })
    //     return list
    // }
    // const makeHubStateData =(data) =>{
    //     let list=[];
    //     data.map(el =>{
    //         list.push({
    //             id: el,
    //             name: el
    //         })
    //         return true
    //     })
    //     return list
    // }
    // const handleWorkingFor =(event) =>{
    //     setFilters({...filters, [event.target.name]: event.target.value})
    // }
    // useEffect(() => {
    //     if(workerPartners.length>0 && shiftStatusList.length>0){
    //         setIsLoading(false)
    //     }
    // }, [workerPartners, shiftStatusList])
    // useEffect(() => {
    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`)
    //         .then(response =>{
    //             console.log(response)
    //             setWorkerPartners(makeWorkerPartnerData(response.data.response))
    //         })
        
    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubs}`)
    //     .then(response =>{
    //         sethubFacilityList(makeHubData(response.data.response)) 
    //     })

    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubCity}`)
    //     .then(response =>{
    //         sethubCityList(makeHubCityData(response.data.response))
    //     })

    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataHubState}`)
    //     .then(response =>{
    //         sethubstateList(makeHubStateData(response.data.response))
    //     })

    //     Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.shiftStatusList}`)
    //     .then(response =>{
    //         setShiftStatusList(makeShiftStatusData(response.data.response.content))
    //     })
    // }, [])
    const output=isLoading?<Grid container style={{ width: "520px", marginTop: '20px' }}>{loader}</Grid>:<Grid container style={{ width: "520px", marginTop: '20px' }} className={style.FilterContainer}>
        <Grid container className={`${style.FilterHeadingContainer}`}>
            <div className={style.flexBaseLine}>
                <Button onClick={handleClose} className={style.filterCloseArrow}>
                    <ArrowForwardIcon />
                </Button>
                <span className={style.filterHeading}>Filters</span>
            </div>
            <div>
                <Button
                    onClick={resetFilters}
                    className={style.resetFiltersButton}
                >
                    Reset
                </Button>
            </div>
        </Grid>
        <div className={style.filterHeadingDivider}></div>
        <Grid container className={style.filtersContainer2}>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Referral Type
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="referralType"
                    options={referralTypeOptions}
                />
            </Grid>

            
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                Candidate Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="candidateStatus"
                    options={candidateStatusOptions}
                />
            </Grid>
            <Grid container xs={12} className={`${style.singleFilterContainer}`}>
                    
                    <span className={style.filterTitle}>
                Claim Status
                </span>
            

                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="claimStatus"
                    options={claimStatusOptions}
                />
             

            </Grid>
            <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>    
                <span className={style.filterTitle}>
               Claim Date
                </span>
                </Grid>
                <Grid  xs={7}>
                 <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="claimDate"
                />
                   </Grid>
            </Grid>
            <Grid container xs={12} className={`${style.singleFilterContainer}`}>
            <Grid xs={5}>    
                <span className={style.filterTitle}>
                Referral Date
                </span>
                </Grid>
                <Grid  xs={7}>
                 <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="referralDate"
                />
                   </Grid>
            </Grid>

            {/* <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
               Referral Date
                </span>
                 <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="referralDate"
                />
            </Grid> */}
           

        </Grid>
        <Grid container className={style.filterButtonDividerContainer}>
            <div className={style.filterHeadingDivider}></div>
            <Grid container style={{ marginTop: '30px' ,marginBottom: '30px' }} className='padding0'>
                <div style={{
                    paddingRight: '16px',
                    paddingLeft: '300px'
                }}>  
                    <Button variant="outlined" onClick={handleCancelFilters} size="medium" color="primary" >
                        Cancel
                    </Button>
                </div>
                <Button variant="contained" size="medium" color="primary" onClick={()=>{handleApply(); handleClose()}} className={style.PrimaryColorButton}>Apply</Button>
                
            </Grid>
        </Grid>
    </Grid >
    return output;
}

export default FilterDrawer;