import axios from "axios";
import React, { useState } from "react";
import UrlLinks from "../../../UrlLinks";
import styles from '../index.module.scss'
import * as moment from "moment";
import { CircularProgress } from "@material-ui/core";
const LastSentMsgs = (props) => {
  const { row, getDocumentList, docListLoader,downloadDocError } = props;
  const [error, setError] = useState("")
  console.log(error)
  const handleClick =  (row) => {
    setError(getDocumentList(
      row.original.userId,
      row.original.profilePhoto,
      row.original.okaygoId,
      row.original.name
    ))
  }
  return (
    <div style={{ textAlign: "center" }}>

      {docListLoader === row.original.userId ? <CircularProgress /> : <button
        className={styles.workerName}
        type="button"
        style={{ fontSize: "16px !important" }}
        onClick={() => { handleClick(row); }}
      >
        Download
      </button>}
      <p>
        {downloadDocError !==null && downloadDocError.userId===row.original.userId ? downloadDocError.err:null }
      </p>
    </div>
  );
};

export default LastSentMsgs;
