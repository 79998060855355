import '../index.css'

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import InfoIcon from '@mui/icons-material/Info';

export default function TemporaryDrawer() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div>
                <div className="inline2 marginLeft font14">Total interviews:</div><div className="inline2 font14">No of interviews scheduled during the said duration.</div>
                <Divider />
                <div className="inline2 marginLeft font14">Leads Allocated:</div><div className="inline2 font14">New leads that are allocated to the user during Time period</div>
                <Divider />
                <div className="inline2 marginLeft font14">Actual Connected Calls:</div><div className="inline2 font14">Calls (OkayGo Eflex numbers of assigned workers) that user has made from his phone as per the call logs in that period</div>
                <Divider />
                <div className="inline2 marginLeft font14">Avg Call time:</div><div className="inline2 font14">Total calling time (OkayGo Eflex numbers of assigned workers)/ Total no of calls(OkayGo Eflex numbers of assigned workers) .During the said duration</div>
                <Divider />
                <div className="inline2 marginLeft font14">Total call time:</div><div className="inline2 font14">Sum of (OkayGo Eflex numbers of assigned workers). During the said duration.</div>
                <Divider />
                <div className="inline2 marginLeft font14">Interviews:</div><div className="inline2 font14"> No of interviews scheduled during the said duration of lead assign</div>
                <Divider />
                <div className="inline2 marginLeft font14">Under Training:</div><div className="inline2  font14"> No of people who were updated to Under Training during the time period of lead assign.</div>
                <Divider />
                <div className="inline2 marginLeft font14">Joined:</div><div className="inline2 font14">No of people who joined during that period of lead assign</div>
                <Divider />
                <div className="inline2 marginLeft font14">Urgent Follow ups:</div><div className="inline2 font14">people who fell in this bucket during the said period of lead assign</div>
                <Divider />
                <div className="inline2 marginLeft font14">Left before 30 days:</div><div className="inline2 font14">**</div>
                <Divider />
                <div className="inline2 marginLeft font14">Total under training:</div><div className="inline2 font14">No of people who were updated to Under Training during the time period.</div>
                <Divider />
                <div className="inline2 marginLeft font14">Total joined:</div><div className="inline2 font14">No of people who joined during that period.</div>

                
                
            </div>

        </Box>
    );

    return (
        <div>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button 
                     variant="contained"
                     className="dataButton"
                     onClick={toggleDrawer(anchor, true)}
                    >
                        Help &nbsp;<InfoIcon/>
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
