import React from "react";

export default function  map(){
    return(<>  
     
    
 
 <body  style={{height:"100%"}}>
 <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
 {/* <link rel="stylesheet" type="text/css" href="./style.css" /> */}
 <script >{function initMap() {
     let google
 const myLatlng = { lat: -25.363, lng: 131.044 };
 const map = new google.maps.Map(document.getElementById("map"), {
   zoom: 4,
   center: myLatlng,
 });
 // Create the initial InfoWindow.
 let infoWindow = new google.maps.InfoWindow({
   content: "Click the map to get Lat/Lng!",
   position: myLatlng,
 });
 infoWindow.open(map);
 // Configure the click listener.
 map.addListener("click", (mapsMouseEvent) => {
   // Close the current InfoWindow.
   infoWindow.close();
   // Create a new InfoWindow.
   infoWindow = new google.maps.InfoWindow({
     position: mapsMouseEvent.latLng,
   });
   infoWindow.setContent(
     JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
   );
   infoWindow.open(map);
 });
}}</script>
 
        <div id="map" style={{height:"100%"}}></div>
    
      
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDlmisklw9xgm7M1vxwoytQh1qSyccIwG4&callback=initMap&libraries=&v=weekly"
          async
        ></script>
      </body>
    </>)
}