import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
import { Popover, Button, Grid, IconButton, Checkbox, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Drawer, FormControlLabel } from '@material-ui/core'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import urlLinks from '../../UrlLinks'
import Axios from 'axios'
import styles from './index.module.scss'
// import ExportModal from './components/ExportModal'
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Pagination from '../../Utils/Pagination'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SendMsg from './components/SendMsg';
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { AuthContext } from '../../services/AuthProvider'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom'
import UrlLinks from '../../UrlLinks'
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'
import NoRecord from './noRecord.svg'
// import NotVerifiedSVG from './Not-Verified_icon.png'
import VerifiedSVG from './verified15x15.svg'
import NotUploadSVG from './Not uploaded.svg'
import RejectedSVG from './Rejected.svg'
import FlagSVG from './Flag.svg'
import PendingSVG from './Pend.svg'
// import VerifiedSVG from './Verified_icon.png'
import './indeterminateCheckbox.css'
import Decrypt from '../../Utils/decrypt'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            max-height : 74vh;
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        border-left: 0;
                }
                background-color: #1c75bc !important;
                color: #ffffff;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            //  border:"1px solid grey",
            marginLeft: '0',
            "&.Mui-focused fieldset": {
                border: "1px solid grey"
            }
        }
    },
    input: {
        // padding:0,
        '& [class*="MuiOutlinedInput-input"]': {
            padding: "8px",
            borderLeft: "1px solid grey",
            borderRadius: "0px"
        }
    },
    select: {
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "8px 10px"
        }
    }
}));
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} />
            </React.Fragment>
        )
    }
)

const WorkerData = (props) => {

    const classes = useStyles();
    const [data, setData] = useState([])
    const [selectedUserIds, setSelectedUserIds] = useState("")
    const [allRecordLoading, setAllRecordLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openSendMsg, setOpenSendMsg] = useState(false)
    const [allRecord, setAllRecord] = useState([])
    const [openAssignModal, setOpenAssignModal] = useState(false)
    const [agreementDownloading, setAgreementDownloading] = useState(false)
    const [emailAgreement, setEmailAgreement] = useState(false)
    // const [exportExcelLoading, setExportExcelLoading] = useState(false)
    // const [excelData, setExcelData] = useState([])
    // const [selectedRows, setSelectedRows] = useState(0)
    const [totalRows, setTotalRows] = useState(0);
    const [allDocumentDownload, setAllDocumentDownload] = useState(null)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [disable, setDisable] = useState(true)
    const [searchByAuto, setsearchByAuto] = useState("");
    const [editContactData, setEditContactData] = useState({
        userId: null,
        contact: null,
        open: false,
        error: ""
    })
    const [reactivationData, setReactivationData] = useState({
        open: false,
        userId: null,
        date: null
    })
    const [crossFilter, setCrossFilter] = useState({ value: "&verificationStatus=2", index: 2 })
    const [dropFilter, setDropFilter] = useState({ value: "", index: 0 })
    const [assignFilterString, setassignFilterString] = useState("");
    const [actions, setActions] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [options, setOptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState(-1)
    const [sortDirection, setSortDirection] = useState(false)
    const [sortDirectionUrl, setSortDirectionUrl] = useState('')
    const [searchBy, setSearchBy] = useState("")
    const [sortUrl, setSortUrl] = useState("")
    const [viewMore, setViewMore] = useState(false)
    const [filterUrl, setFilterUrl] = useState("")
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [BGVResponse, setBGVResponse] = useState(null)
    const [docListLoader, setDocListLoader] = useState(null)
    const [downloadDocError, setDownloadDocError] = useState(null)
    const [downloadError, setDownloadError] = useState("second")
    //const [filterDrawer, setFilterDrawer] = useState(false)

    const [isLeadData, setisLeadData] = useState("");
    const [filters, setFilters] = useState({
        searchBy: "",
        source: "",
        all: true,
        onboardingInitiated: false,
        active: false,
        inactive: false,
        criminalRecord: false,
        temporarilyInactive: false,
        offboardingInitiated: false,
        zone: "",
        facilityState: "",
        facilityName: "",
        facilityCity: "",
        partnerId: "",
        workFor: "",
        workerStatus: "",
        psf: "",
        callStatus: "",
        interviewStatus: "",
        recruiterId: "",
        trainingUpdate: "",
        preferedHub: "",
        appLead: "",
        interviewDate: [null, null],
        firstEarningDate: [null, null],
        leadAddedDate: [null, null],
        bankDetails: "",
        leadCity: "",
        leadSource: "",
        lastEarning: "",
        clientEarningStatus: "",
        clientStatus: "",
        RecruiterAssignedBetween: [null, null],
        idGenerationDate: [null, null],
        IsLmaStatus: "",
        bgvStatus: "",
        lastAppliedBy: ""

    })
    const [AssignFilter, setAssignFilter] = useState({
        all: true,
        assignTab: false,
        notAssignTab: false
    })
    const [partners, setPartners] = useState([{}]);
    const FirstUpperCase = (str) => {
        let s = "";
        s = str;

        if (s.length > 1) {
            let sub = s.substring(0, 1).toUpperCase();
            let remaining = s.substring(1, s.length);
            return sub + remaining;
        }

    }
    let c = null
    let partnersArray = [];
    console.log(crossFilter)
    useEffect(() => {
        // console.log("This is verification details module")
        Axios.get(`${UrlLinks.baseUrl}/configmaster/getCities`)
            .then(response => {
                // // console.log(response.data)
                let v = []
                response.data.response.map(e => v.push(FirstUpperCase(e.trim())))
                c = v.sort()
                c.unshift("All Cities")

                c = v
                // // console.log(c)
                handleOptions()
            })



        Axios.get(`${UrlLinks.baseUrl}${UrlLinks.getPartner}`)
            .then(response => {
                setPartners(response.data.response)

            })


    }, [])
    // // console.log({partners})

    const handleOptions = () => {
        // // console.log(c)
        setOptions(c.map(option => {
            // option = FirstUpperCase(option)
            return { title: option }
            // return <MenuItem key={option} value={option}>{option}</MenuItem>
        }))
    }
    useEffect(() => {
        ApplyFilters(filters);
    }, [searchByAuto]);
    const [aadhaarFront, setAadhaarFront] = useState(null);
    const [aadhaarBack, setAadhaarBack] = useState(null);
    const { showToast } = useContext(AuthContext);
    const [columnType, setColumnType] = useState(null);
    const sortOptions = [
        // ["casper_id", "Casper Id"],
        // ["okaygo_id", "OkayGo Id"],
        // ["profile_id", "Profile Id"],
        // ["onboarding_date", "Onboarding date"],
        ["active_date", "Activation date"],
        ["offboarding_date", "offboarding initiate date"],

        // ["inactive_date", "Inactive date"]
    ]
    function urlToPromise(url) {
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    return reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }
    const createDocumentZip = (documents, profilePhoto, okaygoId, name) => {
        var zip = new JSZip();
        var fileUrl = ""
        var profilePhotoURL = ""
        var photoName = ""
        documents.forEach(function (doc, index) {
            // var filename = "doc" + index+".jpg";
            var filename = doc.file_name;
            filename = filename.substring(0, filename.indexOf('?'))
            if (doc.file_path.substring(0, 5) === 'http:') { // in case if it come https, link wont become httpss
                fileUrl = 'https' + doc.file_path.substring(4, doc.file_path.length)
            }
            else {
                fileUrl = doc.file_path
            }
            // fileUrl =doc.file_path
            // // console.log(filename.substring(0,filename.indexOf('?')))
            zip.file(filename, urlToPromise(fileUrl), { binary: true });
            if (doc.profilePhoto !== null && doc.profilePhoto !== '' && !profilePhotoURL) {
                // // console.log({doc})
                if (doc.profilePhoto.substring(0, 5) === 'http:') {
                    profilePhotoURL = 'https' + doc.profilePhoto.substring(4, doc.profilePhoto.length)
                } else {
                    profilePhotoURL = doc.profilePhoto
                }
                // profilePhotoURL =doc.profilePhoto
                if (profilePhotoURL.includes(".jpg?")) {
                    // console.log({doc, photoName, profilePhotoURL})
                    photoName = `${name.replaceAll(" ", "")}_Profile_Photo.jpg`
                }
                else if (profilePhotoURL.includes(".png?")) {
                    // console.log({doc, photoName, profilePhotoURL})
                    photoName = `${name.replaceAll(" ", "")}_Profile_Photo.png`
                }
                zip.file(photoName, urlToPromise(profilePhotoURL), { binary: true });
            }
        });
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${okaygoId}_${name}_Documents`);
            setDocListLoader(null)
        });

    }



    // console.log(localStorage.getItem("roleType"))
    const handleSearch = () => {
        setFilters({ ...filters, searchBy: searchBy })
        setsearchByAuto(searchBy)

    }
    const handleSearchClose = () => {
        setFilters({ ...filters, searchBy: "" });
        setSearchBy("");
        setFilterUrl('')
        // updateMyData();
    }
    const ApplySort = () => {
        let url = ''
        switch (sortBy) {
            case "casper_id":
                url = '&orderBy=wm.casper_id'
                break;
            case "profile_id":
                url = '&orderBy=wm.worker_id'
                break;
            case "okaygo_id":
                url = '&orderBy=wm.okaygo_id'
                break;
            case "onboarding_date":
                url = '&orderBy=wm.onboarding_initiation_date'
                break;
            case "active_date":
                url = '&orderBy=wm.active_date'
                break;
            case "offboarding_date":
                url = '&orderBy=wm.offboarding_initiation_date'
                break;
            case "inactive_date":
                url = '&orderBy=wm.inactive_date'
                break;
            default:
                url = ''
                break;
        }
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url)
    }



    // // console.log(filters)
    useEffect(() => {
        ApplySort();
        setSortDirection(false)
        setSortDirectionUrl('')
    }, [sortBy])

    const handleStatuses = (event, name) => {
        if ((filters.onboardingInitiated && filters.active && filters.inactive)) {
            setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, all: true })
        }
        else if (name === "all") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: true })
            }
        }
        else if (name === "onboardingInitiated") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: true, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "active") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: true, offboardingInitiated: false, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "offboardingInitiated") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: true, inactive: false, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "temporarilyInactive") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: false, temporarilyInactive: true, all: false })
            }
        }
        else if (name === "inactive") {
            if (!filters[name]) {
                setFilters({ ...filters, onboardingInitiated: false, active: false, offboardingInitiated: false, inactive: true, temporarilyInactive: false, all: false })
            }
        }
        else if (name === "criminalRecord") {
            setFilters({ ...filters, criminalRecord: !filters.criminalRecord })

        }

    }
    const ApplyFilters = () => {
        // console.log(filters);
        // debugger

        let url = updateFilterUrl()

        setFilterUrl(url)
        setFilters(filters)
        // if (url) {
        //     setIsFilterApplied(true)
        // }
        setCurrentPage(1)
    }
    const updateFilterUrl = () => {

        let url = '';
        if (filters["searchBy"])
            url = `${url}&searchBy=${filters["searchBy"]}`
        if (filters["onboardingInitiated"]) {
            url = `${url}&workerStatus=2,5`
        }
        if (filters["status"]) {
            url = `${url}&status=${filters["status"]}`
            setIsFilterApplied(true)
        }
        if (filters["offboardingInitiated"]) {
            url = `${url}&workerStatus=4`
        }
        if (filters["temporarilyInactive"]) {
            url = `${url}&workerStatus=6,8`
        }
        if (filters["criminalRecord"]) {
            url = `${url}&criminal_record=1`
        }
        if (filters["partnerId"]) {
            url = `${url}&partnerId=${filters["partnerId"].id}`
            setIsFilterApplied(true)
        }
        if (filters["facilityName"]) {
            url = `${url}&facilityName=${filters["facilityName"].name}`
            setIsFilterApplied(true)
        }
        if (filters["facilityCity"]) {
            url = `${url}&facilityCity=${filters["facilityCity"].name}`
            setIsFilterApplied(true)
        }
        if (filters["facilityState"]) {
            url = `${url}&facilityState=${filters["facilityState"].name}`
            setIsFilterApplied(true)
        }
        if (filters["source"]) {
            url = `${url}&source=${filters["source"].id}`
            setIsFilterApplied(true)
        }
        if (filters["clientStatus"]) {
            url = `${url}&clientStatus=${filters["clientStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["clientEarningStatus"]) {
            url = `${url}&earningStatus=${filters["clientEarningStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["workerStatus"]) {
            url = `${url}&workerStatus=${filters["workerStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["workFor"]) {
            url = `${url}&workFor=${filters["workFor"]}`
            setIsFilterApplied(true)
        }
        if (filters["psf"]) {
            url = `${url}&psf=${filters["psf"].id}`
            setIsFilterApplied(true)
        }
        if (filters["leadSource"]) {
            url = `${url}&leadSource=${filters["leadSource"].id}`
            setIsFilterApplied(true)
        }
        if (filters["callStatus"]) {
            url = `${url}&callStatus=${filters["callStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["lastAppliedBy"]) {
            url = `${url}&lastAppliedBy=${filters["lastAppliedBy"].id}`
            setIsFilterApplied(true)
        }
        if (filters["appStatus"]) {
            url = `${url}&appStatus=${filters["appStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["interviewStatus"]) {
            url = `${url}&interviewStatus=${filters["interviewStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["recruiterId"]) {
            url = `${url}&recruiterId=${filters["recruiterId"].id}`
            setIsFilterApplied(true)
        }
        if (filters["trainingUpdate"]) {
            url = `${url}&trainingUpdate=${filters["trainingUpdate"].id}`
            setIsFilterApplied(true)
        }
        if (filters["preferedHub"]) {
            url = `${url}&preferedHub=${filters["preferedHub"].id}`
            setIsFilterApplied(true)
        }
        if (filters["appLead"]) {
            url = `${url}&appLead=${filters["appLead"].id}`
            setIsFilterApplied(true)
        }
        if (filters["bankDetails"] || filters["bankDetails"] === 0) {
            url = `${url}&bankdetail=${filters["bankDetails"].id}`
        }
        if (filters["leadCity"] || filters["leadCity"] === 0) {
            url = `${url}&leadCity=${filters["leadCity"].id}`
        }
        if (filters["IsLmaStatus"]) {
            url = `${url}&is_lma_worker=${filters["IsLmaStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["bgvStatus"]) {
            url = `${url}&bgvStatus=${filters["bgvStatus"].id}`
            setIsFilterApplied(true)
        }
        if (filters["RecruiterAssignedBetween"][0] && !filters["RecruiterAssignedBetween"][1])
            url = `${url}&recruiterAssingedFrom=${moment(filters["RecruiterAssignedBetween"][0]).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["RecruiterAssignedBetween"][0] && filters["RecruiterAssignedBetween"][1])
            url = `${url}&recruiterAssingedFrom=2019-01-01&recruiterAssingedTO=${moment(filters["RecruiterAssignedBetween"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["RecruiterAssignedBetween"][0] && filters["RecruiterAssignedBetween"][1])
            url = `${url}&recruiterAssingedFrom=${moment(filters["RecruiterAssignedBetween"][0]).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(filters["RecruiterAssignedBetween"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["idGenerationDate"][0] && !filters["idGenerationDate"][1])
            url = `${url}&idGeneratedFrom=${moment(filters["idGenerationDate"][0]).format("YYYY-MM-DD")}&idGeneratedTO=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["RecruiterAssignedBetween"][0] && filters["RecruiterAssignedBetween"][1])
            url = `${url}&idGeneratedFrom=2019-01-01&idGeneratedTO=${moment(filters["idGenerationDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["idGenerationDate"][0] && filters["idGenerationDate"][1])
            url = `${url}&idGeneratedFrom=${moment(filters["idGenerationDate"][0]).format("YYYY-MM-DD")}&idGeneratedTO=${moment(filters["idGenerationDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["interviewDate"][0] && !filters["interviewDate"][1])
            url = `${url}&interviewDateFrom=${moment(filters["interviewDate"][0]).format("YYYY-MM-DD")}&interviewDateTO=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["interviewDate"][0] && filters["interviewDate"][1])
            url = `${url}&interviewDateFrom=2019-01-01&interviewDateTO=${moment(filters["interviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["interviewDate"][0] && filters["interviewDate"][1])
            url = `${url}&interviewDateFrom=${moment(filters["interviewDate"][0]).format("YYYY-MM-DD")}&interviewDateTO=${moment(filters["interviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["leadAddedDate"][0] && !filters["leadAddedDate"][1])
            url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format("YYYY-MM-DD")}&leadAddedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
            url = `${url}&leadAddedFrom=2019-01-01&leadAddedTo=${moment(filters["leadAddedDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
            url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format("YYYY-MM-DD")}&leadAddedTo=${moment(filters["leadAddedDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["firstEarningDate"][0] && !filters["firstEarningDate"][1])
            url = `${url}&firstEarningFrom=${moment(filters["firstEarningDate"][0]).format("YYYY-MM-DD")}&firstEarningTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
            url = `${url}&firstEarningFrom=2019-01-01&firstEarningTo=${moment(filters["firstEarningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
            url = `${url}&firstEarningFrom=${moment(filters["firstEarningDate"][0]).format("YYYY-MM-DD")}&firstEarningTo=${moment(filters["firstEarningDate"][1]).format("YYYY-MM-DD")} 23:59:59`


        if (filters["lastEarning"].id === "last3days")
            url = `${url}&lastEarningFrom=${moment().subtract(2, "days").format("YYYY-MM-DD")}&lastEarningTo=${moment().format("YYYY-MM-DD")} 23:59:59`
        if (filters["lastEarning"].id === "last5days")
            url = `${url}&lastEarningFrom=${moment().subtract(4, "days").format("YYYY-MM-DD")}&lastEarningTo=${moment().format("YYYY-MM-DD")} 23:59:59`
        if (filters["lastEarning"].id === "last7days")
            url = `${url}&lastEarningFrom=${moment().subtract(6, "days").format("YYYY-MM-DD")}&lastEarningTo=${moment().format("YYYY-MM-DD")} 23:59:59`
        return url
    }

    const resetFilters = () => {
        const filterList = {
            source: "",
            searchBy: "",
            zone: "",
            facilityState: "",
            facilityName: "",
            partnerId: "",
            facilityCity: "",
            workFor: "",
            workerStatus: "",
            psf: "",
            callStatus: "",
            interviewStatus: "",
            recruiterId: "",
            trainingUpdate: "",
            preferedHub: "",
            appLead: "",
            interviewDate: [null, null],
            firstEarningDate: [null, null],
            leadAddedDate: [null, null],
            bankDetails: "",
            leadCity: "",
            leadSource: "",
            lastEarning: "",
            appStatus: "",
            clientEarningStatus: "",
            clientStatus: "",
            RecruiterAssignedBetween: [null, null],
            idGenerationDate: [null, null],
            IsLmaStatus: "",
            bgvStatus: "",
            lastAppliedBy: ""

        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    const resetFiltersWithoutSearch = () => {
        const filterList = {
            source: "",
            searchBy: filters.searchBy,
            zone: "",
            facilityState: "",
            facilityName: "",
            partnerId: "",
            facilityCity: "",
            workFor: "",
            workerStatus: "",
            psf: "",
            callStatus: "",
            interviewStatus: "",
            recruiterId: "",
            trainingUpdate: "",
            preferedHub: "",
            appLead: "",
            interviewDate: [null, null],
            firstEarningDate: [null, null],
            leadAddedDate: [null, null],
            bankDetails: "",
            leadCity: "",
            leadSource: "",
            lastEarning: "",
            appStatus: "",
            clientEarningStatus: "",
            clientStatus: "",
            RecruiterAssignedBetween: [null, null],
            idGenerationDate: [null, null],
            IsLmaStatus: "",
            bgvStatus: "",
            lastAppliedBy: ""

        }
        setFilters(filterList)
        setFilterUrl(filters.searchBy ? "&searchBy=" + filters.searchBy : "")
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    const handleSortDirection = () => {
        if (!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }


    const workerDataColumn = useMemo(
        () => [

            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="overrideCheckbox" color="white" />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} user={row} style={{display:"flex", justifyItems:"center"}}/>
                    </div>
                ),
            },
            {
                Header: "Lead id/OkayGo id",
                accessor: "okaygoId",
                defaultCanFilter: false,
                defaultCanSort: false
            },

            {
                Header: "Name",
                accessor: "name",
                Cell: row => {
                    return <Link to={{ pathname: `/workerview/v2/${row.row.original.contactNo_E}` }} target="_blank"  className="nameLink">{row.row.original.name}</Link>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Contact No",
                accessor: "contactNo",
                defaultCanFilter: false,
                defaultCanSort: false
            },
            {
                Header: "Client",
                Cell: row => {
                    // return  <Link to={{ pathname: `/workerview/v2/${row.row.original.userId}` }} target="_blank" className="nameLink">{row.row.original.name}</Link>
                    return (<div>
                        {row.row.original.clients.map(el => {

                            return <div ><b>{el[0]}: </b>{el[1]}</div>
                        })}
                    </div>);
                },

                defaultCanFilter: false,
                defaultCanSort: false
            },
            // {
            //     Header: "Candidate Status",
            //     accessor: "candidateStatus",
            //     defaultCanFilter: false,
            //     defaultCanSort: false
            // },
            {
                Header: "Document Verification Status",
                accessor: "DVStatus",
                Cell: row => {
                    return <Grid container style={{ lineHeight: "30px", display: "flex" }}>
                        <Grid item xs={6} style={{ textAlign: "left" }}>{makeDocumentstatus("Aadhaar Card", row.row.original.aadharStatus)}</Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>{makeDocumentstatus("PAN Card", row.row.original.panStatus)}</Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>{makeDocumentstatus("Driving License", row.row.original.dlStatus)}</Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>{makeDocumentstatus("Current Address", row.row.original.addressStatus)}</Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>{makeDocumentstatus("Photo", row.row.original.profile_pic_status)}</Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}><Link to={{ pathname: `/verificationdetails/${row.row.original.contactNo_E}/${crossFilter.index}/${makeStatus(crossFilter.index)}}}`}} className="nameLink" target="_blank"  >View details {'>'}</Link></Grid>
                    </Grid>
                },
                defaultCanFilter: false,
                defaultCanSort: false
            },

        ],
        [crossFilter]
    );
    const makeDocumentstatus = (doc, status) => {
        switch (status) {
            case 0:
                return <div style={{ display: "flex",alignItems: 'center' }}><div style={{ paddingTop: "4px" }}><img src={NotUploadSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {doc}</div></div>
            case 1:
                return <div style={{ display: "flex" ,alignItems: 'center'}}><div style={{ paddingTop: "4px" }}><img src={VerifiedSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {doc}</div></div>
            case 2:
                return <div style={{ display: "flex" ,alignItems: 'center'}}><div style={{ paddingTop: "4px" }}><img src={FlagSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {doc}</div></div>
            case 3:
                return <div style={{ display: "flex" ,alignItems: 'center'}}><div style={{ paddingTop: "4px" }}><img src={RejectedSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {doc}</div></div>
            case 4:
                return <div style={{ display: "flex" ,alignItems: 'center'}}><div style={{ paddingTop: "4px" }}><img src={PendingSVG} alt=""></img></div><div style={{ marginLeft: "10px" }}> {doc}</div></div>

            default:
                break;
        }
    }
    const handleSendMsg = () => {
        const indexes = Object.entries(selectedRowIds);
        let idList = ""
        indexes.map((id, index) => {
            if (id[1]) {
                // // console.log(data[parseFloat(id[0])].userId)
                idList += data[parseFloat(id[0])].userId + ",";
            }

            // // console.log(idList)
            return true;
        })
        idList = idList.substr(0, idList.length - 1)
        // // console.log(idList)
        setSelectedUserIds(idList)
        setOpenSendMsg(!openSendMsg)
    }


    const createClientStatus = (status) => {
        let text = ""
        switch (status) {
            case 0:
                text = "Offboarded";
                break;
            case 1:
                text = "Active";
                break;
            default:

                break;
        }
        return text;
    }

    const setLeadRecruiterDetail = (recruiterList, type) => {
        // // console.log(recruiterList)
        // // console.log(type)
        // // console.log(recruiterList[recruiterList.length-1]?.callStatus, recruiterList.length-1, type)
        if (recruiterList.length === 0) {
            return ""
        }
        if (type === "client") {
            return recruiterList[recruiterList.length - 1]?.employerName ? recruiterList[recruiterList.length - 1]?.employerName : "";
        }
        if (type === "interviewDate") {
            return `${recruiterList[recruiterList.length - 1]?.interviewDate ? moment(recruiterList[recruiterList.length - 1]?.interviewDate).format("DD/MM/YYYY") : ""}`
        }
        if (type === "trainingStartDate") {
            return `${recruiterList[recruiterList.length - 1]?.trainingStartDate ? moment(recruiterList[recruiterList.length - 1]?.trainingStartDate).format("DD/MM/YYYY") : ""}`
        }
        if (type === "callStatus") {
            return `${recruiterList[recruiterList.length - 1]?.callStatus ? recruiterList[recruiterList.length - 1]?.callStatus : ""}`
        }
        if (type === "interviewStatus") {
            return `${recruiterList[recruiterList.length - 1]?.interviewStatus ? recruiterList[recruiterList.length - 1]?.interviewStatus : ""}`
        }
        if (type === "trainingUpdate") {
            return `${recruiterList[recruiterList.length - 1]?.trainingStatus ? recruiterList[recruiterList.length - 1]?.trainingStatus : ""}`
        }
        if (type === "assignedOn") {
            return `${recruiterList[recruiterList.length - 1]?.insertedOn ? moment(recruiterList[recruiterList.length - 1]?.insertedOn).format("DD/MM/YYYY") : ""}`
        }

        if (type === "lastUpdate") {
            return `${recruiterList[recruiterList.length - 1]?.updatedOn ? moment(recruiterList[recruiterList.length - 1]?.updatedOn).format("DD/MM/YYYY hh:mm a") : ""}`
        }


        if (type === "firstCalledOn") {
            return `${recruiterList[recruiterList.length - 1]?.firstcalled_on ? moment(recruiterList[recruiterList.length - 1]?.firstcalled_on).format("DD/MM/YYYY hh:mm a") : ""}`
        }

        if (type === "lastCalledOn") {
            return `${recruiterList[recruiterList.length - 1]?.lastcalled_on ? moment(recruiterList[recruiterList.length - 1]?.lastcalled_on).format("DD/MM/YYYY hh:mm a") : ""}`
        }
        if (type === "callStatusLead") {
            return `${recruiterList[recruiterList.length - 1]?.callStatus ? recruiterList[recruiterList.length - 1]?.callStatus : ""}`
        }
        // return `${recruiterList[recruiterList.length-1].recruiterAssignedName} ${recruiterList[recruiterList.length-1].recruiterAssignedNumber}`
        return ""
    }
    //Function to create table data
    const makeData = (response) => {
        let extractedData = [];
        let clients = [];
        response.content.map(el => {
            clients = []
            console.log({ el })
            extractedData.push({
                userId: el.userId,
                okaygoId: el.okayGoId !== null ? el.okayGoId : el.leadId !== null ? el.leadId : "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber) || "",
                contactNo_E: el.phoneNumber.replaceAll('/', '%2F').replaceAll('+', '%2B')|| "",
                panStatus: el.panStatus !== null ? el.panStatus : 0,
                aadharStatus: el.aadharStatus !== null ? el.aadharStatus : 0,
                dlStatus: el.dlStatus !== null ? el.dlStatus : 0,
                addressStatus: el.addressStatus !== null ? el.addressStatus : 0,
                profile_pic_status: el.profile_pic_status !== null ? el.profile_pic_status : 0,

                clientWorkerData: el.clientList !== null ? el.clientList.map((cl) => {
                    clients.push([cl.clientName, createWorkerStatus(cl.clientIdStatus)])
                }) : "",
                clients: clients,


            });
            return true;
        });
        return extractedData;
    }
    // const createWorkerStatus = (status) => {
    //     let text = ""
    //     switch (status) {
    //         case 0:
    //             text = "Not Onboarded";
    //             break;
    //         case 1:
    //             text = "Active";
    //             break;
    //         case 2:
    //             text = "Partially Onboarded";
    //             break;
    //         case 3:
    //             text = "Inactive";
    //             break;
    //         case 4:
    //             text = "Offboarding Initiated";
    //             break;
    //         case 5:
    //             text = "Onboarding Completed";
    //             break;
    //         case 6:
    //             text = "Temporarily Inactive";
    //             break;
    //         case 7:
    //             text = "Temporary Inactivation Initiated";
    //             break;
    //         case 8:
    //             text = "Reactivation Initiated";
    //             break;
    //         default:

    //             break;
    //     }
    //     return text;
    // }
    const createWorkerStatus = (status) => {
        let text = ""
        switch (status) {
            case 0:
                text = "Offboarded";
                break;
            case 1:
                text = "Active";
                break;
            default:

                break;
        }
        return text;
    }
    const makeWorkerData = (response) => {
        let extractedData = [];
        let clients = [];

        response.map(el => {

            let clientWorkerData = el.clientList !== null ? el.clientList.map((cl) => {
                clients.push(cl.clientName + " - " + createWorkerStatus(cl.clientIdStatus))
            }) : "";
            // console.log(clients)

            let temp = {
                "LeadId/okaygoId": el.okayGoId !== null ? el.okayGoId : el.leadId !== null ? el.leadId : "",
                name: `${el.firstName ? el.firstName : ""} ${el.lastName ? el.lastName : ""}`,
                contactNo: Decrypt(el.phoneNumber)|| "",
                PanCardStatus: el.panStatus !== null ? makeStatus(el.panStatus) : "Not Filled",
                AadharStatus: el.aadharStatus !== null ? makeStatus(el.aadharStatus) : "Not Filled",
                DLStatus: el.dlStatus !== null ? makeStatus(el.dlStatus) : "Not Filled",
                AddressStatus: el.addressStatus !== null ? makeStatus(el.addressStatus) : "Not Filled",
                Profile_pic_status: el.profile_pic_status !== null ? makeStatus(el.profile_pic_status) : "Not Filled",

                clients: clients.toString(),
            }
            clients = []

            extractedData.push(temp)

            return true;
        });
        return extractedData;
    }
    const makeStatus = (status) => {
        switch (status) {
            case 0:
                return "Not Filled"
            case 1:
                return "Verified"
            case 2:
                return "Flagged"
            case 3:
                return "Rejected"
            case 4:
                return "Pending"
            default:
                break;
        }

    }


    const updateMyData = () => {
        getExternalWorkerList(perPage, currentPage);
    }

    const createAllRecordExcel = (obj) => {
        setAllRecordLoading(true)
        // var filterUrl=obj.url;
        let requestedBy = ""
        let excelExportedBy = localStorage.getItem("userID");
        if (localStorage.getItem("roleType") === "1") {
            requestedBy = `requestedById=${localStorage.getItem("userID")}`
        }

        // console.log(`${urlLinks.baseUrl}${urlLinks.workerUrls.workerDataListingv3}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}&exportExcel=1&sentToAll=${obj.sentToAll?1:0}${tab.leadDataTab ? assignFilterString : crossFilter}&excelExportedBy=${excelExportedBy}`)
        Axios.get(`${urlLinks.baseUrl}${urlLinks.verificationAPIs.listing}?${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${crossFilter.value}${dropFilter.value}&exportExcel=1`)
            .then(response => {
                if (response) {
                    console.log(response.data.response)
                    // swal(response.data.response,"",  "success");
                    setAllRecord(makeWorkerData(response.data.response))
                }
            })

    }
    const handleOpenExportModal = () => {
        setExportModal(true)
        createAllRecordExcel()
    }
    useEffect(() => {
        setAllRecordLoading(false)
    }, [allRecord])
    const getExternalWorkerList = (rows, pageNo) => {
        setLoading(true)
        let requestedBy = ""
        if (localStorage.getItem("roleType") === "1") {
            // // console.log(localStorage)
            requestedBy = `&requestedById=${localStorage.getItem("userID")}`
        }
        if (localStorage.getItem("roleType")) {
            Axios.get(`${urlLinks.baseUrl}${urlLinks.verificationAPIs.listing}?page_no=${pageNo - 1}&rows=${rows}${requestedBy}${filterUrl}${sortUrl}${sortDirectionUrl}${crossFilter.value}${dropFilter.value}`)
                .then(response => {
                    if (response) {
                        // console.log({response})
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if (currentPage > response.data.response.totalPages)
                            setCurrentPage(1)
                        setData(makeData(response.data.response))
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })

        }
    }
    // // console.log("filter--> " + filterUrl)
    useEffect(() => {
        // console.log({tab})
        getExternalWorkerList(perPage, 1);

    }, [filterUrl, sortUrl, sortDirectionUrl, localStorage.getItem("roleType"), assignFilterString, crossFilter, dropFilter])
    // setColumnType()
    // if(filters.all===true){
    //     setColumnType(workerDataColumn)
    // }
    const columns = workerDataColumn
    const handlePageChange = page => {
        getExternalWorkerList(perPage, page)
        setCurrentPage(page);
    };



    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsSelected, state: { selectedRowIds } } = useTable({ columns, data, updateMyData, docListLoader, downloadDocError, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)
    const handlePerRowsChange = (newPerPage) => {
        getExternalWorkerList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const createZipForDocuments = (urls) => {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = "Agreements.zip";
        urls.forEach(async function (url, index) {
            const record = Object.entries(url)
            // // console.log({ record })
            const filename = `${record[0][0]}`;
            const path = `${record[0][1]}`
            try {
                await JSZipUtils.getBinaryContent(path, function (err, data) {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    // // console.log({ zip })
                    if (count === urls.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                            setAgreementDownloading(false)
                        });
                    }

                })
            }
            catch (err) {
                // // console.log(err);
            }
        });
    }
    useEffect(() => {
        if (selectedRowIds) {
            const indexes = Object.values(selectedRowIds);
            // // console.log({indexes})
            let disableButton = true;
            indexes.map(id => {
                if (id) {
                    disableButton = false;
                    return true;
                }
                return true;
            })
            setDisable(disableButton);
            // // console.log({indexes, selectedRowIds });
        }
    }, [selectedRowIds])
    // // console.log(filters)
    const handleEmailAgreements = () => {
        setEmailAgreement(true)
        const indexes = Object.keys(selectedRowIds)
        let userIdList = []
        indexes.map(id => {
            userIdList.push(data[id].userId)
        })
        // // console.log({ userIdList })
        Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.emailAgreements}${userIdList}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Agreements sent to your registered email id")

                }
                else if (response.data.code === 1001) {
                    showToast("error", response.data.message)
                }

                setEmailAgreement(false)
            })
            .catch(error => {
                setEmailAgreement(false)
                showToast("error", error.message)
            })

    }
    useEffect(() => {
        // console.log({ filters })
    }, [filters])
    const handleDownloadAgreements = () => {
        setAgreementDownloading(true)
        const rowIds = Object.keys(selectedRowIds)
        // // console.log({rowIds})
        let agreementUrls = []
        rowIds.map(row => {
            if (data[row].agreementPath) {
                const fileName = `${data[row].contactNo}_${data[row].name}.pdf`
                agreementUrls.push({
                    [fileName]: data[row].agreementPath
                })
            }
        })
        // // console.log({ agreementUrls })
        if (agreementUrls.length > 0) {
            createZipForDocuments(agreementUrls)
        }
        else {
            setAgreementDownloading(false)
            showToast("error", "It will take some time to generate agreement.")
        }

    }
    const loader = <div style={{ margin: "130px auto" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table" style={{ width: "100%" }}>
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameV2 zIndex1080 header" :
                                        column.render('Header') === "Profile Completion" ? "" :// not visible to hrbp and super hrbp
                                            // (column.render('Header') === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" : // only visible to okaygo admin annd super admin
                                            column.id === "selection" ? "stickCheckbox zIndex1080 header" :
                                                column.render('Header') === "Last SMS sent" ? "width200" : "header"}>
                                {/* width200 */}

                                <span style={(column.render('Header') === "addressGoogle" || column.render('Header') === "documentsPending") ? { width: "250px" } : {}}>
                                    {column.render('Header')}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (

                            <tr {...row.getRowProps()} className={`row ${row.original.criminalRecord ? "criminalRecord" : ""}`}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        className={`${cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                            cell.column.Header === "Name" ? "stickWorkerNameV2 zIndex540" :
                                                // (cell.column.Header === "Delivery status" && ((localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "5"))) ? "displayNone" :
                                                // (cell.column.Header === "Documents" && (!(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6"))) ? "displayNone" :
                                                cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"} ${row.original.criminalRecord === "Yes" ? "criminalRecord" : ""}`}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            }
        </table>
    </div>

    return (
        <Styles>
            <Grid container className="padding2percent">
                <Grid item sm={12}><span className="fontSize24 fontWeightBold marginRight20">Document Details Verification </span>
                    {/* <Button
                        //variant="outlined" 
                        onClick={(event) => { if (tab.leadDataTab === true) { handleTab(event, "1") } }}
                        className={`statusButtons marginRight20 ${tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Worker Data
                    </Button>
                    <Button
                        onClick={(event) => { if (tab.workerDataTab === true) { handleTab(event, "2") } }}
                        // variant="outlined"
                        className={`statusButtons marginRight20 ${tab.leadDataTab ? "blueColorBorder" : "greyColorBorder"}`}
                    >
                        Leads data
                    </Button> */}
                </Grid>
                <Grid item xs={12} className="flexDisplay alignItemsFlexStart">
                    <Grid item sm={7} className="flexDisplay padding0 paddingTop8 justifySpaceBetween">
                        <TextField
                            className={`${classes.root} marginLeft0`}
                            placeholder={"Enter Aadhar No./ Contact number / OkayGo ID / Client ID"}
                            fullWidth
                            value={searchBy}
                            onChange={(event) => setSearchBy(event.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className={styles.searchButtonDiv}>
                            <IconButton
                                // className="filterCLoseButton"
                                className={`${styles.closeButton} ${searchBy ? "" : styles.noVisibility}`}
                                onClick={handleSearchClose}
                            >
                                <CloseSharpIcon />
                            </IconButton>
                            <button
                                onClick={handleSearch}
                                className={styles.searchButton}
                            >
                                Search
                            </button>
                        </div>
                    </Grid>
                </Grid>
                <Grid style={{ paddingTop: "8px" }}>
                    <Button
                        variant="contained"
                        className="dataButton"
                        style={{ marginTop: "7px" }}
                        onClick={handleOpenExportModal}
                        disabled={allRecordLoading}
                    >
                        Export to Excel
                    </Button>
                    {allRecordLoading ?
                        <span style={{ marginRight: "5px" }}>Please wait...it might take few minutes</span>
                        : allRecord.length > 0 ?
                            <CSVLink
                                data={allRecord}
                                filename={isLeadData === "" ? `Worker Data.csv` : `Lead Data.csv`}
                                className='btn btn-primary blueColor downloadExcelButton'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    marginRight: "20px",
                                    fontWeight: '500'
                                }}
                            >
                                Download now
                            </CSVLink>
                            : null}

                    {(localStorage.getItem("roleType") === "2" || localStorage.getItem("roleType") === "6") && <Button
                        // style={{ marginRight: '12px' }}
                        variant="contained"
                        className="dataButton"
                        style={{ marginLeft: "16px", marginTop: "7px" }}
                        onClick={() => handleSendMsg()}
                        disabled={disable}
                    >
                        Send Message
                    </Button>}
                </Grid>
                <Grid container item xs={12} className="flexDisplay paddingTop16 justifyFlexStart" >
                    {<Grid item xs={10} >
                        <Button
                            onClick={(event) => { { setCrossFilter({ value: "&verificationStatus=2", index: 2 }) } }}
                            className={`statusButtons marginRight20 ${crossFilter.value === "&verificationStatus=2" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Flagged
                        </Button>
                        <Button
                            onClick={(event) => { { setCrossFilter({ value: "&verificationStatus=4", index: 4 }) } }}
                            className={`statusButtons marginRight20 ${crossFilter.value === "&verificationStatus=4" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Pending
                        </Button>
                        <Button
                            onClick={(event) => { { setCrossFilter({ value: "&verificationStatus=0", index: 0 }) } }}
                            className={`statusButtons marginRight20 ${crossFilter.value === "&verificationStatus=0" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Not filled
                        </Button>
                        <Button
                            onClick={(event) => { { setCrossFilter({ value: "&verificationStatus=1", index: 1 }) } }}
                            className={`statusButtons marginRight20 ${crossFilter.value === "&verificationStatus=1" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Verified
                        </Button>
                        <Button
                            onClick={(event) => { setCrossFilter({ value: "&verificationStatus=3", index: 3 }) }}
                            className={`statusButtons marginRight20 ${crossFilter.value === "&verificationStatus=3" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            Rejected
                        </Button>
                        <Button
                            // style={{ marginLeft: "20px" }}
                            onClick={(event) => { setCrossFilter({ value: "", index: 0 }) }}
                            className={`statusButtons marginRight20 ${crossFilter.value === "" ? "blueColorBorder" : "greyColorBorder"}`}
                        >
                            All
                        </Button>


                    </Grid>
                    }
                    <Grid item xs={2} >
                        <FormControl variant="outlined" fullWidth margin="dense">
                            <Select
                                id="sortBy"
                                IconComponent={ExpandMoreIcon}
                                value={dropFilter.index}
                                // style={{ opacity: sortBy === 0 ? "0.5" : "1" }}
                                onChange={event => {
                                    if (event.target.value === 0) {
                                        setDropFilter({ value: "", index: event.target.value })
                                    }
                                    else if (event.target.value === 1) {
                                        setDropFilter({ value: "&userStatus=1", index: event.target.value })
                                    }
                                    else if (event.target.value === 2) {
                                        setDropFilter({ value: "&userStatus=2", index: event.target.value })
                                    }
                                    else if (event.target.value === 3) {
                                        setDropFilter({ value: "&userStatus=3", index: event.target.value })
                                    }
                                }}
                            // className={classes.select}
                            // inputProps={{
                            //     name: 'Sort by:',
                            //     id: 'sortBy',
                            // }}
                            >
                                <MenuItem key="fitlerByPartner" value={0} disabled={true}>
                                    Filter by Status
                                </MenuItem>
                                <MenuItem key="fitlerByPartner" value={1}>
                                    Workers
                                </MenuItem>
                                <MenuItem key="fitlerByPartner" value={2}>
                                    Leads in process
                                </MenuItem>
                                <MenuItem key="fitlerByPartner" value={3}>
                                    Non applicant
                                </MenuItem>

                                {/* {partner.map(option => (

                                    <MenuItem key={option[0]} value={option[0]} >
                                        {option[1]}
                                    </MenuItem>
                                ))} */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {loading ? loader : data.length === 0 ? <div className={styles.noRecord} ><img src={NoRecord} alt="noRecord" /><span>No records found</span></div> : <Grid item xs={12} className="paddingTop16">
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>}

                {/* {
                    exportModal &&
                    <ExportModal
                        openOffboardingModal={exportModal}
                        setOpenOffboardingModal={setExportModal}
                        filters={filters}
                        setFilterUrl={setFilterUrl}
                        filterUrl={filterUrl}
                        createAllRecordExcel={createAllRecordExcel}
                        updateFilterUrl={updateFilterUrl}
                        setFilters={setFilters}
                    // tab={tab}
                    />
                } */}
                {
                    openSendMsg &&
                    <SendMsg
                        isLeadData={isLeadData}
                        filterUrl={filterUrl}
                        open={openSendMsg}
                        setOpen={setOpenSendMsg}
                        userIds={selectedUserIds}
                    />
                }
            </Grid>
        </Styles>
    )
}

export default WorkerData;


