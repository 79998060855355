import React, {Component, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import {useEffect} from 'react';
import Marker from 'react-google-maps/lib/components/Marker';
import TextField from '@material-ui/core/TextField';
import {Button, Grid} from '@material-ui/core';
import axios from 'axios';
import urlLinks from '../../UrlLinks'
import "./style.css"
import OTPInput, {ResendOTP} from "otp-input-react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import SimpleModal from './model'
import InstructionModal from './InstructionModal'
import icon from './art.svg'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));
export default function CaptureHubLocation() {
    const [otpVerified, setOtpVerified] = useState(false)
    const classes = useStyles();
    const [openBackDrop, setOpenBackDrop] = useState(false)
    const [otpSent, setOtpSent] = useState(false)
    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState("")
    const [disable, setDisable] = useState(true)
    const [disable2, setDisable2] = useState(true)

    const [open, setOpen] = useState(false)
    const [openInstruction, setOpenInstruction] = useState(false)
    const [disable3, setDisable3] = useState(false)
    const [hubName, setHubName] = useState("")
    const [validHubName, setValidHubName] = useState(true)
    const [googleAddress, setGoogleAddress] = useState("")
    const [locDenied, setLocDenied] = useState(false)
    const [showWrongErr, setShowWrongErr] = useState(false)
    const [loc, setLoc] = useState({lat: null, lng: null})
    const handleChange = (event) => {
        setEmail(event.target.value);
        setDisable3(validateEmail(event.target.value))
    };
    const handleHubNameChange = (event) => {
        setValidHubName(true)
        setHubName(event.target.value);
    };
    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };
    const sendOtp = () => {
        setOpenBackDrop(true)
        axios.post(`${
            urlLinks.baseUrl
        }${
            urlLinks.sendOtp
        }?email=${email}
        `).then(response => {
            setOpenBackDrop(false)
            console.log(response)
            setOtpSent(true)
        }).catch(error => {})
    }

    const verifyOtp = () => {
        setOpenBackDrop(true)

        axios.post(`${
            urlLinks.baseUrl
        }${
            urlLinks.verifyOtp
        }?otp=${otp}&email=${
            email
        }`).then(response => {
            setOpenBackDrop(false)
            console.log(response.data.response.content === 1)
            if (response.data.response.content === 1) {
                setOtpVerified(true)

            } else {
                wrongOTP()
            }


        }).catch(error => {})
    }
    const verifyHub = (hubName) => { // setOpenBackDrop(true)

        let flag = true;
        axios.post(`${
            urlLinks.baseUrl
        }${
            urlLinks.verifyHubName
        }?hubName=${hubName}`).then(response => {
            // setOpenBackDrop(false)
            // console.log(response.data.response.content === 1)
            if (response.data.response === "0") {
                setValidHubName(false)

                flag = false;
                console.log(flag)


            } else if (response.data.response === "1") {
                setValidHubName(true)
                flag = true;
            }


        }).catch(error => {})
        console.log(flag)
        return flag;
    }
    function registerAPI(a, b, c) {
        setOpenBackDrop(true)
        axios.post(`${
            urlLinks.baseUrl
        }${
            urlLinks.registerHubLocation
        }?email=${email}&lat=${
            a
        }&lng=${
            b
        }&hubName=${hubName}&googleAddress=${c}`).then(response => {
            setOpenBackDrop(false)
            setOpen(true)
            // setTimeout(function(){ console.log("hey");window.open("about:blank", "_self");
            // window.close(); }, 2000);


        }).catch(error => {})
    }
    // navigator.permissions.query({name: 'geolocation'}).then(res => {
    //     console.log(res)
    // })
    const shareLocation = () => {
        getLocation();


    }
    function getLocation() {
        axios.post(`${
            urlLinks.baseUrl
        }${
            urlLinks.verifyHubName
        }?hubName=${hubName}`).then(response => {
            if (response.data.response === "1") {
                setValidHubName(true)
                navigator.permissions.query({name: 'geolocation'}).then(res => {
                    if (res.state === "denied") {
                        setLocDenied(true)
                        setOpenInstruction(true)

                    } else if (res.state === "granted" || res.state === "prompt") {
                        setLocDenied(false)
                        navigator.geolocation.getCurrentPosition(function (position) {
                            console.log(position.coords)
                            setLoc({lat: position.coords.latitude, lng: position.coords.longitude})
                            delete axios.defaults.headers.common["Authorization"];
                            axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${
                                position.coords.latitude
                            },${
                                position.coords.longitude
                            }&key=AIzaSyDlmisklw9xgm7M1vxwoytQh1qSyccIwG4`).then((response) => {
                                if (response.data !== null) {
                                    if (response.data.results.length > 0) { // wow
                                        setGoogleAddress(response.data.results[0].formatted_address)
                                        registerAPI(position.coords.latitude, position.coords.longitude, response.data.results[0].formatted_address);
                                    }
                                }
                            })
                            axios.defaults.headers.common['Authorization'] = localStorage.getItem("accessToken")

                        })

                    }
                })
            } else {
                setValidHubName(false)
            }
        })


    }
    const wrongOTP = () => {
        setShowWrongErr(true)
        setOtp("")
    }

    useEffect(() => {
        if (otp.length >= 1) 
            setShowWrongErr(false)

        

        if (otp.length === 4) {
            setDisable(false)
        } else {
            setDisable(true)
        }

    }, [otp])
    useEffect(() => {
        if (hubName.length >= 1) 
            setDisable2(false)
         else {
            setDisable2(true)
        }

    }, [hubName])
    useEffect(() => {
        navigator.permissions.query({name: 'geolocation'}).then(res => {
            if (res.state === "denied") {
                setLocDenied(true)
            }
        })

    }, [])

    function validateEmail(email) {
        // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // if (re.test(String(email).toLowerCase()) === true) {
        //     let e=email;
        //     console.log(e.includes("flipkart"))
        //     if(e.includes("flipkart")){return true}else{return false}
            return true

        // }

    }

    return (<div className="paddingTop20">
        <Backdrop className={
                classes.backdrop
            }
            open={openBackDrop}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <div className="header-custom">
            <img alt="icon" className="icon" src={icon}/>
        </div>
        {
        otpSent === false && <div className="box-info">
            <b>INSTRUCTION :</b><br/>
            This form is to capture Flipkart hubs geolocation. For the geolocation to be captured correctly,
            <i>
                <b>please ensure that you are present within the hub when you submit this form.</b>
            </i>
        </div>
    }
        {
        otpVerified === true && <div className="box-info">
            <b>INSTRUCTION :</b><br/>
            <i>
                <b>Allow the browser to capture your current location on click of Share Location button.</b>
            </i>
        </div>
    }
        {
        otpSent === false ? (

                <div className="box">

                    <p className="heading">Please enter your Flipkart Email ID</p>

                    <Grid container direction="row" justify="center" alignItems="center" className="mt-24">
                        {/* <div> */}
                        <p className="label">Email</p>
                        <TextField id="filled-basic" label="" className="width-250"
                            onChange={handleChange}/>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <div className="sndOTP">
                                <Button variant="contained" color="primary"
                                    onClick={sendOtp}
                                    disabled={
                                        !disable3
                                }>
                                    Send OTP
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            ) : otpVerified !== true ? <div className="box">
                    <p className="heading">Please enter the OTP sent on your Flipkart Email ID</p>
                    <Grid container direction="row" justify="center" alignItems="center" className="mt-24">
                        {/* <div> */}
                        <p className="label">OTP</p>
                        {/* <TextField id="filled-basic" label=""
                        style={{width: "250px"}} 
                            onChange={handleOtpChange}/> */}
                        <OTPInput value={otp}
                            onChange={setOtp}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}/>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <div className="sndOTP">
                                <Button variant="contained" color="primary"
                                    disabled={disable}
                                    onClick={verifyOtp}>
                                    Verify OTP
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    {
                    showWrongErr && <p className="errTxt">Incorrect OTP</p>
                } </div> : <div className="box">
                    <p className="heading">Please enter the name of the hub you are currently at.</p>
                    {/* <p className="info"
                            style={
                                {display: "flex"}
                        }>
                            <InfoIcon fontSize="small"></InfoIcon>
                            &nbsp; Allow the browser to capture your current location on click of Share Location button.</p> */}
                    <Grid container direction="row" justify="center" alignItems="center" className="margin-15">
                        {/* <div> */}

                        <p className="label">Hub Name</p>
                        <TextField id="filled-basic" label="" className="width-250"
                            onChange={handleHubNameChange}/>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <div className="sndOTP">
                                <Button variant="contained" color="primary"
                                    disabled={disable2}
                                    onClick={shareLocation}>
                                    Share Location
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    {!validHubName && <p className="errTxt">Please enter valid hub name</p>}
                    {<InstructionModal open={openInstruction}setOpen={setOpenInstruction}></InstructionModal>} 
                    </div>}
        {open&&<SimpleModal open={open} setOpen={setOpen}/>} 
        
                </div>)
    };
