import { Grid } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Styles from '../index.module.scss'
import EditIcon from '../EditIcon.png'
import StatusUpdateModal from './StatusUpdateModal'
const Status =(props) =>{
    const {row, status, updateMyData, showToast } = props
    const [openStatusUpdateModal, setOpenStatusUpdateModal] = useState(false)
    const handleStatusChange =() =>{
        setOpenStatusUpdateModal(true)
    }
    return (
        <Grid container className={Styles.StatusContainer}  >
            <span style={row.original.status==="No update"?{color: "grey"}:{}}>
                {row.original.status}
            </span>
            <button
                onClick={handleStatusChange}
            >
                <img src={EditIcon} alt="EditIcon" />
            </button>
            {setOpenStatusUpdateModal && 
                <StatusUpdateModal
                    openStatusUpdateModal={openStatusUpdateModal}
                    setOpenStatusUpdateModal={setOpenStatusUpdateModal}
                    data={row.original}
                    updateMyData={updateMyData}
                    status={status}
                    showToast={showToast}
                />
            }
        </Grid>
    )
}

export default Status;
