import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ResponseTable from './ResponseTable'
import CancelIcon from '@mui/icons-material/Cancel';

import { IconButton} from '@material-ui/core';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const {open,handleClose,resData} = props;
  const[counts,setCounts]= React.useState({
    assigned:null,
    unAssigned:null
  })
  React.useEffect(()=>{
    let assigned=0,unAssigned=0;

    resData.map(e=>{
      if(e.isAssigned===1){
        assigned++;
      }else{
        unAssigned++;
      }
    })
    setCounts({
      assigned:assigned,
      unAssigned:unAssigned
    })

  },[resData])

  console.log(counts)
  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          // if (reason !== 'backdropClick') {
          handleClose()
          // }
        }}
      >
        <Box sx={style}>
          {/* Hi */}
          {/* {resData.map(e=>{ return e.assignedStatus})} */}
          <h3>Total Assigned :&nbsp;{counts.assigned}  <span> <IconButton onClick={handleClose} style={{left: '400px',padding:'0px', position: "relative"
}}>
        <CancelIcon />
      </IconButton></span></h3>
         
          <h3>Total Unassigned :&nbsp;{counts.unAssigned}</h3>
{counts.unAssigned!==0&&<ResponseTable resData={resData}/>}
        </Box>
      </Modal>
    </div>
  );
}
